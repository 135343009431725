import React,{useState,useEffect} from 'react'
import {Button, Grid, TextField, Typography, IconButton,Collapse, Box} from '@material-ui/core'
import useStyles from '../style'
import CircularProgress from '@material-ui/core/CircularProgress';
import  {useForm} from 'react-hook-form'
import { useHistory } from 'react-router';
import { useSelector,useDispatch } from 'react-redux';
import {getCcc,addCcc,getCccById,updateCcc} from '../../../../../../store/actions/ccc-actions'
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';





const CccCreate=(props)=>{
    
    const classes = useStyles()
    const dispatch  = useDispatch()
    const history  = useHistory()
    const {register,handleSubmit,reset} = useForm()
    const [email, setemail] = useState('')
    const [isCircular, setisCircular] = useState(false)
    const {message} = useSelector((state)=>state.ccc.addedccc)??''
    const [open, setOpen] = React.useState(false);
    const msg = useSelector((state)=>state.msg.message)
    const {cccbyId} = useSelector(state => state.ccc)
    

    const onSubmit=(data,e)=>{
        setOpen(false)
        e.preventDefault()
        if(data){
            if(props.location.data){
                setOpen(false)
                setisCircular(true)
            dispatch(updateCcc(
                props.location.data,
                data.nm,
                data.lo,
                data.desc,
                email
            )).then(()=>{
                dispatch(getCcc())
                history.push(
                    {
                        pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/ccc/detail/${data.nm}=${props.location.data}` ,
                        data: props.location.data
                    }
                )
                sessionStorage.setItem('rowId',props.location.data)
                
                setisCircular(false)
                reset()
            },()=>{
                dispatch(getCcc())
                setisCircular(false)

            })
            }else{
                setisCircular(true)
            dispatch(addCcc(
                data.nm,
                data.lo,
                data.desc,
                email
            )).then(()=>{
                dispatch(getCcc())
                setisCircular(false)
                setOpen(true)
                reset()
            },()=>{
                dispatch(getCcc())
                setisCircular(false)
                setOpen(true)

            })
            }
        }
    }

    const getUser =()=>{
        var email =  sessionStorage.getItem('user')
        setemail(email)
       
      }
      useEffect(() => {
        if(history.action ==='POP'){
            history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/ccc`)
        }
        getUser()
        if(props.location.data){
        dispatch(getCccById(parseInt(props.location.data))).then(()=>{
                reset({
                    nm:cccbyId?.name,
                    lo:cccbyId?.location,
                    desc:cccbyId?.description
                })
        })
    }
        

    }, [])
    return (
     
        <Grid container direction='column' className={classes.root}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container justifyContent='center' alignItems="center">
                    <Grid item><Typography variant='h3' style={{fontWeight:'bold'}} color='primary'>{props.location.data?"Actualiser les détails d'un CCC":'Ajouter un CCC'}</Typography></Grid>
                </Grid>
            </Grid>
            <Box height={40}/>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
            <Collapse in={open}>
                        <Alert
                        severity={msg?'error':'success'}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        >
                        {msg?msg:message}
                        </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.backPaper}>
               <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justify='center' alignItems='center'>
                        <Grid item>
                        <Grid container  direction='row'  className={classes.spacebetween} spacing={4}>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>Nom*</Typography>
                                <TextField
                                 inputProps={{maxLength:25}} helperText='Nom doit avoir 25 caractères' type ='text' {...register('nm',{required:true})} required  variant='outlined'  fullWidth  className={classes.textField} placeholder='Entrez le nom'/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>Localisation*</Typography>
                                <TextField inputProps={{maxLength:50}} helperText='Localisation doit avoir 50 caractères' type ='text' {...register('lo',{required:true})} required  variant='outlined' placeholder='Entrez la localisation'  fullWidth className={classes.textField}/>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.spacebetween} >
                                <Typography>Description</Typography>
                                <TextField inputProps={{maxLength:1000}} helperText='La description doit avoir 1000 caractères' type = 'text' {...register('desc',{required:true})} required  variant='outlined' placeholder='Entrez votre message' multiline rows={6} fullWidth className={classes.textField}/>
                            </Grid>
                        <Grid item className={classes.spacebetween} >
                            <Grid container justify='space-between' spacing={2} >
                                <Grid item xs={12} sm={0} md={0} lg={0} xl={0}>
                                    <Button type='submit'
                                     disabled={isCircular===true?true:false}
                                     fullWidth className={classes.btn}> {isCircular===true?<CircularProgress size={30} 
                                     style={{color:'white'}} />:<Typography>{props.location.data?'Sauvegarder':'Ajouter'}</Typography>}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
               </form>
            </Grid>

        </Grid>
        )
}

export default CccCreate