import React,{useState,useEffect} from 'react';
import {AppBar,CssBaseline,Drawer,Hidden,List,ListItem,ListItemText,Toolbar,ListItemIcon,Grid,IconButton,Avatar,Typography, Menu,MenuItem,Button, Link} from '@material-ui/core'
import BookIcon from '@material-ui/icons/Book';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import PeopleIcon from '@material-ui/icons/People';
import ListIcon from '@material-ui/icons/List';
import MenuIcon from '@material-ui/icons/Menu';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import {withRouter} from 'react-router-dom'
import useStyles from '../pages/style'
import { useHistory } from 'react-router';
import axiosInstance from '../../../service/axiosApi';
import Logo from '../../website/assets/images/Logo.webp'
import { getUsers } from '../../../store/actions/user-actions';
import {getPartenaire} from '../../../store/actions/partenaire-actions'
import {getMembres} from '../../../store/actions/membre-actions'
import {getAgenda} from '../../../store/actions/agenda-actions'
import {getActualite} from '../../../store/actions/actualite-actions'
import { useDispatch } from 'react-redux';
import { getClecPub } from '../../../store/actions/clec-actions';
import { getCccPub } from '../../../store/actions/ccc-actions';
import {allUserApi} from '../../../store/actions/auth-actions'
import {getEspaceMembre} from '../../../store/actions/espace-actions'
import { clearMessage } from '../../../store/actions/message-actions';
import LanguageIcon from '@material-ui/icons/Language';








const Dashboard =(props)=>{
    const { window } = props;
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [email, setemail] = useState('')
    const [id, setid] = useState('')
    const [role, setrole] = useState('')
    const histo  = useHistory()
    const dispatch = useDispatch()
    const { history } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleAction= (event)=>{
      const value= event.target.value

      if(event.target.value =='1'){
          history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/utilisateur/detail/${id}`)
           sessionStorage.setItem('rowId',id)
           setAnchorEl(false)
      }
      else if (event.target.value =='3'){
        return removeAuth()
      }
      else{
        
        //dispatch(userbyIds(`${parseInt(id)}`)) 
        history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/utilisateur/parametre/${id}`)
        sessionStorage.setItem('rowIds',id)
        setAnchorEl(false)
      }
    }
    const removeAuth=()=>{

      try {
        const response =  axiosInstance.post('api/account/blacklist/', {
            refresh: sessionStorage.getItem("refresh")
        });
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('refresh');
        sessionStorage.removeItem('user')
        sessionStorage.removeItem('org')
        sessionStorage.removeItem('id')
        sessionStorage.removeItem('role')
        axiosInstance.defaults.headers['Authorization'] = null;
        dispatch(clearMessage())
        histo.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/login`)
        return response;
    }
    catch (e) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('refresh');
      sessionStorage.removeItem('user')
      sessionStorage.removeItem('org')
      sessionStorage.removeItem('id')
      dispatch(clearMessage())
      histo.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/login`)

    }
  }


  const retourAuSite=()=>{

    try {
      const response =  axiosInstance.post('api/account/blacklist/', {
          refresh: sessionStorage.getItem("refresh")
      });
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('refresh');
      sessionStorage.removeItem('user')
      sessionStorage.removeItem('org')
      sessionStorage.removeItem('id')
      sessionStorage.removeItem('role')
      axiosInstance.defaults.headers['Authorization'] = null;
      // histo.push(`/`)
      dispatch(clearMessage())
      return response;
  }
  catch (e) {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refresh');
    sessionStorage.removeItem('user')
    sessionStorage.removeItem('org')
    sessionStorage.removeItem('id')
    dispatch(clearMessage())

    // histo.push(`/`)

  }
}

    const getUser=()=>{
      const user =sessionStorage.getItem('user')
      const id  = sessionStorage.getItem('id')
      const role = sessionStorage.getItem('role')
      setid(id)
      setemail(user)
      setrole(role)

  
    }

    useEffect(()=>{
      getUser()
      dispatch(getUsers())
      dispatch(getPartenaire())
      dispatch(getMembres())
      dispatch(getAgenda())
      dispatch(getActualite())
      dispatch(getClecPub())
      dispatch (getCccPub())
      dispatch(allUserApi())
      dispatch(getEspaceMembre())
      dispatch(clearMessage())


       
      },[])



      const drawerListMembre= [

          {
          text: 'Espace membres',
          icon: <AcUnitIcon className={classes.icon}/>,
           onClick: () =>{ history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard`);setMobileOpen(false); dispatch(clearMessage())}
      },
      {
        text: 'Membres',
        icon: <PeopleIcon className={classes.icon}/>,
        onClick: () => {history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/membre`);setMobileOpen(false); dispatch(clearMessage())}
    },
    {
      text: 'Utilisateurs',
      icon: <PersonIcon className={classes.icon}/>,
      onClick: () => {
        history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/utilisateur`);
        setMobileOpen(false); dispatch(clearMessage())
        

      }
  },

    {
        text: 'Partenaires',
        icon: <ListIcon className={classes.icon}/>,
        onClick: () => {
          history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/partenaire`);
          setMobileOpen(false); dispatch(clearMessage())
        },
        
    },
        {
            text: 'Agenda',
            icon: <InsertInvitationIcon className={classes.icon}/>,
            onClick: () => {
                history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/agenda`);
                setMobileOpen(false); dispatch(clearMessage())
            }
        },
        {
          text: 'Actualités',
          icon: <BookIcon className={classes.icon}/>,
           onClick: () => {history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/actualite`);setMobileOpen(false); dispatch(clearMessage())}
      },
    ]
    const drawerList= [

      {
      text: 'Espace membres',
      icon: <AcUnitIcon className={classes.icon}/>,
       onClick: () =>{ history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard`);setMobileOpen(false); dispatch(clearMessage())}
  },
  {
    text: 'Membres',
    icon: <PeopleIcon className={classes.icon}/>,
    onClick: () => {history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/membre`);setMobileOpen(false); dispatch(clearMessage())}
},
{
  text: 'Utilisateurs',
  icon: <PersonIcon className={classes.icon}/>,
  onClick: () => {
    history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/utilisateur`);
    setMobileOpen(false); dispatch(clearMessage())
    

  }
},

{
    text: 'Partenaires',
    icon: <ListIcon className={classes.icon}/>,
    onClick: () => {
      history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/partenaire`);
      setMobileOpen(false); dispatch(clearMessage())
    },
    
},
    {
        text: 'Agenda',
        icon: <InsertInvitationIcon className={classes.icon}/>,
        onClick: () => {
            history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/agenda`);
            setMobileOpen(false); dispatch(clearMessage())
        }
    },
    {
      text: 'Actualités',
      icon: <BookIcon className={classes.icon}/>,
       onClick: () => {history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/actualite`);setMobileOpen(false); dispatch(clearMessage())}
  },
  {
    text:  'CLEC',
    icon: <AccessibilityNewIcon className={classes.icon}/>,
     onClick: () => {history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/clec`);setMobileOpen(false); dispatch(clearMessage())}
  },
  {
      text: 'CCC',
      icon: <LoyaltyIcon className={classes.icon}/>,
      onClick: () => {history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/ccc`);setMobileOpen(false); dispatch(clearMessage())}
  },    
  

]
    const drawer = (
        <div>

          <Grid container direction='column' justify='center' alignItems='center' >
              <Grid item sm={12} xs={12} className={classes.margin_Bottom_30}>
                    <img src={Logo} className={classes.logoLogin} alt="cec-fpi"/>
            </Grid>
              
             
          </Grid>
          <List>
          {
            role>= 4?drawerListMembre.map((item, index) => {
              const { text, icon, onClick } = item;
              return (
                <ListItem button key={text} onClick={onClick}>
                  {icon && <ListItemIcon>{icon}</ListItemIcon>}
                  <ListItemText className={classes.title} primary={text} />
                </ListItem>
              );
            }):drawerList.map((item, index) => {
              const { text, icon, onClick } = item;
              return (
                <ListItem button key={text} onClick={onClick}>
                  {icon && <ListItemIcon>{icon}</ListItemIcon>}
                  <ListItemText className={classes.title} primary={text} />
                </ListItem>
              );
            })
          }
          {/* {drawerList.map((item, index) => {
              const { text, icon, onClick } = item;
              return (
                <ListItem button key={text} onClick={onClick}>
                  {icon && <ListItemIcon>{icon}</ListItemIcon>}
                  <ListItemText className={classes.title} primary={text} />
                </ListItem>
              );
            })} */}
          </List>
          <Grid container direction='row' justify='center' className={classes.margin_100} spacing={1}>
            <Grid item>
             <Link
             style={{textDecoration:'none'}}
            
            href='/'
            onClick={()=>{
              retourAuSite()
            }}
            > <LanguageIcon style={{fontSize:12, marginRight:2}}
            />  Retour au site </Link>
              
              </Grid>

          </Grid>
          
          
        </div>
      );
    
      const container = window !== undefined ? () => window().document.body : undefined;
    
      return (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
            <IconButton
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
            >
                <MenuIcon style={{color:'white'}}/>
            </IconButton>
            <Typography className={classes.title1} variant="h6" noWrap>
                CEC-FPI
            </Typography>
            
            <div className={classes.grow} />
            <Typography className={classes.title1} variant="h6" noWrap>
               {email}
            </Typography>
                <Button onClick={handleClick}><Avatar  className={classes.avatarSize}><b>{email.toUpperCase().substr(0,2)}</b></Avatar> </Button>         
                <Menu
                  id="simple-menu"
                   anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
              >
                  <MenuItem onClick={handleAction} value='1'>Profil</MenuItem>
                  {role<=5?<MenuItem onClick={handleAction} value='2'>Paramètre</MenuItem>:null}
                  <MenuItem style={{color:'red'}} onClick={handleAction} value='3'>Se déconnecter</MenuItem>

              </Menu>
            </Toolbar>
      </AppBar>
          <nav className={classes.drawer} aria-label="Cec-fpi" >
            <Hidden mdUp implementation="css">
              <Drawer
                elevation={0}
                container={container}
                variant="temporary"
              
                anchor='left'
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
              <Drawer
                // style={{border:'none',borderColor:'transparent'}}
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
         <main className={classes.content}>
    
    
              {props.children}
    
          </main>
        </div>
      );
}

export default withRouter(Dashboard)