import axios from "axios";
import {config} from '../config/config'

const baseURL = config.url.API_URL;


const axiosInstance = axios.create({
	baseURL: baseURL,
	timeout: 100000,
	headers: {
		'Content-Type': 'application/json',
		accept: 'application/json',
	}, 
});


export default axiosInstance;