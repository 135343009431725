import {
    FETCH_ACTUALITE_SUCCES,
    FETCH_ACTUALITE_FAIL,
    DELETE_ACTUALITE_SUCCES,
    DELETE_ACTUALITE_FAIL,
    ADD_ACTUALITE_FAIL,
    ADD_ACTUALITE_SUCCES,
    UPDATE_ACTUALITE_SUCCES,
    UPDATE_ACTUALITE_FAIL,
    FETCH_ACTUALITE_BY_ID_SUCCES,
    FETCH_ACTUALITE_BY_ID_FAIL,
    CLEAR_DATA,
    UPDATE_APPROVAL_ACTUALITE_SUCCESS,
    UPDATE_APPROVAL_ACTUALITE_FAIL
} from '../contants/type'



const actualite =(state=[],action)=>{
    const {type,payload} = action

    switch(type){
        case FETCH_ACTUALITE_SUCCES: 
            return {
                ...state,
                actualite:payload
            }
        case FETCH_ACTUALITE_FAIL:
            return {
                ...state
            }
        case DELETE_ACTUALITE_SUCCES:
            return {
                ...state,
                deleteactualite:"L'actualité a été supprimée"
            }
        case DELETE_ACTUALITE_FAIL:
            return {
                ...state,
                deleteactualite:"L'actualité n'a pas été supprimer, veuillez verifier votre connexion"
            }
        case ADD_ACTUALITE_SUCCES:
            return {
                ...state,
                addedactualite: payload
            }
        case ADD_ACTUALITE_FAIL: 
            return{
                ...state,
                addedactualite:"L'actualité n'a pas été ajouter, veuillez verifier votre connexion"
            }
        case UPDATE_ACTUALITE_SUCCES:
            return {
                ...state,
                updatedactualite:payload
            }
        case UPDATE_ACTUALITE_FAIL:
            return {
                ...state,
                updatedactualite:"L'actualité n'a pas été modifier, veuillez verifier votre connexion"

            }
        case FETCH_ACTUALITE_BY_ID_SUCCES:
                return {
                    ...state,
                    actualitebyId:payload
            }
        case FETCH_ACTUALITE_BY_ID_FAIL:
            return {
                ...state,
                actualitebyId:"L'actualité n'a pas été retrouver, veuillez verifier votre connexion"
            }
        
        
        case UPDATE_APPROVAL_ACTUALITE_SUCCESS:
                return {
                    ...state,
                    approval:payload
            }
        case UPDATE_APPROVAL_ACTUALITE_FAIL:
            return {
                ...state,
                approval:"L'actualité n'a pas été éffectué, veuillez verifier votre connexion"
            }
        case CLEAR_DATA:
            return {}
        
        
        default:
            return state
    }


}


const initialStatePub = {
    actualite:[]
}
const actualitePub =(state=initialStatePub,action)=>{
    const {type,payload} = action

    switch(type){
        case FETCH_ACTUALITE_SUCCES: 
            return {
                ...state,
                actualite:payload
            }
        case FETCH_ACTUALITE_FAIL:
            return {
                ...state
            }
        case FETCH_ACTUALITE_BY_ID_SUCCES:
                return {
                    ...state,
                    actualitebyId:payload
            }
        case FETCH_ACTUALITE_BY_ID_FAIL:
            return {
                ...state,
            }
    
        case CLEAR_DATA:
            return {}
        
        default:
            return state
    }


}

const actualiteReducer= {
    actualite,
    actualitePub
}

export default actualiteReducer