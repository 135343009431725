import React,{useState}  from  'react'
import {Button,Grid,TextField,Typography,IconButton,Collapse,} from '@material-ui/core'
import  {useForm} from 'react-hook-form'
import { sendEmailCode } from '../../../store/actions/auth-actions'
import { useSelector,useDispatch } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';




const ChangePassword = (props)=>{

    
    const [cfirm, setcfirm] = useState(false)
    const [ermsg, setermsg] = useState('Les mots de passe doivent correspondre')
    const [ison, setison] = useState(false)
    const [open, setOpen] = React.useState(false);
    const msg = useSelector(state => state.msg.message)

    const { register,watch, handleSubmit,reset} = useForm();
    const dispatch = useDispatch()
    const onSubmit=(data,e)=>{
        e.preventDefault()
        if(data!=null){
            setison(true)
            setOpen(false)
            sessionStorage.setItem('data',data.pass)
            dispatch(sendEmailCode(sessionStorage.getItem('email'))).then(()=>{
                setison(false)
                setOpen(true)
                reset()
                props.handClick()
            },()=>{
                setison(false)
                setOpen(true)
            })
           
        }
    }
  
        

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
        <Grid Container direction='column' justifyContent='center' spacing={3} >   
        <Grid item >
                     <Collapse in={open}>
                        <Alert
                        severity={'error'}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        >
                        { msg}
                        </Alert>
                    </Collapse>
                         </Grid> 
            <Grid item  >
            <Typography>Nouveau mot de passe</Typography>
            <TextField 
                error={cfirm==true?true:false}
                type='password'
                required
                variant='outlined' 
                fullWidth 
                size='small'
                inputProps={{minLength:8,maxLength:20}} 
                {...register('pass',{required:true,})}
                helperText='Mot de passe doit avoir au moins 8 caractères'
            />
            </Grid>
            <br/>
            <Grid item  >
            <Typography>Confirmer le mot de passe</Typography>
            <TextField 
                error={cfirm==true?true:false}
                type='password'
                required
                variant='outlined' 
                inputProps={{minLength:8,maxLength:20}} 
                fullWidth 
                size='small'
                {...register('confirm',{validate: value =>{
                    const a = value === watch('pass')  || "Les mots de passe doivent correspondre"
                      
                      if(a=="Les mots de passe doivent correspondre"){
                          setcfirm(true)
                          setermsg("Les mots de passe doivent correspondre")
                      }
                      return a
                  }
                     })}
                helperText={ermsg}
                
            />
            </Grid>
            <br/>
            <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container justifyContent='flex-end'>
                    <Grid item>
                       <Button type='submit' color='primary'  variant='outlined' disabled={ison===true?true:false}  >{ison===true?(<CircularProgress size={30}/>):'Suivant'}</Button>      
                    </Grid>
                </Grid>
            </Grid>
            
        </Grid>
    </form>
     )
}

export default ChangePassword