import {
    SET_MESSAGE,
    FETCH_PARTENAIRE_SUCCES,
    FETCH_PARTENAIRE_FAIL,
    DELETE_PARTENAIRE_SUCCES,
    DELETE_PARTENAIRE_FAIL,
    ADD_PARTENAIRE_SUCCES,
    ADD_PARTENAIRE_FAIL,
    FETCH_PARTENAIRE_BY_ID_SUCCES,
    FETCH_PARTENAIRE_BY_ID_FAIL,
    UPDATE_PARTENAIRE_BY_ID_SUCCES,
    UPDATE_PARTENAIRE_BY_ID_FAIL,
    CLEAR_DATA
} from '../contants/type'
import PartenaireService from '../../service/partenaire'


export const getPartenaire = ()=>dispatch=>{
    return PartenaireService.getPartenaire().then((data)=>{
        dispatch({
            type:FETCH_PARTENAIRE_SUCCES,
            payload:data
        })
        return Promise.resolve()
    },(error)=>{
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
          type:FETCH_PARTENAIRE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
    })
}

export const getbyIdPartenaire = (id)=>dispatch=>{
  return PartenaireService.getbyIdPartenaire(id).then((data)=>{
      dispatch({
          type:FETCH_PARTENAIRE_BY_ID_SUCCES,
          payload:data
      })
      return Promise.resolve()
  },(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:FETCH_PARTENAIRE_BY_ID_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
  })
}

export const  addPartenaire =(
    name,
    logo,
    localisation,
    email,
    description,
    link,
    create_by,
    modification
)=>dispatch=>{
    return PartenaireService.addPartenaire(
        name,
        logo,
        localisation,
        email,
        description,
        link,
        create_by,
        modification
    ).then((data)=>{
        dispatch({
            type :ADD_PARTENAIRE_SUCCES,
            payload:data
        })
        return Promise.resolve()
    } ,(error)=>{
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
          type:ADD_PARTENAIRE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
    })
}


export const  updatePartenaire =(
  id,
  name,
  logo,
  localisation,
  email,
  description,
  link,
  create_by,
)=>dispatch=>{
  return PartenaireService.updatePartenaire(
      id,
      name,
      logo,
      localisation,
      email,
      description,
      link,
      create_by,
  ).then((data)=>{
      dispatch({
          type :UPDATE_PARTENAIRE_BY_ID_SUCCES,
          payload:data
      })
      return Promise.resolve()
  } ,(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:UPDATE_PARTENAIRE_BY_ID_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
  })
}

export const  updatePartenaireWithoutlogo =(
  id,
  name,
  localisation,
  email,
  description,
  link,
  create_by,
)=>dispatch=>{
  return PartenaireService.updatePartenaireWithoutLogo(
      id,
      name,
      localisation,
      email,
      description,
      link,
      create_by,
  ).then((data)=>{
      dispatch({
          type :UPDATE_PARTENAIRE_BY_ID_SUCCES,
          payload:data
      })
      return Promise.resolve()
  } ,(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:UPDATE_PARTENAIRE_BY_ID_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
  })
}


export const deletePartenaire =(id)=>dispatch=>{
    return PartenaireService.deletePartenaire(id).then((data)=>{
        dispatch({
            type:DELETE_PARTENAIRE_SUCCES,
            payload:id
        })
        return Promise.resolve()

    },(error)=>{
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
          type:DELETE_PARTENAIRE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
    })
}


export const getPartenairePub = ()=>dispatch=>{
  return PartenaireService.paternerPub().then((data)=>{
      dispatch({
          type:FETCH_PARTENAIRE_SUCCES,
          payload:data
      })
      return Promise.resolve()
  },(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:FETCH_PARTENAIRE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
  })
}

export const getPartenaireByIdPub = ()=>dispatch=>{
  return PartenaireService.paternerByIdPub().then((data)=>{
      dispatch({
          type:FETCH_PARTENAIRE_BY_ID_SUCCES,
          payload:data
      })
      return Promise.resolve()
  },(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:FETCH_PARTENAIRE_BY_ID_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
  })
}

export const removeData = () => {
  return {
    type:CLEAR_DATA,
  };
};
