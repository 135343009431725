import React,{useState,useEffect} from  'react'
import {CircularProgress, Grid, Typography, Box ,Collapse,IconButton} from '@material-ui/core'
import Backpaper from '../../../../sc/backPaper';
import EditIcon from '@material-ui/icons/Edit';
import {BtnOrange,BtmRed} from '../../../../sc/utilities'
import useStyles from '../style'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogBox from '../../../../sc/dialog';
import Skeleton from '@material-ui/lab/Skeleton';
import { useDispatch,useSelector } from 'react-redux';
import { getAgenda,deleteAgenda,getAgendaById } from '../../../../../../store/actions/agenda-actions';
import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';


const AgendaDetail =()=>{
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const history  = useHistory()
    const dispatch = useDispatch()
    const [isDel, setisDel] = useState(false)
    const [opens, setOpens] = React.useState(false);
    const  agendabyId= useSelector((state)=>state.agenda.agendabyId)
    const delAgenda=useSelector((state)=>state.agenda.deleteAgenda)
    const [currentUser, setcurrentUser] = useState('')

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        setOpen(false);
      };
    const handleClickOpen = () => {
       setOpen(true);
     };
  
    const handleDelete =()=>{
        handleClickOpen()
    }
    const handleConfirmDelete = (id)=>{
        setisDel(true)
        dispatch(deleteAgenda(id)).then(()=>{
            
             dispatch(getAgenda())
             setisDel(false)
              history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/agenda`)  
        },()=>{
            setOpens(true)
            setisDel(false)
            handleClose()
        })
    }
    const handleModifier = ()=>{
        history.push({
                pathname: `/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/agenda/create`,
                data: `${sessionStorage.getItem('rowId')}`
        })
    }

    const  getUserRole =()=>{
        const role  = sessionStorage.getItem('role')
        setcurrentUser(role)
    }

    useEffect(() => {
       dispatch(getAgendaById(`${parseInt(sessionStorage.getItem('rowId'))}`))
       getUserRole()
    }, [])
    return (
       <Backpaper
            yellowbtn = {currentUser<=2?<BtnOrange onClick={handleModifier}  btnYellowTitle="MODIFIER" startIcon={<EditIcon />} />:null}
            redbtn ={currentUser<=2?<BtmRed onClick={handleDelete}/>:null}

           content={

            <Grid container  spacing={3} style={{paddingBottom:50}}>
            <Grid item>
             <Box height={20}/>
                 <Collapse in={opens}>
                     <Alert
                 severity={'error'}
                     action={
                         <IconButton
                         aria-label="close"
                         color="inherit"
                         size="small"
                         onClick={() => {
                             setOpens(false);
                         }}
                         >
                         <CloseIcon fontSize="inherit" />
                         </IconButton>
                     }
                     >
                     { delAgenda}
                     </Alert>
                 </Collapse>
                 <Box height={20}/>
             </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> {
                agendabyId?<Typography style={{fontWeight:'bold'}}>{agendabyId.title}</Typography>:
                <Skeleton  variant="rect" height={10}  width={100}/>

            }</Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> 
                {
                    agendabyId? <Grid container direction='row' justify='flex-start' spacing={2}>
                    <Grid item><Typography style={{color:'#3F74A4'}}>{agendabyId.date},</Typography></Grid>
                    <Grid item><Typography style={{color:'#3F74A4'}}>de</Typography></Grid>
                    <Grid item><Typography style={{color:'#3F74A4'}}>{agendabyId.start_time}</Typography></Grid>
                    <Grid item><Typography style={{color:'#3F74A4'}}>jusqu'á</Typography></Grid>

                    <Grid item><Typography style={{color:'#3F74A4'}}>{agendabyId.end_time}</Typography></Grid>
                </Grid>: <Grid container direction='row' justify='flex-start' spacing={2}>
                     <Grid item>
                         <Skeleton  variant="rect" height={10}  width={100}/>
                     </Grid>
                     <Grid item>
                         <Skeleton  variant="rect" height={10}  width={100}/>
                     </Grid>
                     <Grid item>
                         <Skeleton  variant="rect" height={10}  width={100}/>
                     </Grid>
                 </Grid>
                }
            </Grid>  
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> {agendabyId?<Typography>{agendabyId.description}</Typography>:
                         <Skeleton  variant="rect" height={400}  />
                     }
            </Grid>
         
          
            < DialogBox
                 fullScreen={fullScreen}
                 open={open}
                 onClose={handleClose}
                 Oui={isDel?(<CircularProgress size={10}/>):'Oui'}
                 title= {agendabyId?`${agendabyId.title}`:null}
                 confirnClick={()=>handleConfirmDelete(agendabyId.id)}
                 nonOnclick={handleClose}
                 content={`Etês-vous sûr de vouloir supprimer cet agenda ?`}/>

      </Grid>
          
           }
                 
            
            
       />
    );
}

export default AgendaDetail



