import React,{useState,useRef} from 'react';
import { makeStyles } from '@material-ui/styles';
import {  Grid, Typography} from '@material-ui/core'
import MoreLink from '../../../widgets/moreLink'
import AnimatedNumber from "animated-number-react";
import { useSelector,useDispatch } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import {getMembrePub} from '../../../../../store/actions/membre-actions'
import { getClecPub } from '../../../../../store/actions/clec-actions';
import { getCccPub } from '../../../../../store/actions/ccc-actions';


const useStyle = makeStyles((theme) => ({

    root:{
        padding: "6em 0vw",
        '& p': {
            textAlign:theme.typography.textBodyAlign
          },
        "& h2":{
            fontWeight:theme.typography.fontWeightBold,
            color:"#FFAE02",
            [theme.breakpoints.down('md')]:{
                fontSize:"5vw",
            },
            [theme.breakpoints.up('md')]:{
                fontSize:"3.8rem",
            },
            lineHeight:0.8
        },
    },
    marginBottom: {
      marginBottom:'2em'  
    },
    section:{
     margin:"3em 0vw"
    },
    stats:{
        fontWeight:theme.typography.fontWeightBold,
        fontSize:"1rem",
    },
    statCell:{
        textAlign:"center",
        "& h2":{
            margin:"0.5em 0vw"
        }
    },
    card:{
        height:200,
        padding: 15,
        [theme.breakpoints.down('sm')]:{
            height:150,
            border: '1px solid #97979720'
        },
        borderBottom:0,
        borderTop:'1px solid #97979720',
        borderRight:'1px solid #97979720',
        borderLeft:'1px solid #97979720'
        
        
    },
    display:{
        [theme.breakpoints.down('sm')]:{
            paddingTop:15,
            paddingBottom:15
        }
       
    },
    typo:{
        [theme.breakpoints.only('sm')]:{
            fontWeight:400,
            fontSize:20
        }
    },

   

}))




const Section = () => {

    const classes = useStyle();
    const {membres} = useSelector(state => state.membrePub)
    const {clec} = useSelector(state => state.clecPub)
    const {ccc} = useSelector(state => state.cccPub)
    const clecs = useRef([])
    clecs.current =clec
    const cccs = useRef([])
    cccs.current = ccc
    const membre = useRef([])

    membre.current = membres??[]

    const dispatch = useDispatch()
    const [state, setstate] = useState(false)
    const [spin, setspin] = useState(false)
    const [stateclec, setstateclec] = useState(false)
    const [spinclec, setspinclec] = useState(false)
    const [stateccc, setstateccc] = useState(false)
    const [spinccc, setspinccc] = useState(false)



    const skeletton = (state,value,spin)=>{

        if(state===false && stateccc===false && stateclec ===false){
          if(spin ===true && spinclec===true && setspinclec===true){
            return (
                    <Skeleton variant='circle' width={30} height={30} style={{marginBottom:20,marginTop:10}}/>
            )
          }else{
            setTimeout(()=>{
            setspin(true)
             dispatch(getMembrePub()).then(()=>{
                 setstate(true)
                 setspin(false)
             },()=>{
                setspin(true)
                setstate(false)
             })
             dispatch(getCccPub()).then(()=>{
                setstateccc(true)
                setspinccc(false)
            },()=>{
               setspinccc(true)
               setstateccc(false)
            })
            dispatch(getClecPub()).then(()=>{
                setstateclec(true)
                setspinclec(false)
            },()=>{
               setspinclec(true)
               setstateccc(false)
            })
            },500 )
            return (
            
           <Skeleton variant='circle' width={30} height={30} style={{marginBottom:20,marginTop:10}}/>
           )
          }
        }
        else if(state===true){
          return (
            
                <AnimatedNumber
                    value={value}
                    formatValue={v=>v.toFixed(0)}
                    duration={4000}
                    frameStyle={perc =>({
                    opacity:perc/100
                    })}
                />
           
          )
        }
      }
    
    const showTable = [
        {
            h2:4,
            caption1:"Pôles",
            caption2:"L'offre, La demande, L'insertion et La régulation."
        },
        {
             h2:clecs.current.length,
            caption1:"CLEC",
            caption2:"Cadres Locaux d'Echange et de Concertation"
        },
        {
            h2:cccs.current.length,
            caption1:"CCC",
            caption2:"Comité Collégial de Coordination (9 membres) pilotent le CEC-FPI"
        },
        {
            h2:membre.current.length,
            caption1:"Membres",
            caption2:null
        },
        
]
    const displayStat = (list) => (
        list.map((item , i) => {
            const {h2} = item
            return(
                (

                    <Grid item key = {i} xs = {12} sm={6}  md={3} lg = {3}  xl={3} className={classes.display} >
                    <Grid  className={classes.card}>
                    <Grid container direction="column" justifyContent='center' className={classes.statCell} alignItems='center'>
                        <Grid item>
                        <Typography align='center' variant="h2">
                           {skeletton(state,h2,spin)}
                        </Typography>
                        </Grid>
                        <Grid item>
                        <Typography variant="caption" className={classes.stats}>
                            {item.caption1}
                        </Typography>
                        </Grid>
                        <Grid item>
                        <Typography variant="caption">
                            {item.caption2}
                        </Typography>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
               
            )
            )
        }
        )
    )
    return (
        <Grid container justifyContent="center">
            <Grid item  className={classes.root} xs = {10} >
                <Grid container direction='row' spacing={3}>
                    <Grid item xs={12} sm={5} md={4} lg={4} xl={4} >
                        <Typography variant="h2"  >
                            Qui sommes nous?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={7}  md={8} lg={8} xl={8}>
                        <Typography variant="body1" className ={classes.marginBottom}>
                            Le Cadre d’Echange et de Concertation des acteurs clés du système de la Formation Professionnelle et l’Insertion au Sud-Kivu, CEC-FPI/SK en sigle, est une grande synergie regroupant les acteurs clés qui interagissent dans le développement du système de la formation professionnelle et de l’insertion régit par l’arrêté provincial n°19/054/GP/SK du 11/11/2019 dont les acteurs clés sont repartis en quatre pôles à savoir : l’Offre, la Demande, l’Insertion et la Régulation. 
                        </Typography>
                        <MoreLink title="En savoir plus" color="#FFAE02" to = "/Sommes_nous"/>
                    </Grid>
                </Grid>
                <Grid container className={classes.section}>
                    <Grid item xs={12} sm={12}  md={12} lg={12} xl={12}>
                        <Grid container  >
                            {displayStat(showTable)}
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    );
};

export default Section;