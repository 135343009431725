import {
    
    FETCH_CCC_SUCCES,
    FETCH_CCC_FAIL,
    DELETE_CCC_SUCCES,
    DELETE_CCC_FAIL,
    ADD_CCC_FAIL,
    ADD_CCC_SUCCES,
    UPDATE_CCC_SUCCES,
    UPDATE_CCC_FAIL,
    FETCH_CCC_BY_ID_SUCCES,
    FETCH_CCC_BY_ID_FAIL,
    CLEAR_DATA

} from '../contants/type'


const initialState = {
    ccc:[]
}
const ccc =(state=initialState,action)=>{
    const {type,payload} = action

    switch(type){
        case FETCH_CCC_SUCCES: 
            return {
                ...state,
                ccc:payload
            }
        case FETCH_CCC_FAIL:
            return {
                ...state
            }
        case DELETE_CCC_SUCCES:
            return {
                ...state,
                deleteccc:"Ccc a été bien supprimer"
            }
        case DELETE_CCC_FAIL:
            return {
                ...state,
                deleteccc:" n' a pas été bien supprimer, veuillez verifier votre connexion"

            }
        case ADD_CCC_SUCCES:
            return {
                ...state,
                addedccc: payload
            }
        case ADD_CCC_FAIL: 
            return{
                ...state
            }
        case UPDATE_CCC_SUCCES:
            return {
                ...state,
                updatedccc:payload
            }
        case UPDATE_CCC_FAIL:
            return {
                ...state
            }
        case FETCH_CCC_BY_ID_FAIL:
            return {
                ...state
            }
        case FETCH_CCC_BY_ID_SUCCES:
            return {
                ...state,
                cccbyId:payload
            }
        case CLEAR_DATA:
            return {}
        
        default:
            return state
    }


}

const initialStatePub = {
    ccc:[]
}
const cccPub =(state=initialStatePub,action)=>{
    const {type,payload} = action

    switch(type){
        case FETCH_CCC_SUCCES: 
            return {
                ...state,
                ccc:payload
            }
        case FETCH_CCC_FAIL:
            return {
                ...state
            }
        case FETCH_CCC_BY_ID_FAIL:
            return {
                ...state
            }
        case FETCH_CCC_BY_ID_SUCCES:
            return {
                ...state,
                cccbyId:payload
            }
        case CLEAR_DATA:
            return {}
        
        default:
            return state
    }


}

const cccReducer= {
    ccc ,
    cccPub
}

export default cccReducer