import React,{useState,useEffect} from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Grid,CardActionArea,CardActions,CardContent,CardMedia,Button,} from '@material-ui/core'
import ShareIcon from '@material-ui/icons/ShareOutlined';
import IconButton from '@material-ui/core/IconButton';
import nullImage from '../images/Image.webp'
import ReactHtmlParser from 'react-html-parser';


const useStyles = makeStyles((theme) => ({
    root: {
      margin:"1em 0em",
      alignSelf: "center",
      "& svg": {
        color: "#0000008a",
        fontSize: 19
      },
      "& img":{
        borderRadius:'0.5vw'
      },
      [theme.breakpoints.down("sm")]:{
        justifyContent:"center"
      },
      [theme.breakpoints.up("sm")]:{
  
      }
    },
    title:{
      margin:"0.65em 0em",
      fontSize:"1.3rem"
    },
    media: {
      height: 260,
      [theme.breakpoints.only('sm')]:{
        height:160
      },
      [theme.breakpoints.down('xs')]:{
        height:120
      }
    },
    card:{
      padding:"0em"
    },
    link: {
      color: "#3F74A4",
    },
  
    img:{
      width:"100%",
       height:"100%",
       [theme.breakpoints.down('xs')]:{
        width:"100%",
        height:"100%",
       }
    },
    btnyellow:{
      fontSize:10,
      backgroundColor:'#FFAE02',
      border:'1px solid #FFAE02' ,
      '&:hover':{
          backgroundColor:'#FFAE02',
      },
      [theme.breakpoints.down('xs')]:{
          fontSize:10
          
      }
  },
  btnred:{
    fontSize:10,
    backgroundColor:'#C10100',
    border:'1px solid #C10100' ,
    color:'white',
    '&:hover':{
        backgroundColor:'#C10100',
    },

    [theme.breakpoints.down('xs')]:{
        fontSize:8

    }
      
},

    
  
  }));

  
const News =({onClick,handleModifie,handledelete,desc,title,publishedDate,image})=>{
  const classes = useStyles();
  const [currentUser, setcurrentUser] = useState('')


  const  getUserRole =()=>{
    const role  = sessionStorage.getItem('role')
    setcurrentUser(role)
}

  useEffect(() => {
   
   getUserRole()


}, [])


    return (
      <Grid container className={classes.root}>
        <Grid item xs = {12}>
          <CardActionArea onClick = {onClick}>
            <CardMedia
              className={classes.media}
              image="import image here"
              title="news title">
              <img alt="News" src={image ? image : nullImage} className={classes.img} />              
            </CardMedia>
            <CardContent className={classes.card}>
              <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                {title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {ReactHtmlParser(`${desc.substr(0,70)}`)}
              </Typography>
            </CardContent>
          </CardActionArea>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end">
            <CardActions>
              <Button size="small" color="primary">
                <Typography variant="caption" className={classes.link}>
                  Publiée: {publishedDate}
                </Typography>
              </Button>
            </CardActions>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="space-between"
          >
            <CardActions>
              <Grid item><Button onClick={handleModifie} className={classes.btnyellow}>MODIFIÉR</Button></Grid>
              {currentUser<=3?
              <Grid item><Button onClick={handledelete} className={classes.btnred}>SUPPRIMER</Button></Grid>
              :null}
            </CardActions>
          </Grid>

        </Grid>
    </Grid>
    )
  }

  export default News