import { makeStyles } from '@material-ui/core/styles'



const Style = makeStyles((theme)=> ({
      content: {
        flexGrow: 1,
        margin: 10,
        
      },
    
    icon:{
      color: '#2C2E59'
    },
    
    margin_100:{
      marginTop : 100,
      
      [theme.breakpoints.up('xs')]:{
        marginTop: 90
      },
    
      [theme.breakpoints.between('sm','md')]:{
        marginTop: 350
      },
    },
    
    
    titleLogin:{
      color:'#4F2970',
      fontWeight:300,
      marginBottom:20,
    },
    subtitleLogin:{
      color:'#4F2970',
      fontWeight:300,
      fontSize:14,
      marginBottom:30
    },
    
    foot:{
      marginTop:20,
      fontSize:12,
      fontWeight:400,
      marginBottom:10
    ,
    },
    grow:{
       flexGrow: 1,
    },

    toolbar: {
        minHeight: 128,
        alignItems: 'flex-start',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
      },

    rootlogin:{
        [theme.breakpoints.down('xs')]:{
             marginTop:10,
            backgroundColor: '#F6FBFF',
            paddingLeft:40,
            paddingRight:40,
            paddingTop:20,
            //height:690
        } ,
        [theme.breakpoints.up('sm')]:{
            marginTop:20,
            backgroundColor: '#F6FBFF',
            padding:20,
            width:600,
        } 

    },
    logoLogin:{
        width:'100px',
        height:'100px',
        [theme.breakpoints.down('xs')]:{
            width:'50px',
             height:'50px',
          
        } 
    },
    btnLogin:{
        backgroundColor:'#FFAE02',
        '&:hover':{
            backgroundColor:'#FFAE02',
        }
    },
    poweredby:{
        fontSize:12
    },

    oxide:{
        width:40,
        height:40,
        [theme.breakpoints.down('xs')]:{
            width:20,
            height:20
          
        } 
    },

    btnred:{
      fontSize:10,
        backgroundColor:'#C10100',
        border:'1px solid #C10100' ,
        color:'white',
        '&:hover':{
            backgroundColor:'#C10100',
        },

        [theme.breakpoints.down('xs')]:{
           
            fontSize:8

        }
          
    },
    btnyellow:{
      fontSize:10,
        backgroundColor:'#FFAE02',
        border:'1px solid #FFAE02' ,
        '&:hover':{
            backgroundColor:'#FFAE02',
        },
        [theme.breakpoints.down('xs')]:{
            
            fontSize:8
            
        }
    },
    backPaperTitle:{
        fontSize:24,
        fontWeight:700,
        [theme.breakpoints.down('xs')]:{
            fontSize:15,
             fontWeight:700,
            }

        },

        email:{
          fontSize:10,
          backgroundColor:'green',
          color:'white',
          border:'1px solid green' ,
          '&:hover':{
              backgroundColor:'green',
          },
          [theme.breakpoints.down('xs')]:{
              
              fontSize:8
              
          }
        },

        rootlogin2:{
          [theme.breakpoints.down('xs')]:{
               marginTop:10,
              backgroundColor: '#F6FBFF',
              paddingLeft:40,
              paddingRight:40,
              paddingTop:20,
              //height:690
          } ,
          [theme.breakpoints.up('sm')]:{
              marginTop:20,
              backgroundColor: '#F6FBFF',
              paddingLeft:150,
              paddingRight:150,
              width:600,
          } 
  
      },

   

}));

export default Style;