import React,{useEffect,useRef} from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "./style";
import Actus from "../../widgets/actus"
import Agenda from "../../widgets/agenda";
import TabBar from "../../widgets/tabbar";
import { useSelector } from "react-redux";
import NewsSkeleton from '../../../dashboard/sc/newsSkeleton'
import { Skeleton, Pagination } from "@material-ui/lab";
import SEO from "../../sc/seo"



const Actualite = () => {

  const classes = useStyles();
  const {actualite} = useSelector(state => state.actualitePub)
  const actualiteRef = useRef([])
  actualiteRef.current =actualite
  const {agenda} = useSelector(state => state.agendaPub)
  const { success } = useSelector(state => state.agendaPub)
  const { message } = useSelector(state => state.msg)

  const agendaRef = useRef([])
  agendaRef.current = agenda
  const newsPerPages = 6
  const [tab1, setTab1] = React.useState(1);
  const [tab2, setTab2] = React.useState(1);
  const [tab3, setTab3] = React.useState(1);
  const [tab4, setTab4] = React.useState(1);
  const [tab5, setTab5] = React.useState(1);
  const [tab6, setTab6] = React.useState(1);



  


  const handleCount = (length, numberPerPage) => (length % numberPerPage) > 0 ? parseInt(length / numberPerPage) + 1 : parseInt(length / numberPerPage)
  
  const handlePageChange = (value, update) => {
    update(value)
    window.scrollTo(0,0)
  }
  
  
  
  const NewsTab = () => {

    if(actualiteRef.current.filter((e)=>e.approval===true).length===0 && message === undefined){
      return (
        <Grid container justifyContent='center'>
      <Grid item className ={classes.noNews}>
        <Typography>{actualiteRef.current.filter((e)=>e.approval===true).length===0?"Pas d'Actualités récentes":null}</Typography>
      </Grid>
    </Grid>
      )
    }
    else if(actualiteRef.current.filter((e)=>e.approval===true).length>0){
      
      return(
        
        <div>
          <Actus List={actualiteRef.current.filter((e)=>e.approval===true).slice((tab1 - 1) * newsPerPages, tab1 * newsPerPages)} config={{ md: 5 }} />
          <Grid container justifyContent="center">
            <Grid item style={{margin:"10px 0px"}}>
              <Pagination size="small" count={handleCount(actualiteRef.current.filter((e)=>e.approval===true).length, newsPerPages)} page={tab1} onChange={(event,value) => handlePageChange(value, setTab1)}/> 
            </Grid>
          </Grid>
        </div>
    
      )
    }else{
      return (
       <Grid container  direction='row'  spacing={3}>
         <Grid item xs={12} sm={6}>
         <NewsSkeleton/>
         
         </Grid>
         <Grid item xs={12} sm={6}>
         <NewsSkeleton/>
         
         </Grid>
       </Grid >
      )
    }
   
    
  }
 
  const NewsTab2 = () => {
    if(actualiteRef.current.filter((e)=>e.approval===true && e.category==="L'offre").length===0 && message === undefined){
      return (
        <Grid container justifyContent='center'>
        <Grid item className ={classes.noNews}>
          <Typography>{actualiteRef.current.filter((e)=>e.approval===true && e.category==="L'offre").length===0?"Pas d'Actualités récentes":null}</Typography>
        </Grid>
      </Grid>
      )
    }
    else if(actualiteRef.current.filter((e)=>e.approval===true).length>0){
      
      return(
        <div>
          <Actus List={actualiteRef.current.filter((e) => e.category === "L'offre" && e.approval===true).slice((tab2 - 1) * newsPerPages, tab2 * newsPerPages)} config={{ md: 5 }} />
          <Grid container justifyContent="center">
            <Grid item style={{margin:"10px 0px"}}>
              <Pagination size="small" count={handleCount(actualiteRef.current.filter((e) => e.category === "L'offre" && e.approval===true).length, newsPerPages)} page={tab2} onChange={(event, value) => handlePageChange(value, setTab2)}/> 
            </Grid>
          </Grid>
        </div>
    
      )
    }else{
      return (
       <Grid container  direction='row'  spacing={3}>
         <Grid item xs={12} sm={6}>
         <NewsSkeleton/>
         
         </Grid>
         <Grid item xs={12} sm={6}>
         <NewsSkeleton/>
         
         </Grid>
       </Grid >
      )
    }
  }
  const NewsTab3 = () => {
    if(actualiteRef.current.filter((e)=>e.category==="La demande" && e.approval===true).length===0 && message === undefined){
      return (
        <Grid container justifyContent='center'>
      <Grid item className ={classes.noNews}>
        <Typography>{actualiteRef.current.filter((e)=>e.category==="La demande" && e.approval===true).length===0?"Pas d'Actualités récentes":null}</Typography>
      </Grid>
    </Grid>
      )
    }
    else if(actualiteRef.current.filter((e)=>e.approval===true).length>0){
      
      return(
        <div>
          <Actus List={actualiteRef.current.filter((e) => e.category === "La demande" && e.approval===true).slice((tab3 - 1) * newsPerPages, tab3 * newsPerPages)} config={{ md: 5 }} />
          <Grid container justifyContent="center">
            <Grid item style={{margin:"10px 0px"}}>
              <Pagination size="small" count={handleCount(actualiteRef.current.filter((e) => e.category === "La demande" && e.approval===true).length, newsPerPages)} page={tab3} onChange={(event, value) => handlePageChange(value, setTab3)}/> 
            </Grid>
          </Grid>
        </div>
    
      )
    }else{
      return (
       <Grid container  direction='row'  spacing={3}>
         <Grid item xs={12} sm={6}>
         <NewsSkeleton/>
         
         </Grid>
         <Grid item xs={12} sm={6}>
         <NewsSkeleton/>
         
         </Grid>
       </Grid >
      )
    }
  }
  const NewsTab4 = () => {
    if(actualiteRef.current.filter((e)=>e.category==="L'insertion" && e.approval===true).length===0 && message === undefined){
      return (
        <Grid container justifyContent='center'>
      <Grid item className ={classes.noNews}>
        <Typography>{actualiteRef.current.filter((e)=>e.category==="L'insertion" && e.approval===true).length===0?"Pas d'Actualités récentes":null}</Typography>
      </Grid>
    </Grid>
      )
    }
    else if(actualiteRef.current.filter((e)=>e.approval===true).length>0){
      
      return(
        <div>
          <Actus List = {actualiteRef.current.filter((e)=>e.category==="L'insertion" && e.approval===true).slice((tab4 - 1) * newsPerPages, tab4 * newsPerPages)} config ={{md:5}}/>
          <Grid container justifyContent="center">
            <Grid item style={{margin:"10px 0px"}}>
              <Pagination size="small" count={handleCount(actualiteRef.current.filter((e) => e.category === "L'insertion").length, newsPerPages)} page={tab4} onChange={(event, value) => handlePageChange(value, setTab4)}/> 
            </Grid>
          </Grid>
        </div>
        
      )
    }else{
      return (
       <Grid container  direction='row'  spacing={3}>
         <Grid item xs={12} sm={6}>
         <NewsSkeleton/>
         
         </Grid>
         <Grid item xs={12} sm={6}>
         <NewsSkeleton/>
         
         </Grid>
       </Grid >
      )
    }
  }

  const NewsTab5 = () => {
    if(actualiteRef.current.filter((e)=>e.category==="La regulation" && e.approval===true).length===0 && message === undefined){
      return (
        <Grid container justifyContent='center'>
      <Grid item className ={classes.noNews}>
        <Typography>{actualiteRef.current.filter((e)=>e.category==="La regulation" && e.approval===true).length===0?"Pas d'Actualités récentes":null}</Typography>
      </Grid>
    </Grid>
      )
    }
    else if(actualiteRef.current.filter((e)=>e.approval===true).length>0){
      
      return(
        <div>
          <Actus List={actualiteRef.current.filter((e) => e.category === "La regulation" && e.approval===true).slice((tab5 - 1) * newsPerPages, tab5 * newsPerPages)} config={{ md: 5 }} />
          <Grid container justifyContent="center">
            <Grid item style={{margin:"10px 0px"}}>
              <Pagination size="small" count={handleCount(actualiteRef.current.filter((e) => e.category === "La regulation" && e.approval===true).length, newsPerPages)} page={tab5} onChange={(event, value) => handlePageChange(value, setTab5)}/> 
            </Grid>
          </Grid>
        </div>
    
      )
    }else{
      return (
       <Grid container  direction='row'  spacing={3}>
         <Grid item xs={12} sm={6}>
         <NewsSkeleton/>
         
         </Grid>
         <Grid item xs={12} sm={6}>
         <NewsSkeleton/>
         
         </Grid>
       </Grid >
      )
    }
    }
  
    const NewsTab6 = () => {
      if(actualiteRef.current.filter((e)=>e.category==="Autres" && e.approval===true).length===0 && message === undefined){
        return (
          <Grid container justifyContent='center'>
        <Grid item className ={classes.noNews}>
          <Typography>{actualiteRef.current.filter((e)=>e.category==="Autres" && e.approval===true).length===0?"Pas d'Actualités récentes":null}</Typography>
        </Grid>
      </Grid>
        )
      }
      else if(actualiteRef.current.filter((e)=>e.approval===true).length>0){
        
        return(
      
          <div>
            <Actus List={actualiteRef.current.filter((e) => e.category === "Autres" && e.approval===true).slice((tab6 - 1) * newsPerPages, tab6 * newsPerPages)} config={{ md: 5 }} />
            <Grid container justifyContent="center">
              <Grid item style={{margin:"10px 0px"}}>
                <Pagination size="small" count={handleCount(actualiteRef.current.filter((e) => e.category === "Autres" && e.approval===true).length, newsPerPages)} page={tab6} onChange={(event, value) => handlePageChange(value, setTab6)} />
              </Grid>
            </Grid>
          </div>
      
        )
      }else{
        return (
         <Grid container  direction='row'  spacing={3}>
           <Grid item xs={12} sm={6}>
           <NewsSkeleton/>
           
           </Grid>
           <Grid item xs={12} sm={6}>
           <NewsSkeleton/>
           
           </Grid>
         </Grid >
        )
      }
      }


  const tabs = [
    {
        tabtile:"TOUT",
        tabPanel: NewsTab()
    },
    {
        tabtile:"L'OFFRE", 
        tabPanel:NewsTab2()
    },
    {
        tabtile:"LA DEMANDE",
        tabPanel:NewsTab3()
    },
    {
        tabtile:"L'INSERTION",
        tabPanel:NewsTab4()
    },
    {
        tabtile:"LA REGULATION",
        tabPanel:NewsTab5()
    },
    {
        tabtile:"AUTRES",
        tabPanel:NewsTab6()
    },
    ]

    const handleAgenda = ()=>{
      if(agendaRef.current.length===0 && message === undefined){
        return (
          <Grid container justifyContent='center'>
          <Grid item className ={classes.noNews} style={{height:"400px"}}>
            <Typography>Aucun événement prévu </Typography>
          </Grid>
        </Grid>
        )
      }else if (agendaRef.current.length > 0){
        return(
          <Grid container>
            {
              agendaRef.current.map((e)=>(
                <Grid item sm={12} md={12}>
                <Agenda
                 item={e}
                />
              </Grid>
              )).slice(0,2)
            }
          </Grid> 
        )
      }
      else{
        return (
          <Grid container  spacing={4} style={{marginTop:40}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container direction ='column' spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <Skeleton variant='rect' width='50%' height={20}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Skeleton variant='rect' width='100%' height={80}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent='space-between'>
                    <Grid item xs={12} sm={6}>
                        <Skeleton variant='rect' width='50%' height={20}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton variant='rect' width='50%' height={20}/>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container direction ='column' spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <Skeleton variant='rect' width='50%' height={20}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Skeleton variant='rect' width='100%' height={80}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container justifyContent='space-between'>
                    <Grid item xs={12} sm={6}>
                        <Skeleton variant='rect' width='50%' height={20}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton variant='rect' width='50%' height={20}/>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
            
          </Grid>
        )
      }
    }




    useEffect(() => {
      window.scrollTo(0, 0)
  
    }, [])

  return (
    <React.Fragment>
      <SEO
        title="L'Actualite & Agenda"
        keywords="L'Actualite & Agenda"
      />
      <Grid container justifyContent="center" className={classes.root}>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9} >
              <Grid container alignContent="flex-start">
                <Grid item sm={12} className={classes.bold_text}>
                  <Typography
                    variant="h5"
                  >
                    ACTUALITES
              </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TabBar
                    Tabs={tabs}
                    tabConfig={{
                      xs: 12,
                      md: 12,
                      xl: 12
                    }}
                    panelConfig={{
                      xs: 12,
                      md: 12
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
              <Grid container >
                <Grid item xs={12}>
                  <Grid container alignContent="flex-start" justifyContent="center">
                    <Grid item xs={12} className={classes.bold_text}>
                      <Typography variant="h5">
                        Agenda
                  </Typography>

                    </Grid>
                    <Grid item xs={12}>
                      {handleAgenda()}
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
    };
    
    export default Actualite;
