import React,{useState,useEffect} from 'react'
import {Button, Grid, TextField,IconButton,Collapse, Typography,Box} from '@material-ui/core'
import useStyles from '../style'
import CloudUpload from '@material-ui/icons/CloudUpload'
import  {useForm} from 'react-hook-form'
import { useSelector,useDispatch } from 'react-redux';
import {getPartenaire,addPartenaire,getbyIdPartenaire,updatePartenaire,updatePartenaireWithoutlogo} from '../../../../../../store/actions/partenaire-actions'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router'





const CreatePartenaire=(props)=>{
    const classes = useStyles()
    const dispatch  = useDispatch()
    const {register,formState: { errors },handleSubmit,reset} = useForm()
    const [email, setemail] = useState('')
    const [isCircular, setisCircular] = useState(false)
    const [errmsg, seterrmsg] = useState("Formats de fichiers supportés: .jpg, jpeg, png. Taille Maximale: 1.5 MB.")
    const [img, setimg] = useState('')
    const [selectedImage, setselectedImage] = useState(null)
    const {message} = useSelector((state)=>state.clec.partenaire)??''
    const [open, setOpen] = React.useState(false);
    const msg = useSelector((state)=>state.msg.message)
    const {partenairebyId}  = useSelector((state)=>state.partenaire)

    const history = useHistory()

    const  handleImage = (e)=>{
        const value =e.target.files[0]
        const limit =1639815;
    
        if(value!=null){

    
            if(!value.name.match(/\.(jpg|jpeg|png)$/)){
    
                seterrmsg("Format non supporté!")
            }else if(value.name.match(/\.(jpg|jpeg|png)$/)&& value.size >limit){
                seterrmsg('Le fichier est trop large. La Taille doit être au plus 1.5 MB.')
    
            }else if(value.name.match(/\.(jpg|jpeg|png)$/)&& value.size <=limit){
                setimg(value)
                seterrmsg('Format et Taille non supporté!')
    
            }
        
        }else{
            seterrmsg("Les formats compatibles sont : .jpg, jpeg, png. Taille maximale: 1.5 MB.")
        } 
    }

    const onSubmit = (data,e)=>{
        e.preventDefault()
        setOpen(false)
        if(data){
            setisCircular(true)
            if(props.location.data && img!==''){
                dispatch(updatePartenaire(
                    props.location.data,
                    data.nom,
                    img,
                    data.localisation,
                    data.email,
                    data.desc,
                    data.link,
                    email
                )).then(()=>{
                    setimg('')
                    setOpen(true)
                    dispatch(getPartenaire())
                    history.push({
                        pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/partenaire/detail/${data.nom}=${props.location.data}` ,
                        data:props.location.data
                     })
                    sessionStorage.setItem('rowId',props.location.data,)
                    setisCircular(false)
                    reset()
                },()=>{
                    setisCircular(false)
                    setOpen(true)
                })
            }else if(props.location.data && img===''){
                dispatch(updatePartenaireWithoutlogo(
                    props.location.data,
                    data.nom,
                    data.localisation,
                    data.email,
                    data.desc,
                    data.link,
                    email
                )).then(()=>{
                    setimg('')
                    setOpen(true)
                    dispatch(getPartenaire())
                    
                    history.push({
                        pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/partenaire/detail/${data.nom}=${props.location.data}` ,
                        data:props.location.data
                     })
                    sessionStorage.setItem('rowId',props.location.data,)
                    setisCircular(false)
                    reset()
                },()=>{
                    setisCircular(false)
                    setOpen(true)
                })
            }
            
            else{
                dispatch(addPartenaire(
                    data.nom,
                    img,
                    data.localisation,
                    data.email,
                    data.desc,
                    data.link,
                    email
                )).then(()=>{
                    setimg('')
                    dispatch(getPartenaire())
                    setisCircular(false)
                    setOpen(true)
                    reset()
                },()=>{
                    setisCircular(false)
                    setOpen(true)
                })
            }
        }
    }
    const getUser =()=>{
        var email =  sessionStorage.getItem('user')
        setemail(email)
       
      }

    useEffect(() => {
        if(history.action ==='POP'){
            history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/partenaire`)
        }
        getUser()

        if(props.location.data){
            dispatch(getbyIdPartenaire(`${parseInt(props.location.data)}`))
            reset(
                {
                    nom:partenairebyId.name,
                    link:partenairebyId.link,
                    email : partenairebyId.email,
                    localisation : partenairebyId.localisation,
                    desc : partenairebyId.description
                }
            )
        }
        if(img){
            setselectedImage(URL.createObjectURL(img))
        }
    }, [img])

    return (
     
       
            <Grid container direction='column' className={classes.root}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container justifyContent='center' alignItems="center">
                    <Grid item><Typography variant='h3' style={{fontWeight:'bold'}} color='primary'>{props.location.data?'Modifier':'Ajouter'} un partenaire</Typography></Grid>
                </Grid>
            </Grid>
            <Box height={40}/>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
            <Collapse in={open}>
                        <Alert
                            severity={msg?'error':'success'}
                            action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        >
                            {msg?msg:message}
                        </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.backPaper}>
               <Grid container justify='center' alignItems='center'>
                   <Grid item>
                   <form onSubmit={handleSubmit(onSubmit)}>
                   <Grid container justify='space-around' className={classes.spacebetween} spacing={4}>
                    <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Typography>Nom du partenaire</Typography>
                        <TextField inputProps={{maxLength:20}} helperText='Nom doit avoir 20 caractères' type='text'required {...register('nom',{required:true})} variant='outlined'  fullWidth  className={classes.textField} placeholder='Entrez le nom'/>
                    </Grid>
                    <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                         <Typography>Siteweb</Typography>
                        <TextField type='url' required {...register('link')} variant='outlined' placeholder='https//www.oxide-digital.com'  fullWidth className={classes.textField}/>
                    </Grid>
                    <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Typography>Email</Typography>
                        <TextField  required {...register('email',{required:true,pattern:/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/})} variant='outlined' placeholder='el@oxide-digital.com'  fullWidth  className={classes.textField} error={errors.email?true:false}
                                        helperText={errors.email?'Entrez un email valid':null}/>
                    </Grid>
                    <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                         <Typography>Localisation</Typography>
                        <TextField inputProps={{maxLength:50}} helperText='Localisation doit avoir 50 caractères' type='text' required {...register('localisation',{required:true})} variant='outlined' placeholder='Indiquez la place'  fullWidth className={classes.textField}/>
                    </Grid>
                   
                </Grid>
                <Grid item className={classes.spacebetween} >
                        <Grid container direction='row' justify='flex-start' spacing={2}>
                            <Grid item >
                            {selectedImage &&img?(
                                   <Box mt={2} textAlign='left'>
                                       <div style={{marginBottom:10}}>Image Preview*:</div>
                                         <img onClick={()=>setimg(null)} src={selectedImage} alt={selectedImage.name} height={50} width={50} />
                                   </Box>
                               ):(
                                <div>
                                    <input 
                                    
                                        accept="image/*" 
                                        type="file" 
                                        id="select-image"
                                        style={{ display: 'none' }}
                                        onChange={e => handleImage(e)}
                                    />
                                    <label htmlFor="select-image">
                                        <Button  variant='text' color="primary" component="span" endIcon ={<CloudUpload style={{color:'#FFAE02',}}/>}>
                                            logo
                                        </Button>
                                    </label>
                                </div>
                               )}
                                <small >{errmsg}</small>
                            </Grid>
                        </Grid>
                       
                    </Grid>


                <Grid item className={classes.spacebetween} >
                         <Typography>Description</Typography>
                        <TextField inputProps={{maxLength:500}} helperText='Description doit avoir 500 caractères'  required {...register('desc',{required:true})} variant='outlined' placeholder='Entrez votre message' multiline rows={6} fullWidth className={classes.textField}/>
                    </Grid>
                <Grid item className={classes.spacebetween} >   
                    <Button type='submit' disabled={isCircular===true?true:false}  fullWidth className={classes.btn}> {isCircular===true?<CircularProgress size={30} style={{color:'white'}} />:<Typography>{props.location.data?'Modifier':'Ajouter'}</Typography>}</Button>
                </Grid>
                </form>
                   </Grid>



               </Grid>
            </Grid>

        </Grid>
        
       
    )
}

export default CreatePartenaire