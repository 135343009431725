import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import React,{useEffect} from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import WebSite from "./ui/website/main";
import Main from "../src/ui/dashboard/main";
import Register from '../src/ui/user_creation/signup'
import ReactGA from 'react-ga';



const theme = createTheme({
  palette: {
    primary: {
      light: "#ffe04c",
      main: "#ffae02",
      dark: "#c67f00",
      fade:"#ffae02ab"
    },
    secondary: {
      light: "#6bffe4",
      main: "#1edab2",
      dark: "#00a783",
    },
    text: {
      primary: "#1D2F3F",
    },
    background:{
      default:'#FFF'
  },
  },
    typography: {
      fontFamily: "Roboto, 'sans-serif'",
      textBodyAlign:"justify",
      fontWeightBold:500,
      fontWeightMedium:400,
      fontWeightRegular:300,
      
    },
  
});

const App = () => {
  useEffect(() => {

    ReactGA.initialize(process.env.REACT_APP_TRACKID)
    ReactGA.pageview(window.location.pathname + window.location.search)
 
   }, [])
 
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route  path={`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard`} component={Main} />
          <Route  path={`/${process.env.REACT_APP_USER_REGISTRATION}/utilisateur/cree/:token`} component={Register} />
          <Route  path="/" component={WebSite} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};
export default App;
