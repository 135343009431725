import React from 'react';
import DoubleArrowRoundedIcon from '@material-ui/icons/DoubleArrowRounded';
import { makeStyles } from "@material-ui/styles"
import { Link } from 'react-router-dom'
import { Grid, Typography} from '@material-ui/core';

const useStyles = makeStyles({
    root: {
            color:props => props.color ? props.color : "#1EDAB2",
            margin: "1em 0vw",
            textDecoration: "none",
            "& svg": {
                fontSize: "1.3rem",
                alignSelf:"center"
            },
    }
  });

const MoreLink = (props) => {

    const classes = useStyles({...props});

    return (
        <Link className = {classes.root} to = {props.to} target={props.target ? props.target : ""}>
            <Grid container alignItems="baseline">
                <Typography variant = "body1">
                    {props.title}
                </Typography>
                <DoubleArrowRoundedIcon />
            </Grid>
        </Link>
    );
};

export default MoreLink;