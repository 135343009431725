import api from './axiosApi';
import apiPub from './axiosPub'




 
 const sendInvitation = (fullname,email,role,organisation)=>{
  return api.post('api/account/sendInvitation/',{
    fullname,email,role,organisation
   })
   .then((res)=>{
     return res.data
   })
 } 

  const lougout = (refresh)=>{
    return api.post('api/account/blacklist/',{refresh}).then((res)=>{
     return res.data
    })
  }


  const userCreation = (
    email,
    password,
    role,
    organisation,
    created_by,
    code
  )=>{
    return apiPub.post('api/account/adduser/',{
      email,
      password,
      role,
      organisation,
      created_by,
      code

    }).then((res)=>{
      return res.data.user
    })
  }

  const userProfile = (
      email,
      first_name,
      last_name,
      phonenumber,
      address,
      organisation,
      fonction,
      province,
      ville_territory,
      created_by
  )=>{
    return apiPub.post('api-profile/addprofile/',{
      email,
      first_name,
      last_name,
      phonenumber,
      address,
      organisation,
      fonction,
      province,
      ville_territory,
      created_by
    }).then((res)=>{
      return res.data
    })
  }


  const sendVerificationCode = (
      email,
      fullname
  )=>{
    return apiPub.post('api/account/verification/code/',{
      email,
      fullname
    }).then((res)=>{
      return res.data
    })
  }


  const checkVerificationCode = (
      email,
      password,
      role,
      organisation,
      first_name,
      last_name,
      phonenumber,
      address,
      fonction,
      province,
      ville_territory,
      created_by,
      code
  )=>{
    return apiPub.post('api/account/check/verification/code/',{
      email,
      password,
      role,
      organisation,
      first_name,
      last_name,
      phonenumber,
      address,
      fonction,
      province,
      ville_territory,
      created_by,
      code
    }).then((res)=>{
      return res.data
    })
  }


  const  contactForm =(
    nom,
    prenom,
    email,
    phonenumber,
    message,
    token
  )=>{
    return apiPub.post('api/account/contact/',{
    nom,
    prenom,
    email,
    phonenumber,
    message,
    token
    }).then((res)=>{
      return res.data
    })
  }



  const userById  = (id)=>{
    return api.get(`api/account/getuser/${id}/`).then((res)=>{
      return res.data.user
    })
  }

  const deleteUser  = (id)=>{
    return api.delete(`api/account/user/${id}/`).then((res)=>{
      return res.data.user
    })
  }

  const updateUser =(
    id,
    email,
    role,
    organisation,
    activated
  )=>{
   return api.patch(`api/account/user/${id}/`,{
    email,
    role,
    organisation,
    activated
   }).then((res)=>{
     return res.data
   })
  }

  const activateUser =(
    id,
    email,
    activated
  )=>{
   return api.patch(`api/account/user/activate/${id}/`,{
    email,
    activated
   }).then((res)=>{
     return res.data.message
   })
  }

  const changePassword =(
    id,
    password
  )=>{
   return api.patch(`api/account/change/password/account/${id}/`,{
    password
   }).then((res)=>{
     return res.data
   })
  }
  const alluUserApi = ()=>{
    return api.get('api/account/users/').then(
      (res)=>{
        return res.data.users
      }
    )
  }

  const checkEmail  =(email)=>{
    return apiPub.post('api/account/check/email/',{
      email
    }).then((res)=>{
      return res.data
    })
  }

  const sendEmailCode  =(email)=>{
    return apiPub.post('api/account/send/email/code/',{email}).then((res)=>{
      return res.data
    })
  }

  const restorePassword = (id,password)=>{

    return apiPub.patch(`api/account/restore/password/${id}/`,{
      password,

    }).then((res)=>{
      return res.data
    })
    
  }


  const checkEmailCode =(code)=>{
    return apiPub.post('api/account/check/email/code/',{code}).then((res)=>{
      return res.data
    })
  }
  const Auth  = {
    userCreation,
    sendVerificationCode,
    userProfile,
    lougout,
    sendInvitation,
    checkVerificationCode,
    contactForm,
    userById,
    updateUser,
    deleteUser,
    changePassword,
    alluUserApi,
    activateUser,
    checkEmail,
    sendEmailCode,
    restorePassword,
    checkEmailCode
  }

export default Auth