import {combineReducers} from 'redux'
import auth from './auth-reducer'
import message from './message-reducer'
import UserReducers from './users-reducers'
import partenaireReducer from './partenaire-reducer'
import membreReducer from './membre-reducer'
// import projetReducer from './projet-reducer'
import agendaReducer from './agenda-reducer'
import actualiteReducer from './actualite-reducer'
import cccReducer from './ccc-reducer'
import clecReducer from './clec-reducer'
import SendEmail from  './sendInvitation'
import AuthReducer from './auth-pub-reducer'
import espaceReducer from './espace-reducer'





const createRootReducer = combineReducers({
    auth : auth,
    msg : message,
    allprofile : UserReducers.getUsersReducers,
    profilebyId : UserReducers.getUserByIdReducer,
    deleteprofile : UserReducers.getDeleteUser,
    updateprofile:UserReducers.updateUserReducer,
    partenaire : partenaireReducer.partenaires,
    membre : membreReducer.membres,
    membreById:membreReducer.getMemberById,
    // projet : projetReducer.projets,
    agenda: agendaReducer.agenda,
    actualite : actualiteReducer.actualite,
    ccc : cccReducer.ccc,
    clec : clecReducer.clec,
    sendEmail:SendEmail,
    sendCode:AuthReducer.sendCodeReducers,
    addUser:AuthReducer.userReducers,
    addprofile: AuthReducer.profileReducers,
    checkcode: AuthReducer.checkCodeReducers,
    actualitePub :actualiteReducer.actualitePub,
    agendaPub:agendaReducer.agendaPub,
    cccPub:cccReducer.cccPub,
    clecPub:clecReducer.clecPub,
    membrePub:membreReducer.membrePub,
    partenairePub:partenaireReducer.partenairePub,
    //projetPub:projetReducer.//projetPub,
    contact:AuthReducer.contactReducers,
    userById: AuthReducer.userByIdReducer,
    userUpdate:AuthReducer.userUpdate,
    userDelete: AuthReducer.userDelete,
    userChangePassword: AuthReducer.userChangePassword,
    users:AuthReducer.allUser,
    activatedUser:AuthReducer.userActivated,
    checkemail: AuthReducer.checkEmailReducer,
    sendEmailCode: AuthReducer.sendEmailCode,
    restorePassword:AuthReducer.restorePassword,
    checkEmailCode: AuthReducer.checkEmailCode,
    userProfile :UserReducers.userProfileReducer,
    espace :espaceReducer.espaceMembre

})

export default createRootReducer