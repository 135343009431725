import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@material-ui/core'
import { InlineShareButtons } from 'sharethis-reactjs'
import Zoom from '@material-ui/core/Zoom'

const useStyle = makeStyles((theme) => ({
    
    root: {
        minWidth: 240
    },
    link: {
        "& input": {
            padding: 12,
        },
        marginBottom:"1em"    
        
    }
}));

const Transition = React.forwardRef((props, ref) => (
    <Zoom ref={ref} {...props}/>
));

const ShareComp = ({handleClose,open,image,title,value,description,url}) => {

    const classes = useStyle()
    const shareConfig = {
        alignment: 'center',  // alignment of buttons (left, center, right)
        color: 'social',      // set the color of buttons (social, white)
        enabled: true,        // show/hide buttons (true, false)
        font_size: 12,        // font size for the buttons
        labels: 'null',        // button labels (cta, counts, null)
        language: 'en',       // which language to use (see LANGUAGES)
        networks: [           // which networks to include (see SHARING NETWORKS)
            'sms',
            'facebook',
            'whatsapp',
            'twitter',
        ],
        padding: 12,          // padding within buttons (INTEGER)
        radius: 4,            // the corner radius on each button (INTEGER)
        size: 40, 
        url: url ? `${url}` : null, 
        image: `${image}`,  // (defaults to og:image or twitter:image)
        description: `${description}`,       // (defaults to og:description or twitter:description)
        title: `${title}`,            // (defaults to og:title or twitter:title)
    }
    return (
        <Dialog
            TransitionComponent = {Transition}
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                Partagez
            </DialogTitle>

            <DialogContent className = {classes.root}>
                <TextField
                    variant="outlined"
                    fullWidth
                    id="link"
                    className = {classes.link}
                    value={value}
                    disabled
                />
                <InlineShareButtons
                    config={shareConfig}
                    
                />
            </DialogContent>

            <DialogActions>
            </DialogActions>
        </Dialog>
    );
};

export default ShareComp;