import { makeStyles } from '@material-ui/core/styles'

const Style = makeStyles((theme)=> ({

    root:{
        marginTop:80,
        color:'#1D2F3F',
        padding:'5vw 0vw',
        
    },
    video:{
        width:"35em",
        height:"21.3em",
        padding:'1em 1em'
    }

}));

export default Style;