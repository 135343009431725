import { makeStyles } from '@material-ui/core/styles'



const drawerWidth = 220;
const Style = makeStyles((theme)=> ({

    root: {
        display: 'flex',

      },
      drawer: {
        [theme.breakpoints.up('md')]: {
          width: drawerWidth,
          flexShrink: 0,
          backgroundColor:'#00A1AF'
    
        },
    
        
      },
      appBar: {
        boxShadow:"0px 0px 0px 0px",
        backgroundColor:'#FFF',
        [theme.breakpoints.down('sm')]: {
          backgroundColor:'#FFAE02',
          
          
          
        },
      
      },
      menuButton: {
        marginRight: theme.spacing(0),
        [theme.breakpoints.up('md')]: {
          display: 'none',
          
        },
      },
      // necessary for content to be below app bar

      drawerPaper: {
        width: drawerWidth,
        borderRight:'0px !important'
      },
      content: {
        flexGrow: 1,
        margin: 10,
        
      },
    margin_Bottom_30:{
        marginBottom:50,
        marginTop:20
    },
    margin_Bottom_10:{
        marginTop:10,
        marginBottom:10
    },
    margin_Top_30:{
        marginTop:30,
        
    },
    avatarSize:{
      color: '#1D384C',
      width:theme.spacing(5),
      height:theme.spacing(5),
      marginLeft:10,
      fontSize:15,
      backgroundColor:'#E0E0E0',
      [theme.breakpoints.down('sm')]:{
          color:'#1D384C',
          backgroundColor:'white',
      }
    
    },
    typography:{
      color: '#FFFFFF'
    },
    typography2:{
      color: '#FFFFFF',
      fontSize:11
    },
    
    title:{
      color:'#1D384C',
      fontSize:13,
      fontWeight:300,
    },
    title1:{
        color:'#1D384C',
        fontSize:13,
        fontWeight:300,
        [theme.breakpoints.down('sm')]:{
          color:'white',
         
      }
      },
    
    icon:{
      color: '#2C2E59'
    },
    
    margin_100:{
      marginTop : 100,
      
      [theme.breakpoints.up('xs')]:{
        marginTop: 90
      },
    
      [theme.breakpoints.between('sm','md')]:{
        marginTop: 350
      },
    },
    
    typoBig:{
      color:'#4F2970',
      fontWeight:300,
      marginTop:57,
      marginBottom:49
    },
    leftandrightInput:{
      padding: 50
    },
    
    titleLogin:{
      color:'#4F2970',
      fontWeight:300,
      marginBottom:20,
    },
    subtitleLogin:{
      color:'#4F2970',
      fontWeight:300,
      fontSize:14,
      marginBottom:30
    },
    
    foot:{
      marginTop:20,
      fontSize:12,
      fontWeight:400,
      marginBottom:10
    ,
    },
    grow:{
       flexGrow: 1,
    },

    toolbar: {
        minHeight: 128,
        alignItems: 'flex-start',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
      },

    rootlogin:{
      

        [theme.breakpoints.down('xs')]:{
            marginTop:80,
            backgroundColor: '#F6FBFF',
            padding:40,
            height:450
        } ,
        [theme.breakpoints.up('sm')]:{
            marginTop:80,
            backgroundColor: '#F6FBFF',
            padding:40,
            width:400,
        } 

    },

    logoLogin:{
        width:'100px',
        height:'100px',
        [theme.breakpoints.down('xs')]:{
            width:'50px',
             height:'50px',
          
        } 
    },
    btnLogin:{
        backgroundColor:'#FFAE02',
        '&:hover':{
            backgroundColor:'#FFAE02',
        }
    },
    poweredby:{
        fontSize:12
    },

    oxide:{
        width:40,
        height:40,
        [theme.breakpoints.down('xs')]:{
            width:20,
            height:20
          
        } 
    },

    backPaper:{
        backgroundColor:'#F6FBFF',
       // height:'vh',
        marginTop:80,
       marginRight:90,
       marginLeft:10,
       paddingLeft:30,
       paddingRight:10,
       [theme.breakpoints.down('xs')]:{
        marginRight:5,
        marginLeft:5,
        paddingLeft:10,
       },
       [theme.breakpoints.only('sm')]:{
        marginRight:5,
        marginLeft:5,
        paddingLeft:10,
       }    

    },
    btnred:{
      fontSize:10,
        backgroundColor:'#C10100',
        border:'1px solid #C10100' ,
        color:'white',
        '&:hover':{
            backgroundColor:'#C10100',
        },

        [theme.breakpoints.down('xs')]:{
           
            fontSize:8

        }
          
    },
    btnyellow:{
      fontSize:10,
        backgroundColor:'#FFAE02',
        border:'1px solid #FFAE02' ,
        '&:hover':{
            backgroundColor:'#FFAE02',
        },
        [theme.breakpoints.down('xs')]:{
            
            fontSize:8
            
        }
    },
    backPaperTitle:{
        fontSize:24,
        fontWeight:700,
        [theme.breakpoints.down('xs')]:{
            fontSize:15,
             fontWeight:700,
            }

        },

        email:{
          fontSize:10,
          backgroundColor:'green',
          color:'white',
          border:'1px solid green' ,
          '&:hover':{
              backgroundColor:'green',
          },
          [theme.breakpoints.down('xs')]:{
              
              fontSize:8
              
          }
        }
   

}));

export default Style;