import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import {Grid,CardActionArea,CardActions,CardContent,CardMedia, Box,} from '@material-ui/core'

import Skeleton from '@material-ui/lab/Skeleton';


const useStyles = makeStyles((theme) => ({
    root: {
      margin:"1em 0em",
      alignSelf: "center",
      "& svg": {
        color: "#0000008a",
        fontSize: 19
      },
      "& img":{
        borderRadius:'0.5vw'
      },
      [theme.breakpoints.down("sm")]:{
        justifyContent:"center"
      },
      [theme.breakpoints.up("sm")]:{
  
      }
    },
    title:{
      margin:"0.65em 0em",
      fontSize:"1.3rem"
    },
    media: {
      height: 200,
      [theme.breakpoints.only('sm')]:{
        height:160
      },
      [theme.breakpoints.down('xs')]:{
        height:120
      }
    },
    card:{
      padding:"0em"
    },
    link: {
      color: "#3F74A4",
    },
  
    img:{
      width:"100%",
       height:"100%",
       [theme.breakpoints.down('xs')]:{
        width:"100%",
        height:"100%",
       }
    },
    btnyellow:{
      fontSize:10,
      backgroundColor:'#FFAE02',
      border:'1px solid #FFAE02' ,
      '&:hover':{
          backgroundColor:'#FFAE02',
      },
      [theme.breakpoints.down('xs')]:{
          fontSize:10
          
      }
  },
  btnred:{
    fontSize:10,
    backgroundColor:'#C10100',
    border:'1px solid #C10100' ,
    color:'white',
    '&:hover':{
        backgroundColor:'#C10100',
    },

    [theme.breakpoints.down('xs')]:{
        fontSize:8

    }
      
},


    
  
  }));

  
const NewsSkeleton =()=>{
  const classes = useStyles();


    return (
      <Grid container className={classes.root}>
        <Grid item xs = {12}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image="import image here"
              title="news title">
              <Skeleton variant='rect' height={200}/>            
            </CardMedia>
            <CardContent className={classes.card}>
            <Box height={20}/>
             <Skeleton variant='rect' height={20} width={100}/>
             <Box height={10}/>
             <Skeleton variant='rect' height={70}/>
            </CardContent>
          </CardActionArea>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <CardActions>
                     <Skeleton variant='rect' height={10}/>
            </CardActions>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="space-between"
          >
          </Grid>

        </Grid>
    </Grid>
    )
  }

  export default NewsSkeleton