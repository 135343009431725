import React from 'react';
import { Grid, Typography} from '@material-ui/core';
import { makeStyles } from "@material-ui/styles"
import More from './moreLink'


const useStyles = makeStyles((theme) => ({
    root: {
     //   margin:"2em 0vw",
     '& span': {
        textAlign:theme.typography.textBodyAlign
      },
        "& h6":{
            fontWeight:theme.typography.fontWeightMedium,
            margin:"1em 0vw"
        }
    }
  }));

const Pole = (props) => {

    const classes = useStyles({...props});
    return (
        <Grid item className={classes.root}
           xs={12} sm={5} md={3} lg={3} xl={3}
        >
            <Grid container >
                    <img
                        alt = "Pole"
                        src= {props.image}
                        width = "100%"
                        height = "100%"
                    />
                    <Typography variant = "h6" style={{fontSize:15,fontWeight:'bold'}}>
                        {props.title}
                    </Typography>
                    <Typography variant = "caption" >
                        {props.caption}
                    </Typography>
                    <More title = "Voir tout" color = {props.color} to = {props.link}/>
            </Grid>
        </Grid>
    );
};

export default Pole;