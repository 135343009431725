import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({


  root:{
    marginTop:130,
      [theme.breakpoints.down("md")]:{
        padding:'2em 0em',
      },
      [theme.breakpoints.up("md")]:{
        padding:'2em 0em',
      }
  },
  tab:{
    padding:"3em 0em",
    [theme.breakpoints.down("xs")]:{
      justifyContent:"center"
    },
    [theme.breakpoints.up("xs")]:{
      justifyContent:"space-between"
    },

  },
  bold_text: {
    marginBottom:20,
    '& h5':{
      fontWeight: theme.typography.fontWeightBold,
    }
  },
  
  noNews: {
    height: 300,
    display: "flex",
    alignItems: "center",
    color:theme.palette.primary.fade    
  }

}));
