import React,{useEffect} from "react";
import PoleComp from '../../widgets/offreComp'
import Image from '../../assets/images/pole/offre.JPG'
import SEO from '../../sc/seo'
const Offre = () => {

  const Actus = [{},{},{}]
  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])
  return (
    <React.Fragment>
      <SEO
        title="L'offre"
        description="L’offre comprend tous les prestataires de formation professionnelle."
        pathSlug="/offre"
        keywords = "offre"
        image={Image}
        imageH={1440}
        imageW = {481}
      />

      <PoleComp
        image={Image}
        title="L'OFFRE"
        highlight="#1EDAB2"
        body="L’offre comprend tous les prestataires de formation professionnelle (Ecoles techniques, centres d’apprentissages, centre de formation professionnelle publiques, privés et religieux, ainsi que les entreprises qui forment directement sur le lieu de travail à travers des stages et des Maîtres-Formateurs et aussi des programmes d’organisation qui œuvrent dans le domaine de la formation initiale et/ou continu).<br/><br/>La formation professionnelle jette un pont entre le monde de l'éducation et le monde du travail : les jeunes femmes et les jeunes hommes augmentent leurs chances de trouver un emploi décent et de gagner un meilleur revenu. Une main-d'œuvre qualifiée accroît la compétitivité des entreprises. En tant que tel, la formation professionnelle a une incidence directe sur la réduction de la pauvreté et des inégalités, ainsi que sur le développement social et économique. <br/><br/>La promotion de formations qualifiantes peut améliorer l'employabilité des groupes cibles, mais n'entraîne pas automatiquement la création d'emplois. Dans les contextes visant à promouvoir l'emploi, il est nécessaire de compléter les projets de formation professionnelle par d'autres mesures, en particulier le développement du secteur privé et du système financier. <br/><br/>L'interaction avec l'éducation de base renforce l'inclusion des groupes vulnérables, pour une transition éclairée de la scolarité obligatoire à la formation professionnelle et pour les parcours d'apprentissage continus tout au long de la vie des individus. <br/><br/><i>Le développement des compétences doit être une priorité et être dispensé à tous les niveaux avec une qualité et une pertinence élevée par des prestataires de services compétents et dotés de ressources suffisantes. </i>"
        actuTitle="Les actualités de l'Offre"
        actus={Actus}
      />
    </React.Fragment>
  );
};

export default Offre;
