import {makeStyles} from '@material-ui/core/styles'

const styles = makeStyles((theme)=>({

    root:{
       marginTop:80,
       paddingLeft:30,
       paddingRight:10,
       marginRight:90,
        
       [theme.breakpoints.down('xs')]:{
        paddingLeft:0,
       paddingRight:0,
       } 
    },

    backPaper:{
       backgroundColor:'#F6FBFF',     
       marginTop:30,
       marginRight:90,
       marginLeft:10,
       
       
       [theme.breakpoints.down('xs')]:{
        marginRight:5,
        marginLeft:5,
        paddingLeft:10,
       }   
    },
    spacebetween:{
        paddingLeft:150,
        paddingRight:150,

        marginTop:30,
        marginBottom:30,
        [theme.breakpoints.down('xs')]:{
            paddingLeft:10,
            paddingRight:10,
           } 
    },
    btn:{
        backgroundColor:'#FFAE02',
        height:50,

        '&:hover':{
            backgroundColor:'#FFAE02',
        },
        [theme.breakpoints.down('xs')]:{
            
            fontSize:8
            
        }
    },
    detailarr:{

        [theme.breakpoints.down('xs')]:{
            
            paddingLeft:0,paddingBottom:40,
            
        }
    }

    

}))

export default styles;