import React,{useState,useEffect} from 'react'
import {Button, Grid, TextField, Typography, IconButton,Collapse, Box} from '@material-ui/core'
import useStyles from '../style'
import CircularProgress from '@material-ui/core/CircularProgress';
import  { useForm} from 'react-hook-form'
import {getEspaceMembre,updateEspaceMembre,updateEspaceMembreWithoutlogo,addEspaceMembre,getbyIdEspaceMembre} from '../../../../../../store/actions/espace-actions'
import { useSelector,useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router';
import CloudUpload from '@material-ui/icons/CloudUpload'





const CreateEspace=(props)=>{
    const classes = useStyles()
    const dispatch  = useDispatch()
    const history  = useHistory()
    const {register,handleSubmit,reset} = useForm()
    const [email, setemail] = useState('')
    const [name, setname] = useState('')
    const [isErr, setisErr] = useState(false)
    const [isCircular, setisCircular] = useState(false)
    const {message} = useSelector((state)=>state.espace.addedespace)??''
    const [open, setOpen] = React.useState(false);
    const msg = useSelector((state)=>state.msg.message)
    const {updatedespace} = useSelector(state => state.espace)
    const [errmsgdoc, seterrmsgdoc] = useState("Les formats compatibles sont : .pdf, .docx, .ppt , excel . Taille Maximale: 6.5 MB.")
    const [doc, setdoc] = useState('')
    const [updaedDoc, setupdaedDoc] = useState('')



     const  handleDoc = (e)=>{
    const value =e.target.files[0]
    console.log(value.size);
    const limit =8639815;
    setisErr(false)
   
            if(value!=null){
                if(!value.name.match(/\.(pdf|docx|.xlsx|.xlsm|.xlsb|.xltx|.pptx|.pptm|.ppt)$/)){
                    seterrmsgdoc("Fichier no supporté!")
                    setisErr(false)
                }else if(value.name.match(/\.(pdf|docx|.xlsx|.xlsm|.xlsb|.xltx|.pptx|.pptm|.ppt)$/)&& value.size >limit){
                    seterrmsgdoc('Le fichier est trop large. La Taille doit être au plus 6.5 MB.')
                }else if(value.name.match(/\.(pdf|docx|.xlsx|.xlsm|.xlsb|.xltx|.pptx|.pptm|.ppt)$/)&& value.size<=limit){
                    setdoc(value)
                    setisErr(false)
                    setname(value.name)
                    seterrmsgdoc('Fichier ou Taille  supporté!')
                    
                }           
            }else{
                setdoc('')
                seterrmsgdoc("Formats de fichiers supportés: .pdf, .docx,.ppt,excel. Taille Maximale: 6.5 MB.")
            } 
        }

    const onSubmit=(data,e)=>{
        setOpen(false)
        e.preventDefault()
        if(data && (doc!='' || updaedDoc!='')){
            if(props.location.data && doc!==''){
                dispatch(updateEspaceMembre(
                    props.location.data,
                    // name,
                    doc,
                    data.desc,
                )).then(()=>{
                    setdoc('')
                    setOpen(true)
                    dispatch(getEspaceMembre())
                    history.push({
                        pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard` ,
                       
                     })

                    setisCircular(false)
                    reset()
                },()=>{
                    setisCircular(false)
                    setOpen(true)
                })
            }else if(props.location.data && doc===''){
                dispatch(updateEspaceMembreWithoutlogo(
                    props.location.data,
                    // name,
                    data.desc,
                )).then(()=>{
                    setdoc('')
                    setOpen(true)
                    dispatch(getEspaceMembre())
                    
                    history.push({
                        pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard` ,
                     })
                    setisCircular(false)
                    reset()
                },()=>{
                    setisCircular(false)
                    setOpen(true)
                })
            }
          else{
            setisCircular(true)
            setisErr(false)
            dispatch(addEspaceMembre(
                name,
                email,
                doc,
                data.desc,
                email
            )).then(()=>{
                dispatch(getEspaceMembre())
                setisCircular(false)
                setOpen(true)
                setdoc('')
                reset()
                setisErr(false)
            },()=>{
                dispatch(getEspaceMembre())
                setisCircular(false)
                setOpen(true)
                setisErr(false)
            })
          }
        }else{
            seterrmsgdoc('Veuillez attacher le document pour sauvegarder!!!')
            setisErr(true)
        }
    }

    const getUser =()=>{
        var email =  sessionStorage.getItem('user')
        setemail(email)
       
      }
      useEffect(() => {
        if(history.action ==='POP'){
            history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard`)
        }
        getUser()
        if(props.location.data){

        dispatch(getbyIdEspaceMembre(props.location.data)).then(()=>{
            if(updatedespace!==undefined){
                reset({
                    desc:updatedespace.description
                }) 
                setupdaedDoc(updatedespace.document)
            }
                
        })
    }
   

    }, [])
    return (
     
        <Grid container direction='column' className={classes.root}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container justifyContent='center' alignItems="center">
                    <Grid item><Typography variant='h3' style={{fontWeight:'bold'}} color='primary'>{updatedespace?"Actualiser les détails d'un document":'Ajouter un document'}</Typography></Grid>
                </Grid>
            </Grid>
            <Box height={40}/>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
            <Collapse in={open}>
                        <Alert
                        severity={msg?'error':'success'}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        >
                            {msg?msg:message}
                        </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.backPaper}>
               <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justify='center' alignItems='center'>
                        <Grid item>
                        <Grid item className={classes.spacebetween} >
                        <Grid container justify='space-between' spacing={2} >
                        <Grid item xs={12} sm={0} md={0} lg={0} xl={0}>
                            {doc || updaedDoc?(
                                   <Box mt={2} textAlign='left'>
                                       <div style={{marginBottom:10}} onClick={()=>{
                                           setupdaedDoc(null)
                                           setdoc(null)
                                       }}>{updaedDoc?'update document':doc.name}:</div>
                                      
                                   </Box>
                               ):(
                                <div>
                                    <input 
                                       
                                        accept="document/*" 
                                        type="file" 
                                        id="select-file"
                                        style={{ display: 'none' }}
                                        onChange={e => handleDoc(e)}
                                    />
                                    <label htmlFor="select-file">
                                        <Button  variant='text' color="primary" component="span" endIcon ={<CloudUpload style={{color:'#FFAE02',}}/>}>
                                            document
                                        </Button>
                                    </label>
                                </div>
                               )}
                                {isErr===true?<strong style={{color:'red'}}>{errmsgdoc}</strong>:<small >{errmsgdoc}</small>}
                            </Grid>
                          
                        </Grid>
                        </Grid>
                        <Grid item className={classes.spacebetween} >
                                <Typography>Commentaire</Typography>
                                <TextField inputProps={{maxLength:200}} helperText='Le commentaire doit avoir 500 caractères' type = 'text' {...register('desc',{required:true,maxLength:500})} required  variant='outlined' placeholder='Entrez votre message' multiline rows={6} fullWidth className={classes.textField}/>
                            </Grid>
                        <Grid item className={classes.spacebetween} >
                            <Grid container justify='space-between' spacing={2} >
                                <Grid item xs={12} sm={0} md={0} lg={0} xl={0}>
                                    <Button type='submit' disabled={isCircular===true?true:false}  fullWidth className={classes.btn}> {isCircular===true?<CircularProgress size={30} style={{color:'white'}} />:<Typography>{updatedespace?'Sauvegarder':'Ajouter'}</Typography>}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
               </form>
            </Grid>

        </Grid>
       
    )
}

export default CreateEspace