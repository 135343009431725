import {
    
    FETCH_CLEC_SUCCES,
    FETCH_CLEC_FAIL,
    DELETE_CLEC_SUCCES,
    DELETE_CLEC_FAIL,
    ADD_CLEC_FAIL,
    ADD_CLEC_SUCCES,
    UPDATE_CLEC_SUCCES,
    UPDATE_CLEC_FAIL,
    FETCH_CLEC_BY_ID_SUCCES,
    FETCH_CLEC_BY_ID_FAIL,
    CLEAR_DATA

} from '../contants/type'


const initialState = {
    clec:[]
}
const clec =(state=initialState,action)=>{
    const {type,payload} = action

    switch(type){
        case FETCH_CLEC_SUCCES: 
            return {
                ...state,
                clec:payload
            }
        case FETCH_CLEC_FAIL:
            return {
                ...state
            }
        case DELETE_CLEC_SUCCES:
            return {
                ...state,
                deleteclec:'Clec a été bien supprimer'
            }
        case DELETE_CLEC_FAIL:
            return {
                ...state,
                deleteclec:"Clec n'a pas été bien supprimer, veuillez vetifier votre connexion"
            }
        case ADD_CLEC_SUCCES:
            return {
                ...state,
                addedclec: payload
            }
        case ADD_CLEC_FAIL: 
            return{
                ...state
            }
        case UPDATE_CLEC_SUCCES:
            return {
                ...state,
                updatedclec:payload
            }
        case UPDATE_CLEC_FAIL:
            return {
                ...state
            }
        case FETCH_CLEC_BY_ID_FAIL:
            return {
                ...state
            }
        case FETCH_CLEC_BY_ID_SUCCES:
            return {
                ...state,
                clecbyId:payload
            }
        case CLEAR_DATA:
            return {}
        
        default:
            return state
    }


}
const initialStatePub = {
    clec:[]
}
const clecPub =(state=initialStatePub,action)=>{
    const {type,payload} = action

    switch(type){
        case FETCH_CLEC_SUCCES: 
            return {
                ...state,
                clec:payload
            }
        case FETCH_CLEC_FAIL:
            return {
                ...state
            }
        case FETCH_CLEC_BY_ID_FAIL:
            return {
                ...state
            }
        case FETCH_CLEC_BY_ID_SUCCES:
            return {
                ...state,
                clecbyId:payload
            }
        case CLEAR_DATA:
            return {}
        
        default:
            return state
    }


}

const clecReducer= {
    clec ,
    clecPub
}

export default clecReducer