import React,{useState,useEffect} from 'react'
import TableData from '../../../sc/table'
import {Grid,Typography,Tab,Tabs,IconButton,Paper, Box ,Collapse} from '@material-ui/core'
import PropTypes from "prop-types";
import useStyles from './style'
import SortIcon from '@material-ui/icons/Sort';
import { SendEmail} from '../../../sc/utilities';
import { useHistory } from 'react-router-dom';
import SendIcon from '@material-ui/icons/Send';
import {useSelector,useDispatch} from 'react-redux'
import DialogBox from '../../../sc/dialog';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getUsers,deleteUser } from '../../../../../store/actions/user-actions';
import SendInvitation from './widget/sendInvitarion'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';









function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
      >
        {value === index && (
          
            <Typography>{children}</Typography>
         
        )}
      </div>
    );
  }
  
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };


  const columns = [
  { field: 'first_name', headerName: 'Nom', width: 160 },
  { field: 'last_name', headerName: 'Prénom', width: 160 },
  { field: 'organisation', headerName: 'Organisation', width: 190 },
  { field: 'fonction', headerName: 'Fonction', width: 170 },
  { field: 'phonenumber', headerName: 'Téléphone', width: 140 },
  { field: 'address', headerName: 'Adresse', width: 140 },
  { field: 'ville_territory', headerName: 'Ville & Territoire', width: 140 },
  { field: 'province', headerName: 'Province', width: 230 },
];

const Utilisateur =()=>{
    const history = useHistory()
    const classes = useStyles()
    const [value, setValue] = React.useState(0);
    const [rowId, setrowId] = useState([])
    const profile = useSelector((state)=>state.allprofile)
    const {profiles} = profile
    const [open, setOpen] = useState(false);
    const [opens, setOpens] = React.useState(false);
    const theme = useTheme();
    const [isDelete, setisDelete] = useState(false)
    const dispatch = useDispatch()
    const [isDel, setisDel] = useState(false)
    const msg = useSelector((state)=>state.msg.message)
    const [er, seter] = useState(false)
    const deluser=useSelector((state)=>state.deleteprofile.deleteuser)
    const [currentUser, setcurrentUser] = useState('')
  
    
   

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        setOpen(false);
       
      
      };
    const handleClickOpen = () => {
       setOpen(true);
     };

    
    
    const handleCheckDelete =()=>{
        if(rowId.length!==0){
            handleClickOpen()
            setisDelete(true)
        }else{
            setisDelete(false)
            return null
        }
    }

    const handleDelete =()=>{
        if(rowId.length!==0 && isDelete===true){
            setisDel(true)
        dispatch(deleteUser(rowId)).then(()=>{
            dispatch(getUsers())
            handleClose()

        }).then(()=>{
            setisDel(false)
            seter(true)
        })
        }
    }

    const handleRowSelected =(rowId)=>{
        if(rowId===0){
            setrowId()
        }
        setrowId(rowId)
    }
    const [openEmail, setopenEmail] = useState(false)
    const handleClickOpenEmail = () => {
        setopenEmail(true);
      };
    const handCloseEmail =()=>{
        setopenEmail(false);
       
    }
  

  


   

    const handleRowClick=(event)=>{
        history.push(
            {
                pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/utilisateur/detail/${event.row.first_name}=${event.id}`,
            }
        )
        sessionStorage.setItem('rowId',event.id)
    }
  
    
      
    const tablabel=[
            {
               title:'UTILISATEURS ACTIFS',
               num : `${profiles.filter((e)=>(e.email.activated===true)).length}`,
               css: {backgroundColor:'#F2D006',color:'white', padding:'3px', margin:'5px' ,fontWeight:300}
             },
             {
             title:'UTILISATEURS INACTIFS',
             num : `${currentUser<=2?profiles.filter((e)=>(e.email.activated===false)).length:'-o-'}`,
             css: {backgroundColor:'#F1545B',color:'white', padding:'3px', margin:'5px' ,fontWeight:300}
           },
      ]
        const handleChange = (event, newValue) => {
             setValue(newValue);
             localStorage.setItem("value",0)
           };

        const  getUserRole =()=>{
            const role  = sessionStorage.getItem('role')
            setcurrentUser(role)
        }
        useEffect(() => {
           getUserRole()
        }, [])
    
   
    return (
        <Grid container>
        <Grid item xs={12} sm={12}>
            <Paper elevation={0} className={classes.backPaper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Tabs
                          disableRipple
                          value={value}
                          onChange={handleChange}
                          TabIndicatorProps={{ className: classes.indicatedColor }}
                          variant="fullWidth"
                          style={{color:'black'}}
                          scrollButtons="on" >
                            {currentUser<=2?tablabel.map(tab => (
                              <Tab
                              key={tab}
                              label={<Typography className={classes.tab}>{tab.title}<span style={tab.css}>{tab.num}</span></Typography>} />)):tablabel.map(tab => (
                                <Tab
                                key={tab}
                                label={<Typography className={classes.tab}>{tab.title}<span style={tab.css}>{tab.num}</span></Typography>} />)).slice(0,1)}
                      </Tabs> 
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Box height={20}/>
                            <Collapse in={opens}>
                                <Alert
                                severity={er?'error':'success'}
                                action={
                                    <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpens(false);
                                        seter(true)
                                    }}
                                    >
                                    <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                >
                                { msg!==null?msg:deluser}
                                </Alert>
                            </Collapse>
                        <Box height={20}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container justify='flex-end' alignItems='flex-end'>
                            <Grid item>
                            {currentUser<=2?<Grid container direction='row' spacing={3}>
                                <Grid item><SendEmail onClick={handleClickOpenEmail} emailtitle="CRÉATION D'UTILISATEUR" startIcon={<SendIcon style={{color:'white',fontSize:14}}/>}/></Grid>
                            </Grid>:null}
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Grid container justify='flex-start' alignItems='flex-start'>
                                    <Grid item>
                                        <Typography className={classes.backPaperTitle}>Utilisateurs</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Grid container justify='flex-end' alignItems='flex-end'>
                                <Grid item>
                                        <IconButton><SortIcon/></IconButton>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TabPanel value={value} index={0}>
                          <TableData onSelectionModelChange={handleRowSelected} columns={columns} rows={profiles.filter((e)=>(e.email.activated===true))} onRowClick={handleRowClick}/>   
                      </TabPanel>
                     {currentUser<=2? <TabPanel value={value} index={1}>
                          <TableData onSelectionModelChange={(rowSelected)=>handleRowSelected(rowSelected)} columns={columns} rows={profiles.filter((e)=>(e.email.activated===false))} onRowClick={handleRowClick}/>
                        </TabPanel>:null}
                    </Grid>
                </Grid>
                <DialogBox
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    //title= {`${userbyId.first_name}`}
                    confirnClick={handleDelete}
                    Oui={isDel===true?(<CircularProgress size={10}/>):'Oui'}
                    nonOnclick={handleClose}
                    content='Etês-vous sûr de vouloir supprimer cet(cette) utilisateur(utilisatrice) ?'
                />
                <SendInvitation
                open={openEmail}
                fullScreen={fullScreen}
                //onClose={handCloseEmail}
                nonComfirm={handCloseEmail}
                />
            </Paper>
        </Grid>
    </Grid>
    )
}

export default Utilisateur