import React,{useState,useEffect} from 'react'
import {Grid, StepLabel,Stepper,Step,Typography, Button} from '@material-ui/core'
import useStyle from './style'
import Logo from '../website/assets/images/Logo.webp'
import oxide from '../website/assets/images/oxide.svg'
import axiosInstance from '../../service/axiosPub'
import ChangePassword from './widget/changePassword'
import VerificationUser from  './widget/verification'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
const steps = ["Nouveau le mot pass","Vérification code"];


const RestorePassword = (props)=>{
    
     const history= useHistory();
    const classes = useStyle()

    const {success} = useSelector(state => state.restorePassword.checkEmail)??false
    const {message} = useSelector(state => state.restorePassword.checkEmail)??false
    const [activeStep, setActiveStep] = React.useState(0);
    const refresh = props.match.params.token
    sessionStorage.setItem('refresh',refresh)
    const refresh_token =refresh!=undefined?JSON?.parse(atob(refresh?.split('.')[1])):''
    sessionStorage.setItem('email',refresh_token.email)
    sessionStorage.setItem('id',refresh_token.id)
    
    const now = Math.ceil(Date.now() / 1000);
    const handleNext = () => setActiveStep((prev) => prev + 1)
    const [err, seterr] = useState(false)

    const handleSteps = (step) => {
        switch (step) {
          case 0:
            return <ChangePassword {...props} handClick={handleNext}/>
          case 1:
            return  <VerificationUser {...props} handClick={handleNext}/>
          default:
            throw new Error('Unknown step')
        }
      }

    const getAccessToken  =async()=>{

        return await axiosInstance.post('api-token/token/refresh/',{
            refresh:sessionStorage.getItem('refresh')
        }).then((res)=>{
            seterr(false)
		    sessionStorage.setItem('token', res.data.access);
        }).catch((err)=>{
		    sessionStorage.setItem('token', '');
            seterr(true)
        }) 
    }
    
    useEffect(() => {
        getAccessToken()
    }, [])
   
   if(refresh_token.exp>now && err===false ){
    return (
        <Grid container justify='center' alignItems='center'>
       
             <Grid item>
                 <Grid container  className={classes.rootlogin} spacing={3}>
                     <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                     <Grid container justifyContent='center' alignItems='center'>
                             <Grid item>
                                 <img src={Logo} className={classes.logoLogin} alt="cec-fpi"/>
                             </Grid>
                     </Grid>
                     </Grid>
                    {
                        success===true?(
                            <Grid container justify='center' spacing={3} alignItems='center'>
                                <Grid item>
                                   
                                    <Typography align='center' color='primary'>{message} </Typography>
                                    <br/>
                                    <br/>
                                    <Typography align='center'>Veuillez cliquez sur login pour se diriger vers  notre Tableau de bord.</Typography>
                                    <br/>
                                    <Typography align='center'><Button  onClick={()=>history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/login`)} style={{textDecoration:'none'}}>Login</Button></Typography>

                                </Grid>
                            </Grid>
                        ):
                        (
                            <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Stepper
                                      activeStep={activeStep}
                                      style={{ margin: '10px 0 5px' ,backgroundColor:'#F6FBFF'}}
                                      alternativeLabel
                                  >
                                      {steps.map((label) => (
                                      <Step key={label}>
                                          <StepLabel>{label}</StepLabel>
                                      </Step>
                                      ))}
                                  </Stepper>
                                  </Grid>
                               <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                                   {handleSteps(activeStep)}
                               </Grid>
                            </Grid>
                              
                        )
                    }
                    
                 </Grid>
                 <Grid item style={{marginTop:20}}>
                         <Grid Container direction='column' justify='center' alignItems='center'>
                             <Grid item ><Typography align='center'  className={classes.poweredby}>Réalisé par :</Typography></Grid>
                             <Grid item> <center><img src={oxide} alt='Oxide logo' className={classes.oxide}/></center></Grid>
                         </Grid>
                     </Grid>
             </Grid>
    </Grid>
    )
   }else{
    return (
        <Grid container justify='center' alignItems='center'>
       
    <Grid item>
        <Grid container  className={classes.rootlogin} spacing={3}>
            <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container justifyContent='center' alignItems='center'>
                    <Grid item>
                        <img src={Logo} className={classes.logoLogin} alt="cec-fpi"/>
                    </Grid>
            </Grid>
            </Grid>
           
           <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
               <Typography align='center'>
                        Bonjour Mr/Mdme, 
               </Typography>
              
           </Grid>
           <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
           <Typography align='center'>
               Nous sommes désolés de vous dire que le lien a déjà expiré, veuillez contacter l'administrateur pour obtenir de l'aide. ou cliquez sur ce lien <a href=' http://127.0.0.1:3000/Contactez_nous'>Contact l'administrateur</a><br/>
            Merci de votre compréhension.<br/>
            Meilleure considération :
                    Cec-fpi/sud-kivu
               </Typography>
           </Grid>
        </Grid>
        <Grid item style={{marginTop:20}}>
                <Grid Container direction='column' justify='center' alignItems='center'>
                    <Grid item ><Typography align='center'  className={classes.poweredby}>Powered by :</Typography></Grid>
                    <Grid item> <center><img src={oxide} alt='Oxide logo' className={classes.oxide}/></center></Grid>
                </Grid>
            </Grid>
    </Grid>
</Grid>
    )

   }
}

export default RestorePassword;