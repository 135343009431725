import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Fab } from '@material-ui/core';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';

const Style = makeStyles((theme)  => ({
    fab: {
        position: "fixed",
        bottom: "2em",
        right: "1em",
        zIndex:99
   }
}));
  


const BackTop = (props) => {
    
    const classes = Style()

    useEffect(() => {
        const HandleScroll = () => {
            setScrollPosition(window.pageYOffset)
        }
        window.addEventListener('scroll', HandleScroll)
        return () => window.removeEventListener('scroll', HandleScroll)
    }, [])


    const [Scrollposition, setScrollPosition] = useState(0)
    return (
        Scrollposition > window.innerHeight &&
            <Fab size="small"
            className={classes.fab}
            onClick = {() => window.scrollTo(0,0)}
            >
                <KeyboardArrowUpOutlinedIcon />
            </Fab>

    );
};

export default BackTop;