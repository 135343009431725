import React,{useState,useEffect}  from  'react'
import {Button, Grid,Typography,Box ,Collapse,IconButton} from '@material-ui/core'
import ReactCodeInput from 'react-code-input'
import CircularProgress from '@material-ui/core/CircularProgress';
import {sendCodeAction,checkVerificationCodeActions} from '../../../store/actions/auth-actions'
import {useDispatch,useSelector} from 'react-redux'
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import axiosInstance from '../../../service/axiosPub';



const VerificationUser = ()=>{
    const {fn,ln,addr,fon,phone,prov,ville}=JSON.parse(sessionStorage.getItem('data2'))
    const createdby = sessionStorage.getItem('createdby')
    const [codeInput, setcodeInput] = useState(0)
    const [user, setuser] = useState('')
    const [userText, setuserText] = useState("un code de vérification a été envoyé à votre adresse e-mail. Veuillez le saisir dans les cases ci-dessous.")
    const dispatch = useDispatch()
    const [ison, setison] = useState(false)
    const [opens, setOpens] = React.useState(false);
    const {message} = useSelector(state => state.msg)
    const [err, seterr] = useState(false)
    const role  = sessionStorage.getItem('role')
    const {email,org,pass}=JSON.parse(sessionStorage.getItem('data1'))
   
    

  
  

    const takeCodeInput = (e)=>{
        const value = e
        if(value.length===6){
            setison(true)
            setcodeInput(value)
            dispatch(checkVerificationCodeActions(
               email,
               pass,
               role,
               org,
               fn,
               ln,
               phone,
               addr,
               fon,
               prov,
               ville,
               createdby,
               value
            )).then(()=>{
                setOpens(false)
                seterr(false)
                sessionStorage.removeItem('data1')
                sessionStorage.removeItem('data2')
                sessionStorage.removeItem('role')
                sessionStorage.removeItem('createdby')
                blacklistToken()
                sessionStorage.removeItem('refresh')
                sessionStorage.removeItem('token')

            },()=>{
                setOpens(true)
                setison(false)
                seterr(true)
            })
           
        }
        return 
    }
    const sendCode = ()=>{ 
        setison(true)
        setOpens(false)
        dispatch(sendCodeAction(email,fn)).then(()=>{
            setison(false)
        },()=>{
            setOpens(true)
           
            seterr(true)
        })
    }
    const blacklistToken = async()=>{
      return  await axiosInstance.post('api/account/register/blacklist/',{
            refresh:sessionStorage.getItem('refresh')
        })
    }
    
    useEffect(() => {
      
       if(JSON.parse(sessionStorage.getItem('data2'))){
           const {fn,ln} = JSON.parse(sessionStorage.getItem('data2'))
           setuser(fn+"\n"+ln)
       }
    }, [])
    return (
        <Grid container direction='column' justifyContent='center' spacing={3}>
         <Grid item>
            <Box height={20}/>
                <Collapse in={opens}>
                    <Alert
                      severity={'error'}
                      action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpens(false);    
                             }}>
                        <CloseIcon fontSize="inherit" />
                        </IconButton>}>
                    { message}
                    </Alert>
                </Collapse>
        </Grid>
        <Grid item >
            <Typography align='center'>
                Bonjour Mr/Mdme {user}, {userText}
            </Typography>
        </Grid>
       
        
       <Grid item>

          <Grid container justifyContent='center'>
          {
           codeInput.length===6 && err===false ?(
               <Grid item>
                   <CircularProgress size={40}/>
               </Grid>
           ):
           (<Grid item>
            <ReactCodeInput name='code' type='text' fields={6}  onChange={takeCodeInput}/>
      </Grid>)
       }

          </Grid>
      </Grid>
    
       <Grid item >
            <Grid  container justifyContent='center'>
               {
                   ison===false && err==true?
                   <Grid item> <Button  color='primary' variant={ison===true?'text':'outlined'} onClick={sendCode}>{ison===true?<CircularProgress size={20}/>:('Reenvoyer le code')}</Button></Grid>:null
                    
               }
            </Grid>
        </Grid>
    </Grid>
    )
}

export default VerificationUser