import {
    EMAIL_SEND_SUCCESS,
    EMAIL_SEND_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_FAIL,
    LOGOUT,
    SET_MESSAGE,
    REFRESH_TOKEN,
    ADD_USER_SUCCES,
    ADD_USER_FAIL,
    ADD_PROFILE_SUCCES,
    ADD_PROFILE_FAIL,
    ADD_SEND_CODE_SUCCES,
    ADD_SEND_CODE_FAIL,
    CHECK_VERIFICATION_CODE_SUCCES,
    CHECK_VERIFICATION_CODE_FAIL,
    SEND_CONTACT_SUCCESS,
    SEND_CONTACT_FAIL,
    FETCH_USERS_BY_ID_SUCCES,
    FETCH_USERS_BY_ID_FAIL,
    UPDATE_USER_SUCCES,
    UPDATE_USER_FAIL,
    DELETE_USER_BY_ID_SUCCES,
    DELETE_USER_BY_ID_FAIL,
    CHANGE_PASSWORD_USER_SUCCES,
    CHANGE_PASSWORD_USER_FAIL,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAIL,
    ACTIVATED_USER_SUCCES,
    ACTIVATED_USER_FAIL,
    CHECK_EMAIL_SUCCESS,
    CHECK_EMAIL_FAIL,
    SEND_EMAIL_CODE_SUCCESS,
    SEND_EMAIL_CODE_FAIL,
    RESTORE_PASSWORD_SUCCESS,
    RESTORE_PASSWORD_FAIL,
    CHECK_EMAIL_CODE_SUCCESS,
    CHECK_EMAIL_CODE_FAIL,
    CLEAR_DATA
  } from '../contants/type';
  
  import AuthService from '../../service/auth';


  export const logins = (email, password) => (dispatch) => {
    return AuthService.login(email,password).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
  
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: LOGIN_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  export const sendEmail =(fullname,email,role,organisation)=>dispatch=>{
    return AuthService.sendInvitation(fullname,email,role,organisation).then((data)=>{
      dispatch({
        type:EMAIL_SEND_SUCCESS,
        payload:data
      })
      return Promise.resolve();

    },(error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: EMAIL_SEND_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
   
  }

  export const refreshToken = (accessToken) => (dispatch) => {
    dispatch({
      type: REFRESH_TOKEN,
      payload: accessToken,
    })
  }

  export const logout  =(refesh)=>dispatch=>{
    return AuthService.lougout(refesh).then((res)=>{
      dispatch({
        type: LOGOUT,
      })
      return Promise.resolve()
    },(error)=>{
      const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: LOGOUT_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      
    })
  }


  export const userCreationAction =(
    email,
    password,
    role,
    organisation,
    created_by,
    code
  )=>dispatch=>{
    return AuthService.userCreation(
      email,
      password,
      role,
      organisation,
      created_by,
      code
    ).then((data)=>{
      dispatch({
        type:ADD_USER_SUCCES,
        payload:data
      })
      return Promise.resolve();

    },(error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: ADD_USER_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
   
  }

  
  export const userProfileAction =(
    email,
    first_name,
    last_name,
    phonenumber,
    address,
    organisation,
    fonction,
    province,
    ville_territory,
    created_by
  )=>dispatch=>{
    return AuthService.userProfile(

      email,
      first_name,
      last_name,
      phonenumber,
      address,
      organisation,
      fonction,
      province,
      ville_territory,
      created_by
    ).then((data)=>{
      dispatch({
        type:ADD_PROFILE_SUCCES,
        payload:data
      })
      return Promise.resolve();

    },(error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: ADD_PROFILE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
   
  }

  export const sendCodeAction =(
    email,
    fullname
  )=>dispatch=>{
    return AuthService.sendVerificationCode(
      email,
      fullname
    ).then((data)=>{
      dispatch({
        type:ADD_SEND_CODE_SUCCES,
        payload:data
      })
      return Promise.resolve();

    },(error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: ADD_SEND_CODE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
   
  }

export const checkVerificationCodeActions = (
      email,
      password,
      role,
      organisation,
      first_name,
      last_name,
      phonenumber,
      address,
      fonction,
      province,
      ville_territory,
      created_by,
      code
)=>dispatch=>{
  return AuthService.checkVerificationCode(
      email,
      password,
      role,
      organisation,
      first_name,
      last_name,
      phonenumber,
      address,
      fonction,
      province,
      ville_territory,
      created_by,
      code
  ).then((data)=>{
   dispatch({
    type: CHECK_VERIFICATION_CODE_SUCCES,
    payload:data
   })
   return Promise.resolve()
  },(error)=>{
    const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: CHECK_VERIFICATION_CODE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
  )
}


export const contactActions = (
  nom,
  prenom,
  email,
  phonenumber,
  message,
  token
)=>dispatch=>{
return AuthService.contactForm(
  nom,
  prenom,
  email,
  phonenumber,
  message,
  token
).then((data)=>{
dispatch({
type: SEND_CONTACT_SUCCESS,
payload:data
})
return Promise.resolve()
},(error)=>{
const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    dispatch({
      type: SEND_CONTACT_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  }
)
}

export const userbyIds = (id) =>dispatch=>{
  return AuthService.userById(id).then((data)=>{
  dispatch({
  type: FETCH_USERS_BY_ID_SUCCES,
  payload:data
  })
  return Promise.resolve()
  },(error)=>{
  const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: FETCH_USERS_BY_ID_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  )
}

export const updateUser= (
  id,
  email,
  role,
  organisation,
  activated
  ) =>dispatch=>{
  return AuthService.updateUser(
    id,
    email,
    role,
    organisation,
    activated
  ).then((data)=>{
  dispatch({
  type: UPDATE_USER_SUCCES,
  payload:data
  })
  return Promise.resolve()
  },(error)=>{
  const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_USER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  )
}



export const activatedUser= (
  id,
  email,
  activated
  ) =>dispatch=>{
  return AuthService.activateUser(
    id,
    email,
    activated
  ).then((data)=>{
  dispatch({
  type: ACTIVATED_USER_SUCCES,
  payload:data
  })
  return Promise.resolve()
  },(error)=>{
  const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ACTIVATED_USER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  )
}


export const deleteUser = (id) =>dispatch=>{
  return AuthService.deleteUser(id).then((data)=>{
  dispatch({
  type: DELETE_USER_BY_ID_SUCCES,
  payload:data
  })
  return Promise.resolve()
  },(error)=>{
  const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_USER_BY_ID_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  )
}


export const changeUserPassword= (
  id,
  password
  ) =>dispatch=>{
  return AuthService.changePassword(
    id,
    password
  ).then((data)=>{
  dispatch({
  type: CHANGE_PASSWORD_USER_SUCCES,
  payload:data
  })
  return Promise.resolve()
  },(error)=>{
  const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CHANGE_PASSWORD_USER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  )
}


export const allUserApi = () =>dispatch=>{
  return AuthService.alluUserApi().then((data)=>{
  dispatch({
  type: FETCH_USERS_SUCCESS,
  payload:data
  })
  return Promise.resolve()
  },(error)=>{
  const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: FETCH_USERS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  )
}

export const checkEmailAction =(
  email
)=>dispatch=>{
  return AuthService.checkEmail(
    email
  ).then((data)=>{
    dispatch({
      type:CHECK_EMAIL_SUCCESS,
      payload:data
    })
    return Promise.resolve();

  },(error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CHECK_EMAIL_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
 
}


export const sendEmailCode = (email) =>dispatch=>{
  return AuthService.sendEmailCode(email).then((data)=>{
  dispatch({
  type: SEND_EMAIL_CODE_SUCCESS,
  payload:data
  })
  return Promise.resolve()
  },(error)=>{
  const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SEND_EMAIL_CODE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  )
}

export const restorePasswordActions = (
      id,
      password,
) =>dispatch=>{
  return AuthService.restorePassword(
      id,
      password,

  ).then((data)=>{
  dispatch({
  type: RESTORE_PASSWORD_SUCCESS,
  payload:data
  })
  return Promise.resolve()
  },(error)=>{
  const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: RESTORE_PASSWORD_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  )
}



export const checkEmailCodeAction =(
  code
)=>dispatch=>{
  return AuthService.checkEmailCode(
    code
  ).then((data)=>{
    dispatch({
      type:CHECK_EMAIL_CODE_SUCCESS,
      payload:data
    })
    return Promise.resolve();

  },(error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: CHECK_EMAIL_CODE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
 
}
export const removeData = () => {
  return {
    type:CLEAR_DATA,
  };
};





