import React,{useEffect} from "react";
import PoleComp from '../../widgets/demandeComp'
import Image from '../../assets/images/pole/demande.JPG'
import SEO from '../../sc/seo'

const Demande = () => {
  const Actus = [{},{},{}]

  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])
  return (
    <React.Fragment>
      <SEO
        title="La demande"
        keywords="La demande"
      />
      <PoleComp
        image={Image}
        title="LA DEMANDE"
        highlight="#FFAE02"
        body="Il existe différentes catégories d'acteurs du secteur privé : les grandes entreprises et les multinationales, les start-ups et les petites et moyennes entreprises (PME), les structures artisanales du secteur formel et non formel et les petits commerces, les entreprises sociales, et d’autres partenaires du secteur privé, qui sont généralement représentés par des associations professionnelles, des chambres de commerce, des fédérations et/ou des organisations faîtières.<br/><br/>Les entreprises privées sont l'épine dorsale d'une économie et jouent un rôle crucial dans la création d'emplois et la réduction de la pauvreté. Il est donc essentiel de renforcer l'environnement des entreprises et de trouver des solutions innovantes pour le développement économique et la promotion du secteur privé. En outre, les entreprises ont besoin de capitaux d'investissement et d'une main-d'œuvre qualifiée pour créer plus d’emplois.<br/><br/>Ainsi le succès du système de l’Enseignement Technique et de la Formation Professionnelle (ETFP) repose sur une collaboration étroite avec une large alliance de partenaires issus des ministères, des autorités locales, des écoles professionnelles, des ONG et, surtout, du secteur privé. Il est donc important d’accroître davantage et d’évaluer à échéance régulière l’implication du monde du travail, particulièrement du secteur privé productif et des partenaires sociaux dans la prestation, les évaluations, la certification et le financement de la formation, du niveau central au niveau local, dans l’enseignement formel, non formel et informel.<br/><br/><i>Ainsi le secteur privé doit jouer un rôle explicite et décisif dans l'élaboration et la mise en œuvre du développement des compétences.</i>"
        actuTitle="Les actualités de la demande"
        actus={Actus}
      />
    </React.Fragment>
  );
};

export default Demande;
