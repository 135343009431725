import { makeStyles } from '@material-ui/core/styles'

const Style = makeStyles((theme)=> ({

   
    root:{
        
        marginTop:80,
        marginBottom:'5em',
        color:'#1D2F3F',
        [theme.breakpoints.down("md")]:{
            padding:'3em 0em',
        },
        [theme.breakpoints.up("md")]:{
            padding:'6em 0em',
        },
        "& h5":{
            fontWeight:theme.typography.fontWeightBold,
        }
    },
    pageHead:{
        textAlign:'center',

        '& h2':{
            fontSize:"2.65rem",
            fontWeight: theme.typography.fontWeightBold,
            marginBottom: '1em'
        }
    },
    sectionTitle:{
        margin:"2.3em 0vw",
    },
    tab:{
        margin:"3em 0vw",
    },

    tableHead: {
    }



}))

export default Style;