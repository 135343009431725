import React,{useState,useEffect}  from 'react'
import { Grid, CircularProgress,Typography, Box ,Collapse,IconButton} from '@material-ui/core'
import Backpaper from '../../../../sc/backPaper';
import EditIcon from '@material-ui/icons/Edit';
import {BtnOrange,BtmRed} from '../../../../sc/utilities'
import { useDispatch,useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogBox from '../../../../sc/dialog';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import {deletePartenaire,getPartenaire,getbyIdPartenaire} from '../../../../../../store/actions/partenaire-actions'
import useStyle from '../style'


const PartenaireDetail =()=>{
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const history = useHistory()
    const classes  =useStyle()
    const [isDel, setisDel] = useState(false)
    const [opens, setOpens] = React.useState(false);
    const dispatch = useDispatch()
    const {partenairebyId}  = useSelector((state)=>state.partenaire)
    const msg = useSelector((state)=>state.msg.message)
    const [currentUser, setcurrentUser] = useState('')


    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        setOpen(false);
      };
    const handleClickOpen = () => {
       setOpen(true);
     };

     const handleModifier = ()=>{
        history.push({
                pathname: `/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/partenaire/create`,
                data: sessionStorage.getItem('rowId')
        })
    }
    
    
    const handleDelete =()=>{
        handleClickOpen()
    }
    const handleConfirmDelete = (id)=>{
        setisDel(true)
        dispatch(deletePartenaire(id)).then(()=>{
             dispatch(getPartenaire())
              history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/partenaire`)  
        })
}

     const  getUserRole =()=>{
        const role  = sessionStorage.getItem('role')
        setcurrentUser(role)
        }
    useEffect(() => {
       dispatch(getbyIdPartenaire(`${parseInt(sessionStorage.getItem('rowId'))}`)).then(()=>{

       },()=>{
               setOpens(true)
       })
       getUserRole()
    }, [])

    if(partenairebyId===undefined){
        return (
            <Backpaper
            yellowbtn = {currentUser<=2?<BtnOrange 
                onClick={handleModifier} 
                //disabled={partenairebyId===0?true:false}
                 btnYellowTitle="MODIFIER" startIcon={<EditIcon style={{fontSize:14}}/>} />:null}
            redbtn ={currentUser<=2?<BtmRed onClick={handleDelete}/>:null}
    
            content={
             <Grid container justifyContent='center' style={{paddingBottom:50}}>
                 <Grid item>
                      <Grid container justifyContent='space-between' spacing={4} className={classes.spacingBtw}>
                                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                          <Grid container direction='column' spacing={1}>
                                              <Grid item ><Typography ></Typography>Organisation</Grid>
                                                
                                                  <Skeleton  variant="rect" height={10}  width={100}/>
                                                 
                                    
                                          </Grid>
                                  </Grid>
                                 
                                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                          <Grid container direction='column' spacing={1}>
                                              <Grid item ><Typography ></Typography>Localisation</Grid>
                                          
                                                  <Skeleton  variant="rect" height={10}  width={100}/>
                                                 
                                                
    
                                          </Grid>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                          <Grid container direction='column' spacing={1}>
                                              <Grid item ><Typography ></Typography>Email</Grid>
                                            
                                                  <Skeleton  variant="rect" height={10}  width={100}/>
                                                 
                                              
    
                                          </Grid>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                          <Grid container direction='column' spacing={1}>
                                              <Grid item ><Typography ></Typography>Link</Grid>
                                            
                                                  <Skeleton  variant="rect" height={10}  width={100}/>
                                                 
                                                
    
                                          </Grid>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                          <Grid container direction='column' spacing={1}>
                                             
                                                 
                                                  <Skeleton  variant="rect" height={10}  width={100}/>
                                                 
                                                 
    
                                          </Grid>
                                  </Grid>
                                  
                                  
                           
                          
                      </Grid>
                     < DialogBox
                         fullScreen={fullScreen}
                         open={open}
                         onClose={handleClose}
                         title= {partenairebyId?`${partenairebyId.name}`:null}
                         nonOnclick={handleClose}
                         confirnClick={()=>handleConfirmDelete(partenairebyId.id)}
                         content={`Êtez - vous sûr de vouloir supprimer ce partenaire ${partenairebyId?`${partenairebyId.name}`:null} `}/>
                         
                 </Grid>
             </Grid>
                  
              } 
             
        />
        )
    }else{
        return (
            <Backpaper
            yellowbtn = {currentUser<=2?<BtnOrange 
                onClick={handleModifier} 
                //disabled={partenairebyId===0?true:false}
                 btnYellowTitle="MODIFIER" startIcon={<EditIcon style={{fontSize:14}}/>} />:null}
            redbtn ={currentUser<=2?<BtmRed onClick={handleDelete}/>:null}
    
            content={
             <Grid container justifyContent='center' style={{paddingBottom:50}}>
                 <Grid item xs={12}>
                         <Box height={20}/>
                         <Collapse in={opens}>
                         <Alert
                         severity={'error'}
                         action={
                                 <IconButton
                                 aria-label="close"
                                 color="inherit"
                                 size="small"
                                 onClick={() => {
                                 setOpens(false);
                                 }}
                                 >
                                 <CloseIcon fontSize="inherit" />
                                 </IconButton>
                         }
                         >
                         { msg}
                         </Alert>
                         </Collapse>
                         <Box height={20}/>
                 </Grid>
                 <Grid item>
                      <Grid container justifyContent='space-between' spacing={4} className={classes.spacingBtw}>
                                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                          <Grid container direction='column' spacing={1}>
                                              <Grid item ><Typography ></Typography>Organisation</Grid>
                                               
                                                 <Grid item ><Typography style={{fontWeight:'bold'}}>{partenairebyId.name}</Typography></Grid>:
                                                
                                          </Grid>
                                  </Grid>
                                 
                                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                          <Grid container direction='column' spacing={1}>
                                              <Grid item ><Typography >Localisation</Typography></Grid>
                                              
                                                 <Grid item ><Typography style={{fontWeight:'bold'}}>{partenairebyId.localisation}</Typography></Grid>:
                                                 
    
                                          </Grid>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                          <Grid container direction='column' spacing={1}>
                                              <Grid item ><Typography >Email</Typography></Grid>
                                                 <Grid item ><Typography style={{fontWeight:'bold'}}>{partenairebyId.email}</Typography></Grid>:
                                          </Grid>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                          <Grid container direction='column' spacing={1}>
                                              <Grid item ><Typography >Lien</Typography></Grid>
                                                 <Grid item ><Typography style={{fontWeight:'bold'}}>{partenairebyId.link}</Typography></Grid>:
                                          </Grid>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                          <Grid container direction='column' spacing={1}>
                                              <Grid item ><Typography ></Typography>Description</Grid>
                                                 <Grid item ><Typography style={{fontWeight:400}}>{partenairebyId.description}</Typography></Grid>:
                                          </Grid>
                                  </Grid>   
                      </Grid>
                     < DialogBox
                         fullScreen={fullScreen}
                         open={open}
                         onClose={handleClose}
                         title= {partenairebyId?`${partenairebyId.name}`:null}
                         nonOnclick={handleClose}
                         Oui={isDel===true?<CircularProgress size={10}/>:"Oui"}
                         confirnClick={()=>handleConfirmDelete(partenairebyId.id)}
                         content={`Etês-vous sûr de vouloir supprimer ce partenaire?`}/>
                         
                 </Grid>
             </Grid>
                  
              } 
             
        />
        )
    }

  
}

export default PartenaireDetail
