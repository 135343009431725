import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  root: {
    background: "#F8F9FA",
    padding: "5em 0em",
    "& h2": {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: "2.75rem",
    },
    "& span": {
      color: "#D12028",
    },
  },
  iframe:{
    width: "100%",
    height:"100vh",
    minHeight: 240,
    maxHeight:330,
    [theme.breakpoints.up("xl")]: {
      maxWidth:1080,
      minHeight: 400,

    },
    [theme.breakpoints.down("sm")]: {
      margin: "2em 0em",
     },
  },
  richtext: {
    fontSize: 40,
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      fontWeight: 500,
    },
  },
}));

const Section = () => {
  const classes = useStyle();

  return (
    <Grid container className={classes.root} justifyContent="space-evenly">
      <Grid item xs={10} md={5} lg={4} xl={4}>
        <Typography variant ="h4" className={classes.richtext}>
          Vidéo de sensibilisation sur la formation
          <span> professionnelle </span>
          et l'apprentissage des
          <span> métiers</span>.
        </Typography>
      </Grid>
      <Grid item xs={10} md={5} lg={5} xl={5}>
        <iframe
          title="Sensibilisation"
          className={classes.iframe}
          src="https://www.youtube.com/embed/Y9St27yzdz8"
        />
      </Grid>
    </Grid>
  );
};

export default Section;
