import React,{useState,useEffect} from  'react'
import { Grid, Typography, Box ,Collapse,IconButton} from '@material-ui/core'
import Backpaper from '../../../../sc/backPaper';
import EditIcon from '@material-ui/icons/Edit';
import {BtnOrange} from '../../../../sc/utilities'
import { useDispatch,useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import useStyle from '../style'
import { userProfile } from '../../../../../../store/actions/user-actions';
import { clearMessage } from '../../../../../../store/actions/message-actions';




const UserProfileDetail =()=>{
    const history = useHistory()
    const classes  =useStyle()

    const [opens, setOpens] = React.useState(false);
    const dispatch = useDispatch()
    const  {profile} = useSelector((state)=>state.userProfile)
    const msg = useSelector((state)=>state.msg.message)
    const [currentUser, setcurrentUser] = useState('')

     const handleModifier = ()=>{
        history.push({
                pathname: `/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/utilisateur/create`,
                data: sessionStorage.getItem('rowId')
        })
    }
    
   const  getUserRole =()=>{
        const role  = sessionStorage.getItem('role')
        setcurrentUser(role)
    }
    useEffect(() => {
       dispatch(userProfile(`${parseInt(sessionStorage.getItem('rowId'))}`)).then(()=>{

       },()=>{
               setOpens(true)
       })
       getUserRole()
    }, [])
    return (
       <Backpaper
       title={'Profil'}
           yellowbtn = {currentUser<=5?<BtnOrange onClick={handleModifier} disabled={profile!==undefined?false:true} btnYellowTitle="MODIFIER" startIcon={<EditIcon style={{fontSize:14}}/>} />:null}
           content={
            <Grid container justifyContent='center' style={{paddingBottom:50}}>
                <Grid item xs={12}>
                        <Box height={20}/>
                        <Collapse in={opens}>
                        <Alert
                        severity={'error'}
                        action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                setOpens(false);
                                dispatch(clearMessage())
                                history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/utilisateur`)
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                        }
                        >
                        { msg}
                        </Alert>
                        </Collapse>
                        <Box height={20}/>
                </Grid>
                <Grid item>
                     <Grid container justifyContent='space-between' spacing={4} className={classes.spacingBtw}>
                                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography >Nom</Typography></Grid>
                                                {profile!==undefined?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{profile.first_name}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }
                                         </Grid>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography >Prénom</Typography></Grid>
                                             {profile!==undefined?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{profile.last_name}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography >Organisation</Typography></Grid>
                                             {profile!==undefined?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{profile.organisation}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography >Fonction</Typography></Grid>
                                             {profile!==undefined?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{profile.fonction}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography >Adresse</Typography></Grid>
                                             {profile!==undefined?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{profile.address}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography >Numéro de téléphone</Typography></Grid>
                                             {profile!==undefined?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{profile.phonenumber}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography >Ville & Territoire</Typography></Grid>
                                             {profile!==undefined?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{profile.ville_territory}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography >Province</Typography></Grid>
                                             {profile!==undefined?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{profile.province}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }

                                         </Grid>
                                 </Grid>
                                 
                     </Grid>
                     <br/>
                     <br/>
                     
                </Grid>
            </Grid>
                 
             } 
            
       />
    );
}

export default UserProfileDetail