import api from './axiosApi';


const addEspace =(
    name,
    sharedBy,
    document,
    description,
    create_by,
)=>{

    const formData  =new FormData() 
    formData.append('name',name)
    formData.append('sharedBy',sharedBy)
    formData.append('document',document)
    formData.append('description',description)
    formData.append('create_by',create_by)
   return api.post('api-space/addSpace/',
    formData
   ).then((res)=>{
        return res.data
    }).catch((err)=>{
        console.log(err.request);
    })
}

const getEspace =()=>{
    return api.get('api-space/space/').then((res)=>{
         return res.data.Spaces
     })
 }

 const deleteEspace =(id)=>{
    return api.delete(`api-space/space/${id}/`).then((res)=>{
        return res.data
    })
}

const updateEspace =(
    id,
    // name,
    // sharedBy,
    document,
    description,
    )=>{
        const formData  =new FormData() 
        // formData.append('name',name)
        // formData.append('sharedBy',sharedBy)
        formData.append('document',document)
        formData.append('description',description)
    return api.patch(`api-space/space/${id}/`,formData).then((res)=>{
        return res.data
    })
}

const updateEspaceWithoutLogo =(
    id,
    // name,
    // shareBy,
    description,
    )=>{
        const formData  =new FormData() 
        // formData.append('name',name)
        // formData.append('shareBy',shareBy)
        formData.append('description',description)
    return api.patch(`api-space/space/withoutdoc/${id}/`,formData).then((res)=>{
        return res.data
    })
}

const getbyIdEspace =(id)=>{
    return api.get(`api-space/spaceOneDetail/${id}/`).then((res)=>{
        return res.data.Space
    })
}

const EspaceService = {
    getEspace,
    getbyIdEspace,
    deleteEspace,
    updateEspace,
    addEspace,
    updateEspaceWithoutLogo
}

export default EspaceService;









