import {
    FETCH_MEMBRE_SUCCES,
    FETCH_MEMBRE_FAIL,
    DELETE_MEMBRE_SUCCES,
    DELETE_MEMBRE_FAIL,
    ADD_MEMBRE_SUCCES,
    ADD_MEMBRE_FAIL,
    UPDATE_MEMBRE_SUCCES,
    UPDATE_MEMBRE_FAIL,
    FETCH_MEMBRE_BY_ID_FAIL,
    FETCH_MEMBRE_BY_ID_SUCCES,
    CLEAR_DATA
} from '../contants/type'


const membres =(state=[],action)=>{
    const {type,payload} = action

    switch(type){
        case FETCH_MEMBRE_SUCCES: 
            return {
                ...state,
                membres:payload
            }
        case FETCH_MEMBRE_FAIL:
            return {}
        case DELETE_MEMBRE_SUCCES:
            return {
                ...state,
                deleteMembre:"Le membre a été bien supprimer"
            }
        case DELETE_MEMBRE_FAIL:
            return {
                ...state,
                deleteMembre:"Le membre n'a pas été supprimer, Veuillez verifier votre connexion"
            }
        case ADD_MEMBRE_SUCCES:
            return {
                ...state,
                addedmembre: payload
            }
        case ADD_MEMBRE_FAIL: 
            return{}
        case UPDATE_MEMBRE_SUCCES:
            return {
                ...state,
                updatedmembre:payload
            }
        case UPDATE_MEMBRE_FAIL:
            return {
                ...state
            }
        case CLEAR_DATA:
            return {}
        
        default:
            return state
    }


}
const getMemberById =(state= [],action)=>{
    const {type, payload}  = action

    switch(type){
        case FETCH_MEMBRE_BY_ID_SUCCES:
            return {
                ...state,
                membrebyId:payload
            }
        case FETCH_MEMBRE_BY_ID_FAIL:
                return {
                    ...state
                }
        
        default:
            return state
    }
}


const membrePub =(state=[],action)=>{
    const {type,payload} = action

    switch(type){
        case FETCH_MEMBRE_SUCCES: 
            return {
                ...state,
                membres:payload
            }
        case FETCH_MEMBRE_FAIL:
            return {}
        
        case FETCH_MEMBRE_BY_ID_SUCCES:
            return {
                ...state,
                membrebyId:payload
            }
        case FETCH_MEMBRE_BY_ID_FAIL:
                return {
                    ...state
                }
        case CLEAR_DATA:
            return {}
       
        default:
            return state
    }


}

const membreReducer= {
    membres ,
    getMemberById,
    membrePub
}

export default membreReducer