import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  // Footer Style

  big_title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  list_title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  footer_bg: {
    backgroundColor: "#F6FBFF",
    marginTop: "10px",
    color:"#1D2F3F"
  },
  oxide_color: {
    color: "#EB9B29",
    textDecoration:"none"
  },
  link_style: {
    marginLeft:0,
    marginRight:0,
    textDecoration: "none",
    color: "#1D2F3F",
    "&:hover": {
      background: "#ffae02",
    },
   
  },

  // AppBar Styles

  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  sectionDesktop: {
    padding: "5px",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  whitebg: {
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "white",
    },
  },
  alignApp: {
    [theme.breakpoints.up("sm")]: {
      justifyContent:"center"
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  media: {
    height: 45,
  },

  // Margins and Paddings
  margin_top_5: {
    marginTop: "5vh",
  },
  margin_topbot_5: {
    marginTop: "5vh",
    marginBottom: "5vh",
  },
  margin_botton_10: {
    marginBottom: "10vh",
    marginLeft:0
  },
  margin_botton_5: {
    marginBottom: "5vh",
  },
  margin_botton_3: {
    marginBottom: "3vh",
  },
  margin_botton_4: {
    marginBottom: "4vh",
  },
  margin_botton_2: {
    marginBottom: "2vh",
  },
  margin_botton_1: {
    marginBottom: "1vh",

   
  },
  icon:{
    
    [theme.breakpoints.down('xs')]:{
      fontSize:15
    }
  },
  typodescr:{
    width: '70%',
    textAlign:theme.typography.textBodyAlign,
    [theme.breakpoints.down('xs')]:{
      width:'100%'
    }

  },
  oxide:{
    [theme.breakpoints.down('xs')]:{
      fontSize:12,
      marginTop:10
    }
  },
  swiss:{
    textDecoration:'none'
  },

  flexstartfooter:{
   [theme.breakpoints.down('xs')]:{
    justifyContent:'start'
   }
  }

}));
