import React,{useState,useEffect} from 'react'
import {Button, IconButton,Collapse,Grid, MenuItem, FormControl,FormControlLabel,RadioGroup,Radio,Box,Select, TextField, Typography} from '@material-ui/core'
import useStyles from '../style'
import CircularProgress from '@material-ui/core/CircularProgress';
import  {useForm} from 'react-hook-form'
import { useSelector,useDispatch } from 'react-redux';

import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import {userbyIds,updateUser} from  '../../../../../../store/actions/auth-actions'





const UpdateUserAccount =(props)=>{
    const classes = useStyles()
    const dispatch  = useDispatch()
    const history  = useHistory()
    const {register,handleSubmit,reset,formState:{errors}} = useForm()
    const [isCircular, setisCircular] = useState(false)
    const [changerole, setchangerole] = useState() 
    const {message} = useSelector((state)=>state.membre.addedmembre)??''
    const [open, setOpen] = React.useState(false);
    const msg = useSelector((state)=>state.msg.message)
    const  {user} = useSelector((state)=>state.userById)



    const onSubmit =(data,e)=>{
        e.preventDefault()
        setOpen(false)
        if(data){
           if(props.location.data){
            setisCircular(true)
            dispatch(updateUser(
                props.location.data,
                data.em,
                role,
                data.org,
                display,
            )).then(()=>{
                setisCircular(false)
                setOpen(true)
                setrole('')
                reset()
                history.push({
                   pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/utilisateur/parametre/${props.location.data}`,
                   data:props.location.data
                })
                sessionStorage.setItem('rowId',props.location.data,)
                
            },()=>{
                setOpen(true)
                setisCircular(false)
                //reset()
            })
           }
        }
    }
    const [display, setdisplay] = useState('True')
    const isDisplay=(e)=>{
        const value = e.target.value 
        setdisplay(value)
      }
    
    const [role, setrole] = useState('')
    const getrole = (e)=>{
        const value = e.target.value
        setrole(value)
    }
    useEffect(() => {
        if(history.action ==='POP'){
            history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/utilisateur`)
        }
           if(props.location.data){
            dispatch(userbyIds(`${parseInt(props.location.data)}`)).then(()=>{
                reset({
                    em:user.email,
                    org:user.organisation,
                    role:user.role
                })
        
                if(user.activated===true){
                    setdisplay('True')
                }else{
                    setdisplay('False')
                }
                if(user.role==="Administrateur"){
                    setrole('1')
                }
                else if(user.role==="Secrétaire"){
                    setrole('2')
                }
                else if(user.role==="Auteur"){
                    setrole('3')
                }
                else if(user.role==="Contributeur"){
                    setrole('4')
                }
                else if(user.role==="Membre"){
                    setrole('5')
                }
               
                setchangerole(parseInt(props.location.data))
            

            })
           }
           
    }, [])
    return (
     
      
            <Grid container direction='column' className={classes.root}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container justifyContent='center' alignItems="center">
                    <Grid item><Typography variant='h3' style={{fontWeight:'bold'}} color='primary'>Actualiser mon compte</Typography></Grid>
                </Grid>
            </Grid>
            <Box height={40}/>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Collapse in={open}>
                        <Alert
                        severity={msg?'error':'success'}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        >
                            {msg?msg:message}
                        </Alert>
                    </Collapse>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.backPaper}>
               <Grid container justify='center' alignItems='center'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid item>
                        <Grid container justify='row' className={classes.spacebetween} spacing={4}>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography>E-mail</Typography>
                                    <TextField error={errors.email?true:false}
                                        helperText={errors.email?'Saisissez un Email valid':null} required type='email' {...register('em',{required:true,pattern:/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/})} variant='outlined'  placeholder='Entrez l`e-mail '  fullWidth  className={classes.textField}/>
                            </Grid>       
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography>Organisation</Typography>
                                    <TextField inputProps={{maxLength:30}} helperText='Organisation doit avoir 30 caractères' required type='text' {...register('org',{required:true})} variant='outlined'  placeholder="Entrez l'adresse"  fullWidth className={classes.textField}/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography>Rôle</Typography>

                                   {
                                       changerole? 
                                     <TextField  onClick={()=>{setchangerole()}} type='text' {...register('role',{required:true})} variant='outlined'    fullWidth className={classes.textField}/>
                                       
                                       :<Select
                                       required
                                       labelId="demo-simple-select-helper-label"
                                       variant="outlined"
                                       id="demo-simple-select-helper"
                                       size="small"
                                       fullWidth
                                       value={role}
                                       onClick={getrole}
                                      >
                                          <MenuItem value={"1"}>Administrateur</MenuItem>
                                          <MenuItem value={"2"}>Secrétaire</MenuItem>
                                          <MenuItem value={"3"}>Auteur</MenuItem>
                                          <MenuItem value={"4"}>Contributeur</MenuItem>
                                          <MenuItem value={"5"}>Membre</MenuItem>
                                      </Select>
                                   }
                            </Grid>        
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography>Activer</Typography>
                                    <FormControl component="fieldset">
                                            <RadioGroup name="occupation"   className={classes.radiolabel}>
                                                <FormControlLabel checked={display=='True'?true:false} value={'True'}   onClick={isDisplay} control={<Radio size='small'/>} label={<Box  component="div" fontSize={13}>Oui</Box>} />
                                                <FormControlLabel checked={display=='False'?true:false} value={'False'}  onClick={isDisplay} control={<Radio size='small'/>} label={<Box  component="div" fontSize={13}>Non</Box>} />
                                            </RadioGroup>
                                    </FormControl>
                            </Grid>
                            </Grid>
                        
                            <Grid item className={classes.spacebetween} >
                                 <Button type='submit'
                                  //disabled={isCircular===true?true:false} 
                                   fullWidth
                                    className={classes.btn}>
                                         {isCircular===true?
                                         <CircularProgress size={30} style={{color:'white'}} />
                                         :
                                         <Typography>Sauvegarder</Typography>}
                                         </Button>

                            </Grid>
                        </Grid>
                </form>
               </Grid>
              

            </Grid>
           
        </Grid>
       
       
    )
}

export default UpdateUserAccount


