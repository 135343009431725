import React,{useEffect} from 'react'
import { makeStyles } from "@material-ui/styles"
import { Link } from 'react-router-dom'
import { Grid, Typography, Button} from '@material-ui/core';

const Style = makeStyles((theme) => ({
    root: {
        marginTop:130,
        color:'#1D2F3F',
        [theme.breakpoints.down("md")]:{
            padding:'3em 0em',
        },
        [theme.breakpoints.up("md")]:{
            padding:'6em 0em',
        },
        '& h1':{
            fontWeight:700,
            [theme.breakpoints.down("md")]:{
                fontSize:"7rem",
            },
            [theme.breakpoints.up("md")]:{
                fontSize:"8vw",
            },
            lineHeight:"0.8em",
            letterSpacing:"0.5rem"
        },
        '& h2':{
            margin:'1em 0em',
            [theme.breakpoints.down("md")]:{
                fontSize:"1.2rem",
            },
            [theme.breakpoints.up("md")]:{
                fontSize:"1.2vw",
            },
            fontWeight:100,
        },
        '& a':{
            textDecoration:"none",
        }
    },
    container:{
        background:"#1d2f3f09",
        borderRadius:'1em',
        [theme.breakpoints.down("md")]:{
            padding:'1em 0em',
        },
        [theme.breakpoints.up("md")]:{
            padding:'2.5em 0em',
        },
    },
    Button: {
        color:"white",
        margin:'2em 0em',
        padding:"1em 0em",
        background:"#1EDAB2",
        alignSelf: "center",
        '&:hover': {
            background: "#1EDAB2",
            color:"white"
        }
    }
  }));

const DefaultPage=()=>{
    const classes = Style();
    useEffect(() => {
        window.scrollTo(0, 0)
    
      }, [])
    return (
        <Grid container justifyContent = "center" className={classes.root}>
            <Grid item xs = {10} md ={6} xl = {4} className={classes.container}>
                <Grid container justifyContent = "center">
                    <Grid item xs ={10}>
                        <Typography variant = "h1" align="center">
                            404
                        </Typography>
                        <Typography variant = "h2" align="center">
                            Désolé(e), la page que vous recherchez n'existe pas.
                        </Typography>
                    </Grid>
                    <Grid item xs = {10} sm = {4}>
                        <Link to = "/">
                            <Button variant = "text" className = {classes.Button} fullWidth>
                                PAGE D'ACCUEIL
                            </Button>
                    </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DefaultPage