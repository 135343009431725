import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import Image from "../../../assets/images/photo.JPG";

const useStyle = makeStyles((theme) => ({
  root: {
    position: "relative",
    '& p': {
      textAlign:theme.typography.textBodyAlign
    },
    marginTop: 70,
    [theme.breakpoints.down("xs")]: {
      marginTop: 94,
    },
  },

  title: {
    width: "40%",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      top: "2.6em",
    },
    [theme.breakpoints.up("sm")]: {
      top: "10em",
    },
    left: "8vw",
    color: "#F6FBFF",
    "& h2": {
      fontSize: 'clamp(20px,6vw,85px)',
      fontWeight: theme.typography.fontWeightBold,
    },
    "& p": {
      fontSize: 25,
      margin: "4.1vw 0vw",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.9vw",
      },
    },
  },

  first: {
    color: "#EB9B29",
  },
  img: {
    height: "50em",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      //marginTop:70,
      height: 320,
    },
  },
}));

const Section1 = () => {
  const classes = useStyle();
  return (
    <Grid container direction="column" className={classes.root} spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <img alt="section 1" src={Image} width="100%" className={classes.img} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.title}
      >
        <Typography variant="h2" className={classes.first}>
          Un système
        </Typography>
        <Typography variant="h2" className={classes.second}>
          d’ETFP plus performant 

        </Typography>
        <Typography variant="body1">
          A travers :
          <br/>
            Le Cadre d’Echange et de Concertation des acteurs clés du système de
            la Formation Professionnelle et de l’Insertion (CEC-FPI) du Sud-Kivu
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Section1;
