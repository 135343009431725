import React from 'react';
import { DataGrid, frFR } from '@material-ui/data-grid';
import { Grid } from '@material-ui/core'



export default function CustomPaginationActionsTable({columns,rows}) {
  

  return (
    <Grid container>
      <Grid item xs ={12} style = {{minHeight:400}}>
        <DataGrid
          columns={columns}
          rows={rows}
          disableSelectionOnClick
          disableDensitySelector
          localeText={frFR.props.MuiDataGrid.localeText}

        />
      </Grid>

    </Grid>
  );
}
