import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import Pole from '../../../widgets/pole'
import Offre from '../../../assets/images/accueil/offre.jpg'
import Demande from '../../../assets/images/accueil/demande.jpg'
import Insertion from '../../../assets/images/accueil/insertion.jpg'
import Regulation from '../../../assets/images/accueil/regulation.jpg'



const useStyle = makeStyles((theme) => ({

    root:{
        background:"#FFF4F4",
        padding: "5.7vw 0vw ",
        '& p': {
            textAlign:theme.typography.textBodyAlign
          },

    },
    head:{
        alignSelf: "center",
        "& h2": {
          padding: "5vw 4vw",
          textAlign: "center",
          [theme.breakpoints.down("md")]: {
            fontSize: "4.2vw",
          },
          [theme.breakpoints.up("md")]: {
            fontSize: "2.7rem",
          },
          fontWeight: theme.typography.fontWeightBold,
        },
    },
    poles:{
            marginTop:"3.6em"
    },

}))


const Section = () => {

   

    const classes = useStyle();
    return (
        <Grid container className = {classes.root} justifyContent = "center">
            <Grid xs = {10} item className = {classes.head}>
                <Typography variant='h2' >
                    Les 4 pôles
                </Typography>
                <Typography variant = "body1">
                    Les pôles de la formation professionnelle en constituent les piliers indispensables pour son relèvement et les membres du CEC-FPI/SK sont constitués à partir desdits pôles. Ils sont au total 4 pôles à savoir : l’offre, la demande, l’insertion et la régulation.
                </Typography>
            </Grid>
            <Grid item xs = {10}>
                <Grid container justifyContent = "space-between" className = {classes.poles} spacing={3}>

                    <Pole image = {Offre} 
                        title = "L'offre" 
                        caption = "L’offre comprend tous les prestataires de formation professionnelle (Ecoles techniques, centres d’apprentissages, centre de formation professionnelle publiques, privés et religieux, ainsi que les entreprises qui forment directement sur le lieu de travail à travers des stages et des Maîtres-Formateurs et aussi des programmes d’organisation qui œuvrent dans le domaine de la formation initiale et/ou continu."
                        color = "#1EDAB2"  
                        link = "/Offre" />
                    <Pole image = {Demande} 
                        title = "La demande" 
                        caption = "Il existe différentes catégories d'acteurs du secteur privé : les grandes entreprises et les multinationales, les start-ups et les petites et moyennes entreprises (PME), les structures artisanales du secteur formel et non formel et les petits commerces, les entreprises sociales, et d’autres partenaires du secteur privé."
                        color = "#FFAE02" 
                        link = "/Demande" />
                    <Pole image = {Insertion} 
                        title = "L'insertion" 
                        caption = "L’insertion est prise en charge par des agences publiques et privées, par la société civile et par différents partenaires, tels que des instituts de micro finances et des ONG(I)s. Elles fonctionnent globalement comme une interface entre le système éducatif et le monde du travail, facilitant la transition entre l'enseignement et la formation technique et professionnel (ETFP) et le marché du travail. "
                        color = "#C10100" 
                        link = "/Insertion" />
                    <Pole image = {Regulation} 
                        title = "La regulation" 
                        caption = "Le développement des compétences en RDC est organisé sous la tutelle administrative de différents ministères ayant en charge : l’Emploi, le Travail et la Prévoyance sociale (ETPS), l’Enseignement primaire, secondaire et professionnel (EPSP), la Formation professionnelle et Métiers (FPM), les Affaires sociales (AS), la Santé publique (SP), l’Enseignement supérieur et universitaire (ESU), la Jeunesse. "
                        color = "#1D2F3F" 
                        link = "/Regulation" />

                </Grid>
            </Grid>
        </Grid>
    );
};

export default Section;