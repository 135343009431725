import api from './axiosApi';
import apiPub from './axiosPub'


const addCcc =(
    name,
    location,
    description,
    create_by
)=>{
   return api.post('api-ccc/ccc/',{
    name,
    location,
    description,
    create_by
   }).then((res)=>{
        return res.data
    })
}

const getCcc =()=>{
    return api.get('api-ccc/ccc/list/').then((res)=>{
         return res.data.ccc
     })
 }

const getCccById =(id)=>{
  return  api.get(`api-ccc/ccc/${id}/`).then((res)=>{
        return res.data.ccc
    })
}

const updateCcc =(
        id,
        name,
        location,
        description,
        create_by,
        modification
        )=>{
   return  api.patch(`api-ccc/ccc/${id}/`,{
        name,
        location,
        description,
        create_by,
        modification
    }).then((res)=>{
        return res.data
    })
}

const deleteCcc =(id)=>{
    return api.delete(`api-ccc/ccc/${id}/`).then((res)=>{
        return res.data
    })
}

const createModification=(
    modified_by,
    modification_date,
    app,
    Activity
)=>{
    return api.post('api-modification/modification/',{
        modified_by,
        modification_date,
        app,
        Activity
    }).then((res)=>{
        return res.data
    })
}

const getCccPub =()=>{
    return apiPub.get(`api-ccc/ccc/pub/`).then((res)=>{
        return res.data.ccc
    })
}

const getCccByIdPub =(id)=>{
    return apiPub.get(`api-ccc/ccc/pub/${id}/`).then((res)=>{
        return res.data
    })
}

const cccSerivce = {
    addCcc,
    getCcc,
    getCccById,
    updateCcc,
    deleteCcc,
    createModification,
    getCccPub,
    getCccByIdPub


}
export default cccSerivce;
   