import React from  'react'
import {BrowserRouter as Router,Route, Switch } from "react-router-dom";
import Login from './pages/login';
import MainRoutes from '../dashboard/pages/mainRoutes'
import ProtectedRoute from './pages/protectedRoutes';
import ForgotPassword from '../user_forget_password/forgot_pass';
import RestorePassword from '../user_forget_password/restore_password';


const Main =()=>{
    return (
       <Router>
           <Switch>
                <Route exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/login'} component={Login}/>
                <Route exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/oublie'} component={ForgotPassword}/>
                <Route exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/recuper/mot_de_pass/:token'} component={RestorePassword}/>
                <ProtectedRoute  path={`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard`} component={MainRoutes}/>
           </Switch>
       </Router>
    )
}

export default Main