import React, { useState,useEffect } from "react";
import { Grid } from "@material-ui/core";
import Style from "./style";
import { useHistory } from "react-router-dom";
import SEO from '../../sc/seo'

const Video = () => {
  const classes = Style();

  let history = useHistory()


  useEffect(() => {
    if (history.action === "POP") {
      history.push("/")
    }
  },[])

  const [pageDisplay] = useState({
    videos: [
      {
        title: "Video 1",
        link: "https://www.youtube.com/embed/Y9St27yzdz8",
      },
      {
        title: "Video 2",
        link: "https://www.youtube.com/embed/iq_onLY4GpM",
      },
      {
        title: "Video 3",
        link: "https://www.youtube.com/embed/g3lfIBV73_Q",
      },
    ],
  });

  const renderVideos = (videoList) => {
    let template = null;

    template = videoList.map((item, i) => (
      <Grid item key = {i} className={classes.video} xs={12} sm={6} md={6} lg={6} xl={6}>
        <iframe title={item.title} src={item.link} width="100%" height = "100%" />
      </Grid>
    ));
    return template;
  };

  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])

  return (
    <React.Fragment>
      <SEO
        title="Vidéos"
        keywords="Vidéos"
      />
      <Grid container justifyContent="center">
        <Grid item
          xs={11}
          sm={10}
          md={10}
          lg={10}
          xl={10}
        >
          <Grid

            container
            className={classes.root}
            justifyContent="space-between"
          >
            {renderVideos(pageDisplay.videos)}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
    };
    
    export default Video;
