import {
    SET_MESSAGE,
    FETCH_ACTUALITE_SUCCES,
    FETCH_ACTUALITE_FAIL,
    DELETE_ACTUALITE_SUCCES,
    DELETE_ACTUALITE_FAIL,
    ADD_ACTUALITE_FAIL,
    ADD_ACTUALITE_SUCCES,
    UPDATE_ACTUALITE_SUCCES,
    UPDATE_ACTUALITE_FAIL,
    FETCH_ACTUALITE_BY_ID_SUCCES,
    FETCH_ACTUALITE_BY_ID_FAIL,
    CLEAR_DATA,
    UPDATE_APPROVAL_ACTUALITE_SUCCESS,
    UPDATE_APPROVAL_ACTUALITE_FAIL

} from '../contants/type'

import actualiteService from '../../service/actualite'


export const getActualite =()=>dispatch=>{
    return actualiteService.getActualite().then((data)=>{
        dispatch({
            type:FETCH_ACTUALITE_SUCCES,
            payload: data
        })
        return Promise.resolve()

    },(error)=>{
     const message =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
        type:FETCH_ACTUALITE_FAIL,
        });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
    })
}

export const addActualite = (
    title,
    cover_image,
    category,
    document,
    description,
    create_by,
    modification
)=>dispatch=>{
    return actualiteService.addActualite(
    title,
    cover_image,
    category,
    document,
    description,
    create_by,
    modification
    ).then((data)=>{
        dispatch({
            type:ADD_ACTUALITE_SUCCES,
            payload:data
        })
        return Promise.resolve()
    },(error)=>{
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
          type:ADD_ACTUALITE_FAIL,
        });
    
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
    
        return Promise.reject();
  
    })
}

export const updateActualite = (
    id,
    title,
    cover_image,
    category,
    document,
    description,
    create_by,

)=>dispatch=>{
    return actualiteService.updateActualite(
    id,
    title,
    cover_image,
    category,
    document,
    description,
    create_by,

    ).then((data)=>{
        dispatch({
            type:UPDATE_ACTUALITE_SUCCES,
            payload:data
        })
        return Promise.resolve()
    },(error)=>{
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
          type:UPDATE_ACTUALITE_FAIL,
        });
    
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
    
        return Promise.reject();
  
    })
}

export const updateActualiteWithNoImageAndDoc = (
  id,
  title,
  category,
  description,
  create_by,

)=>dispatch=>{
  return actualiteService.updateActualiteWithNoImageAndDoc(
  id,
  title,
  category,
  description,
  create_by,

  ).then((data)=>{
      dispatch({
          type:UPDATE_ACTUALITE_SUCCES,
          payload:data
      })
      return Promise.resolve()
  },(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:UPDATE_ACTUALITE_FAIL,
      });
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
  
      return Promise.reject();

  })
}


export const updateActualiteWithNoDoc = (
  id,
  title,
  cover_image,
  category,
  description,
  create_by,

)=>dispatch=>{
  return actualiteService.updateActualiteWithNoDoc(
  id,
  title,
  cover_image,
  category,
  description,
  create_by,

  ).then((data)=>{
      dispatch({
          type:UPDATE_ACTUALITE_SUCCES,
          payload:data
      })
      return Promise.resolve()
  },(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:UPDATE_ACTUALITE_FAIL,
      });
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
  
      return Promise.reject();

  })
}



export const updateActualiteWithNoImage = (
  id,
  title,
  category,
  document,
  description,
  create_by,

)=>dispatch=>{
  return actualiteService.updateActualiteWithNoImage(
  id,
  title,
  category,
  document,
  description,
  create_by,

  ).then((data)=>{
      dispatch({
          type:UPDATE_ACTUALITE_SUCCES,
          payload:data
      })
      return Promise.resolve()
  },(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:UPDATE_ACTUALITE_FAIL,
      });
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
  
      return Promise.reject();

  })
}


export const getActualiteById =(id)=>dispatch=>{
    return actualiteService.getActualiteById(id).then((data)=>{
      dispatch({
          type:FETCH_ACTUALITE_BY_ID_SUCCES,
          payload: data
      })
      return Promise.resolve()
    },(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:FETCH_ACTUALITE_BY_ID_FAIL,
      });
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
  
      return Promise.reject();
    })
  
}

export const deleteActualite =(id)=>dispatch=>{
    return actualiteService.deleteActualite(id).then((data)=>{
      dispatch({
          type:DELETE_ACTUALITE_SUCCES,
          payload: data
      })
      return Promise.resolve()
    },(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:DELETE_ACTUALITE_FAIL,
      });
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
  
      return Promise.reject();
    })
  
}



export const getActualitePub =()=>dispatch=>{
  return actualiteService.getActualitePub().then((data)=>{
      dispatch({
          type:FETCH_ACTUALITE_SUCCES,
          payload: data
      })
      return Promise.resolve()

  },(error)=>{
   const message =
      (error.response &&
      error.response.data &&
      error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
      type:FETCH_ACTUALITE_FAIL,
      });

  dispatch({
    type: SET_MESSAGE,
    payload: message,
  });

  return Promise.reject();
  })
}


export const getActualiteByIdPub =(id)=>dispatch=>{
  return actualiteService.getActualiteByIdPub(id).then((data)=>{
    dispatch({
        type:FETCH_ACTUALITE_BY_ID_SUCCES,
        payload: data
    })
    return Promise.resolve()
  },(error)=>{
    const message =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString();
    dispatch({
      type:FETCH_ACTUALITE_BY_ID_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  })

}

export const removeData = () => {
  return {
    type:CLEAR_DATA,
  };
};


export const updateActualiteApproval = (
  id,
  approval
)=>dispatch=>{
  return actualiteService.updateApprovalActualite(
  id,
  approval,
  ).then((data)=>{
      dispatch({
          type:UPDATE_APPROVAL_ACTUALITE_SUCCESS,
          payload:data
      })
      return Promise.resolve()
  },(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:UPDATE_APPROVAL_ACTUALITE_FAIL,
      });
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
  
      return Promise.reject();

  })
}

