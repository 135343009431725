import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Typography, Breadcrumbs , Grid} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Pages from './sitemap.json'

const Style = makeStyles((theme) => ({
    
    root: {
        height: 50,
        background: "#ffffff82",
        backdropFilter: "blur(5px)",
        borderBottom: "1px solid RGBA(0, 0, 0, 0.05)",
        borderTop: '1px solid RGBA(0, 0, 0, 0.05)',
        alignItems: 'center',

        '& ol': {
            alignItems: 'baseline'
        },

        '& a': {
            textDecoration: 'none',
            color: '#1D2F3F',
            fontSize: 13,
            extAlign: 'center',
       
        },
        '& h6': {
            color: '#3F74A4',
            fontSize: 13,
            textAlign: 'center',


        }
        
    },

    bread: {

    }
}))

const Beadcrumb = () => {

    let location = useLocation();
    
    
    const getRoute = (address) => {
        return address ? address.split(',').filter((item) => item !== "") : []
    }

    
    const [route, setroute] = useState(getRoute(sessionStorage.getItem('Breadcrumb')))

    useEffect(() => {

        const routes = getRoute(sessionStorage.getItem('Breadcrumb'))
        let pages = getRoute(sessionStorage.getItem('Breadcrumb'))
        location.pathname === "/" && (pages = [])
        pages.push(location.pathname)
        routes.pop() !== location.pathname && sessionStorage.setItem('Breadcrumb', pages)
        setroute(getRoute(sessionStorage.getItem('Breadcrumb')))
        
    }, [location])
    


    const classes = Style();

    const handleClick = (index) => {
        sessionStorage.setItem('Breadcrumb', route.slice(0, index))
    }
    


   
    const pathDirectory = (directory) => {
        
        let template = null;
        template = directory.map((item, i) => {
          return i === directory.length - 1 ? 
                <Typography variant='h6' key ={i}> {Pages[item]} </Typography>
                :
                <Link key={i} to={item} onClick = {() => handleClick(i)}>
                    {Pages[item]}
                </Link>
        })
        

        return template
    }


    return (
        <Grid container className={classes.root} alignItems="center" justifyContent="center">
            <Grid item xs= {10} style = {{padding:5}}>
                <Breadcrumbs maxItems={7} separator=">" component="div" className = {classes.bread}>
                    {pathDirectory(route)} 
                </Breadcrumbs>
            </Grid>
        </Grid>
    );
};

export default Beadcrumb;