import React,{useEffect} from  'react'
import { Grid,Typography} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Logo from '../../../../../website/assets/images/Logo.webp'
import congo from "../../../../../website/assets/images/congo.webp";




const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

 

export const Receipt= React.forwardRef((props, ref) =>{
    const classes = useStyles();
    const {membre,email} = props 
    // console.log(product);
    useEffect(() => {
       
    }, [])

    // console.log(saleSetting);
    // console.log(system_setting)    
      return (

        <div ref={ref}>
            <Grid  container direction='column' spacing={4} style={{padding:30}} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Grid container justifyContent='space-between'>
                        <Grid item>
                             <img src={Logo} height={50}   alt="cec-fpi"/>
                        </Grid>
                        <Grid item>
                             <img src={congo}  height={30}  alt="drc"/>
                        </Grid>
                        
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container justifyContent='center' alignItems='center'>
                        <Grid item><Typography variant='subtitle1'  style={{fontWeight:'normal'}}>LISTE DES MEMBRES DU CEC-FPI</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{height:'auto'}}>
                     <TableContainer>
                        <Table className={classes.table} aria-label="caption table">
                            <caption><Typography variant='subtitle2' align='right' style={{color:'#1D2F3F'}} >Nombre total des membres du CEC-FPI:  {membre.length}</Typography></caption>
                            <TableHead>
                            <TableRow>
                                <TableCell  style={{color:'#1D2F3F'}}>NOM</TableCell>
                                <TableCell style={{color:'#1D2F3F'}} >EMAIL</TableCell>
                                <TableCell style={{color:'#1D2F3F'}} >POLE</TableCell>
                                <TableCell style={{color:'#1D2F3F'}} >FONCTION</TableCell>
                                <TableCell style={{color:'#1D2F3F'}} >PHONE</TableCell>
                                <TableCell style={{color:'#1D2F3F'}} >INSTITUTION</TableCell>
                                <TableCell style={{color:'#1D2F3F'}} >SIGLE</TableCell>

                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {membre.map((row) => (
                                <TableRow >
                                <TableCell component="th" scope="row" style={{color:'#1D2F3F'}}>
                                    {row.first_name} {row.last_name}
                                </TableCell>
                                <TableCell style={{color:'#1D2F3F'}} >{row.email}</TableCell>
                                <TableCell style={{color:'#1D2F3F'}} >{row.pole}</TableCell>
                                <TableCell style={{color:'#1D2F3F'}} >{row.fonction}</TableCell>
                                <TableCell style={{color:'#1D2F3F'}} >{row.phonenumber}</TableCell>

                                <TableCell style={{color:'#1D2F3F'}} >{row.organisation}</TableCell>

                                <TableCell style={{color:'#1D2F3F'}} >{row.sigle}</TableCell>

                                {/* <TableCell style={{color:'#1D2F3F'}} >{row.fonction}</TableCell> */}

                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <br/>
                {/* <Divider /> */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container  justifyContent='flex-end'>
                       <Grid item>
                           <Grid container direction='column'>
                            <Grid item>
                                <Typography style={{color:'#1D2F3F'}} >Imprimé le : {`${new Date().getDay()}-${new Date().getMonth()}-${new Date().getFullYear()}`}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography style={{color:'#1D2F3F'}} >Par : {email} </Typography>
                            </Grid>
                            </Grid>
                       </Grid>
                       
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                   
                </Grid>
            </Grid>
        </div>
      );
    
  })

