import React,{useEffect} from "react";
import Section1 from './widgets/section1'
import Section2 from './widgets/section2'
import Section3 from './widgets/section3'
import Section4 from './widgets/section4'
import Section5 from './widgets/section5'
import Section6 from './widgets/section6'
import Section7 from './widgets/section7'
import Section8 from './widgets/section8'
import Style from './style'

const Accueil = () => {

  const classes = Style();

  
  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])

  return (
    <div className = {classes.root}>
      <Section1/>
      <Section2/>
      <Section3/>
      <Section4/>
      <Section5/>
      <Section6/>
      <Section7/>
      <Section8/>
    </div>
  );
};

export default Accueil;
