import React,{useEffect} from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import SEO from "../../sc/seo"
import Style from './style'
import { useSelector,useDispatch } from 'react-redux';
import {getAgendaByIdPub} from '../../../../store/actions/agenda-actions'
import { Skeleton } from '@material-ui/lab';




const Activite = () => {

    const classes = Style();
    const dispatch = useDispatch()
    const { agendabyId } = useSelector(state => state.agendaPub)
    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(getAgendaByIdPub(parseInt(sessionStorage.getItem('rowid'))))
    
      }, [])
   
    return (
        <React.Fragment>
            <SEO
                title="L'Activité"
                keywords="activite"
            />
            <Grid container className={classes.root} justifyContent="center">
                <Grid item xs={10} sm={10} md={10} lg={8} xl={8} className={classes.head}>
                    {
                        agendabyId === undefined ?
                            <div>
                                <Skeleton variant='rect' width="50%" height={20} />
                                <br />
                            </div>
                            : <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                                {agendabyId.title}
                            </Typography>
                    }
                    {agendabyId === undefined ? <div>
                        <Skeleton variant='rect' width="30%" height={20} />
                        <br />
                    </div> :
                        <Grid container direction='row' spacing={2}>
                            <Grid item>
                                <Typography variant='caption' style={{ textAlign: 'justify' }}>
                                    {agendabyId.date}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='caption' style={{ textAlign: 'justify' }}>
                                    De
                            </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='caption' style={{ textAlign: 'justify' }}>
                                    {agendabyId.start_time}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='caption' style={{ textAlign: 'justify' }}>
                                    à
                            </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='caption' style={{ textAlign: 'justify' }}>
                                    {agendabyId.end_time}
                                </Typography>
                            </Grid>

                        </Grid>}

                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
                    {agendabyId === undefined ? <div>
                        <Skeleton variant='rect' width="100%" height={200} />
                        <br />
                    </div> : <Typography variant="body1" className={classes.body}>
                            {agendabyId.description}
                        </Typography>}
                </Grid>
            </Grid>
        </React.Fragment>
    )
        };
        
export default Activite;