import { Helmet } from 'react-helmet';
import React from 'react';
const MetaData = (
    {
        title,
        description,
        pathSlug,
        keywords,
        image,
        imageW,
        imageH

    }) => {
    
    const url = `${process.env.REACT_APP_CECFPI_URL}${pathSlug}`
    const pageTitle = `CEC-FPI 🌐 ${title ?  `- ${title}` : ''}`
    return (
        <Helmet>
            <title>{pageTitle}</title>
            <link rel='canonical' href={url} />

            <meta charSet="utf-8" />
            
            <meta name='description' content = {description}/>
            <meta name='keywords' content={keywords ? `CEC-FPI ${keywords}` : "metaDescription"} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />

            <meta property="og:title" content={title} />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={url} />
            <meta property="og:site_name" content="CEC-FPI 🌐" />
            <meta property="og:description" content={description} />
            {image && <meta property="og:image" content={image} />}
            {image && <meta property="og:image:width" content={imageW} />}
            {image && <meta property="og:image:height" content={imageH} />}
        </Helmet>
    );
}
export default MetaData;