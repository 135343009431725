import api from './axiosApi';
import apiPub from './axiosPub'


const addClec =(
    name,
    location,
    description,
    create_by
)=>{
   return api.post('api-clec/clec/',{
    name,
    location,
    description,
    create_by
   }).then((res)=>{
        return res.data
    })
}

const getClec =()=>{
    return api.get('api-clec/clec/list/').then((res)=>{
         return res.data.clec
     })
 }

const getClecById =(id)=>{
  return  api.get(`api-clec/clec/${id}/`).then((res)=>{
        return res.data.Clec
    })
}

const updateClec =(
        id,
        name,
        location,
        description,
        create_by,
        modification)=>{
   return  api.patch(`api-clec/clec/${id}/`,{
        name,
        location,
        description,
        create_by,
        modification
    }).then((res)=>{
        return res.data
    })
}

const deleteClec =(id)=>{
    return api.delete(`api-clec/clec/${id}/`).then((res)=>{
        return res.data
    })
}

const createModification=(
    modified_by,
    modification_date,
    app,
    Activity
)=>{
    return api.post('api-modification/modification/',{
        modified_by,
        modification_date,
        app,
        Activity
    }).then((res)=>{
        return res.data
    })
}


const clecPub =()=>{
    return apiPub.get(`api-clec/clec/pub/`).then((res)=>{
        return res.data.clec
    })
}

const clecByIdPub =(id)=>{
    return apiPub.get(`api-clec/clec/pub/${id}/`).then((res)=>{
        return res.data
    })
}

const clecService ={
    addClec,
    getClec,
    getClecById,
    updateClec,
    deleteClec,
    createModification,
    clecPub,
    clecByIdPub

}
export default clecService;