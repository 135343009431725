
const prod = {
    url: {
    API_URL: `${process.env.REACT_APP_CEC_FPI_API}`
    //API_URL: `${process.env.REACT_APP_OXIDE_API}`
    }
};

const dev = {
    url: {
    //API_URL :`${process.env.REACT_APP_TESTING_API}`
    //API_URL: `${process.env.REACT_APP_CEC_FPI_API}`
     API_URL: `${process.env.REACT_APP_LOCALHOST_API}`
    }
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;

