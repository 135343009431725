import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    borderLeft: `5px solid #C10100`,
    margin: "1em 0em",
    '& p':{
      textAlign:theme.typography.textBodyAlign,
      fontSize:"0.93rem",
      padding:"0.5em 0em"
    }
  },
  link: {
    color: "#1D2F3F",
    textDecoration: "none",
    "&:hover": {
      color: "#3F74A4",
    },
  },
  bold_text: {
    fontWeight: theme.typography.fontWeightBold,
    
  },
}));

export default function Agenda(props) {
  const {item} =props 
  const history = useHistory()
  const classes = useStyles();

  return (
    <div className={classes.link}  onClick={()=>{
      history.push(`/Activite/${item.title}=${item.id}`)
      sessionStorage.setItem('rowid',item.id)
    }}>
      <Grid container className={classes.root} justifyContent="center">
        <Grid item xs={11}>
          <Typography variant="body1" className={classes.bold_text}>
            {item.title}
          </Typography>

          <Typography variant="body1">
            {item.description}
          </Typography>
        </Grid>

        <Grid item xs={11}>
          <Grid container justifyContent="space-between">
            <Grid item >
              <Typography variant="body1" className={classes.bold_text}>
                {item.start_time}
              </Typography>
            </Grid>
            <Grid item >
              <Typography variant="body1">
                {item.end_time}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
