import React,{useEffect} from "react";
import { Grid, Typography } from "@material-ui/core";
import Style from "./style";
import Image from "../../assets/images/IMG_2721.JPG";
import SEO from "../../sc/seo"

const Sommes_nous = (props) => {
  const Classes = Style();
  const paragraphList = [
    {
      title: "L'objectif",
      body: "L'objectif primordial poursuivi par le Cadre est d'assurer la bonne coordination des acteurs clés impliqués dans le développement du système de la Formation Professionnelle et de l’Insertion."
    },
    {
      title: "La mission",
      body: "Sa mission est donc d’améliorer le fonctionnement du système de la Formation Professionnelle et de l’Insertion en facilitant l’échange et la concertation, la collaboration et la coordination des acteurs clés de la FPI, en cohérence avec les mandats des parties prenantes et les lois y afférentes."
    },
      
    ]
   


  const paragraphRender = (list) => {
    let template = null;

    template = list.map((paragraph, i) => (
      <Grid item key={i} className={Classes.paragraphContainer} xs = {11} sm = {5} md = {5} lg = {5}>
        <Typography variant="h4">
          {paragraph.title}
        </Typography>
        <Typography variant = "body1" dangerouslySetInnerHTML={{ __html: paragraph.body}}/>
      </Grid>
    ));

    return template;
  };

  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])

  return (
    <React.Fragment>
      <SEO
        title="Qui sommes-nous?"
        keywords="Qui sommmes-nous?"
      />
      <Grid container justifyContent="center"  >
        <Grid item className={Classes.root} xs={10} sm={10} md={10} lg={10}>
          <Grid container className={Classes.cecFpiContainer} justifyContent="center">
            <Grid item xs={11} sm={11} md={11} lg={11}>
              <Typography variant="h1">
                CEC-FPI du Sud-Kivu
              </Typography>

              <Typography variant="body1">
                Le Cadre d’Echange et de Concertation des acteurs clés du système de la Formation Professionnelle et l’Insertion au Sud-Kivu, CEC-FPI/SK en sigle, est une grande synergie regroupant les acteurs clés qui interagissent dans le développement du système de la formation professionnelle et de l’insertion régit par l’arrêté provincial n°19/054/GP/SK du 11/11/2019 dont les acteurs clés sont repartis en quatre pôles à savoir : l’Offre, la Demande, l’Insertion et la Régulation.
              <br />
                <br />
                Le CEC-FPI/SK exerce ses activités sur toute l’étendue de la province du Sud-Kivu. Il a deux organes de gestion à savoir : le Comité de Coordination Collégiale, CCC en sigle, et l’Assemblée Générale, AG. Evidemment, pour le besoin de la cause, il est établi des commissions spécialisées pour appuyer le CCC à bien mener la gestion quotidienne du Cadre.
              <br />
                <br />
                Aussi, le Cadre procède à la création des CLEC au niveau des territoires à l’intérieur de la province du Sud-Kivu pour l’élargissement et la proximité de son action jusqu’au fin fond dans les entités rurales.
  
              </Typography>
            </Grid>
          </Grid>

          <Grid item className={Classes.section}>
            <Grid container justifyContent="space-evenly">{paragraphRender(paragraphList)}</Grid>
          </Grid>

          <Grid item className={Classes.section} >
            <Grid container justifyContent="center" className={Classes.section}>
              <Grid item xs={11} sm={10} md={10} lg={10}>
                <Typography align='center' variant="h4">Notre équipe</Typography>
              </Grid>

              <Grid item xs={11} sm={11} md={11} lg={11}>
                <img src={Image} alt="Team" width="100%" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Sommes_nous;
