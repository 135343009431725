import {
    EMAIL_SEND_FAIL,
    EMAIL_SEND_SUCCESS,
    } from "../contants/type";
    

const initialState = {

}

export default function (state= initialState,action){
    const {type,payload} = action

    switch (type) {
        case EMAIL_SEND_SUCCESS:
          return {
            ...state,
            email: payload,
          };
        case EMAIL_SEND_FAIL:
          return {
            ...state,
            email: "L'envoi de l'e-mail a echoué, veuillez votre connexion"
          };
        default:
            return state
        }

}