import React,{useState,useEffect} from 'react'
import {Button, IconButton,Collapse,Grid, MenuItem, FormControl,FormControlLabel,RadioGroup,Radio,Box,Select, TextField, Typography} from '@material-ui/core'
import useStyles from '../style'
import CircularProgress from '@material-ui/core/CircularProgress';
import {getMembres,addMembres,getMemberById,updateMembres,updateMembresWithLogo} from '../../../../../../store/actions/membre-actions'
import  {useForm,Controller} from 'react-hook-form'
import { useSelector,useDispatch } from 'react-redux';
import CloudUpload from '@material-ui/icons/CloudUpload'
import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'







const MembreCreate=(props)=>{
    const classes = useStyles()
    const dispatch  = useDispatch()
    const history  = useHistory()
    const {register,handleSubmit,reset,control,formState:{errors}} = useForm()
    const [email, setemail] = useState('')
    const [isCircular, setisCircular] = useState(false)
    const  {membrebyId}= useSelector((state)=>state.membreById)
    const [changepole, setchangepole] = useState()
    const [errmsg, seterrmsg] = useState("Formats de fichiers supportés: .jpg, jpeg, png. Taille Maximale : 1.5 MB.")
    const [img, setimg] = useState('')
    const [selectedImage, setselectedImage] = useState(null)
    const {message} = useSelector((state)=>state.membre.addedmembre)??''
    const [open, setOpen] = React.useState(false);
    const msg = useSelector((state)=>state.msg.message)




    const  handleImage = (e)=>{
        const value =e.target.files[0]

        const limit =1639815;
    
        if(value!=null){

    
            if(!value.name.match(/\.(jpg|jpeg|png)$/)){
    
                seterrmsg("Fichier non supporté!")
            }else if(value.name.match(/\.(jpg|jpeg|png)$/)&& value.size >limit){
                seterrmsg('Le fichier est trop large. La Taille doit être au plus 1.5 MB.')
    
            }else if(value.name.match(/\.(jpg|jpeg|png)$/)&& value.size <=limit){
                setimg(value)
                seterrmsg('Fichier et Taille non supporté!')
    
            }
        
        }else{
            seterrmsg("Formats de fichiers supportés: .jpg, jpeg, png. Taille Maximale: 1.5 MB.")
        } 
    }

    const onSubmit =(data,e)=>{
        e.preventDefault()
        setOpen(false)
        if(data){
           if(props.location.data && img!==''){
            setisCircular(true)
            dispatch(updateMembres(
                props.location.data,
                data.fn,
                data.ln,
                data.em,
                data.phon,
                data.addr,
                data.org,
                data.fon,
                data.obj,
                data.mis,
                pole,
                display,
                img,
                data.link,
                data.sigle,
                email
            )).then(()=>{
                setisCircular(false)
                setOpen(true)
                setimg('')
                setpole('')
                reset()
                dispatch(getMembres())
                history.push({
                   pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/membre/detail/${data.fn}=${props.location.data}` ,
                   data:props.location.data
                })
                sessionStorage.setItem('rowId',props.location.data,)
                
            },()=>{
                setOpen(true)
                setisCircular(false)
                //reset()
            })
           }else if(props.location.data && img===''){
            setisCircular(true)
            dispatch(updateMembresWithLogo(
                props.location.data,
                data.fn,
                data.ln,
                data.em,
                data.phon,
                data.addr,
                data.org,
                data.fon,
                data.obj,
                data.mis,
                pole,
                display,
                data.link,
                data.sigle,
                email
            )).then(()=>{
                setisCircular(false)
                setOpen(true)
                setimg('')
                setpole('')
                reset()
                dispatch(getMembres())
                history.push({
                   pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/membre/detail/${data.fn}=${props.location.data}` ,
                   data:props.location.data
                })
                sessionStorage.setItem('rowId',props.location.data,)
                
            },()=>{
                setOpen(true)
                setisCircular(false)
            })
           }
           else{
            setisCircular(true)
            dispatch(addMembres(
                data.fn,
                data.ln,
                data.em,
                data.phon,
                data.addr,
                data.org,
                data.fon,
                data.obj,
                data.mis,
                pole,
                display,
                img,
                data.link,
                data.sigle,
                email
            )).then(()=>{
                setisCircular(false)
                setimg('')
                setOpen(true)
                setdisplay('True')
                setpole('')
                reset()
                dispatch(getMembres())
               
            },()=>{
                setOpen(true)
                setisCircular(false)
                //reset()
            })
           }
        }
    }
    const [display, setdisplay] = useState('True')
    const isDisplay=(e)=>{
        const value = e.target.value 
        setdisplay(value)
      }
    
    const [pole, setpole] = useState('')
    const getpole = (e)=>{
        const value = e.target.value
        setpole(value)
    }
    const getUser =()=>{
        var data =  sessionStorage.getItem('user')
        setemail(data)
       
      }
    useEffect(() => {
        if(history.action ==='POP'){
            history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/membre`)
        }
        getUser()
       
           if(props.location.data){
            dispatch(getMemberById(`${parseInt(props.location.data)}`)).then(()=>{
                reset({
                    fn:membrebyId.first_name,
                    ln:membrebyId.last_name,
                    em:membrebyId.email,
                    phon:membrebyId.phonenumber,
                    addr:membrebyId.address,
                    org:membrebyId.organisation,
                    fon:membrebyId.fonction,
                    obj:membrebyId.objectif,
                    mis:membrebyId.mission,
                    link:membrebyId.link,
                    sigle:membrebyId.sigle,
                    pole:membrebyId.pole
                })
        
                if(membrebyId.display===true){
                    setdisplay('True')
                }else{
                    setdisplay('False')
                }
                if(membrebyId.pole==="L'offre"){
                    setpole('A')
                }
                else if(membrebyId.pole==="La demande"){
                    setpole('B')
                }
                else if(membrebyId.pole==="L'insertion"){
                    setpole('C')
                }
                else if(membrebyId.pole==="La regulation"){
                    setpole('D')
                }
               
                setchangepole(parseInt(props.location.data))
            

            })
           }
           
        

        if(img){
            setselectedImage(URL.createObjectURL(img))
        }
       

    }, [img])
    return (
     
      
            <Grid container direction='column' className={classes.root}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container justifyContent='center' alignItems="center">
                    <Grid item><Typography variant='h3' style={{fontWeight:'bold'}} color='primary'>{props.location.data?"Actualiser les détails d'un membre":'Ajouter un membre'}</Typography></Grid>
                </Grid>
            </Grid>
            <Box height={40}/>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Collapse in={open}>
                        <Alert
                        severity={msg?'error':'success'}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        >
                            {msg?msg:message}
                        </Alert>
                    </Collapse>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.backPaper}>
               <Grid container justify='center' alignItems='center'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid item>
                        <Grid container justify='row' className={classes.spacebetween} spacing={4}>
                           <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>Nom</Typography>
                                <TextField   type='text' {...register('fn',{required:true})} variant='outlined'   fullWidth  className={classes.textField} placeholder='Entrez le nom'/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>Prénom</Typography>
                                <TextField    type='text' {...register('ln',{required:true})} variant='outlined'  placeholder='Entrez le prénom'  fullWidth className={classes.textField}/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography>E-mail</Typography>
                                    <TextField error={errors.email?true:false}
                                        helperText={errors.email?'Saisissez un Email valid':null} required type='email' {...register('em',{required:true,pattern:/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/})} variant='outlined'  placeholder='Entrez l`e-mail '  fullWidth  className={classes.textField}/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography>Institution</Typography>
                                    <TextField  required type='text' {...register('org',{required:true})} variant='outlined'  placeholder='Entrez l`institution'  fullWidth className={classes.textField}/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography>Sigles</Typography>
                                    <TextField  required type='text' {...register('sigle',{required:true})} variant='outlined'  placeholder='Entrez le sigle'  fullWidth className={classes.textField}/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography>Numéro de téléphone</Typography>
                                    <Controller
                                    name='phon'
                                    control={control}
                                    render={
                                    ({
                                        field: { onChange, onBlur, value, name }
                                    }) => (
                                        <PhoneInput
                                            
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            name= "phon"
                                            id='phon'
                                            inputProps={{
                                                "aria-invalid":false,
                                                required: "true",
                                                }}
                                            disableInitialCountryGuess={true}
                                            autoComplete='none'
                                            inputClass={`MuiInputBase-input MuiInputBase-phone ${classes.phone}`}
                                            containerClass="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl "
                                            buttonClass=""
                                            specialLabel=""
                                            inputStyle={{
                                            fontSize: "0.8rem",
                                            width: "100%",
                                            backgroundColor:'transparent',
                                            
                                            }}
                                            placeholder='Entrez votre numéro'
                                            country= 'cd'


                                        />
                                    )
                                    }
                                />
                                
                                
                            </Grid>
                            
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography>Adresse</Typography>
                                    <TextField inputProps={{maxLength:30}} helperText='Adresse doit avoir 30 caractères' required type='text' {...register('addr',{required:true})} variant='outlined'  placeholder="Entrez l'adresse"  fullWidth className={classes.textField}/>
                            </Grid>
                            
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography>Fonction</Typography>
                                    <TextField  type='text' {...register('fon',{required:true})} variant='outlined'  placeholder='Entrez la fonction'  fullWidth className={classes.textField}/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography>Pôle</Typography>

                                   {
                                       changepole? 
                                     <TextField  onClick={()=>{setchangepole()}} type='text' {...register('pole',{required:true})} variant='outlined'    fullWidth className={classes.textField}/>
                                       
                                       :<Select
                                       required
                                       labelId="demo-simple-select-helper-label"
                                       variant="outlined"
                                       id="demo-simple-select-helper"
                                       size="small"
                                       fullWidth
                                       value={pole}
                                       onClick={getpole}
                                      >
                                          <MenuItem value={"A"}>L'offre</MenuItem>
                                          <MenuItem value={"B"}>La demande</MenuItem>
                                          <MenuItem value={"C"}>L'insertion</MenuItem>
                                          <MenuItem value={"D"}>La regulation</MenuItem>
                                      </Select>
                                   }
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography>Objectif</Typography>
                                    <TextField inputProps={{maxLength:40}} helperText='Objectif doit avoir 40 caractères' multiline rows={4}  {...register('obj',{required:true})} variant='outlined'  placeholder='Notre objectif'  fullWidth className={classes.textField}/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography>Mission</Typography>
                                    <TextField inputProps={{maxLength:40}} helperText='Mission doit avoir 40 caractères'  type='text' multiline rows={4} {...register('mis',{required:true})} variant='outlined'  placeholder='Notre mission'  fullWidth className={classes.textField}/>
                            </Grid>
                        
                           
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography>Actif</Typography>
                                    <FormControl component="fieldset">
                                            <RadioGroup name="occupation"   className={classes.radiolabel}>
                                                <FormControlLabel checked={display=='True'?true:false} value={'True'}   onClick={isDisplay} control={<Radio size='small'/>} label={<Box  component="div" fontSize={13}>Oui</Box>} />
                                                <FormControlLabel checked={display=='False'?true:false} value={'False'}  onClick={isDisplay} control={<Radio size='small'/>} label={<Box  component="div" fontSize={13}>Non</Box>} />
                                            </RadioGroup>
                                    </FormControl>
                            </Grid>
                            
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Typography>Lien</Typography>
                                    <TextField   type='url'  {...register('link',{required:true})} variant='outlined'  placeholder='https://oxide-digital.com'  fullWidth className={classes.textField}/>
                            </Grid> 
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6} >

                               {selectedImage &&img?(
                                   <Box mt={2} textAlign='left'>
                                       <div style={{marginBottom:10}}>Image Preview*:</div>
                                         <img onClick={()=>setimg(null)} src={selectedImage} alt={selectedImage.name} height={50} width={50} />
                                   </Box>
                               ):(
                                <div>
                                    <input 
                                        
                                        accept="file/*" 
                                        type="file" 
                                        id="select-image"
                                        style={{ display: 'none' }}
                                        onChange={e => handleImage(e)}
                                    />
                                    <label htmlFor="select-image">
                                        <Button  variant='text' color="primary" component="span" endIcon ={<CloudUpload style={{color:'#FFAE02',}}/>}>
                                            logo
                                        </Button>
                                    </label>
                                </div>
                               )}
                                <small >{errmsg}</small>
                            </Grid>   
                            </Grid>
                        
                            <Grid item className={classes.spacebetween} >
                                 <Button type='submit'
                                  //disabled={isCircular===true?true:false} 
                                   fullWidth
                                    className={classes.btn}>
                                         {isCircular===true?
                                         <CircularProgress size={30} style={{color:'white'}} />
                                         :
                                         <Typography>{props.location.data?'Sauvegarder':'Ajouter'}</Typography>}
                                         </Button>

                            </Grid>
                        </Grid>
                </form>
               </Grid>
              

            </Grid>
           
        </Grid>
       
       
    )
}

export default MembreCreate