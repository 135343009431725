import api from './axiosApi';
import apiPub from './axiosPub'


const addMembre =(
    first_name,
    last_name,
    email,
    phonenumber,
    address,
    organisation,
    fonction,
    objectif,
    mission,
    pole,
    display,
    logo,
    link,
    sigle,
    created_by,
   
)=>{
    const formData = new FormData();
    formData.append('first_name',first_name)
    formData.append('last_name',last_name)
    formData.append('email',email)
    formData.append('phonenumber',phonenumber)
    formData.append('address',address)
    formData.append('organisation',organisation)
    formData.append('fonction',fonction)
    formData.append('objectif',objectif)
    formData.append('mission',mission)
    formData.append('pole',pole)
    formData.append('display',display)
    formData.append('logo',logo)
    formData.append('link',link)
    formData.append('sigle',sigle)
    formData.append('created_by',created_by)
   return api.post('api-member/addmember/',formData).then((res)=>{
        return res.data
    })
}

const getMembre =()=>{
    return api.get('api-member/members/').then((res)=>{
         return res.data.members
     })
 }

const getMembreById =(id)=>{
  return  api.get(`api-member/membre/${id}/`).then((res)=>{
        return res.data.member
    })
}

const updateMembre =(
    id,
    first_name,
    last_name,
    email,
    phonenumber,
    address,
    organisation,
    fonction,
    objectif,
    mission,
    pole,
    display,
    logo,
    link,
    sigle,
    created_by,
    modification
        )=>{
    const formData = new FormData();
    formData.append('first_name',first_name)
    formData.append('last_name',last_name)
    formData.append('email',email)
    formData.append('phonenumber',phonenumber)
    formData.append('address',address)
    formData.append('organisation',organisation)
    formData.append('fonction',fonction)
    formData.append('objectif',objectif)
    formData.append('mission',mission)
    formData.append('pole',pole)
    formData.append('display',display)
    formData.append('logo',logo)
    formData.append('link',link)
    formData.append('sigle',sigle)
    formData.append('created_by',created_by)
   return  api.patch(`api-member/member/${id}/`,formData
    ).then((res)=>{
        return res.data
    })
}


const updateMembreWithoutLogo =(
    id,
    first_name,
    last_name,
    email,
    phonenumber,
    address,
    organisation,
    fonction,
    objectif,
    mission,
    pole,
    display,
    link,
    sigle,
    created_by,
    modification
        )=>{
    const formData = new FormData();
    formData.append('first_name',first_name)
    formData.append('last_name',last_name)
    formData.append('email',email)
    formData.append('phonenumber',phonenumber)
    formData.append('address',address)
    formData.append('organisation',organisation)
    formData.append('fonction',fonction)
    formData.append('objectif',objectif)
    formData.append('mission',mission)
    formData.append('pole',pole)
    formData.append('display',display)
    formData.append('link',link)
    formData.append('sigle',sigle)
    formData.append('created_by',created_by)
   return  api.patch(`api-member/member/${id}/`,formData
    ).then((res)=>{
        return res.data
    })
}

const deleteMembre =(id)=>{
    return api.delete(`api-member/member/${id}/`).then((res)=>{
        return res.data
    })
}

const createModification=(
    modified_by,
    modification_date,
    app,
    Activity
)=>{
    return api.post('api-modification/modification/',{
        modified_by,
        modification_date,
        app,
        Activity
    }).then((res)=>{
        return res.data
    })
}

const membrePub =()=>{
    return apiPub.get(`api-member/member/pub/`).then((res)=>{
        return res.data.members
    })
}


const membreByIdPub =(id)=>{
    return apiPub.get(`api-member/member/pub/${id}/`).then((res)=>{
        return res.data
    })
}

const MemberService= {
    addMembre,
    getMembre,
    getMembreById,
    updateMembre,
    deleteMembre,
    createModification,
    membrePub,
    membreByIdPub,
    updateMembreWithoutLogo,

}

export default MemberService;
   

