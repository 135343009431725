import {
    FETCH_ESPACE_SUCCES,
    FETCH_ESPACE_FAIL ,
    DELETE_ESPACE_SUCCES, 
    DELETE_ESPACE_FAIL,
    UPDATE_ESPACE_DOCUMENT_SUCCES,
    UPDATE_ESPACE_WITHOUT_DOCUMENT_FAIL,
    ADD_ESPACE_SUCCES ,
    ADD_ESPACE_FAIL ,
    UPDATE_ESPACE_SUCCES, 
    UPDATE_ESPACE_FAIL ,
    FETCH_ESPACE_BY_ID_SUCCES, 
    FETCH_ESPACE_BY_ID_FAIL,
    CLEAR_DATA

} from '../contants/type'


const initialState = {
    espace:[]
}
const espaceMembre =(state=initialState,action)=>{
    const {type,payload} = action

    switch(type){
        case FETCH_ESPACE_SUCCES: 
            return {
                ...state,
                espace:payload
            }
        case FETCH_ESPACE_FAIL:
            return {
                ...state
            }
        case DELETE_ESPACE_SUCCES:
            return {
                ...state,
                deleteespace:'espace a été bien supprimer'
            }
        case DELETE_ESPACE_FAIL:
            return {
                ...state,
                deleteespace:"espace n'a pas été bien supprimer, veuillez vetifier votre connexion"
            }
        case ADD_ESPACE_SUCCES:
            return {
                ...state,
                addedespace: payload
            }
        case ADD_ESPACE_FAIL: 
            return{
                ...state
            }
        case UPDATE_ESPACE_SUCCES:
            return {
                ...state,
                updatedespace:payload
            }
        case UPDATE_ESPACE_FAIL:
            return {
                ...state
            }
        case UPDATE_ESPACE_DOCUMENT_SUCCES:
            return {
                ...state,
                updatedespace:payload
            }
        case UPDATE_ESPACE_WITHOUT_DOCUMENT_FAIL:
            return {
                ...state
            }
        case FETCH_ESPACE_BY_ID_SUCCES:
            return {
                ...state,
                updatedespace:payload
            }
        case FETCH_ESPACE_BY_ID_FAIL:
            return {
                ...state
            }
        case CLEAR_DATA:
            return {}
        
        default:
            return state
    }


}

const espaceReducer= {
    espaceMembre
}

export default espaceReducer