import React, { useState,useEffect }from 'react';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Box, Grid, Typography  ,Collapse,IconButton, CircularProgress, Button } from '@material-ui/core'
import useStyles from '../style'
import EditIcon from '@material-ui/icons/Edit';
import {BtnOrange,BtmRed} from '../../../../sc/utilities'
import Backpaper from '../../../../sc/backPaper';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogBox from '../../../../sc/dialog';
import {getActualite,getActualiteById,deleteActualite,updateActualiteApproval} from '../../../../../../store/actions/actualite-actions'
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router';
import { useDispatch,useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { clearMessage } from '../../../../../../store/actions/message-actions';


const ActualiteDetail = () => {

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const history = useHistory()
    const dispatch = useDispatch()
    const {actualitebyId} = useSelector((state)=>state.actualite)
    const delACtua= useSelector((state)=>state.actualite.deleteactualite)
    const [isDel, setisDel] = useState(false)
    const [opens, setOpens] = React.useState(false);
    const [currentUser, setcurrentUser] = useState('')
    const [isApproval, setisApproval] = useState(false)
    const {message} = useSelector(state => state.actualite.approval)??''

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        setOpen(false);
      };
    const handleClickOpen = () => {
       setOpen(true);
     };

     const handleModifier = ()=>{
        history.push({
                pathname: `/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/actualite/create`,
                data: parseInt(sessionStorage.getItem('rowId'))
        })
    }
    
    
    const handleDelete =()=>{
        handleClickOpen()
    }
    const handleConfirmDelete = (id)=>{
        setisDel(true)
        dispatch(deleteActualite(id)).then(()=>{
             dispatch(getActualite())
              history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/actualite`)  
              setisDel(false)
        },()=>{
            setisDel(false)
        })
    }
    const  getUserRole =()=>{
        const role  = sessionStorage.getItem('role')
        setcurrentUser(role)
    }

    const handleApproval = (id,approval)=>{
        setOpens(false)
        setisDel(true)
        if(approval?.approval ===false){
                dispatch(updateActualiteApproval(id,true)).then(()=>{
                dispatch(getActualite())
                dispatch(getActualiteById(`${parseInt(sessionStorage.getItem('rowId'))}`))
                setOpens(true)
                setisDel(false)
                setisApproval(true)
                },()=>{
                setOpens(true)
                setisDel(false)
                setisApproval(false)
                })
        }else if(approval?.approval ===true){
                dispatch(updateActualiteApproval(id,false)).then(()=>{
                        dispatch(getActualite())
                        dispatch(getActualiteById(`${parseInt(sessionStorage.getItem('rowId'))}`))
                        setOpens(true)
                        setisDel(false)
                        setisApproval(true)
                        },()=>{
                        setOpens(true)
                        setisDel(false)
                        setisApproval(false)
                        })
        }
   }




    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(clearMessage())
        dispatch(getActualiteById(`${parseInt(sessionStorage.getItem('rowId'))}`))
        getUserRole()
    
      }, [])
    return (
        <Backpaper
            yellowbtn={<BtnOrange onClick={handleModifier} btnYellowTitle="Modifier" startIcon={<EditIcon style={{fontSize:14}}/>} />}
            redbtn={currentUser<=2?<BtmRed onClick={handleDelete}/>:null}
            content={
               <div>
                    <Box height={20}/>
                <Collapse in={opens}>
                    <Alert
                   severity={isApproval===true?'success':'error'}
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpens(false);
                            dispatch(clearMessage())
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    >
                    {isApproval===true|| isApproval===false?message:delACtua}
                    </Alert>
                </Collapse>
                <Box height={20}/>
                    <Grid container justifyContent='center'>
                    <Grid item xs ={10} sm={10} md={10} lg={8} xl={8}>
                                <img src = {actualitebyId?.cover_image} alt ={"ActualiteDetail"} width="100%" height={400}/>
                    </Grid>
                    <Grid item xs ={10}  sm={10} md={10} lg={8} xl={8} >
                       {actualitebyId? <Typography variant = "h5" style={{fontWeight:'bold'}}>
                            {actualitebyId.title}
                            </Typography>:
                            <div>
                                 <Box height={10}/>
                                <Skeleton variant='rect' width={150} height={20}/>
                            </div>
                            }
                        {actualitebyId?<Typography variant = 'caption'>
                                    {actualitebyId.created_date}
                                </Typography>:
                                <div>
                                    <Box height={10}/>
                                    <Skeleton variant='rect' width={120} height={20}/>
                                </div>
                                }

                    </Grid>
                    <Grid item xs ={10}  sm={10} md={10} lg={8} xl={8}>
                       {actualitebyId? <Typography variant = "body1"  className = {classes.body}>
                            
                            {ReactHtmlParser(actualitebyId.description)}
                        </Typography>:
                         <div>
                            <Box height={10}/>
                            <Skeleton variant='rect'  height={400}/>
                            <Box height={40}/>
                            </div>}
                        {actualitebyId!==undefined&&actualitebyId.document!==null?
                        <a className = {classes.link} target='_blank' href={actualitebyId.document}>
                            {actualitebyId?<Grid container spacing={2}>
                                <Grid item>
                                    <CloudDownloadIcon/>
                                </Grid>
                                <Grid item>
                                    <Typography variant = "body1">
                                        Télécharger le document
                                    </Typography>
                                </Grid>
                            </Grid> :
                            <div>
                                <Skeleton variant='rect' width={150} height={20}/>
                                <Box height={40}/>
                                </div>}
                        </a>:null}
                        <Box height={50}/>
                    </Grid>
                    {currentUser<=2?
                    <Grid item xs ={10}  sm={10} md={10} lg={8} xl={8} >
                    {actualitebyId?
                    
                    <Button 
                    size='small'
                   // fullWidth
                    onClick={()=>handleApproval(actualitebyId.id,actualitebyId)}
                    >{isDel===true?<CircularProgress size={10}/>:    <Typography style={{fontSize:10 ,color:actualitebyId.approval===true?'red':'#009688'}}>{actualitebyId.approval===true?' DESAPPROUVER':'APPROUVER'}</Typography>}</Button>:
                            <div>
                                <Box height={10}/>
                                <Skeleton variant='rect' width={120} height={20}/>
                            </div>
                            }

                </Grid>:null}
                    
                    <Box height={40}/>
                    < DialogBox
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleClose}
                        //title= {userbyId?`${userbyId.first_name}`:null}
                        Oui={isDel===true?(<CircularProgress size={10}/>):'Oui'}
                        nonOnclick={handleClose}
                        confirnClick={()=>handleConfirmDelete(actualitebyId.id)}
                        content={`Etês-vous sûr de vouloir supprimer cette actualite `}/>
                        
                </Grid>
               </div>
            }
    />
    );
};

export default ActualiteDetail;