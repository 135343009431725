import {
    SET_MESSAGE,
    FETCH_MEMBRE_SUCCES,
    FETCH_MEMBRE_FAIL,
    DELETE_MEMBRE_SUCCES,
    DELETE_MEMBRE_FAIL,
    ADD_MEMBRE_SUCCES,
    ADD_MEMBRE_FAIL,
    UPDATE_MEMBRE_SUCCES,
    UPDATE_MEMBRE_FAIL,
    FETCH_MEMBRE_BY_ID_FAIL,
    FETCH_MEMBRE_BY_ID_SUCCES,
    CLEAR_DATA
    
} from '../contants/type'

import MemberService from '../../service/membre'


export const getMembres =()=>dispatch=>{
    return MemberService.getMembre().then((data)=>{
        dispatch({
            type:FETCH_MEMBRE_SUCCES,
            payload: data
        })
        return Promise.resolve()

    },(error)=>{
     const message =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
        type:FETCH_MEMBRE_FAIL,
        });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
    })
}

export const addMembres = (
    first_name,
    last_name,
    email,
    phonenumber,
    address,
    organisation,
    fonction,
    objectif,
    mission,
    pole,
    display,
    logo,
    link,
    sigle,
    created_by,
    modification
)=>dispatch=>{
    return MemberService.addMembre(
    first_name,
    last_name,
    email,
    phonenumber,
    address,
    organisation,
    fonction,
    objectif,
    mission,
    pole,
    display,
    logo,
    link,
    sigle,
    created_by,
    modification
    ).then((data)=>{
        dispatch({
            type:ADD_MEMBRE_SUCCES,
            payload:data
        })
        return Promise.resolve()
    },(error)=>{
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
          type:ADD_MEMBRE_FAIL,
        });
    
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
    
        return Promise.reject();
  
    })
}

export const updateMembres = (
    id,
    first_name,
    last_name,
    email,
    phonenumber,
    address,
    organisation,
    fonction,
    objectif,
    mission,
    pole,
    display,
    logo,
    link,
    sigle,
    created_by,
    modification
)=>dispatch=>{
    return MemberService.updateMembre(
    id,
    first_name,
    last_name,
    email,
    phonenumber,
    address,
    organisation,
    fonction,
    objectif,
    mission,
    pole,
    display,
    logo,
    link,
    sigle,
    created_by,
    modification
    ).then((data)=>{
        dispatch({
            type:UPDATE_MEMBRE_SUCCES,
            payload:data
        })
        return Promise.resolve()
    },(error)=>{
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
          type:UPDATE_MEMBRE_FAIL,
        });
    
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
    
        return Promise.reject();
  
    })
}

export const updateMembresWithLogo = (
  id,
  first_name,
  last_name,
  email,
  phonenumber,
  address,
  organisation,
  fonction,
  objectif,
  mission,
  pole,
  display,
  link,
  sigle,
  created_by,
  modification
)=>dispatch=>{
  return MemberService.updateMembreWithoutLogo(
  id,
  first_name,
  last_name,
  email,
  phonenumber,
  address,
  organisation,
  fonction,
  objectif,
  mission,
  pole,
  display,
  link,
  sigle,
  created_by,
  modification
  ).then((data)=>{
      dispatch({
          type:UPDATE_MEMBRE_SUCCES,
          payload:data
      })
      return Promise.resolve()
  },(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:UPDATE_MEMBRE_FAIL,
      });
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
  
      return Promise.reject();

  })
}


export const getMemberById =(id)=>dispatch=>{
    return MemberService.getMembreById(id).then((data)=>{
      dispatch({
          type:FETCH_MEMBRE_BY_ID_SUCCES,
          payload: data
      })
      return Promise.resolve()
    },(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:FETCH_MEMBRE_BY_ID_FAIL,
      });
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
  
      return Promise.reject();
    })
  
}

export const deleteMember =(id)=>dispatch=>{
    return MemberService.deleteMembre(id).then((data)=>{
      dispatch({
          type:DELETE_MEMBRE_SUCCES,
          payload: data
      })
      return Promise.resolve()
    },(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:DELETE_MEMBRE_FAIL,
      });
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
  
      return Promise.reject();
    })
  
}



export const getMembrePub =()=>dispatch=>{
  return MemberService.membrePub().then((data)=>{
      dispatch({
          type:FETCH_MEMBRE_SUCCES,
          payload: data
      })
      return Promise.resolve()

  },(error)=>{
   const message =
      (error.response &&
      error.response.data &&
      error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
      type:FETCH_MEMBRE_FAIL,
      });

  dispatch({
    type: SET_MESSAGE,
    payload: message,
  });

  return Promise.reject();
  })
}


export const getMemberByIdPub =(id)=>dispatch=>{
  return MemberService.membreByIdPub(id).then((data)=>{
    dispatch({
        type:FETCH_MEMBRE_BY_ID_SUCCES,
        payload: data
    })
    return Promise.resolve()
  },(error)=>{
    const message =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString();
    dispatch({
      type:FETCH_MEMBRE_BY_ID_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  })

}
export const removeData = () => {
  return {
    type:CLEAR_DATA,
  };
};


