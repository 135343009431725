import React from "react";
import { Divider, Grid, List, ListItem, Typography } from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import useStyles from "./style";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer_bg}>
      <Grid container justifyContent = "center">
        <Grid item xs={10} >
          <Grid
            container
            justifyContent="center"
            className={classes.margin_top_5}
          >
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Grid container>
                <Grid item className={classes.margin_botton_5}>
                  <Typography variant="h4" className={classes.big_title}>
                    CEC-FPI du Sud-Kivu
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" className={classes.typodescr}>
                      Le Cadre d’Echange et de Concertation des acteurs clés du système de la Formation Professionnelle et de l’Insertion au Sud-Kivu, CEC-FPI du Sud-Kivu en sigle, est une grande synergie regroupant les acteurs clés qui interagissent dans le développement du système de la formation professionnelle et de l’insertion régit par l’arrêté provincial n°19/054/GP/SK du 11/11/2019 dont les acteurs clés sont repartis en quatre pôles à savoir : l’Offre, la Demande, l’Insertion et la Régulation.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            
              <List >
                <ListItem
                

                  
                  className={classes.margin_botton_1}
                >
                  <Typography variant="body1" className={classes.list_title}>
                    Les pages principales
                  </Typography>
                </ListItem>
                <ListItem 
                
               >
                  <NavLink to="/" className={classes.link_style}>
                    <Typography variant="body1">Accueil</Typography>
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to="/Actualite" className={classes.link_style}>
                    <Typography variant="body1">
                      Actualités et Agenda
                    </Typography>
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to="/Video" className={classes.link_style}>
                    <Typography variant="body1">Videos</Typography>
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to="/Clec" className={classes.link_style}>
                    <Typography variant="body1">CLEC</Typography>
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to="/Members" className={classes.link_style}>
                    <Typography variant="body1">Membres</Typography>
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to="/Offre" className={classes.link_style}>
                    <Typography variant="body1">L’offre</Typography>
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to="/Demande" className={classes.link_style}>
                    <Typography variant="body1">La demande</Typography>
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to="/Insertion" className={classes.link_style}>
                    <Typography variant="body1">L’insertion</Typography>
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to="/Regulation" className={classes.link_style}>
                    <Typography variant="body1">La regulation</Typography>
                  </NavLink>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <List>
                <ListItem
                  className={classes.margin_botton_1}
                >
                  <Typography variant="body1" className={classes.list_title}>
                    Autres pages
                  </Typography>
                </ListItem>
                <ListItem>
                  <NavLink className={classes.link_style} to="/Sommes_nous">
                    <Typography variant="body1">Qui sommes-nous?</Typography>
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink className={classes.link_style} to="/Contactez_nous">
                    <Typography variant="body1">Contactez-nous</Typography>
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to="/Mention_legale" className={classes.link_style}>
                    <Typography variant="body1">Mentions légales</Typography>
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to="/Sitemap" className={classes.link_style}>
                    <Typography variant="body1"> Plan du site</Typography>
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to="/Ccc" className={classes.link_style}>
                    <Typography variant="body1">CCC</Typography>
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to="/Paternaires" className={classes.link_style}>
                    <Typography variant="body1">Paternaires</Typography>
                  </NavLink>
                </ListItem>
                <ListItem>
                  <a href={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/login'} className={classes.link_style}>
                    <Typography variant="body1">Espace membres</Typography>
                  </a>
                </ListItem>
              </List>
            </Grid>
            {/* <Grid container className={classes.margin_botton_5}>
              <Grid
                container
                justifyContent="flex-start"
                className={classes.margin_botton_2}
              >
                <Typography variant="body1" className={classes.list_title}>
                  Vous pouvez aussi nous suivre sur:
                </Typography>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={12} sm={6}>
                  <Grid container
                  >
                    <Link to = "" className={classes.link_style}>
                      <InstagramIcon className={classes.icon} />
                    </Link>

                    <Link to = "" className={classes.link_style}>
                      <TwitterIcon className={classes.icon} />
                    </Link>

                    <Link to = "" className={classes.link_style}>
                      <FacebookIcon className={classes.icon} />
                    </Link>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
              </Grid>
            </Grid> */}
            <Grid container>
              <Divider orientation="horizontal" variant="fullWidth" light />
            </Grid>

            <Grid
              container
              justifyContent="center"
              className={classes.margin_botton_1}
            >
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography className={classes.oxide}>
                  © 2021 <a href='https://www.swisscontact.org/fr/pays/RD-congo' target='_blank' rel="noreferrer" className={classes.swiss}>Swisscontact</a>. All rights reserved.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
              >
                <Grid container justify='flex-end' className={classes.flexstartfooter}>
                  <Typography className={classes.oxide}>
                    Designed and developed by &nbsp;
                    <a href="https://oxide-digital.com" className={classes.oxide_color} target='_blank'  rel="noreferrer">
                      Oxide Digital Technology
                    </a>
                  </Typography>
                </Grid>
                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
