import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from '@material-ui/core'
import { Grid } from "@material-ui/core";

const Style = makeStyles({
  root: {
    width:"100%",
    
  },
});




const TabBar = (props) => {

  const classes = Style();

  const [tabValue, setTabvalue] = useState(0);

  const handleTabs = (event, tab) => {
    setTabvalue(tab)
  }

  const TabPanel = (props) => {
    const { children, value, index } = props;
    return (
      <div>
        {value === index && children}
      </div>
    )

  }
  return (
    <Grid container className={classes.root} justifyContent="center">

      <Grid item {...props.tabConfig}>
        <Tabs value={tabValue}
          onChange={handleTabs}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="secondary"
          textColor="secondary"
        >

          {props.Tabs.map((item, i) => (
            <Tab label={item.tabtile} key={i}  style={{fontWeight:'bold'}}/>
          ))}

        </Tabs>
      </Grid>
      <Grid item {...props.panelConfig} >
          {props.Tabs.map((item, i) => (
            <TabPanel value={tabValue} index ={i} key={i}>{item.tabPanel}</TabPanel>
          ))}
      </Grid>
    </Grid>
  );
}

export default TabBar
