import React,{useState} from 'react'
import {Grid,TextField,Typography,Button,IconButton,Collapse,} from '@material-ui/core'
import useStyle from './style'
import Logo from '../website/assets/images/Logo.webp'
import oxide from '../website/assets/images/oxide.svg'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import  {useForm} from 'react-hook-form'
import { Link } from 'react-router-dom'
import { checkEmailAction } from '../../store/actions/auth-actions'
import { useSelector,useDispatch } from 'react-redux'

const ForgotPassword = ()=>{
    
   
    const classes = useStyle()
    const { register, handleSubmit,reset} = useForm();
    const [ison, setison] = useState(false)
   
    const [open, setOpen] = React.useState(false);
    const [err, seterr] = useState(false)
    const dispatch = useDispatch()
    const msg = useSelector(state => state.msg.message)
    const {message} = useSelector(state => state.checkemail.checkEmail)??''

    const onSubmit = (data,e)=>{
        e.preventDefault()

        if(data!=null){
            setison(true)
            setOpen(false)
            dispatch(checkEmailAction(data.em)).then(()=>{
                setison(false)
                setOpen(true)
                reset()
            },()=>{
                setison(false)
                setOpen(true)
                seterr(true)
            })
        }
    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justify='center' alignItems='center' >
             <Grid item>
                 <Grid container direction='column'  className={classes.rootlogin2} spacing={3}>
                     <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                     <Grid container  justifyContent='center' alignItems='center'>
                             <Grid item>
                                 <img src={Logo} className={classes.logoLogin} alt="cec-fpi"/>
                             </Grid>
                     </Grid>
                     </Grid> 
                     <Grid item>
                        <Typography align='center'>
                        Veuillez saisir votre adresse e-mail ci-dessous.  Vous y recevrez un lien pour la restauration de votre mot de passe.
                        </Typography>
                    </Grid>
                     <br/>
                     <Grid item >
                     <Collapse in={open}>
                        <Alert
                        severity={err?'error':'success'}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        >
                        { msg?msg:message}
                        </Alert>
                    </Collapse>
                         </Grid> 
                    
                     
                     <Grid item >
                            <TextField label='E-mail' {...register('em')} required placeholder="Entrez l'e-mail" size='small' variant='outlined' fullWidth={true}/>
                     </Grid>
                     
                     <Grid item  >
                            <Button   type="submit" disabled={ison===true?true:false}  variant='text'  fullWidth className={classes.btnLogin}>{ison===true?(<CircularProgress size={30}/>):'Envoyer'}</Button>                                           
                    </Grid>
                    <br/>
                    <Grid item >
                            <Typography>Avez-vous récupéré votre mot de passe?</Typography>
                            <Typography><Link to={`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/login`} style={{textDecoration:'none'}}> Se connecter </Link> </Typography>
                     </Grid>
                    <br/>
                    
                 </Grid>
                 <Grid item style={{marginTop:20}}>
                         <Grid Container direction='column' justify='center' alignItems='center'>
                             <Grid item ><Typography align='center'  className={classes.poweredby}>Powered by :</Typography></Grid>
                             <Grid item> <center><img src={oxide} alt='Oxide logo' className={classes.oxide}/></center></Grid>
                         </Grid>
                     </Grid>
             </Grid>
    </Grid>
    </form>
    )
}

export default ForgotPassword;