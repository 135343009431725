import {makeStyles} from '@material-ui/core/styles'

const styles = makeStyles((theme)=>({
    rootdetail:{
        backgroundColor:'#F6FBFF',
        color:'#1D2F3F',
        [theme.breakpoints.down("md")]:{
            padding:'3em 0em',
        },
        [theme.breakpoints.up("md")]:{
            padding:'6em 0em',
        }
        

   },
    body:{
            margin:'3em 0em 1em'
    },
    head:{
        '& h5':{
            margin:'1em 0vw',
            
        },
        '& span':{
            color:'#3F74A4'
        },
    },
    link:{
        color:"#1EDAB2",
        margin: "3em 0vw",
        textDecoration: "none",
        "& svg": {
            fontSize: "1.3rem",
            alignSelf:"center",
            
        },
   },
    root:{
        marginTop:80,
       paddingLeft:30,
       paddingRight:10,
       marginRight:90,
       [theme.breakpoints.down('xs')]:{
        paddingLeft:0,
       paddingRight:0,
       } 
    },

    backPaper:{
       backgroundColor:'#F6FBFF',     
       marginTop:30,
       marginRight:90,
       marginLeft:10,
       
       
       [theme.breakpoints.down('xs')]:{
        marginRight:5,
        marginLeft:5,
        paddingLeft:10,
       }   
    },
    spacebetween:{
        paddingLeft:20,
       paddingRight:20,

        marginTop:30,
        marginBottom:30,
    },
    btn:{
        backgroundColor:'#FFAE02',
        height:50,

        '&:hover':{
            backgroundColor:'#FFAE02',
        },
        [theme.breakpoints.down('xs')]:{
            
            fontSize:8
            
        }
    }
}))

export default styles;