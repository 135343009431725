import React,{useState,useEffect}  from  'react'
import {Button,Grid,TextField,Typography} from '@material-ui/core'
import  {useForm} from 'react-hook-form'
import jwt_decode from "jwt-decode";

const UserAccount = (props)=>{

    const refresh = props.match.params.token
    const refresh_token =JSON.parse(atob(refresh.split('.')[1]))
    const now = Math.ceil(Date.now() / 1000);
    const token = jwt_decode(props.match.params.token)
    const [cfirm, setcfirm] = useState(false)
    const [ermsg, setermsg] = useState('Les mots de passe doivent correspondre')

    const { register,watch, handleSubmit,reset} = useForm();

    const onSubmit=(data,e)=>{
        e.preventDefault()
        if(data.email && data.pass){
            sessionStorage.setItem('data1',JSON.stringify(data))
            sessionStorage.setItem('createdby',refresh_token.user_id)
            sessionStorage.setItem('role',refresh_token.role)

            props.handClick()
        }
    }
    useEffect(() => {
        if(refresh_token.exp>now){
            const data1 =JSON.parse(sessionStorage.getItem('data1'))
            if(data1){
                reset({
                 email:token.email,
                 org: token.organisation,
                 pass:data1.pass,
                 confirm:data1.confirm
                })
 
            }else{
             reset({
                 email:token.email,
                 org: token.organisation,
  
             })
            }
 
        }
        
    }, [])
     return (
        <form onSubmit={handleSubmit(onSubmit)}>
        <Grid Container direction='column' spacing={3}>
            <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography>E-mail</Typography>
            <TextField 
                type='email'
                disabled
                required
                variant='outlined' 
                fullWidth 
                size='small'
                {...register('email',{required:true,})}
            />
            </Grid>
            <br/>
            <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography>Organisation</Typography>
            <TextField 
                type='text'
                required
                disabled
                variant='outlined' 
                fullWidth 
                size='small'
                {...register('org',{required:true,})}
                
            />
            </Grid>
            <br/>
            <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography>Mot de passe</Typography>
            <TextField 
                error={cfirm==true?true:false}
                type='password'
                required
                variant='outlined' 
                fullWidth 
                size='small'
                inputProps={{minLength:8,maxLength:20}} 
                {...register('pass',{required:true,})}
                helperText='Mot de passe doit avoir au moins 8 caractères'
            />
            </Grid>
            <br/>
            <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography>Confirmer le mot de passe</Typography>
            <TextField 
                error={cfirm==true?true:false}
                type='password'
                required
                variant='outlined' 
                inputProps={{minLength:8,maxLength:20}} 
                fullWidth 
                size='small'
                {...register('confirm',{validate: value =>{
                    const a = value === watch('pass')  || "Les mots de passe doivent correspondre"
                      
                      if(a=="Les mots de passe doivent correspondre"){
                          setcfirm(true)
                          setermsg("Les mots de passe doivent correspondre")
                      }
                      return a
                  }
                     }
                     )}
                helperText={ermsg}
                
            />
            </Grid>
            <br/>
            <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container justifyContent='flex-end'>
                    <Grid item>
                       <Button type='submit' color='primary'  variant='outlined'>Suivant</Button>
                    </Grid>
                </Grid>
            </Grid>
            
        </Grid>
    </form>
     )
}

export default UserAccount