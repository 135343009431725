import React,{useState,useEffect} from 'react'
import Backpaper from '../../../sc/backPaper'
import TableData from '../../../sc/table'
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import SortIcon from '@material-ui/icons/Sort';
import { useHistory } from 'react-router-dom';
import {BtnOrange,BtmRed} from '../../../sc/utilities'
import {useSelector,useDispatch}  from 'react-redux'
import DialogBox from '../../../sc/dialog';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {getAgenda,deleteAgenda} from '../../../../../store/actions/agenda-actions'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { Box ,Collapse,IconButton} from '@material-ui/core';






const columns = [
    {
        field:   `title`,
        headerName: `TITRE`,
        width: 150,
    },
    {
        field:   `date`,
        headerName: `DATE PREVUE`,
        width: 250,
    },
    {
        field:   `start_time`,
        headerName: `COMMENCE A`,
        width: 230,
    },
    {
        field:   `end_time`,
        headerName: `JUSQU'A`,
        width: 150,
    },
   
    ]

const Agenda =()=>{

    const history = useHistory()
    const agenda = useSelector((state)=>state.agenda.agenda)
    const delAgenda=useSelector((state)=>state.agenda.deleteAgenda)
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const [isDelete, setisDelete] = useState(false)
    const dispatch = useDispatch()
    const [rowId, setrowId] = useState([])
    const [opens, setOpens] = React.useState(false);
    const [isDel, setisDel] = useState(false)
    const [er, seter] = useState(false)
    const msg = useSelector((state)=>state.msg.message)
    const [currentUser, setcurrentUser] = useState('')
    
    

   

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        setOpen(false);
       
      
      };
    const handleClickOpen = () => {
       setOpen(true);
     };
    
    
    const handleCheckDelete =()=>{
        if(rowId.length!==0){
            handleClickOpen()
            setisDelete(true)
        }else{
            setisDelete(false)
            return null
        }
    }

    const handleDelete =()=>{
        if(rowId.length!==0 && isDelete===true){
        setisDel(true)
        dispatch(deleteAgenda(rowId)).then(()=>{
            dispatch(getAgenda())
            setOpens(true)
            setisDel(false)
            handleClose()

        },()=>{
            setOpens(true)
            setisDel(false)
            handleClose()
            seter(true)
        })


        }
    }

    const handleRowSelected =(rowId)=>{

        if(rowId===0){
            setrowId()
        }

        setrowId(rowId)
      
       
    }


    const handleRowClick=(row)=>{

         history.push({
            pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/agenda/detail/${row.row.title}=${row.id}`,
        })
        sessionStorage.setItem('rowId',row.id)
    }
    const handleCreate =()=>{
        history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/agenda/create`)
    }

   const  getUserRole =()=>{
        const role  = sessionStorage.getItem('role')
        setcurrentUser(role)
    }
    useEffect(() => {
        getUserRole()
       
    }, [])
    return (

        <Backpaper
            title={"Agenda"}
            icon={<SortIcon/>}
            yellowbtn = {currentUser<=2?<BtnOrange onClick={handleCreate} btnYellowTitle="CRÉER" startIcon={<AddCircleOutlineRoundedIcon style={{color:'white',fontSize:14}}/>} />:null}

            content={
                <div >
                    <Box height={20}/>
                    <Collapse in={opens}>
                        <Alert
                        severity={er?'error':'success'}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpens(false);
                                seter(true)
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        >
                        { msg!==null?msg:delAgenda}
                        </Alert>
                    </Collapse>
                    <Box height={20}/>
                    <TableData
                        columns={columns}
                        rows={agenda??[]}
                        onRowClick ={handleRowClick} 
                        onSelectionModelChange={(selectedRow)=>handleRowSelected(selectedRow)}
                    />
                    <DialogBox
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleClose}
                        //title= {`${userbyId.first_name}`}
                        Oui={isDel===true?(<CircularProgress size={10}/>):'Oui'}
                        confirnClick={handleDelete}
                        nonOnclick={handleClose}
                        content='Etês-vous sûr de vouloir supprimer cet agenda'
                    />
                </div>
            }
            
            />
        
    )
}

export default Agenda