import React,{useRef}  from 'react';
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Actus from './actus'
import Table from './table'
import NewsSkeleton from '../../dashboard/sc/newsSkeleton'
import {useSelector} from 'react-redux'


const Style = makeStyles((theme)=> ({


    root:{
        marginTop:80,
        color: '#1D2F3F',
        '& sup': {
            fontSize:10
        },
        '& p': {
            textAlign:theme.typography.textBodyAlign
          },
        '& h3':{
            textAlign:"center",
            fontWeight:theme.typography.fontWeightBold,
            margin:"1.2em 0em",
            [theme.breakpoints.down("md")]:{
                fontSize:"9vw"
              },
              [theme.breakpoints.up("md")]:{
                fontSize:"2.75rem"
              }
        }
        

    },
    headBackground:{
        background:"#F6FBFF",
        padding:"5vw 0vw",
        "& h2":{
            fontWeight:theme.typography.fontWeightBold,
            margin:"1vw 0vw",
            fontSize:"2.75rem"
        },
        "& p":{
            margin:"1em 0vw"
        },
        "& img":{
            borderRadius:"0.5vw",
        }
    },
    bodyRender: {
        padding:"5vw 0vw",
    },

    clec:{
        padding:"3.1vw 0vw",
        "& p":{
            margin:"2vw 0vw"
        },
        "& h2":{
            fontSize:"2.75rem"
        },
    },
    Left:{
        margin:"2em 0em",
        [theme.breakpoints.up("md")]:{
            order:"2"
        }
        
    },
    Right:{
        margin:"2em 0em",
        [theme.breakpoints.up("md")]:{
            order:"1"
        }
    },
    table: {
        padding:"3vw 0vw"
    },
    noNews: {
        height: 300,
        display: "flex",
        alignItems: "center",
        color: theme.palette.primary.fade
    }
   

}));

const Widget = (props) =>{
    const {actualite} = useSelector(state => state.actualitePub)
    const actualiteRef = useRef([])
    const { message } = useSelector(state => state.msg)
    actualiteRef.current =actualite


    const handleSkeleton =()=>{
     
        if(actualiteRef.current.length>0){
          return (
            <Actus List={actualiteRef.current.filter((e)=>e.category==="CCC" && e.approval==true).slice(0,3)} /> 
          )
        }else{
          return (
              <Grid container justifyContent='space-between' spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <NewsSkeleton/>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <NewsSkeleton/>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <NewsSkeleton/>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <NewsSkeleton/>
                  </Grid>
              </Grid>
          )
        }
      }

    const classes = Style();
    
    const ListRender = (List) => {
        // positioning according to image
        let template = List.map((item , i) =>{
            let pos = ""
            i % 2 === 0 ? pos = "left" : pos = "right"
            return <Grid item key ={i} >
                <Grid container 
                    alignItems = "center" 
                    justifyContent="space-evenly" 
                    className={classes.clec}>
                    <Grid item 
                        xs = {10} sm={8} md = {4} lg={4} 
                        className = {pos === "left" ? classes.Left : classes.Right} 
                    >
                        <img src={item.image} alt={`CLEC_${i}`} width="100%" height = "100%"/>
                    </Grid>
                    <Grid item 
                        xs = {10} sm={10} md = {5} lg={5} 
                        className = {pos === "left" ? classes.Right : classes.Left}
                    >
                        <Typography variant="h2" >
                            {item.title}
                        </Typography>

                        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: item.Body}}/>

                    </Grid >
                </Grid>
            </Grid>
        })

        return template
    }

    const bodyRender = (content) => (
        <Grid container
            alignItems = "center"
            justifyContent="space-evenly"
            className={classes.bodyRender}
        >
            <Grid item xs = {10} sm = {10} md = {10} lg = {10}>
                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: content.paragraph }} />
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10} className = {classes.table}>
                <Table columns={content.table.columns} rows={content.table.rows}/>
            </Grid>
        </Grid> 
    )


    return (
        <React.Fragment>
            <Grid container className={classes.root} direction="column">
                <Grid container
                    alignItems="center"
                    justifyContent="space-evenly"
                    className={classes.headBackground}
                >
                    <Grid item xs={10} sm={10} md={10} lg={10} className={classes.head}>
                        <Typography variant="h2" >
                            {props.title}
                        </Typography>

                        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: props.Body }} />

                    </Grid >

                </Grid>

                {props.List && ListRender(props.List)}
                {props.paragraph && bodyRender(props.paragraph)}

                {(actualiteRef.current.length > 0 || message != undefined) && <Grid container justifyContent="center">
                    <Grid container justifyContent="center">
                        <Grid item xs={10}>
                            <Typography variant="h3" >
                                {props.ActuTitle}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={10}>
                        {handleSkeleton()}
                    </Grid>
                </Grid>}
            </Grid>
        </React.Fragment>
    )
}

export default Widget