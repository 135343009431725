import React,{useState} from 'react'
import {Button,IconButton,Collapse,Grid, TextField, Typography,InputAdornment} from '@material-ui/core'
import useStyle from '../pages/style'
import Logo from '../../website/assets/images/Logo.webp'
import oxide from '../../website/assets/images/oxide.svg'
import  {useForm} from 'react-hook-form'
import {useHistory,Redirect} from 'react-router-dom'
import axiosInstance from '../../../service/axiosApi'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';



const Login =()=>{

    const classes = useStyle()
    const history= useHistory();
    const { register, handleSubmit,formState: { errors }} = useForm();
    const [ison, setison] = useState(false)
    const [msg, setmsg] = useState()
    const [open, setOpen] = React.useState(false);
    const [err, seterr] = useState()
    const [isPassword, setisPassword] = useState(false)


    const handleClickShowPassword = () => {
        setisPassword(!isPassword)
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    

    const onSubmit=(data,e)=>{
        e.preventDefault()
        if(data.email && data.password){
            handleLogin(data.email,data.password)
        }
    }

   
    const handleLogin  = async(email, password)=>{
        setison(true)
        setOpen(false)
        await axiosInstance.post(`api/account/userlogin/`,{
            email:email,password:password
        }).then((res)=>{
            const usrdata = res.data.authenticatedUser 
            setOpen(true)
                const data = (axiosInstance.defaults.headers["Authorization"] ="Bearer " + res.data.access);      
            sessionStorage.setItem("token", res.data.access);
            sessionStorage.setItem("refresh", res.data.refresh);
            sessionStorage.setItem('user',usrdata.email)
            sessionStorage.setItem('role',usrdata.role)
            sessionStorage.setItem('org',usrdata.organization)
            sessionStorage.setItem('id',usrdata.id)
            sessionStorage.setItem("value",0)
            history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard`);
            return data;
        }).catch((err)=>{
            seterr(true)
            setison(false)
            setOpen(true)
            const message =
            (err.response &&
            err.response.data &&
            err.response.data.message) ||
            err.message ||
            err.toString();
        return setmsg(message)
        })
    }

    if(sessionStorage.getItem('token')!=null){
        return <Redirect to={`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard`} />
    }
 
    return (
       <Grid container justify='center' alignItems='center'>
           <form onSubmit={handleSubmit(onSubmit)}>
                <Grid item>
                    <Grid container  className={classes.rootlogin} spacing={3}>
                        <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container justifyContent='center' alignItems='center'>
                                <Grid item>
                                    <img src={Logo} className={classes.logoLogin} alt="cec-fpi"/>
                                </Grid>
                        </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Collapse in={open}>
                                <Alert
                                severity={err?'error':'success'}
                                action={
                                    <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpen(false);
                                        
                                    }}
                                    >
                                    <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                >
                               { msg}
                                </Alert>
                            </Collapse>
                        </Grid>
                        <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid Container direction='column' spacing={2}>
                                <Grid item><Typography>E-mail*</Typography></Grid>
                                <Grid item>
                                    <TextField 
                                        //name='email'
                                        type='email'
                                        required
                                        variant='outlined' 
                                        fullWidth
                                        size='small'
                                        {...register('email',{required:true,pattern:/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/})}
                                        error={errors.email?true:false}
                                        helperText={errors.email?'write a valid email':null}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid Container direction='column' spacing={2}>
                                <Grid item><Typography>Mot de passe*</Typography></Grid>
                                <Grid item>
                                    <TextField 
                                        type={!isPassword?'password':'text'}
                                        required
                                        variant='outlined' 
                                        fullWidth 
                                        size='small'
                                        {...register('password',{required:true})}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                 <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    >
                                                    {!isPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                            </InputAdornment>
                                          }}
                                     />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                             <Button color='secondary' style={{textTransform:'inherit'}}  onClick={()=>{
                                 history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/oublie`)
                             }} >Avez-vous oublié le mot de passe?</Button>                                           
                        </Grid>
                        <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Button   type="submit" disabled={ison===true?true:false}  variant='text'  fullWidth className={classes.btnLogin}>{ison===true?(<CircularProgress size={30}/>):'connexion'}</Button>                                           
                        </Grid>
                       
                        <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid Container direction='column' justify='center' alignItems='center'>
                                <Grid item ><Typography align='center'  className={classes.poweredby}>Powered by :</Typography></Grid>
                                <Grid item> <center><img src={oxide} alt='Oxide logo' className={classes.oxide}/></center></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
           </form>
       </Grid>
    )
}

export default Login