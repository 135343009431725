import api from './axiosApi';
import apiPub from './axiosPub'


const addAgenda =(
    title,
    date,
    start_time,
    end_time,
    description,
    create_by
)=>{
   return api.post('api-agenda/agenda/',{
    title,
    date,
    start_time,
    end_time,
    description,
    create_by,
   }).then((res)=>{
        return res.data
    })
}

const getAgenda =()=>{
    return api.get('api-agenda/agenda/').then((res)=>{
         return res.data.agenda
     })
 }

const getAgendaById =(id)=>{
  return  api.get(`api-agenda/agenda/${id}/`).then((res)=>{
        return res.data.agenda
    })
}

const updateAgenda =(
        id,
        title,
        date,
        start_time,
        end_time,
        description,
        create_by,
        modification
        )=>{
   return  api.patch(`api-agenda/agenda/${id}/`,{
        id,
        title,
        date,
        start_time,
        end_time,
        description,
        create_by,
        modification
       
    }).then((res)=>{
        return res.data
    })
}

const deleteAgenda =(id)=>{
    return api.delete(`api-agenda/agenda/${id}/`).then((res)=>{

        return res.data
    })
}

const createModification=(
    modified_by,
    modification_date,
    app,
    Activity
)=>{
    return api.post('api-modification/modification/',{
        modified_by,
        modification_date,
        app,
        Activity
    }).then((res)=>{
        return res.data
    })
}

const agendaPub =()=>{
    return apiPub.get('api-agenda/agenda/pub/').then((res)=>{
        return res.data.agenda
    })
}


const agendaByIdPub =(id)=>{
    return apiPub.get(`api-agenda/agenda/pub/${id}/`).then((res)=>{
        return res.data.agenda
    })
}

const AgendaService = {
    getAgenda,
    addAgenda,
    getAgendaById,
    updateAgenda,
    deleteAgenda,
    createModification,
    agendaPub,
    agendaByIdPub

    
}
export default AgendaService;
   