import React,{useState,useEffect} from 'react'
import Backpaper from '../../../sc/backPaper'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useHistory } from 'react-router';
import {BtnOrange,BtmRed} from '../../../sc/utilities'
import TableData from '../../../sc/table'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogBox from '../../../sc/dialog';
import SortIcon from '@material-ui/icons/Sort';
import {useSelector,useDispatch}  from 'react-redux'
import {getEspaceMembre,deleteEspaceMembre} from '../../../../../store/actions/espace-actions'
import { Box ,Menu,MenuItem,InputAdornment,Collapse,IconButton, TextField} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import adobe from  '../../../images/adobe.png'
import docs from '../../../images/docs.png'
import excel from  '../../../images/excel.png'
import ppt from '../../../images/ppt.png'
import CloudDownload from '@material-ui/icons/CloudDownload'




const EspaceMembre =()=>{
    const history = useHistory()
    const [rowId, setrowId] = useState([])
    const [open, setOpen] = useState(false);
    const [doc, setdoc] = useState('')
    const theme = useTheme();
    const [isDelete, setisDelete] = useState(false)
    const dispatch = useDispatch()
    const {espace} = useSelector((state)=>state.espace)
    const {deleteespace} = useSelector((state)=>state.espace)
    const [opens, setOpens] = React.useState(false);
    const [isDel, setisDel] = useState(false)
    const [er, seter] = useState(false)
    const msg = useSelector((state)=>state.msg.message)
    const [currentUser, setcurrentUser] = useState('')
    const [anchor, setAnchor] = React.useState(null);
    const [filteredEspace, setfilteredEspace] = useState([])
    

    const handleClickAnchor = (event) => {
        //setAnchor(null);
       // setAnchor(null)
        const value  = event.currentTarget
        setAnchor(value);
      };
    
    const handleCloseAnchor = () => {
         setAnchor(null);
      };


   


    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        setOpen(false);
      };
    const handleClickOpen = () => {
       setOpen(true);
     };
    
    
    const handleCheckDelete =()=>{
        if(rowId.length!==0){
            handleClickOpen()
            setisDelete(true)
        }else{
            setisDelete(false)
            return null
        }
    }

    const handleDelete =()=>{
        setisDel(true)
        setOpens(true)
        if(rowId.length!==0 && isDelete===true){
        dispatch(deleteEspaceMembre(rowId)).then(()=>{
            handleClose()
            dispatch(getEspaceMembre())
            setOpens(true)
            setisDel(false)

        },()=>{
            handleClose()
            setOpens(true)
            setisDel(false)
            seter(true)
        })

        handleClose()
        }
    }

    const getMenuSelected = (event)=>{
        const menu = event.target.value
        setAnchor(null)

        if(menu  ===1){
            handleCheckDelete()
            handleCloseAnchor()
        }
        else if (menu ===2){
            handleModifier()
            handleCloseAnchor()
        }
        else if (menu ===3){
            handleCloseAnchor()
        }

    }

    const handleRowSelected =(rowId)=>{
        if(rowId===0){
            setrowId()
        }
       // console.log(rowId);
        const a=espace.filter((e)=>e.id==rowId);
        setrowId(rowId)
        setdoc(a.map((e)=>e.document))
    }

    const handleModifier = ()=>{
        history.push({
                pathname: `/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/espace/create`,
                data:rowId
        })

    }
    const handleSpace =()=>{
        history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/espace/create`)
    }
    const handleRowClick = (row)=>{
        setdoc(row.row.document)
       
    }
    const  getUserRole =()=>{
        const role  = sessionStorage.getItem('role')
        setcurrentUser(role)
    }


    const columns = [
        {
            field:   `document`,
            headerName: `Type`,
            width: 110,
            disableClickEventBubbling: true,
            renderCell: (params) => {
              //  console.log(params.value.includes('.docx'));
    
                if(params.value.includes('.docx')){
                    return (
                        <img
                          src = {docs}
                          height={40}
                          width={40}
                          style={{marginLeft:16}}
                          variant="contained"
                          color="primary"
                          size="small"/>
                               )  
                }
                else if (params.value.includes('.pdf')){
                    return (
                        <img
                          src = {adobe}
                          height={40}
                          width={40}
                          style={{marginLeft:16}}
                          variant="contained"
                          color="primary"
                          size="small"/>
                               )  
                }
    
                else if (params.value.includes('.ppt')|| params.value.includes('.pptx')|| params.value.includes('.pptm')|| params.value.includes('.pptm')){
                    return (
                        <img
                          src = {ppt}
                          height={40}
                          width={40}
                          style={{marginLeft:16}}
                          variant="contained"
                          color="primary"
                          size="small"/>
                               )  
                }
    
                else if (params.value.includes('.xlsx') ||params.value.includes('.xlsm')||params.value.includes('.xlsb')|| params.value.includes('.xltx')){
                    return (
                        <img
                          src = {excel}
                          height={40}
                          width={40}
                          style={{marginLeft:16}}
                          variant="contained"
                          color="primary"
                          size="small"/>
                               )  
                }
    
                   
            },
           
        },
    
        {
            field:   `name`,
            headerName: `Nom`,
            width: 250,
            renderCell: (params) => {
                //console.log(params.value.includes('.docx'));
                    return (
                        <a target='_blank' style={{textDecoration:'none'}} href={doc}>
                            {params.value}
                        </a>
                               )  
                
            }
        },
        
        {
            field:   `sharedBy`,
            headerName: `Partagé par`,
            width: 220,
        },
        {
            field:   `created_date`,
            headerName: `Crée le`,
            width: 150,
        },
        {
            field:   `description`,
            headerName: `Commentaire`,
            width: 270,
        },
        // {
        //     field:   `actions`,
        //     headerName: `Télécharger `,
        //     width: 150,
        //     disableClickEventBubbling: true,
        //     renderCell: (params) => {
        //         //console.log(params.value.includes('.docx'));
        //             return (
        //                 <a target='_blank' style={{textDecoration:'none'}} href={doc}>
        //                     <IconButton><CloudDownload color='secondary'/></IconButton>
        //                 </a>
        //                        )  
                
        //     }
        // },
    
        
       
       
        ]


    useEffect(() => {
        getUserRole()
    }, [])


    return (
        <Backpaper
            iconClick={rowId.length!==0?handleClickAnchor:null}
            icon={currentUser<=2? <SortIcon /> :null}
            title={"Espace membres"}
            yellowbtn = {<TextField
                onChange ={(val)=>{
                  const value = espace.filter((item)=> item.name.toLowerCase().indexOf(val.target.value)>-1)
                  //console.log(value);
                  setfilteredEspace(value)
                }}
                placeholder= 'Rechercher avec le nom'
                fullWidth  InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }} />}
             redbtn = {currentUser<=2?<BtnOrange onClick={handleSpace} btnYellowTitle="Ajouter" startIcon={<AddCircleOutlineIcon style={{color:'white',fontSize:14}}/>} />:null}
             content={
                <div>
                    <Menu
                        //id="simple-menu"
                        anchorEl={anchor}
                        keepMounted
                        //onClick = {getMenuSelected}
                        variant = 'menu'
                        open={Boolean(anchor)}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}  >
                        <MenuItem onClick={getMenuSelected} value='1'>Supprimer</MenuItem>
                        <MenuItem onClick={getMenuSelected} value='2'>Modifier</MenuItem>
                        <MenuItem onClick={getMenuSelected} value='3' style={{color:'red'}}>Annuler</MenuItem>
                  </Menu>
                    <Box height={20}/>
                        <Collapse in={opens}>
                            <Alert
                            severity={er?'error':'success'}
                            action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpens(false);
                                    seter(false)
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            >
                            {msg!==null?msg: deleteespace}
                            </Alert>
                        </Collapse>
                    <Box height={20}/>

                    <TableData
                        columns={columns}
                        rows={filteredEspace.length>0?filteredEspace:espace??[]}
                        onSelectionModelChange={(selectedRow)=>handleRowSelected(selectedRow)}
                        onRowClick={handleRowClick}
                        //onRowSelected={handleRowSelected}
                    />
                    <DialogBox
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleClose}
                        //title= {`${userbyId.first_name}`}
                        Oui={isDel===true?<CircularProgress size={10}/>:"Oui"}
                        confirnClick={handleDelete}
                        nonOnclick={handleClose}
                        content='Etes-vous sûr de vouloir supprimer ce fichier ? '
                    />
               </div>
            }
        />
    )
}

export default EspaceMembre