import {
    ADD_USER_SUCCES,
    ADD_USER_FAIL,
    ADD_PROFILE_SUCCES,
    ADD_PROFILE_FAIL,
    ADD_SEND_CODE_SUCCES,
    ADD_SEND_CODE_FAIL,
    CHECK_VERIFICATION_CODE_SUCCES,
    CHECK_VERIFICATION_CODE_FAIL,
    SEND_CONTACT_SUCCESS,
    SEND_CONTACT_FAIL,
    FETCH_USERS_BY_ID_SUCCES,
    FETCH_USERS_BY_ID_FAIL,
    UPDATE_USER_SUCCES,
    UPDATE_USER_FAIL,
    DELETE_USER_BY_ID_SUCCES,
    DELETE_USER_BY_ID_FAIL,
    CHANGE_PASSWORD_USER_SUCCES,
    CHANGE_PASSWORD_USER_FAIL,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAIL,
    ACTIVATED_USER_SUCCES,
    ACTIVATED_USER_FAIL,
    CHECK_EMAIL_SUCCESS,
    CHECK_EMAIL_FAIL,
    SEND_EMAIL_CODE_SUCCESS,
    SEND_EMAIL_CODE_FAIL,
    RESTORE_PASSWORD_SUCCESS,
    RESTORE_PASSWORD_FAIL,
    CHECK_EMAIL_CODE_SUCCESS,
    CHECK_EMAIL_CODE_FAIL


    
} from '../contants/type'


const initialState = {
    user: []
}

const initialStatepro = {
    profile: []
}

const initialStatesend = {
    sendCode: []

}

const initialStateCheckcode = {
  checkcode :[]
}



const userByIdReducer = (state = [],action)=>{
  const {type,payload} = action

  switch (type) {
      case FETCH_USERS_BY_ID_SUCCES:
        return {
          ...state,
          user: payload,
        
        };
      case FETCH_USERS_BY_ID_FAIL:
        return {
          ...state,
          //user: 'La creation Utilisateur  a échoue car l\'utilisateur existe déjà ou veuillez  verifier si vous etez connectés',
        
        };
      default:
        return state;
    }

}
const userReducers = (state = initialState,action)=>{
    const {type,payload} = action

    switch (type) {
        case ADD_USER_SUCCES:
          return {
            ...state,
            user: payload,
          
          };
        case ADD_USER_FAIL:
          return {
            ...state,
            user: 'La création Utilisateur  a échoue car l\'utilisateur existe déjà ou veuillez  verifier si vous etez connectés',
          
          };
        default:
          return state;
      }

}

const profileReducers = (state = initialStatepro,action)=>{
    const {type,payload} = action

    switch (type) {
        case ADD_PROFILE_SUCCES:
          return {
            ...state,
            profile: payload,
          };
        case ADD_PROFILE_FAIL:
          return {
            ...state,
          };
        default:
          return state;
      }

}

const sendCodeReducers = (state = initialStatesend,action)=>{
    const {type,payload} = action

    switch (type) {
        case ADD_SEND_CODE_SUCCES:
          return {
            ...state,
            sendCode: payload,
          };
        case ADD_SEND_CODE_FAIL:
          return {
            ...state,
            sendCode:''
          };
        default:
          return state;
      }

}

const checkCodeReducers = (state = initialStateCheckcode,action)=>{
  const {type,payload} = action

  switch (type) {
      case CHECK_VERIFICATION_CODE_SUCCES:
        return {
          ...state,
          checkcode: payload,
        };
      case CHECK_VERIFICATION_CODE_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }

}

const contactReducers = (state = [],action)=>{
  const {type,payload} = action

  switch (type) {
      case SEND_CONTACT_SUCCESS:
        return {
          ...state,
          contact: payload,
        };
      case SEND_CONTACT_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }

}


const userUpdate = (state =[] ,action)=>{
  const {type,payload} = action

  switch (type) {
      case UPDATE_USER_SUCCES:
        return {
          ...state,
          updateUser: payload,
        };
      case UPDATE_USER_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
}

const userActivated = (state =[] ,action)=>{
  const {type,payload} = action

  switch (type) {
      case ACTIVATED_USER_SUCCES:
        return {
          ...state,
          activateUser: payload,
        };
      case ACTIVATED_USER_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
}

const userDelete = (state =[] ,action)=>{
  const {type,payload} = action

  switch (type) {
      case DELETE_USER_BY_ID_SUCCES:
        return {
          ...state,
          userDelete: payload,
        };
      case DELETE_USER_BY_ID_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
}

const userChangePassword = (state =[] ,action)=>{
  const {type,payload} = action

  switch (type) {
      case CHANGE_PASSWORD_USER_SUCCES:
        return {
          ...state,
          changePassword: payload,
        };
      case CHANGE_PASSWORD_USER_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
}


const allUser = (state =[] ,action)=>{
  const {type,payload} = action

  switch (type) {
      case FETCH_USERS_SUCCESS:
        return {
          ...state,
          users: payload,
        };
      case FETCH_USERS_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
}


const checkEmailReducer =(state =[] ,action)=>{
  const {type,payload} = action

  switch (type) {
      case CHECK_EMAIL_SUCCESS:
        return {
          ...state,
          checkEmail: payload,
        };
      case CHECK_EMAIL_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
}

const sendEmailCode =(state =[] ,action)=>{
  const {type,payload} = action

  switch (type) {
      case SEND_EMAIL_CODE_SUCCESS:
        return {
          ...state,
          checkEmail: payload,
        };
      case SEND_EMAIL_CODE_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
}


const restorePassword =(state =[] ,action)=>{
  const {type,payload} = action

  switch (type) {
      case RESTORE_PASSWORD_SUCCESS:
        return {
          ...state,
          checkEmail: payload,
        };
      case RESTORE_PASSWORD_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
}

const checkEmailCode =(state =[] ,action)=>{
  const {type,payload} = action

  switch (type) {
      case CHECK_EMAIL_CODE_SUCCESS:
        return {
          ...state,
          checkEmailCode: payload,
        };
      case CHECK_EMAIL_CODE_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
}




const AuthReducer = {
    userReducers,
    profileReducers,
    sendCodeReducers,
    checkCodeReducers,
    contactReducers,
    userByIdReducer,
    userUpdate,
    userDelete,
    userChangePassword,
    allUser,
    userActivated,
    checkEmailReducer  ,
    sendEmailCode,
    restorePassword,
    checkEmailCode
}

export default AuthReducer