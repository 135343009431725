import React from 'react'
import useStyles from '../pages/style'
import {Grid,IconButton,Paper, Typography} from '@material-ui/core'


const Backpaper =({content,title,yellowbtn,redbtn,icon,access,iconClick})=>{
    const classes = useStyles()
    return (
           <Grid container>
                <Grid item xs={12} sm={12}>
                    <Paper elevation={0} className={classes.backPaper}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container justify='flex-end' alignItems='flex-end'>
                                    <Grid item>
                                    <Grid container direction='row' spacing={3}>
                                        <Grid item>{access}</Grid>
                                        <Grid item>{yellowbtn}</Grid>
                                        <Grid item>{redbtn}</Grid>
                                    </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Grid container justify='flex-start' alignItems='flex-start'>
                                            <Grid item>
                                                <Typography className={classes.backPaperTitle}>{title}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Grid container justify='flex-end' alignItems='flex-end'>
                                        <Grid item>
                                                <IconButton onClick={iconClick}>{icon}</IconButton>
                                        </Grid>
                                    </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {content}
                    </Paper>
                </Grid>
            </Grid>


    )
}




export default Backpaper;