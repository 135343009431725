import React ,{useRef}from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Actus from "./actus";
import NewsSkeleton from '../../dashboard/sc/newsSkeleton'
import {useSelector} from 'react-redux'



const Style = makeStyles((theme)=> ({
  root: {
    
    color: "#1D2F3F",
    '& sup': {
      fontSize:10
    },
    '& i': {
       color: props => props.highlight ? props.highlight : "#1D2F3F"
      }, 
      '& p': {
        textAlign:theme.typography.textBodyAlign
      },
      '& dt': {
        fontWeight: theme.typography.fontWeightBold,
        marginTop: '1.5em',
        marginBottom: '1.5em',
        fontSize:18
    },
    '& dd': {
      marginTop: '1em',
      marginBottom:'1em'
      },
      
        
      "& h3": {
        fontWeight: '700',
        margin: "1.2em 0em",
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
          fontSize: "9vw"
        },
        [theme.breakpoints.up("md")]: {
          fontSize: "2.75rem"
        }
      }
    },
    title:{
      "& h2":{
          fontWeight:'700',
          margin:"0.5em 0em",
          fontSize:"2.75rem"
        },
    },
    paragraph: {
    [theme.breakpoints.down('md')]:{
      margin:"0em"
        
      },
      [theme.breakpoints.up('md')]:{
        margin:"1em 0em"
      }
    },
    img:{


      marginTop:124,
      [theme.breakpoints.down('xs')]: {
        marginTop:99,
        height:200
          
        },
  },
  noNews: {
    height: 300,
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.fade
  }
}));

const InserctionComp = (props) => {
  const classes = Style(props);
  const { actualite } = useSelector(state => state.actualitePub)
  const { message } = useSelector(state => state.msg)
  const actualiteRef = useRef([])
  actualiteRef.current =actualite


  const handleSkeleton =()=>{
      if(actualiteRef.current.length>0){
        return (
          <Actus List={actualiteRef.current.filter((e)=>e.category==="L'insertion" && e.approval==true).slice(0,3)} /> 
        )
      }else{
        return (
            <Grid container justifyContent='space-between' spacing={3}>
                <Grid item xs={12} sm={3}>
                  <NewsSkeleton/>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <NewsSkeleton/>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <NewsSkeleton/>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <NewsSkeleton/>
                </Grid>
            </Grid>
        )
      }
    }


  return (
    <Grid container className={classes.root} justifyContent="center">
      <Grid item xs={12} >
        <img alt={props.title} src={props.image} width="100%"
          className={classes.img}
        />
      </Grid>
      <Grid item xs={10} sm={10} md={10} lg={8} xl={8}>

        <Grid container className={classes.title} >
          <Typography variant="h2">
            {props.title}
          </Typography>
        </Grid>


        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={12} className={classes.paragraph}>
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: props.body }} />
          </Grid>
        </Grid>
        {(actualiteRef.current.filter((e) => e.category === "L'insertion" && e.approval==true).length > 0 || message != undefined)
          ? <React.Fragment>
            <Grid
              container
              justifyContent="space-around"
            >
              <Typography variant="h3">
                {props.actuTitle}
              </Typography>
            </Grid>
            {handleSkeleton()}
          </React.Fragment>
          :
          <Grid style={{ height: "40px" }} />}
      </Grid>
    </Grid>
  );
};

export default InserctionComp;
