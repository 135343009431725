import { makeStyles } from '@material-ui/core/styles'

const Style = makeStyles((theme)=> ({

    root:{
        marginTop:80,
        color:'#1D2F3F',
        [theme.breakpoints.down("md")]:{
            padding:'3em 0em',
        },
        [theme.breakpoints.up("md")]:{
            padding:'6em 0em',
        }
        

    },
    body:{
            margin:'3em 0em 1em'
    },

    head:{
        '& h5':{
            margin:'1rem 0vw',
            
        },
        '& span':{
            color:'#3F74A4'
        },
    },
    link:{
        color:"#1EDAB2",
        margin: "3em 0vw",
        textDecoration: "none",
        "& svg": {
            fontSize: "1.3rem",
            alignSelf:"center",
            
        },
    }


}));

export default Style;