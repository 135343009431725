import React,{useState,useEffect} from 'react'
import {Button, IconButton,Grid, Collapse,TextField, Typography, Box} from '@material-ui/core'
import useStyles from '../style'
import CircularProgress from '@material-ui/core/CircularProgress';
import {getAgenda,getAgendaById,updateAgenda,addAgenda} from '../../../../../../store/actions/agenda-actions'
import  {useForm} from 'react-hook-form'
import { useSelector,useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';


const AgendaCreate=(props)=>{
    const classes = useStyles()
    const dispatch  = useDispatch()
    const {register,handleSubmit,reset,formState:{errors}} = useForm()
    const history  = useHistory()
    const [email, setemail] = useState('')
    const [isCircular, setisCircular] = useState(false)
    const  agendabyId= useSelector((state)=>state.agenda.agendabyId)
    const [open, setOpen] = React.useState(false);
    const {message} = useSelector((state)=>state.agenda.addedagenda)??''

    const msg = useSelector((state)=>state.msg.message)






    const onSubmit =(data,e)=>{
        e.preventDefault()
        if(data){
            if(props.location.data){
                setisCircular(true)
                dispatch(updateAgenda(
                    props.location.data,
                    data.titre,
                    data.date,
                    data.com,
                    data.jus,
                    data.desc,
                    email,
                )).then(()=>{
                    setisCircular(false)
                    setOpen(true)
                    dispatch(getAgenda())
                    history.push({
                    pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/agenda/detail/${data.titre}=${props.location.data}` ,
                    data:props.location.data
                    })
                    sessionStorage.setItem('rowId',props.location.data,)
                    reset()
                },()=>{
                    setOpen(true)
                    setisCircular(false)
                    //reset()
                })
            }else{
                setisCircular(true)
                dispatch(addAgenda(
                    data.titre,
                    data.date,
                    data.com,
                    data.jus,
                    data.desc,
                    email

                )).then(()=>{
                    setisCircular(false)
                    dispatch(getAgenda())
                    setOpen(true)
                    reset()
                },()=>{
                    setOpen(true)
                    setisCircular(false)

                })
            }
        }
    }
    const getUser =()=>{
        var email =  sessionStorage.getItem('user')
        setemail(email)
       
      }
    useEffect(() => {
        if(history.action ==='POP'){
            history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard`)
        }
        setOpen(false)
        getUser()
        if(props.location.data){
            dispatch(getAgendaById(`${parseInt(props.location.data)}`))
            reset(
                {
                    titre:agendabyId.title,
                    date:agendabyId.date,
                    com : agendabyId.start_time,
                    jus : agendabyId.end_time,
                    desc : agendabyId.description
                }
            )
        }
       
    }, [])

    return (
     
        <Grid container direction='column' className={classes.root}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container justifyContent='center' alignItems="center">
                    <Grid item><Typography variant='h3' style={{fontWeight:'bold'}} color='primary'>{props.location.data?"Actualiser les détails d'un agenda":'Ajouter  un agenda'}</Typography></Grid>
                </Grid>
            </Grid>
            <Box height={40}/>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Collapse in={open}>
                        <Alert
                        severity={msg?'error':'success'}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        >
                            {msg?msg:message}
                        </Alert>
                    </Collapse>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.backPaper}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justify='center' alignItems='center'>
                        <Grid item>
                        <Grid container justify='space-around' className={classes.spacebetween} spacing={4}>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>Titre</Typography>
                                <TextField inputProps={{maxLength:25}} helperText='Titre doit avoir 25 caractères' type='text' {...register('titre',{required:true,maxLength:25})} required variant='outlined'  fullWidth  className={classes.textField} placeholder='Entrez le titre'/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>Date prévue</Typography>
                                <TextField  type='date' {...register('date',{required:true})} required variant='outlined' placeholder='01/02/2021 '  fullWidth className={classes.textField}/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>Commence à</Typography>
                                <TextField  type='time' {...register('com',{required:true})} required variant='outlined' placeholder='12:00 '  fullWidth  className={classes.textField}/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>Jusqu'à</Typography>
                                <TextField  type='time' {...register('jus',{required:true})} required variant='outlined' placeholder='03:00'  fullWidth className={classes.textField}/>
                            </Grid>
                        
                        </Grid>
                        <Grid item className={classes.spacebetween} >
                                <Typography>Description</Typography>
                                <TextField inputProps={{maxLength:350}} helperText='La description doit avoir 350 caratères' type='text' {...register('desc',{required:true,maxLength:350})} required variant='outlined' placeholder='Entrez votre message' multiline rows={6} fullWidth className={classes.textField}/>
                            </Grid>
                        <Grid item className={classes.spacebetween} >
                            <Button type='submit' disabled={isCircular===true?true:false}  fullWidth className={classes.btn}> {isCircular===true?<CircularProgress size={30} style={{color:'white'}} />:<Typography>{props.location.data?'Sauvegarder':'Ajouter'}</Typography>}</Button>
                        </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Grid>

        </Grid>
       
    )
}

export default AgendaCreate