import {Button} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import useStyles from '../pages/style' 



const BtmRed = ({onClick,disabled})=>{
    const classes = useStyles()
    return(
        <Button disabled={disabled} onClick={onClick} className={classes.btnred} startIcon={<DeleteIcon style={{color:'white',fontSize:14}}/>}>
           SUPPRIMER
        </Button>
    )
}


const BtnOrange = ({btnYellowTitle,startIcon,onClick,disabled})=>{
    const classes = useStyles()
    return (
        <Button disabled={disabled} onClick={onClick} className={classes.btnyellow} startIcon={startIcon}>
          {btnYellowTitle}
        </Button>
    )
}

const SendEmail = ({emailtitle,startIcon,onClick,disabled})=>{
    const classes = useStyles()
    return (
        <Button disabled={disabled} onClick={onClick} className={classes.email} startIcon={startIcon}>
          {emailtitle}
        </Button>
    )
}

export {
    BtmRed,
    BtnOrange,
    SendEmail
}