import React,{useState}  from  'react'
import {Button, Grid,Typography,Box ,Collapse,IconButton} from '@material-ui/core'
import ReactCodeInput from 'react-code-input'
import CircularProgress from '@material-ui/core/CircularProgress';
import {useDispatch,useSelector} from 'react-redux'
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { restorePasswordActions,sendEmailCode,checkEmailCodeAction} from '../../../store/actions/auth-actions';
import axiosInstance from '../../../service/axiosPub';

const VerificationUser = (props)=>{
    const [codeInput, setcodeInput] = useState(0)
    const [user, setuser] = useState('')
    const [userText, setuserText] = useState("un code de vérification a été envoyé à votre adresse e-mail. Veuillez le saisir dans les cases ci-dessous.")
    const dispatch = useDispatch()
    const [ison, setison] = useState(false)
    const [opens, setOpens] = React.useState(false);
    const {message} = useSelector(state => state.msg)
    const {code} = useSelector(state => state.sendEmailCode.checkEmail)

    const [err, seterr] = useState(false)
   

  
  

    const takeCodeInput = (e)=>{
        const value = e
        if(value.length===6){
            setison(true)
            setOpens(false)
            dispatch(checkEmailCodeAction(value)).then(()=>{
                dispatch(restorePasswordActions(
                    parseInt(sessionStorage.getItem('id')),
                    sessionStorage.getItem('data'),
                    )).then(()=>{
                        setison(false)
                        blacklistToken()
                        sessionStorage.removeItem('email')
                        sessionStorage.removeItem('id')
                        sessionStorage.removeItem('token')
                        sessionStorage.removeItem('refresh')
                        sessionStorage.removeItem('data')
                       
                    },()=>{
                        setison(false)
                        setOpens(true)
                        seterr(true)
                    })
            },()=>{
                setison(false)
                setOpens(true)
                seterr(true)  
            })
        }
        return 
    }
    const sendCode = ()=>{ 
            setison(true)
            setOpens(false)
            dispatch(sendEmailCode(sessionStorage.getItem('email'))).then(()=>{
                setison(false)
                //setOpens(true)
            },()=>{
                setison(false)
                setOpens(true)
            })

    }

    const blacklistToken = async()=>{
        return  await axiosInstance.post('api/account/register/blacklist/',{
              refresh:sessionStorage.getItem('refresh')
          })
      }
    
    

   
    return (
        <Grid container direction='column' justifyContent='center' spacing={3}>
         <Grid item>
            <Box height={20}/>
                <Collapse in={opens}>
                    <Alert
                      severity={'error'}
                      action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpens(false);    
                             }}>
                        <CloseIcon fontSize="inherit" />
                        </IconButton>}>
                    { message}
                    </Alert>
                </Collapse>
        </Grid>
        <Grid item >
                <Typography align='center'>
                Bonjour Mr/Mdme {user}, {userText}
            </Typography>
        </Grid>
       
        
       <Grid item>

          <Grid container justifyContent='center'>
          {
           codeInput.length===6 && err===false ?(
               <Grid item>
                   <CircularProgress size={40}/>
               </Grid>
           ):
           (<Grid item>
            <ReactCodeInput  name='code' type='text' fields={6}  onChange={takeCodeInput}/>
      </Grid>)
       }

          </Grid>
      </Grid>
    
       <Grid item >
            <Grid  container justifyContent='center'>
               {
                   ison===false && err==true?
                   <Grid item> <Button  color='primary' variant={ison===true?'text':'outlined'} onClick={sendCode}>{ison===true?'loading .....':('renvoyer le code')}</Button></Grid>:null
                    
               }
            </Grid>
        </Grid>
    </Grid>
    )
}

export default VerificationUser