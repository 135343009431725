import React from  'react'
import {Button,Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions} from '@material-ui/core'



const DialogBox =({fullScreen,open,onClose,title,content,nonOnclick,confirnClick,Oui})=>{
    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title" >
            <DialogTitle id="responsive-dialog-title">Etês-vous sûr?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                   {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={nonOnclick} style={{color:'#009688',fontWeight:300}} size='small'>
                    Non
                </Button>
                <Button 
                onClick={confirnClick}
                    autoFocus
                    style={{backgroundColor:'#D12028',color:'white',fontWeight:300}} size='small'>
                    {Oui}
                </Button>
            </DialogActions>
         </Dialog>
    )
}

export default DialogBox
