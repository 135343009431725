export const EMAIL_SEND_SUCCESS = "EMAIL_SEND_SUCCESS";
export const EMAIL_SEND_FAIL = "EMAIL_SEND_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const FETCH_USERS_BY_ID_SUCCES = 'FETCH_USERS_BY_ID_SUCCES';
export const FETCH_USERS_BY_ID_FAIL = 'FETCH_USERS_BY_ID_FAIL';
export const DELETE_USER_BY_ID_SUCCES = 'DELETE_USER_BY_ID_SUCCES';
export const DELETE_USER_BY_ID_FAIL = 'DELETE_USER_BY_ID_FAIL';
export const UPDATE_USER_SUCCES = 'UPDATE_USER_SUCCES';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const CHANGE_PASSWORD_USER_SUCCES = 'CHANGE_PASSWORD_USER_SUCCES';
export const CHANGE_PASSWORD_USER_FAIL = 'CHANGE_PASSWORD_USER_FAIL';
export const ACTIVATED_USER_SUCCES = 'ACTIVATED_USER_SUCCES';
export const ACTIVATED_USER_FAIL = 'ACTIVATED_USER_FAIL';

export const FETCH_PARTENAIRE_SUCCES = 'FETCH_PARTENAIRE_SUCCES';
export const FETCH_PARTENAIRE_FAIL = 'FETCH_PARTENAIRE_FAIL';
export const DELETE_PARTENAIRE_SUCCES = 'DELETE_PARTENAIRE_SUCCES';
export const DELETE_PARTENAIRE_FAIL = 'DELETE_PARTENAIRE_FAIL';
export const ADD_PARTENAIRE_SUCCES = 'ADD_PARTENAIRE_SUCCES';
export const ADD_PARTENAIRE_FAIL = 'ADD_PARTENAIRE_FAIL';
export const FETCH_PARTENAIRE_BY_ID_SUCCES = 'FETCH_PARTENAIRE_BY_ID_SUCCES';
export const FETCH_PARTENAIRE_BY_ID_FAIL = 'FETCH_PARTENAIRE_BY_ID_FAIL';
export const UPDATE_PARTENAIRE_BY_ID_SUCCES = 'UPDATE_PARTENAIRE_BY_ID_SUCCES';
export const UPDATE_PARTENAIRE_BY_ID_FAIL = 'UPDATE_PARTENAIRE_BY_ID_FAIL';



export const FETCH_MEMBRE_SUCCES = 'FETCH_MEMBRES_SUCCES';
export const FETCH_MEMBRE_FAIL = 'FETCH_MEMBRE_FAIL';
export const DELETE_MEMBRE_SUCCES = 'DELETE_MEMBRE_SUCCES';
export const DELETE_MEMBRE_FAIL = 'DELETE_MEMBRE_FAIL';
export const ADD_MEMBRE_SUCCES = 'ADD_MEMBRE_SUCCES';
export const ADD_MEMBRE_FAIL = 'ADD_MEMBRE_FAIL';
export const UPDATE_MEMBRE_SUCCES = 'UPDATE_MEMBRE_SUCCES';
export const UPDATE_MEMBRE_FAIL = 'UPDATE_MEMBRE_FAIL';
export const FETCH_MEMBRE_BY_ID_SUCCES = 'FETCH_MEMBRE_BY_ID_SUCCES';
export const FETCH_MEMBRE_BY_ID_FAIL = 'FETCH_MEMBRE_BY_ID_FAIL';


export const FETCH_AGENDA_SUCCES = 'FETCH_AGENDAS_SUCCES';
export const FETCH_AGENDA_FAIL = 'FETCH_AGENDA_FAIL';
export const DELETE_AGENDA_SUCCES = 'DELETE_AGENDA_SUCCES';
export const DELETE_AGENDA_FAIL = 'DELETE_AGENDA_FAIL';
export const ADD_AGENDA_SUCCES = 'ADD_AGENDA_SUCCES';
export const ADD_AGENDA_FAIL = 'ADD_AGENDA_FAIL';
export const UPDATE_AGENDA_SUCCES = 'UPDATE_AGENDA_SUCCES';
export const UPDATE_AGENDA_FAIL = 'UPDATE_AGENDA_FAIL';
export const FETCH_AGENDA_BY_ID_SUCCES = 'FETCH_AGENDA_BY_ID_SUCCES';
export const FETCH_AGENDA_BY_ID_FAIL = 'FETCH_AGENDA_BY_ID_FAIL';

export const FETCH_PROJET_SUCCES = 'FETCH_PROJETS_SUCCES';
export const FETCH_PROJET_FAIL = 'FETCH_PROJET_FAIL';
export const DELETE_PROJET_SUCCES = 'DELETE_PROJET_SUCCES';
export const DELETE_PROJET_FAIL = 'DELETE_PROJET_FAIL';
export const ADD_PROJET_SUCCES = 'ADD_PROJET_SUCCES';
export const ADD_PROJET_FAIL = 'ADD_PROJET_FAIL';
export const UPDATE_PROJET_SUCCES = 'UPDATE_PROJET_SUCCES';
export const UPDATE_PROJET_FAIL = 'UPDATE_PROJET_FAIL';
export const FETCH_PROJET_BY_ID_SUCCES = 'FETCH_PROJET_BY_ID_SUCCES';
export const FETCH_PROJET_BY_ID_FAIL = 'FETCH_PROJET_BY_ID_FAIL';



export const FETCH_CLEC_SUCCES = 'FETCH_CLECS_SUCCES';
export const FETCH_CLEC_FAIL = 'FETCH_CLEC_FAIL';
export const DELETE_CLEC_SUCCES = 'DELETE_CLEC_SUCCES';
export const DELETE_CLEC_FAIL = 'DELETE_CLEC_FAIL';
export const ADD_CLEC_SUCCES = 'ADD_CLEC_SUCCES';
export const ADD_CLEC_FAIL = 'ADD_CLEC_FAIL';
export const UPDATE_CLEC_SUCCES = 'UPDATE_CLEC_SUCCES';
export const UPDATE_CLEC_FAIL = 'UPDATE_CLEC_FAIL';
export const FETCH_CLEC_BY_ID_SUCCES = 'FETCH_CLEC_BY_ID_SUCCES';
export const FETCH_CLEC_BY_ID_FAIL = 'FETCH_CLEC_BY_ID_FAIL';



export const FETCH_CCC_SUCCES = 'FETCH_CCCS_SUCCES';
export const FETCH_CCC_FAIL = 'FETCH_CCC_FAIL';
export const DELETE_CCC_SUCCES = 'DELETE_CCC_SUCCES';
export const DELETE_CCC_FAIL = 'DELETE_CCC_FAIL';
export const ADD_CCC_SUCCES = 'ADD_CCC_SUCCES';
export const ADD_CCC_FAIL = 'ADD_CCC_FAIL';
export const UPDATE_CCC_SUCCES = 'UPDATE_CCC_SUCCES';
export const UPDATE_CCC_FAIL = 'UPDATE_CCC_FAIL';
export const FETCH_CCC_BY_ID_SUCCES = 'FETCH_CCC_BY_ID_SUCCES';
export const FETCH_CCC_BY_ID_FAIL = 'FETCH_CCC_BY_ID_FAIL';



export const FETCH_ACTUALITE_SUCCES = 'FETCH_ACTUALITES_SUCCES';
export const FETCH_ACTUALITE_FAIL = 'FETCH_ACTUALITE_FAIL';
export const DELETE_ACTUALITE_SUCCES = 'DELETE_ACTUALITE_SUCCES';
export const DELETE_ACTUALITE_FAIL = 'DELETE_ACTUALITE_FAIL';
export const ADD_ACTUALITE_SUCCES = 'ADD_ACTUALITE_SUCCES';
export const ADD_ACTUALITE_FAIL = 'ADD_ACTUALITE_FAIL';
export const UPDATE_ACTUALITE_SUCCES = 'UPDATE_ACTUALITE_SUCCES';
export const UPDATE_ACTUALITE_FAIL = 'UPDATE_ACTUALITE_FAIL';
export const FETCH_ACTUALITE_BY_ID_SUCCES = 'FETCH_ACTUALITE_BY_ID_SUCCES';
export const FETCH_ACTUALITE_BY_ID_FAIL = 'FETCH_ACTUALITE_BY_ID_FAIL';


export const ADD_USER_SUCCES = 'ADD_USER_SUCCES';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';

export const ADD_PROFILE_SUCCES = 'ADD_PROFILE_SUCCES';
export const ADD_PROFILE_FAIL = 'ADD_USER_FAIL';
export const FETCH_PROFILE_SUCCES = 'FETCH_PROFILE_SUCCES';
export const FETCH_PROFILE_FAIL = 'FETCH_USER_FAIL';

export const ADD_SEND_CODE_SUCCES = 'ADD_SEND_CODE_SUCCES';
export const ADD_SEND_CODE_FAIL = 'ADD_SEND_CODE_FAIL';

export const CHECK_VERIFICATION_CODE_SUCCES = 'CHECK_VERIFICATION_CODE_SUCCES';
export const CHECK_VERIFICATION_CODE_FAIL = 'CHECK_VERIFICATION_CODE_FAIL';


export const SEND_CONTACT_SUCCESS = 'SEND_CONTACT_SUCCESS';
export const SEND_CONTACT_FAIL = 'SEND_CONTACT_FAIL';

export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';
export const CHECK_EMAIL_FAIL = 'CHECK_EMAIL_FAIL';

export const SEND_EMAIL_CODE_SUCCESS = 'SEND_EMAIL_CODE_SUCCESS';
export const SEND_EMAIL_CODE_FAIL = 'SEND_EMAIL_CODE_FAIL';

export const RESTORE_PASSWORD_SUCCESS = 'RESTORE_PASSWORD_SUCCESS';
export const RESTORE_PASSWORD_FAIL = 'RESTORE_PASSWORD_FAIL';

export const CHECK_EMAIL_CODE_SUCCESS = 'CHECK_EMAIL_CODE_SUCCESS';
export const CHECK_EMAIL_CODE_FAIL = 'CHECK_EMAIL_CODE_FAIL';



export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAIL = 'USER_PROFILE_FAIL';
export const CLEAR_DATA = 'CLEAR_DATA';


export const FETCH_ESPACE_SUCCES = 'FETCH_ESPACES_SUCCES';
export const FETCH_ESPACE_FAIL = 'FETCH_ESPACE_FAIL';
export const DELETE_ESPACE_SUCCES = 'DELETE_ESPACE_SUCCES';
export const DELETE_ESPACE_FAIL = 'DELETE_ESPACE_FAIL';
export const ADD_ESPACE_SUCCES = 'ADD_ESPACE_SUCCES';
export const ADD_ESPACE_FAIL = 'ADD_ESPACE_FAIL';
export const UPDATE_ESPACE_SUCCES = 'UPDATE_ESPACE_SUCCES';
export const UPDATE_ESPACE_FAIL = 'UPDATE_ESPACE_FAIL';
export const UPDATE_ESPACE_DOCUMENT_SUCCES = 'UPDATE_ESPACE_WITHOUT_DOCUMENT_SUCCES';
export const UPDATE_ESPACE_WITHOUT_DOCUMENT_FAIL = 'UPDATE_ESPACE_WITHOUT_DOCUMENT_FAIL';
export const FETCH_ESPACE_BY_ID_SUCCES = 'FETCH_ESPACE_BY_ID_SUCCES';
export const FETCH_ESPACE_BY_ID_FAIL = 'FETCH_ESPACE_BY_ID_FAIL';

export const UPDATE_APPROVAL_ACTUALITE_SUCCESS = 'UPDATE_APPROVAL_ACTUALITE_SUCCESS';
export const UPDATE_APPROVAL_ACTUALITE_FAIL = 'UPDATE_APPROVAL_ACTUALITE_FAIL';

