import {makeStyles} from '@material-ui/core/styles'


const style  = makeStyles((theme)=>({

    indicatedColor:{
        backgroundColor:'#00A1AF',
    },
    backPaperTitle:{
        fontSize:24,
        fontWeight:700,
        [theme.breakpoints.down('xs')]:{
            fontSize:15,
             fontWeight:700,
            }

     },
     root:{
        marginTop:80,
       paddingLeft:30,
       paddingRight:10,
       marginRight:90,
        
       [theme.breakpoints.down('xs')]:{
        paddingLeft:0,
       paddingRight:0,
       } 
    },

    backPaper:{
        backgroundColor:'#F6FBFF',
        marginTop:80,
        marginRight:90,
        marginLeft:10,
        paddingLeft:30,
        paddingRight:10,
        [theme.breakpoints.down('xs')]:{
         marginRight:5,
         marginLeft:5,
         paddingLeft:10,
        }   
    },
    spacebetween:{
        paddingLeft:150,
        paddingRight:150,

        marginTop:30,
        marginBottom:30,
        [theme.breakpoints.down('xs')]:{
            paddingLeft:10,
            paddingRight:10,
           }  
    },
    btn:{
        backgroundColor:'#FFAE02',
        height:50,

        '&:hover':{
            backgroundColor:'#FFAE02',
        },
        [theme.breakpoints.down('xs')]:{
            
            fontSize:8
            
        }
    },

    spacingBtw:{

        paddingRight:150,
        paddingLeft:150,
        [theme.breakpoints.down('xs')]:{
            
            paddingRight:10,
            paddingLeft:10,
        },
    
    
    },
    phone: {

        "&:focus": {
            borderColor: "#FFAE02 !important",
            boxShadow:"0 0 0 1px #FFAE02 !important"
        },
        "&:active": {
            borderColor: "#FFAE02 !important",
            boxShadow:"0 0 0 1px #FFAE02 !important"
        }
        
    }

     
}))

export default style