import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import More from "../../../widgets/moreLink";
import Swiss from "../../../assets/images/accueil/swissLogo.png";
import Suisse from "../../../assets/images/accueil/suisseLogo.png";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "3vw 0vw",
    
    "& h4": {
      fontWeight: theme.typography.fontWeightBold,
    },
  },

  container: {
    background: "#1d2f3f09",
    justifyContent: "space-evenly",
    padding: "5em 3em",
  },

  partenaire: {
    padding: "5em 0vw",
  },
  paragraph: {
    marginTop: "1em",
      textAlign:theme.typography.textBodyAlign
  },
}));

const Section = () => {
  const classes = useStyle();

  return (
    <Grid container className={classes.root} justifyContent="center">
      <Grid item className={classes.container} xs={10} xl={8}>
        <Grid container justifyContent="space-between">
          <Grid item xs={11} sm={10} md={3}>
            <Grid container direction="column" justifyContent="center">
              <Typography variant="h4">Partenaires</Typography>

              <Typography variant="body1" className={classes.paragraph}>
                La Direction suisse du développement et de la coopération (DDC)
                finance le projet PROMOST <br />
                <br />
                La fondation suisse pour la coopération technique implémente
                depuis 2016 le projet PROMOST
              </Typography>

              <More title="Voir tout" to="/Paternaires" />
            </Grid>
          </Grid>

          <Grid item xs={11} sm={4} md={3} lg={3}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={classes.partenaire}
            >
              <img alt="Parternaire" src={Swiss} width="80%" />
            </Grid>
          </Grid>

          <Grid item xs={11} sm={4} md={3} lg={3}>
            <Grid container alignItems="center" className={classes.partenaire}>
              <img alt="Parternaire" src={Suisse} width="100%" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Section;
