import React,{useState,useEffect} from  'react'
import { CircularProgress,Grid, Typography, Box ,Collapse,IconButton, Button} from '@material-ui/core'
import Backpaper from '../../../../sc/backPaper';
import EditIcon from '@material-ui/icons/Edit';
import {BtnOrange,BtmRed} from '../../../../sc/utilities'
import { useDispatch,useSelector } from 'react-redux';
import { getUserById,getUsers,deleteUser } from '../../../../../../store/actions/user-actions';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogBox from '../../../../sc/dialog';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import useStyle from '../style'
import SettingsIcon from '@material-ui/icons/Settings';





const UtilisateurDetail =()=>{
    const history = useHistory()
    const classes  =useStyle()

    const [opens, setOpens] = React.useState(false);
    const dispatch = useDispatch()
    const  userById = useSelector((state)=>state.profilebyId)
    const {userbyId}= userById
    const msg = useSelector((state)=>state.msg.message)
    const [currentUser, setcurrentUser] = useState('')

     const handleModifier = ()=>{
        history.push({
                pathname: `/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/utilisateur/create`,
                data: sessionStorage.getItem('rowId')
        })
    }
    
   const handlePameretre =(id)=>{
           history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/utilisateur/parametre/${id}`)
           sessionStorage.setItem('rowIds',id)
   }
   const  getUserRole =()=>{
        const role  = sessionStorage.getItem('role')
        setcurrentUser(role)
    }
    useEffect(() => {
       dispatch(getUserById(`${parseInt(sessionStorage.getItem('rowId'))}`)).then(()=>{

       },()=>{
               setOpens(true)
       })
       getUserRole()
    }, [])
    return (
       <Backpaper
           yellowbtn = {currentUser<=2?<BtnOrange onClick={handleModifier} disabled={userbyId.length===0?true:false} btnYellowTitle="MODIFIER" startIcon={<EditIcon style={{fontSize:14}}/>} />:null}
           content={
            <Grid container justifyContent='center' style={{paddingBottom:50}}>
                <Grid item xs={12}>
                        <Box height={20}/>
                        <Collapse in={opens}>
                        <Alert
                        severity={'error'}
                        action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                setOpens(false);
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                        }
                        >
                        { msg}
                        </Alert>
                        </Collapse>
                        <Box height={20}/>
                </Grid>
                <Grid item>
                     <Grid container justifyContent='space-between' spacing={4} className={classes.spacingBtw}>
                                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography ></Typography>Nom</Grid>
                                                {userbyId.length!==0?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{userbyId.first_name}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }
                                         </Grid>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography ></Typography>Prénom</Grid>
                                             {userbyId.length!==0?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{userbyId.last_name}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography >Organisation</Typography></Grid>
                                             {userbyId.length!==0?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{userbyId.organisation}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography >Fonction</Typography></Grid>
                                             {userbyId.length!==0?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{userbyId.fonction}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography >Adresse</Typography></Grid>
                                             {userbyId.length!==0?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{userbyId.address}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography >Numéro de téléphone</Typography></Grid>
                                             {userbyId.length!==0?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{userbyId.phonenumber}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography > Ville & Territoire </Typography></Grid>
                                             {userbyId.length!==0?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{userbyId.ville_territory}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography >Province</Typography></Grid>
                                             {userbyId.length!==0?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{userbyId.province}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }

                                         </Grid>
                                 </Grid>
                     </Grid>
                     <br/>
                     <br/>
                    {currentUser<=3 ?<Grid item >
                             <Grid container className={classes.spacingBtws} >
                                <Grid item >
                                {userbyId.length!==0?
                                <Button onClick={()=>handlePameretre(userbyId.email)} size='small' fullWidth startIcon={<SettingsIcon/>} ><Typography style={{fontSize:10}}>Paramètres du compte</Typography></Button>   :         
                                <Skeleton  variant="rect" height={10}  width={100}/>}  
                                 </Grid>
                             </Grid>
                     </Grid>:null}
                </Grid>
            </Grid>
                 
             } 
            
       />
    );
}

export default UtilisateurDetail