import React,{useEffect} from "react";
import { Grid, Typography } from "@material-ui/core";
import Style from "./style";
import { Link } from "react-router-dom"
import SEO from '../../sc/seo'


const Mention_legale = (props) => {
  const classes = Style();

  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])
 
  return (
    <React.Fragment>
                <SEO
                    title="Mentions legales"
                    keywords="Mentions legales"
                />
    <Grid container justifyContent = "center">
      <Grid item xs = {10} sm={10} md ={10} lg={8} xl={8}>
        <Grid container className={classes.root}>
          <Typography variant="h5">
            Mentions légales
          </Typography>
          <Typography variant="body1">
            
          <dt>CONDITIONS D’UTILISATION</dt><br/><br/>

          Le Cadre d’Echange et de Concertation des acteurs clés du système de la Formation Professionnelle et l’Insertion au Sud-Kivu, CEC-FPI/SK en sigle, est une grande synergie regroupant les acteurs clés qui interagissent dans le développement du système de la formation professionnelle et de l’insertion régit par l’arrêté provincial n°19/054/GP/SK du 11/11/2019 dont les acteurs clés sont repartis en quatre pôles à savoir : l’Offre, la Demande, l’Insertion et la Régulation.
          Le CEC–FPI vous prie de bien vouloir lire, avec attention, les différentes conditions d'utilisation ainsi que la politique de confidentialité de ce site avant de naviguer sur ses pages.
          <br/><br/>
          <dt>1.	Accord de l’utilisateur</dt>

          <br/>
          Le présent site web est une plateforme dont l'objectif global est de sensibiliser le grand public au travail du CEC-FPI et de permettre aux décideurs et aux autres personnes intervenant dans l’ETFP de s’impliquer pour le relèvement et le développement de ce secteur en province du Sud-Kivu en particulier et en RDC en général. Le Comité Collégial de Coordination du CEC-FPI met en œuvre tous les moyens dont il dispose, pour assurer une information fiable et les mises à jour nécessaires de ce site web ainsi que de la totalité de son contenu.
          En visitant ce site web, l'utilisateur reconnait avoir lu et accepté préalablement l’intégralité de ces présentes conditions d'utilisation ainsi que la politique de confidentialité. La navigation sur le site est conditionnée par cet accord qui est considéré tacite et prouvé du seul fait de la consultation continue du site.
          L'accès continu est acté pour tout utilisateur qui ouvre trois pages ou plus de ce site. Alors que la première page donne accès à la page d'accueil et que la deuxième page donnerait accès aux mentions légales du site, l’utilisateur qui ouvre une troisième page sera réputé avoir accepté les présentes conditions d'utilisation et notre politique de confidentialité dans leur intégralité et sans réserve.
          Il est demandé à tout utilisateur en contrariété avec ces conditions d’utilisation de ne pas utiliser le présent site et de s’en déconnecter aussi subitement qu’il découvre son désaccord. 
          Dans la mesure où il peut s’avérer nécessaire, le CEC-FPI se réserve le droit de modifier ces conditions d'utilisation à tout moment, sans préavis ni notification. De ce fait, nous vous demandons d’avoir l’obligeance de vérifier périodiquement s’il n’y a pas eu une modification récente des conditions d’utilisation. La référence à la dernière version qui est en application est obligatoire pour chaque utilisateur qui visite le site. 
          Les présentes conditions d’utilisation ont un champ d’application limité uniquement à ce site internet.
          <br/><br/>
          <dt>2.	Limitation de responsabilité</dt>
          <br/>
          Le site web <a href ="https://cec-fpi.org" target = "_blank" rel ="noreferrer">cec-fpi.org</a> vous est proposé en l'état, sans garantie d'aucune sorte, expresse ou tacite. L’utilisateur est seul responsable de son utilisation de ce site.
          Le CEC–FPI exclut toute garantie, expresse ou tacite, de quelque nature que ce soit, en rapport avec les services proposés par le site web ou par n’importe quel potentiel intermédiaire. 
          Vous convenez de libérer le CEC–FPI, ses dirigeants, employés et partenaires, ainsi que les administrateurs de ce site, de toute revendication et responsabilité découlant de votre accès à ce site web, de votre utilisation ou de votre impossibilité d'y avoir accès ou de l'utiliser.
          Vous convenez par ailleurs que le CEC-FPI ou les contributeurs aux informations disponibles sur le site ne sauraient être tenus responsables de tout dommage ou préjudice causé ou prétendument causé par la fiabilité que vous attribuez aux informations fournies sur les différentes pages du site.
          Seulement au cas où la loi applicable n’autoriserait pas la limitation ou l’exclusion de responsabilité ou des dommages, la limitation ou l’exclusion pourrait ne pas s’appliquer à vous. Cependant, notre responsabilité sera limitée aussi pleinement que la loi applicable le permet.
          <br/><br/>
          <dt>3.	Utilisation et propriété du contenu</dt>
          <br/>
            Toutes les informations qui apparaissent sur ce site web et que celui-ci contient, telles que les graphiques, textes, logos, audios, vidéos, photos, données, sont la propriété exclusive du CEC–FPI, ou de ses partenaires le cas échéant, et ne peuvent être copiées, reproduites, réimprimées, transmis, distribués, modifiés, exécutés, affichés ou utilisés de quelque manière que ce soit, en partie ou en totalité, sans l'accord explicite et préalable du CEC–FPI, ou de ses partenaires le cas échéant, sauf si l'utilisateur fait une seule copie du contenu et que cela soit exclusivement à des fins personnelles et non commerciales qui ne porte pas préjudice au CEC–FPI, ou à ses partenaires le cas échéant, et à condition encore que l'utilisateur mentionne explicitement le propriétaire du contenu utilisé tout en respectant les droits d'auteur qui sont rattachés au contenu qu'il compte utilisé.
          <br /><br />Dans l'hypothèse où un utilisateur souhaiterait utiliser des informations tirées du site à des fins commerciales, non seulement l'accord du CEC–FPI, ou de ses partenaires le cas échéant, est requis, mais cet accord doit être matérialisé par un contrat qui déterminera les droits pécuniaires du CEC–FPI, ou de ses partenaires le cas échéant, sur le profit ou l’économie qui pourrait résulter de l'utilisation commerciale de ces informations tirées du site.
  
  
          <br/><br/>
          <dt>4.	Différentes informations relatives au site web</dt>
          <br/>
          Editeur du Site : Commuté Collégial de Coordination du CEC-FPI, CCC en sigle <br/>
          Responsable éditorial : Vice-Président du CEC-FPI <br/>
          Adresse : Bukavu, Avenu MAHENGE, N°5, Commune d’IBANDA, Sud-Kivu/RDC<br/>
          Téléphone : +243 99 53 01 783 <br/>
          Email : <a href = "mailto:cecfpi.sudkivu@gmail.com">cecfpi.sudkivu@gmail.com</a><br/>
          Site Web : <a href="https://cec-fpi.org" target ="_blank" rel="noreferrer">cec-fpi.org</a>
          <br/><br/>
          Développement : Oxide Digital Technology <br/>
          Adresse : 21 Avenue Kabare, Bukavu, Congo-Kinshasa <br/>
          Email : <a href = "mailto:info@oxide-digital.com">info@oxide-digital.com </a><br/>
          Numéro : +243973260160<br/>
          Site Web: <a href="https://oxide-digital.com" target ="_blank" rel="noreferrer">https://oxide-digital.com</a><br/>
          <br/><br/>
          Hébergeur : Oxide Digital Technology <br/>
          Adresse : 21 Avenue Kabare, Bukavu, Congo-Kinshasa <br/>
          Email : <a href = "mailto:info@oxide-digital.com ">info@oxide-digital.com </a><br/>
          Numéro : +243973260160<br/>
          Site web de l’hébergeur : <a href="https://oxide-digital.com" target ="_blank" rel="noreferrer">https://oxide-digital.com</a><br/>
          <br/><br/>
          <dt>5.	Autres termes</dt>
          <br/>
          Les présentes conditions d’utilisation ne créent pas de relation d’emploi, d’agence, de partenariat ou de quelque autre nature entre le CEC–FPI et l’utilisateur. Si vous n’avez pas signé de contrat distinct avec le CEC–FPI, les présentes conditions d’utilisation constituent l’intégralité du contrat liant le CEC–FPI et vous.
          L’utilisateur convient qu’il ne s’adonnera à aucun usage perturbant du présent site et de tout son équipement. Il s’engage à accéder au site en utilisant un matériel ne pouvant aucunement mettre en péril le bon fonctionnement et l’accès à ce site à cause des virus ou autres logiciels malveillants de caractère à menacer la pérennité ou le facile accès au site.
          Nous espérons qu’aucun désagrément sérieux ne se produira dans votre utilisation du site. En cas de survenance d’un désagrément sérieux, nous vous encourageons à nous contacter afin de réfléchir conjointement et pacifiquement à la résolution du désagrément.
          Nous vous recommandons par ailleurs de vous imprégner attentivement de notre politique de confidentialité, afin que vous soyez conscient de la façon par laquelle nous collectons, traitons et protégeons diverses données.
          <br/><br/>
          Nous vous remercions d’avoir pris le temps de lire les présentes conditions d’utilisation et sommes très heureux de votre consentement qui se prouve par votre utilisation continue du site. Nous espérons que les différentes informations contenues sur le site vous seront d’une grande utilité.
           <br/><br/><br/>
          <dt>POLITIQUE DE CONFIDENTIALITE</dt>
          <br/><br/>
          <dt>1.	Introduction</dt>
          <br/>

          Nous vous prions de lire attentivement et dans son intégralité cette politique de confidentialité. 
          Elle a pour but d'informer l'utilisateur de la manière dont le site web collecte, traite et protège diverses données. C'est le cas notamment des données personnelles, qui peuvent être collectées auprès de l'utilisateur, avec son consentement et parfois sans, lorsqu'il visite ce site.
          Nous ne vendons ni ne louons vos informations personnelles, nous ne les communiquons pas par ailleurs à des tiers dans le but de vous vendre ou proposer quoi que ce soit. Nous les utilisons pour déterminer comment rendre ce site plus attractif et accessible, pour adapter les informations disponibles sur le site par rapport aux utilisateurs et pour comprendre quels contenus et quelles données disponibles sur le site sont satisfaisants : tout cela dans le but de fournir un rendement meilleur et efficace du site aux utilisateurs.
          Cette politique de confidentialité est aussi valable pour les informations que nous recueillons auprès de nos partenaires ou de tiers.
          Tout utilisateur qui poursuit sa navigation sur ce site après avoir lu la présente politique de confidentialité est réputé avoir accepté clairement et sans équivoque le traitement de ses données tel qu'il est expliqué. Si, toutefois, l'utilisateur n'est pas d'accord avec cette politique de confidentialité, il est invité à quitter aussitôt ce site.
          Nous reconnaissons que seule une minorité d'utilisateur est familiarisée aux termes techniques pouvant être utilisés dans cette politique de confidentialité. Nous utiliserons donc un langage aussi peu technique que possible et expliquerons certains termes clés.
          En conclusion de cette introduction, nous informons les utilisateurs que leurs activités sur ce site sont exclusivement personnelles et non publiques comme ça peut l’être sur les réseaux sociaux.
          <br/><br/>
          <dt>2.	Les cookies</dt>
          <br/>

          Ce site recueille certains types d'information à l'aide de diverses pratiques, méthodes ou techniques couramment utilisées. Celles-ci comprennent généralement une variété de technologies telles que les cookies.
          Les cookies sont des fragments de données stockés par un site web sur l'appareil avec lequel l'utilisateur y accède. Comme de nombreux sites web, nous utilisons des cookies et des technologies similaires pour collecter des données sur l'utilisation, l'audience, la fréquentation et autres paramètres du site. Par conséquent, nous collecterons diverses statistiques et informations sur l'utilisation du site du simple fait de la visite du site par les utilisateurs. 

          Au moyen de ces technologies, nous recueillons des informations sur la localisation approximative de l'utilisateur, le navigateur avec lequel il visite le site, les pages du site qu'il consulte, etc. Toutes ces informations sont collectées de manière à ne permettre aucune identification personnelle et directe de l'utilisateur.
          <br/><br/>
          <dt>3.	Quand est-ce qu’on peut partager les données recueillies des utilisateurs ?</dt>
          <br/>

          Nous sommes obstinément déterminés à protéger les informations personnelles
          des utilisateurs. En vertu de cette politique, nous ne pouvons partager vos Informations que dans des circonstances particulières.

          De temps à autre, afin d'améliorer ou d'étendre les services offerts par ce site, le CEC-FPI pourra faire appel à des développeurs qui auront accès à des données du site, y compris les données recueillies auprès des utilisateurs, aux fins décrites ci-dessus.
          Dans cette hypothèse, le CEC-FPI mettra en place des exigences, telles que des protections contractuelles nécessaires et raisonnables, pour s'assurer que ces prestataires de services traitent vos informations personnelles conformément aux principes de la présente politique de confidentialité et à nos instructions. 
          Toutefois, si ces derniers ne respectent pas leurs obligations, le CEC-FPI ne pourra en être tenue responsable.
          <br/><br/>
          <dt>4.	Protection de vos données</dt>
          <br/>

          Nous nous employons à préserver vos informations personnelles contre tout accès, utilisation ou divulgation non autorisés. Hélas, il n'existe pas de système de transmission ou de stockage des données totalement sûr. Nous ne saurions donc garantir que notre sécurité ne sera pas violée.
          <br/><br/>
          <dt>5.	Les liens extérieurs à ce site</dt>
          <br/>

          Il est possible de trouver des liens qui sont extérieurs ou tiers à notre site mais disponibles sur celui-ci. Si l'utilisateur visite un tel lien, la présente politique de confidentialité ne s'applique plus à lui, il est dès lors soumis à la politique de confidentialité du site qu'il visite.
          Les utilisateurs, visiteurs ou développeurs de ce site web ou d’autres sites web ne peuvent mettre en place un lien en direction de ce site sans l'autorisation expresse et préalable du CEC-FPI au travers son organe compétent.

          <br/><br/>
          <dt>6.	Les enfants et le service proposé par le présent site</dt>
          <br/>

          Ce site n'est pas destiné à l'usage des enfants de moins de 15 ans et ne doit pas être utilisé par eux.
          En revanche, l’usage de ce site est considéré sain pour tous les enfants âgés de 15 ans révolus et ce sur base de l'article 133 de la loi n° 015/2002 portant code du travail et des objectifs poursuivis par le CEC-FPI, notamment au travers de ce site web.
          <br/><br/>
          <dt>7.	Modification de cette politique de confidentialité</dt>
          <br/>

          Des modifications peuvent être apportées à cette politique de confidentialité de temps à autre. La version la plus récente de la politique régira le traitement de vos données personnelles.
          En continuant à utiliser le site après l'entrée en vigueur de ces modifications, vous acceptez d'être lié par la politique de confidentialité.
          <br/><br/>
          <dt>8.	Comment nous contacter</dt>
          <br/>

          Adresse : Bukavu, Avenu MAHENGE, N°5, Commune d’IBANDA, Sud-Kivu/RDC <br/>
          Téléphone : +243 99 53 01 783<br/>
          Email : <a href = "mailto:cecfpi.sudkivu@gmail.com">cecfpi.sudkivu@gmail.com</a><br/>
          Site Web : <a href="https://cec-fpi.org" target ="_blank" rel="noreferrer">cec-fpi.org</a><br/>
          <br/>
          Il demeure aussi possible pour l’utilisateur de nous contacter au travers la page intitulée « <Link to ="/Contactez_nous">Contactez-nous</Link> » disponible sur le site web.
          <br/><br/><br/>


          Bonne continuation sur notre site web et portez-vous bien.

            
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </React.Fragment>
  );
    };
    
    export default Mention_legale;
