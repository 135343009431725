import React,{useEffect} from 'react';

import CccTemp from '../../widgets/cccComp'
import CCC from './ccc.json'
import SEO from '../../sc/seo'



const Ccc =()=>{




    const membersCCC = {
        columns: [
            {
                field:"nom",
                headerName: "NOM",
                width:400
            },
            {
                field:"instution",
                headerName:"INSTITUTION",
                width:200
            },
            {
                field:"fonction",
                headerName:"Fonction au sein du CEC-FPI/SK",
                width:300
            },
            {
                field:"pole",
                headerName:"Pôle",
                width:200
            },
        ],
        rows: CCC.members
    }
    const actualites = [{}, {}, {}]
    
    const paragraph = "Le (la) Ministre Provincial (e) de la Formation Professionnelle est d’office Président(e) du CEC-FPI/SK. Les autres membres sont issus deux à deux de chaque pôle selon les fonctions ci-après :<br/><br/><dd>-	Président (e) Ministre de la FPM</dd><dd>-	Vice-Président (e) issu de la demande</dd><dd>-	Secrétaire issu de l’offre</dd><dd>-	Secrétaire adjoint (e) issu de l’insertion</dd><dd>-	Trésorier (ère) issu de l’insertion</dd><dd>-	Trésorier (ère) adjoint (e) issu de l’offre</dd><dd>-	Trois conseillers. Issus l’un de la demande et deux autres de la régulation. </dd><br/>Actuellement, les personnalités ci-après ont été élues membres du CCC pour un mandat de deux ans, dans la dernière Assemblée Générale élective conformément au règlement intérieur et à l’Arrêté provincial régissant le CEC-FPI/SK. "

    useEffect(() => {
        window.scrollTo(0, 0)
    
      }, [])

    return (
        <React.Fragment>
            <SEO
                title="CCC"
                keywords="CCC"
            />
            <CccTemp
                title="Le Comité de Coordination Collégiale (CCC)"
                Body="Le Comité de Coordination Collégiale, CCC en sigle, est l’un des organes du Cadre d’Echange et de Concertation des acteurs clés du système de la Formation Professionnelle et l’Insertion au Sud-Kivu, CEC-FPI /SK, s’occupant de la gestion permanente de cette dernière. Il est composé de neuf membres emmenant de quatre pôles de la formation professionnelle. Ils sont élus lors d’une Assemblée Générale élective du CEC - FPI pour un mandat de deux ans renouvelable une seule fois conformément à l’arrêté portant création du Cadre et son règlement d’ordre intérieur."
                titleImage={Image}
                paragraph={{
                    paragraph: paragraph,
                    table: membersCCC
                }}
                members={membersCCC}
                // List = {[""]}
                ActuTitle="Les actualités du CCC"
                actualites={actualites}
            />
        </React.Fragment>
    )
}

export default Ccc