import React,{useRef, useEffect} from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import Actus from "../../../widgets/actus";
import More from "../../../widgets/moreLink";
import {useSelector} from 'react-redux'
import NewsSkeleton from '../../../../dashboard/sc/newsSkeleton'


const useStyle = makeStyles((theme) => ({
  root: {

  },
  head: {
    alignSelf: "center",
    "& h2": {
      padding: "5vw 4vw",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "4.2vw",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "2.7rem",
      },
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  noNews: {
    height: 300,
    display: "flex",
    alignItems: "center",
    color:theme.palette.primary.fade 
  }
}));

const Section = () => {
  const classes = useStyle();
  const { actualite } = useSelector(state => state.actualitePub)
  const { message } = useSelector(state => state.msg)
  const actualiteRef = useRef([])
  actualiteRef.current = actualite






  const handleSkeleton = () => {
    // if (actualiteRef.current.length === 0) {
    //   return (
    //     <Grid container justifyContent='center'>
    //       <Grid item className={classes.noNews}>
    //         <Typography>Pas d'Actualités récentes</Typography>
    //       </Grid>
    //     </Grid>
    //   )
    // }
   // console.log(actualiteRef.current);
    if (actualiteRef.current.length > 0) {
      return (
        <Actus List={actualiteRef.current.filter((e)=>e.approval==true).slice(0, 3)} />
      )
    } else {
      return (
        <Grid container justifyContent='space-between' spacing={3}>
          <Grid item xs={12} sm={3}>
            <NewsSkeleton />
          </Grid>
          <Grid item xs={12} sm={3}>
            <NewsSkeleton />
          </Grid>
          <Grid item xs={12} sm={3}>
            <NewsSkeleton />
          </Grid>
          <Grid item xs={12} sm={3}>
            <NewsSkeleton />
          </Grid>
        </Grid>
      )
    }
  }
  return (
    (actualiteRef.current.length > 0 || message != undefined) && <Grid container justifyContent="center" className={classes.root}>
      <Grid item className={classes.head} xs={10} xl={6}>
        <Typography variant="h2">
          Actualités récentes des membres clés du CEC-FPI et de ses partenaires
        </Typography>
      </Grid>
      <Grid item xs={10}>
        {handleSkeleton()}
      </Grid>
      <Grid item xs={10}>
        <Grid container justifyContent="center">
          <More title="Voir tout" to="/Actualite" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Section;
