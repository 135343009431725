import React, { useState } from "react";
import { useHistory } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Image from '../../website/assets/images/Image.webp'
import ShareIcon from '@material-ui/icons/ShareOutlined';
import IconButton from '@material-ui/core/IconButton';
import ShareComp from './shareComp'
import ReactHtmlParser from 'react-html-parser';




const useStyles = makeStyles((theme) => ({
  
  
  root: {

    margin:"1em 0em",
    alignSelf: "center",
    '& p': {
      textAlign:theme.typography.textBodyAlign
    },
    "& svg": {
      color: "#0000008a",
      fontSize: 19
    },
    "& img":{
      borderRadius:'0.5vw'
    },
    [theme.breakpoints.down("sm")]:{
      justifyContent:"center"
    },
    [theme.breakpoints.only("md")]:{
    
    }
  },
  title:{
    margin:"0.65em 0em",
    fontSize: "1.3rem",
    
  },
  media: {
    height: 260,
    [theme.breakpoints.only('sm')]:{
      height:200
    },
    [theme.breakpoints.down('xs')]:{
      height:"60vw"
    }
  },
  card:{
    padding:"0em"
  },
  link: {
    color: "#3F74A4",
  },

  img:{
    width:"100%",
     height:"100%",
     [theme.breakpoints.down('xs')]:{
      width:"100%",
      height:"100%",
     }
  }

}));

export default function News(props) {
  const classes = useStyles();
  const linkto = useHistory();
  const [shareOpen, setShareOpen] = useState(false)


  const shareHandle = () => {
    setShareOpen(!shareOpen)
  }

  const image =props.cover_image
  const title = props.title
  const desc = `${ReactHtmlParser(props.description.substr(0,250))}`


  return (
    <Grid container className={classes.root}>
      <Grid item xs = {12}>
        <CardActionArea onClick = { () => {
          linkto.push(`/Article/${props.title}=${props.id}`)
          sessionStorage.setItem('rowid',props.id)
        }}>
          <CardMedia

            className={classes.media}
            title="news title"
          >
            <img alt="News" src={props.cover_image ? props.cover_image : Image} className={classes.img} />
            
          </CardMedia>
          <CardContent className={classes.card}>
            <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
              {props.title}
          </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {ReactHtmlParser(props.description.substr(0,100))}
          </Typography>
          </CardContent>
        </CardActionArea>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton aria-label="share" onClick={shareHandle}>
            <ShareIcon />
          </IconButton>
          <CardActions>
            <Button size="small" color="primary">
              <Typography variant="caption" className={classes.link}>
                Publiée: {props.created_date}
            </Typography>
            </Button>
          </CardActions>
        </Grid>
      </Grid>
      <ShareComp
        open={shareOpen}
        handleClose = {shareHandle}
        image ={image}
        title={title}
        value ={`${process.env.REACT_APP_CECFPI_URL}/Article/${encodeURIComponent(props.title)}=${encodeURIComponent(props.id)}`}
        url = {`${process.env.REACT_APP_CECFPI_URL}/Article/${encodeURIComponent(props.title)}=${encodeURIComponent(props.id)}`}
        
        
      />
    </Grid>
  );
}
