import React from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import News from "./news";


const Style = makeStyles((theme)=> ({
    root: {
        [theme.breakpoints.down("md")]:{
            justifyContent:"space-around"
          },
          [theme.breakpoints.up("md")]:{
              justifyContent:"space-between"
          }
      },
    actus:{
    [theme.breakpoints.down("md")]:{

        justifyContent:"space-around"
        },
        [theme.breakpoints.up("md")]:{
            justifyContent: length => (length % 3) !== 0 ? "" : "space-between",
            
        }
    },
    news:{
        [theme.breakpoints.down("xs")]:{
          justifyContent:"center"
        },
        [theme.breakpoints.up("xs")]:{
    
        }
    },
    section: {
        
        [theme.breakpoints.only("sm")]: {
            flexBasis: "clamp(20%, 35vw,48%)",
        },
        [theme.breakpoints.up("md")]:{
            flexBasis:"clamp(30%, 15vw,33.333333%)",
            
          }
    },
    tab: {
        [theme.breakpoints.up("sm")]: {
            flexBasis: "clamp(20%, 35vw,48%)",
            maxWidth:"50%"
        },
        
    }
    
}));

const Actus = ( { config, position, List} ) => {

    const classes = Style(List.length);


    const actualitesRender = (ActuList) => (
        
        ActuList.map((item , i) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key = {i} {...config} className = {position ? classes.tab :classes.section}>
                <Grid container className = {classes.news}>
                    <News  {...item}/>
                </Grid>
            </Grid>
        ))
    )

    return (
        <Grid container 
            className = {classes.actus}
            spacing={4}
        >
            {actualitesRender(List)}

        </Grid>
    );
};

export default Actus;