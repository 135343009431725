import React,{useState,useEffect} from 'react'
import Backpaper from '../../../sc/backPaper'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useHistory } from 'react-router';
import {BtnOrange,BtmRed} from '../../../sc/utilities'
import TableData from '../../../sc/table'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogBox from '../../../sc/dialog';
import SortIcon from '@material-ui/icons/Sort';
import {useSelector,useDispatch}  from 'react-redux'
import {getClec,deleteClec} from '../../../../../store/actions/clec-actions'
import { Box ,Collapse,IconButton} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';




const columns = [
    {
        field:   `name`,
        headerName: `NOM`,
        width: 240,
    },
    {
        field:   `location`,
        headerName: `LOCALISATION`,
        width: 200,
    },
    {
        field:   `created_date`,
        headerName: `DATE DE CREATION`,
        width: 210,
    },
    {
        field:   `description`,
        headerName: `DESCRIPTION`,
        width: 310,
    },
   
    ]

const Clec =()=>{
    const history = useHistory()
    const [rowId, setrowId] = useState([])
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const [isDelete, setisDelete] = useState(false)
    const dispatch = useDispatch()
    const {clec} = useSelector((state)=>state.clec)
    const {deleteclec} = useSelector((state)=>state.clec)
    const [opens, setOpens] = React.useState(false);
    const [isDel, setisDel] = useState(false)
    const [er, seter] = useState(false)
    const msg = useSelector((state)=>state.msg.message)
    const [currentUser, setcurrentUser] = useState('')




    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        setOpen(false);
       
      
      };
    const handleClickOpen = () => {
       setOpen(true);
     };
    
    
    const handleCheckDelete =()=>{
        if(rowId.length!==0){
            handleClickOpen()
            setisDelete(true)
        }else{
            setisDelete(false)
            return null
        }
    }

    const handleDelete =()=>{
        setisDel(true)
        setOpens(true)
        if(rowId.length!==0 && isDelete===true){
        dispatch(deleteClec(rowId)).then(()=>{
            dispatch(getClec())
            handleClose()
            setOpens(true)
            setisDel(false)

        },()=>{
            handleClose()
            setOpens(true)
            setisDel(false)
            seter(true)
        })

        handleClose()
        }
    }

    const handleRowSelected =(rowId)=>{
        if(rowId===0){
            setrowId()
        }

        setrowId(rowId)
      
       
    }
    const handleClec =()=>{
        history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/clec/create`)
    }
    const handleRowClick = (row)=>{
        history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/clec/detail/${row.row.name}=${row.id}`)
        sessionStorage.setItem('rowId',row.id)
    }
    const  getUserRole =()=>{
        const role  = sessionStorage.getItem('role')
        setcurrentUser(role)
    }

    useEffect(() => {
        getUserRole()
    }, [])


    return (
        <Backpaper
            icon={<SortIcon/>}
            title={"CLEC"}
            yellowbtn = {currentUser<=3?<BtnOrange onClick={handleClec} btnYellowTitle="CRÉER" startIcon={<AddCircleOutlineIcon style={{color:'white',fontSize:14}}/>} />:null}
             content={
                <div>
                    <Box height={20}/>
                        <Collapse in={opens}>
                            <Alert
                            severity={er?'error':'success'}
                            action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpens(false);
                                    seter(false)
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            >
                            {msg!==null?msg: deleteclec}
                            </Alert>
                        </Collapse>
                    <Box height={20}/>

                    <TableData
                        columns={columns}
                        rows={clec??[]}
                        onSelectionModelChange={(selectedRow)=>handleRowSelected(selectedRow)}
                        onRowClick={handleRowClick}
                        //onRowSelected={handleRowSelected}
                    />
                    <DialogBox
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleClose}
                        //title= {`${userbyId.first_name}`}
                        Oui={isDel===true?<CircularProgress size={10}/>:"Oui"}
                        confirnClick={handleDelete}
                        nonOnclick={handleClose}
                        content='Etês-vous sûr de vouloir supprimer ce membre ?'
                    />
               </div>
            }
        />
    )
}

export default Clec