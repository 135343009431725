import {
  SET_MESSAGE,
  FETCH_PROFILE_FAIL,
  FETCH_PROFILE_SUCCES,
  FETCH_USERS_BY_ID_SUCCES,
  FETCH_USERS_BY_ID_FAIL,
  DELETE_USER_BY_ID_SUCCES,
  DELETE_USER_BY_ID_FAIL,
  UPDATE_USER_SUCCES,
  UPDATE_USER_FAIL,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  CLEAR_DATA
}from '../contants/type'
import UserActions from '../../service/user'


export const getUsers =()=>(dispatch)=>{
    return UserActions.getUser().then((data)=>{
        dispatch({
            type:FETCH_PROFILE_SUCCES,
            payload: data
        })
        return Promise.resolve()
    },(error)=>{
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          dispatch({
            type:FETCH_PROFILE_FAIL,
          });
    
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
    
          return Promise.reject();

    })

}

export const getUserById =(id)=>dispatch=>{
  return UserActions.getUserById(id).then((data)=>{
    dispatch({
        type: FETCH_USERS_BY_ID_SUCCES,
        payload: data
    })
    return Promise.resolve()
  },(error)=>{
    const message =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString();
    dispatch({
      type:FETCH_USERS_BY_ID_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  })
}

export const deleteUser =(id)=>dispatch=>{
  return UserActions.deleteUser(id).then((data)=>{
    dispatch({
      type:DELETE_USER_BY_ID_SUCCES,
      payload:id
    })
    return Promise.resolve()
  },(error)=>{
    const message =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString();
    dispatch({
      type:DELETE_USER_BY_ID_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  })
}

export const updateUser = (
  id,
  email,
  first_name,
  last_name,
  phonenumber,
  address,
  organisation,
  fonction,
  province,
  ville_territory,
  created_by,
  modification
)=>dispatch=>{
  return UserActions.updateUser(
    id,
    email,
    first_name,
    last_name,
    phonenumber,
    address,
    organisation,
    fonction,
    province,
    ville_territory,
    created_by,
    modification
  ).then((data)=>{
    dispatch({
      type:UPDATE_USER_SUCCES,
      payload:data
  })
        return Promise.resolve()
      },(error)=>{
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
          type:  UPDATE_USER_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();

      })
        
}


export const userProfile = (
  id
)=>dispatch=>{
  return UserActions.userProfile(
    id
  ).then((data)=>{
    dispatch({
      type:USER_PROFILE_SUCCESS,
      payload:data
  })
        return Promise.resolve()
      },(error)=>{
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
          type:  USER_PROFILE_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();

      })
        
}
export const removeData = () => {
  return {
    type:CLEAR_DATA,
  };
};

