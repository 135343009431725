import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography} from '@material-ui/core'
import Image from "../../../assets/images/ministre.JPG"
import DoubleArrowRoundedIcon from '@material-ui/icons/DoubleArrowRounded';

const useStyle = makeStyles((theme) => ({

    root:{
        '& p': {
            textAlign:theme.typography.textBodyAlign
          },
        [theme.breakpoints.down("md")]:{
            padding:'3em 0em',
        },
        [theme.breakpoints.up("md")]:{
            padding:'7em 0em',
        }

    },
    
    head: {
        marginBottom:"1em",
    },
    section:{
        background:"#1d2f3f09",
        justifyContent:"space-around",
        padding:"3vw 2vw",
        borderRadius:"0.5vw",
        [theme.breakpoints.down("sm")]:{
            padding:"5vw 3vw",
        },
        "& h4":{
            fontWeight: theme.typography.fontWeightMedium,
            [theme.breakpoints.down("md")]:{
                fontSize:"5.2vw"
            },
            [theme.breakpoints.up("md")]:{
                fontSize:"1.75rem"
            }
        }
   },
    more: {
        color:"#1EDAB2",
        margin: "1em 0vw",
        "& svg": {
            fontSize: "1.3rem",
            alignSelf:"center"
        },
        cursor:"pointer"
    },

    left: {
        transform: "rotate(180deg)" 
    },

    right: {
        transform: "0"
    },
    img:{
        width:'100%',
        height:400,
        
    },

    
}))


const Section = () => {

    const classes = useStyle()
    
    const [readMore, setReadMore] = useState(false)
    



    const extra = `<em> <br/>La vision du gouvernement est de libérer la population de l'obsession selon laquelle seule une éducation supérieure ou académique pourrait assurer une carrière réussie et veut au contraire valoriser les métiers manuels. Car c'est précisément le secteur artisanal qui rend possible une économie saine et l'émergence d'une classe moyenne. La finalité du secteur de l’ETFP s’aligne à celle de l’enseignement qui est de former des hommes et des femmes imprégnées des valeurs humaines, morales, spirituelles, culturelles et civiques, en les dotant des qualifications professionnelles qui leur permettent de s’insérer dans le monde du travail et de s’adapter tout au long de leurs vies aux différentes mutations socio-économiques.<br/><br/>Ce processus de changement nous interpellera encore longtemps, et je suis très heureuse que nous puissions compter sur l'engagement concret des différents partenaires financiers et techniques qui œuvrent dans la province du Sud-Kivu, entre autres celui de la Coopération Suisse et de Swisscontact. Je tiens à leur exprimer mes sincères remerciements ainsi qu'aux principaux acteurs du Cadre d'Echange et de Concertation du système de la Formation Professionnelle et d'Insertion, en sigle CEC-FPI/SK, car avec eux nous pouvons à juste titre espérer atteindre les objectifs ambitieux que nous nous sommes fixés. Ainsi, le présent Site Web est une plateforme dont l'objectif global est de sensibiliser le grand public au travail du CEC-FPI et de permettre aux décideurs et aux autres personnes intervenant dans  l’ETFP de s’impliquer pour le relèvement et le développement de ce secteur  en province du Sud-Kivu en particulier et en  RDC en général.</em> <br/><br/> Geneviève MIZUMBI <br/><br/><span class = "MuiTypography-root MuiTypography-caption">Ministre Provinciale de l’Education <br/> Présidente du CEC-FPI</span>`
    return (
        <Grid container direction='row' className = {classes.root} justifyContent = "center">

            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Grid container direction='row' className = {classes.section} spacing={5}>

                    <Grid item xs={12} sm={7} md={4} lg={4} xl={4}>
                          <img alt ="Ministre" src = {Image}  className={classes.img}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Grid container className = {classes.head}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="h4" >
                                Madame Géneviève MIZUMBI
                            </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="caption" >
                                Ministre Provinciale de l’Education et Présidente du CEC-FPI/SK
                            </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="body1" >
                                <em>L’objectif poursuivi par le Ministère de la Formation Professionnelle et Métiers pour la Province du Sud-Kivu, est défini par le Gouvernement central. Il consiste d’abord à sensibiliser toutes les couches de la société congolaise ainsi que les partenaires au développement sur l’importance, les défis et les enjeux de la Formation Professionnelle; ensuite de partager les meilleures pratiques en la matière dans une perspective de création d’emploi, d’insertion et de réinsertion dans le marché du travail, de promouvoir l’auto emploi et la lutte contre la pauvreté et enfin, de discuter des voies et moyens de renforcer l'adéquation entre l'enseignement professionnel et l'employabilité des groupes vulnérables.<br/><br/>Le gouvernement, tant au niveau national que provincial, est conscient que le développement économique et social du pays dépend de l'existence d'une main-d'œuvre qui est capable de dynamiser le marché du travail et d’améliorer la productivité. C’est pour cela qu’il a décidé de faire de l’Enseignement Technique et de la Formation Professionnelle (ETFP) l’une des principales priorités de ses interventions.</em>
                            </Typography>
                                <Typography variant="body1"
                                    dangerouslySetInnerHTML={{ __html: readMore ? extra : null }} />
                                <div onClick={() => {
                                
                                    setReadMore(!readMore)
                                    readMore && window.scrollTo(0, window.innerHeight + (window.innerHeight * 0.10))
                                }}>
                                    <Grid className={classes.more} container alignItems="baseline">
                                        <Typography variant="body1">
                                            {!readMore ? "Lire la suite" : "Lire peu"}
                                        </Typography>
                                        <DoubleArrowRoundedIcon className={readMore ? classes.left : classes.rigt} />
                                    </Grid>
                                </div>                           
                        </Grid>                        
                        </Grid>
                    </Grid>


                </Grid>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
        </Grid>
    );
};

export default Section;