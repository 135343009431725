import {
    FETCH_AGENDA_SUCCES,
    FETCH_AGENDA_FAIL,
    DELETE_AGENDA_SUCCES,
    DELETE_AGENDA_FAIL,
    ADD_AGENDA_SUCCES,
    ADD_AGENDA_FAIL,
    UPDATE_AGENDA_SUCCES,
    UPDATE_AGENDA_FAIL,
    FETCH_AGENDA_BY_ID_SUCCES,
    FETCH_AGENDA_BY_ID_FAIL,
    CLEAR_DATA
} from '../contants/type'


const initialState = {
    agenda:[]
}
const agenda =(state=initialState,action)=>{
    const {type,payload} = action

    switch(type){
        case FETCH_AGENDA_SUCCES: 
            return {
                ...state,
                agenda:payload
            }
        case FETCH_AGENDA_FAIL:
            return {
                ...state,
                agenda:[]
            }
        case DELETE_AGENDA_SUCCES:
            return {
                ...state,
                deleteAgenda:'L`agenda a été supprimé'
            }
        case DELETE_AGENDA_FAIL:
            return {
                ...state,
                deleteAgenda:'L`agenda n`a pas été supprimé!! veuillez verifier votre connexion'
            }
        case ADD_AGENDA_SUCCES:
            return {
                ...state,
                addedagenda: payload
            }
        case ADD_AGENDA_FAIL: 
            return{
                ...state,
                agenda:"L'agenda n'a pas été ajouter, Veuillez verifier votre connexion"
            }
        case UPDATE_AGENDA_SUCCES:
            return {
                ...state,
                updatedAgenda:payload
            }
        case UPDATE_AGENDA_FAIL:
            return {
                ...state
            }
        case FETCH_AGENDA_BY_ID_SUCCES:
            return {
                ...state,
                agendabyId:payload
            }
        case FETCH_AGENDA_BY_ID_FAIL:
            return {
                ...state,
              
            }
        case CLEAR_DATA:
            return {}
        
        default:
            return state
    }


}

const initialStatePub = {
    agenda:[]
}
const agendaPub =(state=initialStatePub,action)=>{
    const {type,payload} = action

    switch(type){
        case FETCH_AGENDA_SUCCES: 
            return {
                ...state,
                agenda:payload,
                success:true
            }
        case FETCH_AGENDA_FAIL:
            return {
                ...state,
                success:false
            }
        case FETCH_AGENDA_BY_ID_SUCCES:
            return {
                ...state,
                agendabyId:payload
            }
        case FETCH_AGENDA_BY_ID_FAIL:
            return {
                ...state,
              
            }
        
        default:
            return state
    }


}

const agendaReducer= {
    agenda ,
    agendaPub
}

export default agendaReducer