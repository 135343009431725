import React from  'react'
import {Dialog,DialogContent,DialogContentText} from '@material-ui/core'



const ChangePassword =({fullScreen,open,onClose,content})=>{
    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title" >
            <DialogContent>
                <DialogContentText>
                   {content}
                </DialogContentText>
            </DialogContent>
         </Dialog>
    )
}

export default ChangePassword
