import {
    FETCH_PARTENAIRE_SUCCES,
    FETCH_PARTENAIRE_FAIL,
    DELETE_PARTENAIRE_SUCCES,
    DELETE_PARTENAIRE_FAIL,
    ADD_PARTENAIRE_SUCCES,
    ADD_PARTENAIRE_FAIL,
    FETCH_PARTENAIRE_BY_ID_SUCCES,
    FETCH_PARTENAIRE_BY_ID_FAIL,
    UPDATE_PARTENAIRE_BY_ID_SUCCES,
    UPDATE_PARTENAIRE_BY_ID_FAIL,
    CLEAR_DATA
} from '../contants/type'


const initialState = {
    partenaire  :[]
}


const partenaires = (state = initialState,action)=>{
    const {type,payload} = action

    switch(type){
        case FETCH_PARTENAIRE_SUCCES:
            return {
                ...state,
                partenaire: payload
            }
        case FETCH_PARTENAIRE_FAIL:
            return {
                ...state
            }
        case DELETE_PARTENAIRE_SUCCES:
            return {
                ...state,
                deletedpartenaire:"Le partenaire a été bien supprimé"
            }
        case DELETE_PARTENAIRE_FAIL:
            return {
                ...state,
                deletedpartenaire:"Partenaire n'a pas été bien supprimé, veuillez verifier votre connexion"

            }
        case ADD_PARTENAIRE_SUCCES:
            return {
                ...state,
                partenaire: payload
            }
        case ADD_PARTENAIRE_FAIL:
            return {
                ...state
            }
        case FETCH_PARTENAIRE_BY_ID_SUCCES:
            return {
                ...state,
                partenairebyId: payload
            }
        case FETCH_PARTENAIRE_BY_ID_FAIL:
            return {
                ...state
            }
            case UPDATE_PARTENAIRE_BY_ID_SUCCES:
                return {
                    ...state,
                    updatepartenaire: payload
                }
            case UPDATE_PARTENAIRE_BY_ID_FAIL:
                return {
                    ...state
                }
            case CLEAR_DATA:
                return {}
        default:
            return state
    }
}


const initialStatePub = {
    partenaire  :[]
}


const partenairePub = (state = initialStatePub,action)=>{
    const {type,payload} = action

    switch(type){
        case FETCH_PARTENAIRE_SUCCES:
            return {
                ...state,
                partenaire: payload
            }
        case FETCH_PARTENAIRE_FAIL:
            return {
                ...state
            }
        default:
            return state
    }
}


const partenaireReducer ={
    partenaires,
    partenairePub
}


export default partenaireReducer