import React,{useState,useEffect} from  'react'
import {Grid, Typography, Box ,Collapse,IconButton} from '@material-ui/core'
import Backpaper from '../../../../sc/backPaper';
import EditIcon from '@material-ui/icons/Edit';
import {BtnOrange,BtmRed} from '../../../../sc/utilities'
import { useDispatch,useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogBox from '../../../../sc/dialog';
import Skeleton from '@material-ui/lab/Skeleton';
import {getMembres,deleteMember,getMemberById} from '../../../../../../store/actions/membre-actions'
import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import useStyle from '../style'




const MembreDetail =()=>{
    const [open, setOpen] = useState(false);
    const history = useHistory()
    const theme = useTheme();
    const classes  =useStyle()
    const dispatch = useDispatch()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const  {membrebyId}= useSelector((state)=>state.membreById)
    const [currentUser, setcurrentUser] = useState('')

    const handleModifier = ()=>{
        history.push({
                pathname: `/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/membre/create`,
                data: sessionStorage.getItem('rowId')
        })
    }
    const handleClose = () => {
        setOpen(false);
      };
    const handleClickOpen = () => {
       setOpen(true);
     };
    const handleDelete =()=>{
        handleClickOpen()
    }
    const handleConfirmDelete = (id)=>{
            dispatch(deleteMember(id)).then(()=>{
                 dispatch(getMembres())
                  history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/membre`)  
            })
    }
    const  getUserRole =()=>{
        const role  = sessionStorage.getItem('role')
        setcurrentUser(role)
    }
    useEffect(() => {
        dispatch(getMemberById(`${parseInt(sessionStorage.getItem('rowId'))}`))
        getUserRole()
     }, [])
    
    return (
        <Backpaper
           yellowbtn = {currentUser<=2?<BtnOrange
                 disabled={membrebyId?false:true} 
                 btnYellowTitle="MODIFIER" startIcon={<EditIcon style={{fontSize:14}}/>} onClick={handleModifier} />:null}
           redbtn ={currentUser<=2?<BtmRed 
               disabled={membrebyId?false:true}
                 onClick={handleDelete}/>:null}

           content={
            <Grid container justifyContent='center' style={{paddingBottom:50}}>
                <Grid item>
                     <Grid container justifyContent='space-between' spacing={4} className={classes.spacingBtw}>
                       
                                 <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography ></Typography>Nom</Grid>
                                                {membrebyId?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{membrebyId.first_name}</Typography></Grid>:
                                                <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }
                                         </Grid>
                                 </Grid>
                                 <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography ></Typography>Prénom</Grid>
                                             {membrebyId?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{membrebyId.last_name}</Typography></Grid>:
                                                        <Skeleton  variant="rect" height={10}  width={100}/>
            
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography ></Typography>Organisation</Grid>
                                             {membrebyId?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{membrebyId.organisation}</Typography></Grid>:
                                                        <Skeleton  variant="rect" height={10}  width={100}/>
            
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography ></Typography>E-mail</Grid>
                                             {membrebyId?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{membrebyId.email}</Typography></Grid>:
                                                        <Skeleton  variant="rect" height={10}  width={100}/>
            
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography ></Typography>Fonction</Grid>
                                             {membrebyId?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{membrebyId.fonction}</Typography></Grid>:
                                                        <Skeleton  variant="rect" height={10}  width={100}/>
            
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography ></Typography>Sigle</Grid>
                                             {membrebyId?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{membrebyId.sigle}</Typography></Grid>:
                                                        <Skeleton  variant="rect" height={10}  width={100}/>
            
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography ></Typography>Mission</Grid>
                                             {membrebyId?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{membrebyId.mission}</Typography></Grid>:
                                                        <Skeleton  variant="rect" height={10}  width={100}/>
            
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography ></Typography>Numéro de téléphone</Grid>
                                             {membrebyId?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{membrebyId.phonenumber}</Typography></Grid>:
                                                        <Skeleton  variant="rect" height={10}  width={100}/>
            
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography ></Typography>Objectif</Grid>
                                             {membrebyId?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{membrebyId.objectif}</Typography></Grid>:
                                                        <Skeleton  variant="rect" height={10}  width={100}/>
            
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography ></Typography>Lien</Grid>
                                             {membrebyId?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{membrebyId.link}</Typography></Grid>:
                                                        <Skeleton  variant="rect" height={10}  width={100}/>
            
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography ></Typography>Pôle</Grid>
                                             {membrebyId?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{membrebyId.pole}</Typography></Grid>:
                                                        <Skeleton  variant="rect" height={10}  width={100}/>
            
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography ></Typography>Créé par</Grid>
                                             {membrebyId?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{membrebyId.created_by}</Typography></Grid>:
                                                        <Skeleton  variant="rect" height={10}  width={100}/>
            
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography ></Typography>Logo</Grid>
                                             {membrebyId?
                                                <Grid item >
                                                          <img  src={membrebyId.logo} alt='logo' height={50} width={50} />

                                                </Grid>:
                                                        <Skeleton  variant="rect" height={10}  width={100}/>
            
                                                }

                                         </Grid>
                                 </Grid>
                          
                         
                     </Grid>
                    < DialogBox
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleClose}
                        title= {membrebyId?`${membrebyId.first_name}`:null}
                        nonOnclick={handleClose}
                        Oui={'Oui'}
                        confirnClick={()=>handleConfirmDelete(membrebyId.id)}
                        content={`Etês-vous sûr de vouloir supprimer ce membre ?`}/>
                        
                </Grid>
            </Grid>
                 
             } 
            
       />
    );
}

export default MembreDetail