import React,{useEffect,useRef} from 'react'
import { Grid, Typography} from '@material-ui/core'
import Style from './style'
import logo from '../../assets/images/Logo.webp'
import MoreLink from '../../widgets/moreLink'
import { useSelector } from 'react-redux'
import SEO from '../../sc/seo'

const Partenaires = () => {

    const classes = Style();
    const {partenaire} = useSelector(state => state.partenaire)
    const partnerRef = useRef([])
    partnerRef.current = partenaire

    useEffect(() => {
        window.scrollTo(0, 0)
    
      }, [])
    
    const handlePartner = ()=>{
        if(partnerRef.current.length ===0){
            return (
                <Grid item>
                
                    <Typography>Pas de Partenaire</Typography>
                  
            </Grid>
            )
        }
        else if(partnerRef.current.length>0){
            let template =null;

            template = partnerRef.current.map((item , i ) => (
                
                <Grid item key = {i} 
                    xs = {12} sm = {5} md = {3}
                    className ={classes.partenaireContainer}>
                    <Grid container direction='column' justifyContent = 'center'>
                        <Grid item >
                            <img src ={logo} alt ={"Partenaire_logo"}/>
                        </Grid>
                        <Grid item>
                            <Typography variant = 'h2' >
                                {item.name}
                            </Typography>
                            <Typography variant = 'caption'>
                                {item.description}
                            </Typography>
                        </Grid>
                        {
                            item.link!==null?<Grid item>
                            <Grid container justifyContent='center' >
                                <Grid item>
                                    <MoreLink
                                            title="Visiter siteweb"
                                            target = "_blank"
                                    to = {item.link}
                                        />
                                </Grid>
                            </Grid>
                        </Grid>:null
                        }
    
                    </Grid>
                </Grid>
            ))
    
            return template;
        }
    }
   
    return (
        <React.Fragment>
            <SEO
                title="Partenaires"
                keywords="Partenaires"
            />
            <Grid container className={classes.root} justifyContent="center">
                <Grid item className={classes.pageHead} xs={10} md={8}>
                    <Typography variant='h2'>
                        PARTENAIRES
                </Typography>
                    <Typography variant='body1'>
                        Trouvez ci-dessous les logos et liens des partenaires et acteurs avec lesquels nous travaillons et qui nous soutiennent dans nos objectifs et notre mission
                </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Grid container justifyContent={partnerRef.current.length === 0 ? 'center' : "space-between"}>
                        {handlePartner()}
                    </Grid>
                </Grid>


            </Grid>
        </React.Fragment>
    );
};

export default Partenaires;