import React,{useState,useEffect} from  'react'
import { CircularProgress,Grid, Typography, Box ,Collapse,IconButton, Button,TextField} from '@material-ui/core'
import Backpaper from '../../../../sc/backPaper';
import EditIcon from '@material-ui/icons/Edit';
import {BtnOrange,BtmRed} from '../../../../sc/utilities'
import { useDispatch,useSelector } from 'react-redux';
import {getUsers} from '../../../../../../store/actions/user-actions';
import {userbyIds,deleteUser,changeUserPassword,activatedUser} from  '../../../../../../store/actions/auth-actions'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogBox from '../../../../sc/dialog';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import useStyle from '../style'
import ChangePassword from '../../../../sc/changePassword';
import  {useForm} from 'react-hook-form'
import { clearMessage } from '../../../../../../store/actions/message-actions';





const Parametre =()=>{
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const history = useHistory()
    const classes  =useStyle()
    const [isDel, setisDel] = useState(false)
    const [opens, setOpens] = React.useState(false);
    const [openPasswprd, setopenPasswprd] = useState(false)
    const dispatch = useDispatch()
    const [role, setrole] = useState('')
    const  {user} = useSelector((state)=>state.userById)
    const { register,watch, handleSubmit,reset} = useForm();
    const [cfirm, setcfirm] = useState(false)
    const [ermsg, setermsg] = useState('Les mots de passe doivent correspondre')
    const {message} = useSelector(state => state.userChangePassword.changePassword)??''
    const msg = useSelector((state)=>state.msg.message)
    const [currentUser, setcurrentUser] = useState('')
    const [userLogin, setuserLogin] = useState('')
    const {activateUser} = useSelector(state => state.activatedUser)

// console.log(activateUser);

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        setOpen(false);
      };
    const handleClickOpen = () => {
       setOpen(true);
     };
     const handleClosePassword = () => {
        setopenPasswprd(false);
      };
    const handleClickOpenPassword = () => {
        setopenPasswprd(true);
     };

     const handleModifier = ()=>{
        history.push({
                pathname: `/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/utilisateur/account/update`,
                data: sessionStorage.getItem('rowIds')
        })
    }
    
    
    const handleDelete =()=>{
        handleClickOpen()
    }
    const handleConfirmDelete = (id)=>{
        setisDel(true)
        dispatch(deleteUser(id)).then(()=>{
              dispatch(getUsers())
              history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/utilisateur`)  
        })
}
  

   const onSubmit =(data,e)=>{
           e.preventDefault()
           setOpens(false)
           setisDel(true)
           if(data!=null){
                dispatch(changeUserPassword(
                        user.id,
                        data.pass
                )).then(()=>{
                      reset()  
                      setOpens(true)
                      dispatch(getUsers())
                      handleClosePassword()
                      setisDel(false)

                },()=>{
                    setOpens(true)  
                    setisDel(false)
                    handleClosePassword()
                })
           }

   }

   const handleDisplayUser = (id,email,user)=>{
        setOpens(false)
        setisDel(true)
        if(user?.activated ===false){
                dispatch(activatedUser(id,email,true)).then(()=>{
                dispatch(getUsers())
                dispatch(userbyIds(`${parseInt(sessionStorage.getItem('rowIds'))}`))
                setOpens(true)
                setisDel(false)
                },()=>{
                setOpens(true)
                setisDel(false)
                })
        }else if(user?.activated ===true){

               
                dispatch(activatedUser(id,email,false)).then(()=>{
                        dispatch(getUsers())
                        dispatch(userbyIds(`${parseInt(sessionStorage.getItem('rowIds'))}`))
                        setOpens(true)
                        setisDel(false)
                        },()=>{
                        setOpens(true)
                        setisDel(false)
                        })
        }
   }

   const  getUserRole =()=>{
        const role  = sessionStorage.getItem('role')
        const user  = sessionStorage.getItem('user')
        setuserLogin(user)
        setcurrentUser(role)
    }

    useEffect(() => {
       dispatch(userbyIds(`${parseInt(sessionStorage.getItem('rowIds'))}`)).then(()=>{
        if(user!==undefined){
                if(user.role==="Administrateur"){
                        setrole('1')
                    }
                    else if(user.role==="Secrétaire"){
                        setrole('2')
                    }
                    else if(user.role==="Auteur"){
                        setrole('3')
                    }
                    else if(user.role==="Contributeur"){
                        setrole('4')
                    }
                    else if(user.role==="Membre"){
                        setrole('5')
                    }
        }      
       },()=>{
               setOpens(true)
       })
       getUserRole()
    }, [])
    return (
       <Backpaper
           //title='Changer le mot de pass'
           yellowbtn = {currentUser<=2?<BtnOrange onClick={handleModifier}  btnYellowTitle="MODIFIER" startIcon={<EditIcon style={{fontSize:14}}/>} />:null}
           redbtn ={currentUser<=1?<BtmRed onClick={handleDelete}/>:null}
           title={'Paramètre'}
           content={
            <Grid container justifyContent='center' style={{paddingBottom:50}}>
                <Grid item xs={12}>
                        <Box height={20}/>
                        <Collapse in={opens}>
                        <Alert
                        severity={message||activateUser?'success':'error'}
                        action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                setOpens(false);
                                dispatch(clearMessage())
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                        }
                        >
                        { msg?msg:message||activateUser}
                        </Alert>
                        </Collapse>
                        <Box height={20}/>
                </Grid>
                <Grid item>
                     <Grid container justifyContent='space-between' spacing={4} className={classes.spacingBtw}>
                                 <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography >E-mail</Typography></Grid>
                                                {user!==undefined?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{user.email}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }
                                         </Grid>
                                 </Grid>
                                 <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography ></Typography>Rôle</Grid>
                                             {user!==undefined?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{user.role}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }

                                         </Grid>
                                 </Grid>
                                 <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                         <Grid container direction='column' spacing={1}>
                                             <Grid item ><Typography >Organisation</Typography></Grid>
                                             {user!==undefined?
                                                <Grid item ><Typography style={{fontWeight:'bold'}}>{user.organisation}</Typography></Grid>:
                                                 <Skeleton  variant="rect" height={10}  width={100}/>
                                                
                                                }

                                         </Grid>
                                 </Grid>
                                 
                               
                          
                         
                     </Grid>
                     <br/>
                     { currentUser<=2?<Grid item>
                             <Grid container className={classes.spacingBtws}>
                             {user!==undefined?
                                <Grid item>
                                    <Button onClick={()=>handleDisplayUser(user.id,user.email,user)}  size='small' fullWidth>
                                    {isDel===true?<CircularProgress size={10}/>:    <Typography style={{fontSize:10 ,color:user.activated?'red':'#009688'}}>{user.activated?'Desactiver':'Activer'}</Typography>}
                                            </Button>
                                    </Grid>:
                                <Skeleton  variant="rect" height={10}  width={100}/>
                                    
                                    }
                             </Grid>
                     </Grid>:null}
                     <br/>
                     {currentUser<=5?<Grid item>
                             <Grid container className={classes.spacingBtws}>
                             {user!==undefined?
                                <Grid item>
                                    <Button onClick={handleClickOpenPassword} size='small' fullWidth endIcon={<EditIcon style={{color:'red'}}/>} ><Typography style={{fontSize:10 ,color:'red'}}>changer le mot de passe</Typography></Button>    
                                </Grid>:
                                <Skeleton  variant="rect" height={10}  width={100}/>
                                
                                }
                             </Grid>
                     </Grid>:null}
                    < DialogBox
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleClose}
                        nonOnclick={handleClose}
                        Oui={isDel===true?<CircularProgress size={10}/>:"Oui"}
                        confirnClick={()=>handleConfirmDelete(user.id)}
                        content={`Etês-vous sûr de vouloir supprimer ${user?`${user.email}`:null} `}/>
                        
                </Grid>
                <ChangePassword
                fullScreen={fullScreen}
                open={openPasswprd}
                nonOnclick={handleClosePassword}

                content={
                       <form onSubmit={handleSubmit(onSubmit)}> 
                          <Grid container direction='column' justifyContent='center' spacing={5}>
                                <Grid item> <Typography align='center' style={{fontWeight:'bold'}}>Changer le mot de passe</Typography></Grid>
                                <Grid item>
                                        <Grid container direction='column' spacing={3} >
                                                <Grid item >
                                                <Typography>Nouveau mot de passe</Typography>
                                                <TextField 
                                                        error={cfirm==true?true:false}
                                                        type='password'
                                                        required
                                                        variant='outlined' 
                                                        fullWidth 
                                                        size='small'
                                                        inputProps={{minLength:8,maxLength:20}} 
                                                        {...register('pass',{required:true,})}
                                                        helperText='Mot de passe doit avoir au moins 8 caractères'
                                                />
                                                </Grid>
                                                <Grid item >
                                                <Typography>Confirmer le mot de passe</Typography>
                                                <TextField 
                                                        error={cfirm==true?true:false}
                                                        type='password'
                                                        required
                                                        variant='outlined' 
                                                        inputProps={{minLength:8,maxLength:20}} 
                                                        fullWidth 
                                                        size='small'
                                                        {...register('confirm',{validate: value =>{
                                                        const a = value === watch('pass')  || "Les mots de passe doivent correspondre"
                                                        
                                                        if(a=="Les mots de passe doivent correspondre"){
                                                                setcfirm(true)
                                                                setermsg("Les mots de passe doivent correspondre")
                                                        }
                                                        return a
                                                        }
                                                        })}
                                                        helperText={ermsg}
                                                        
                                                />
                                                </Grid>
                                                <Grid item >
                                                        <Grid container direction='row' justifyContent='flex-end' spacing={2}>
                                                                <Grid item> 
                                                                    <Button autoFocus onClick={handleClosePassword} style={{color:'#D12028',fontWeight:300}} size='small'>
                                                                        Annuler
                                                                        </Button>
                                                                </Grid>
                                                                <Grid item>
                                                                   <Button 
                                                                        //onClick={confirnClick}
                                                                        type='submit'
                                                                        autoFocus
                                                                        style={{color:'#009688',fontWeight:300}} size='small'>
                                                                       {isDel===true?<CircularProgress size={10}/>: 'Sauvegarder'}
                                                                  </Button>
                                                                </Grid>
                                                        </Grid>
                                                </Grid>
                                        </Grid>
                                </Grid>
                         </Grid>
                       </form>
                }
                non={'Annuler'}
                Oui={'Sauvegarder'}
                />
                
            </Grid>
                 
             } 
            
       />
    );
}

export default Parametre