import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom'

const useStyle = makeStyles((theme) => ({
    root:{
        margin:"6em 0vw",
        textAlign:"center",
        "& h4":{
            fontWeight:theme.typography.fontWeightBold
        },
        "& p":{
            padding:"2em 3vw"
        },
        "& a":{
            textDecoration:"none",

        }
    },
    Button: {
        color:"white",
        padding:"1em 0rem",
        backgroundColor:"#1EDAB2",
        alignSelf:"center",
        '&:hover': {
            color: 'white',
            backgroundColor:'#1EDAB2'
        },
    },
    

}))


const Section = () => {

    const classes = useStyle();

    return (
        <Grid container justifyContent = "center" >
            <Grid item xs = {11} sm = {9} md ={5} lg = {8}>
                <Grid container className = {classes.root} direction = "column" >
                    <Typography variant = "h4">
                        Contactez-nous
                    </Typography>
                    <Typography variant = "body1">
                        Chèr(e)s visiteurs, si vous souhaitez de plus amples informations sur une thématique 
                        spécifique, contactez-nous et introduisez votre demande dans l’espace « Message ». 
                        Nous vous répondrons dès que possible. Merci
                    </Typography>
                    <Grid container justifyContent = "center">
                        <Grid item xs = {12} sm = {4}>
                            
                            <Link to = "/Contactez_nous" className={classes.links}>
                                <Button  variant='text' disableRipple className = {classes.Button} fullWidth>
                                    Contactez-nous
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Section;