import {
    SET_MESSAGE,
    FETCH_ESPACE_SUCCES ,
    FETCH_ESPACE_FAIL ,
    DELETE_ESPACE_SUCCES, 
    DELETE_ESPACE_FAIL,
    UPDATE_ESPACE_DOCUMENT_SUCCES,
    UPDATE_ESPACE_WITHOUT_DOCUMENT_FAIL,
    ADD_ESPACE_SUCCES ,
    ADD_ESPACE_FAIL ,
    UPDATE_ESPACE_SUCCES, 
    UPDATE_ESPACE_FAIL ,
    FETCH_ESPACE_BY_ID_SUCCES, 
    FETCH_ESPACE_BY_ID_FAIL,
    CLEAR_DATA
} from '../contants/type'
import EspaceService from '../../service/espace_membre'

export const getEspaceMembre = ()=>dispatch=>{
    return EspaceService.getEspace().then((data)=>{
        dispatch({
            type:FETCH_ESPACE_SUCCES,
            payload:data
        })
        return Promise.resolve()
    },(error)=>{
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
          type:FETCH_ESPACE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
    })
}

export const getbyIdEspaceMembre = (id)=>dispatch=>{
  return EspaceService.getbyIdEspace(id).then((data)=>{
      dispatch({
          type:FETCH_ESPACE_BY_ID_SUCCES,
          payload:data
      })
      return Promise.resolve()
  },(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:FETCH_ESPACE_BY_ID_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
  })
}

export const  addEspaceMembre =(
    name,
    sharedBy,
    document,
    description,
    create_by
)=>dispatch=>{
    return EspaceService.addEspace(
        name,
        sharedBy,
        document,
        description,
        create_by
    ).then((data)=>{
        dispatch({
            type :ADD_ESPACE_SUCCES,
            payload:data
        })
        return Promise.resolve()
    } ,(error)=>{
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
          type:ADD_ESPACE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
    })
}


export const  updateEspaceMembre =(
    id,
    // name,
    // sharedBy,
    document,
    description,

)=>dispatch=>{
  return EspaceService.updateEspace(
    id,
    // name,
    // sharedBy,
    document,
    description,
  ).then((data)=>{
      dispatch({
          type :UPDATE_ESPACE_SUCCES,
          payload:data
      })
      return Promise.resolve()
  } ,(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:UPDATE_ESPACE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
  })
}

export const  updateEspaceMembreWithoutlogo =(
    id,
    // name,
    // shareBy,
    description,
)=>dispatch=>{
  return EspaceService.updateEspaceWithoutLogo(
    id,
    // name,
    // shareBy,
    description,
  ).then((data)=>{
      dispatch({
          type :UPDATE_ESPACE_DOCUMENT_SUCCES,
          payload:data
      })
      return Promise.resolve()
  } ,(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:UPDATE_ESPACE_WITHOUT_DOCUMENT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
  })
}


export const deleteEspaceMembre =(id)=>dispatch=>{
    return EspaceService.deleteEspace(id).then((data)=>{
        dispatch({
            type:DELETE_ESPACE_SUCCES,
            payload:id
        })
        return Promise.resolve()

    },(error)=>{
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
          type:DELETE_ESPACE_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
    })
}




export const removeData = () => {
  return {
    type:CLEAR_DATA,
  };
};
