import api from './axiosApi';
import apiPub from './axiosPub'


const addActualite =(
        title,
        cover_image,
        category,
        document,
        description,
        created_by
)=>{
   const formData  = new FormData()
   formData.append('title',title)
   formData.append('cover_image',cover_image)
   formData.append('category',category)
   formData.append('document',document)
   formData.append('description',description)
   formData.append('created_by',created_by)
   return api.post('api-actualite/addActualite/',formData).then((res)=>{
        return res.data
    })
}

const getActualite =()=>{
    return api.get('api-actualite/actualites/').then((res)=>{
         return res.data.actualities
     })
 }

const getActualiteById =(id)=>{
  return  api.get(`api-actualite/actualiteOneDetail/${id}/`).then((res)=>{
        return res.data.Actualite
    })
}

const updateActualite =(
       
        id,
        title,
        cover_image,
        category,
        document,
        description,
        created_by,
        )=>{
    const formData  = new FormData()
    formData.append('id',id)
    formData.append('title',title)
    formData.append('cover_image',cover_image)
    formData.append('category',category)
    formData.append('document',document)
    formData.append('description',description)
    formData.append('created_by',created_by)
   return  api.patch(`api-actualite/actualite/${id}/`,formData).then((res)=>{
        return res.data
    })
}

const updateActualiteWithNoImage =(   
    id,
    title,
    category,
    document,
    description,
    created_by,
    )=>{
const formData  = new FormData()
formData.append('id',id)
formData.append('title',title)
formData.append('category',category)
formData.append('document',document)
formData.append('description',description)
formData.append('created_by',created_by)

return  api.patch(`api-actualite/actualite/${id}/`,formData).then((res)=>{
    return res.data
})
}

const updateActualiteWithNoDoc =(     
    id,
    title,
    cover_image,
    category,
    description,
    created_by,

    )=>{
const formData  = new FormData()
formData.append('id',id)
formData.append('title',title)
formData.append('cover_image',cover_image)
formData.append('category',category)
formData.append('description',description)
formData.append('created_by',created_by)

return  api.patch(`api-actualite/actualite/${id}/`,formData).then((res)=>{
    return res.data
})
}
const updateActualiteWithNoImageAndDoc =(
       
    id,
    title,
    category,
    description,
    created_by,
    )=>{
const formData  = new FormData()
formData.append('id',id)
formData.append('title',title)
formData.append('category',category)
formData.append('description',description)
formData.append('created_by',created_by)

return  api.patch(`api-actualite/actualite/${id}/`,formData).then((res)=>{
    return res.data
})
}
const deleteActualite =(id)=>{
    return api.delete(`api-actualite/actualite/${id}/`).then((res)=>{
        return res.data
    })
}

const createModification=(
    modified_by,
    modification_date,
    app,
    Activity
)=>{
    return api.post('api-modification/modification/',{
        modified_by,
        modification_date,
        app,
        Activity
    }).then((res)=>{
        return res.data
    })
}

const getActualitePub =()=>{
    return apiPub.get('api-actualite/actualites/pub/').then((res)=>{
        return res.data.actualities
    })
}

const getActualiteByIdPub =(id)=>{
    return apiPub.get(`api-actualite/actualiteOneDetail/pub/${id}/`).then((res)=>{
        return res.data.Actualite
    })
}

const updateApprovalActualite =(     
    id,
    approval,
    )=>{
return api.patch(`api-actualite/actualite/approval/${id}/`,{
    id,
    approval
}).then((res)=>{
    return res.data
})
}

const actualiteService = {
    addActualite,
    getActualite,
    getActualiteById,
    updateActualite,
    deleteActualite,
    createModification,
    getActualitePub,
    getActualiteByIdPub,
    updateActualiteWithNoImage,
    updateActualiteWithNoDoc,
    updateActualiteWithNoImageAndDoc,
    updateApprovalActualite

}

export default actualiteService;
   

