import React,{useState,useEffect} from 'react'
import {Button, Grid, TextField, Typography} from '@material-ui/core'
import useStyles from '../style'
import { useHistory } from 'react-router';
import {getUsers,getUserById,updateUser} from '../../../../../../store/actions/user-actions'
import { useSelector,useDispatch } from 'react-redux';
import  {useForm,Controller} from 'react-hook-form'
import CircularProgress from '@material-ui/core/CircularProgress';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'



const UtilisateurCreate=(props)=>{

    
    const classes = useStyles()
    const dispatch  = useDispatch()
    const history  = useHistory()
    const {register,control,handleSubmit,reset} = useForm()
    const [email, setemail] = useState('')
    const [isCircular, setisCircular] = useState(false)
    const  userById = useSelector((state)=>state.profilebyId)
    const {userbyId}= userById

    const onSubmit = (data,e)=>{
        e.preventDefault()
        if(data){
            if(props.location.data){
                setisCircular(true)
                dispatch(updateUser(
                    props.location.data,
                    data.em,
                    data.fn,
                    data.ln,
                    data.phn,
                    data.addr,
                    data.org,
                    data.fon,
                    data.prov,
                    data.ville,
                    email,
                )).
                then(()=>{
                    setisCircular(false)
                    dispatch(getUsers())
                    history.push({
                        pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/utilisateur/detail/${data.fn}=${props.location.data}` ,
                        data:props.location.data
                     })
                     sessionStorage.setItem('rowId',props.location.data,)
                     reset()
                })
            }
        }

    }
    const getUser =()=>{
        var email =  sessionStorage.getItem('user')
        setemail(email) 
      }
    useEffect(() => {
        if(history.action ==='POP'){
            history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/utilisateur`)
        }
        getUser()
        if(props.location.data){
            dispatch(getUserById(`${parseInt(props.location.data)}`))

            reset({
                em : userbyId.email,
                fn : userbyId.first_name,
                ln : userbyId.last_name,
                phn : userbyId.phonenumber,
                addr : userbyId.address,
                org: userbyId.organisation,
                fon:userbyId.fonction,
                prov:userbyId.province,
                ville:userbyId.ville_territory
            })
        }
    }, [])
    return (
     
        <Grid container direction='column' className={classes.root}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container justifyContent='center' alignItems="center">
                    <Grid item><Typography variant='h3' style={{fontWeight:'bold'}} color='primary'>Actualiser un utilisateur</Typography></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.backPaper}>
               <Grid container justify='center' alignItems='center'>
                <Grid item>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container justify='space-between' className={classes.spacebetween} spacing={4}>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>Nom</Typography>
                                <TextField type='text' {...register('fn',{required:true})} required variant='outlined' placeholder='01/02/2021 '  fullWidth={true} className={classes.textField}/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>Prénom</Typography>
                                <TextField type='text' {...register('ln',{required:true})} required variant='outlined' placeholder='12:00 '  fullWidth={true}  className={classes.textField}/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>Numéro de téléphone</Typography>
                                <Controller
                                    name='phn'
                                    control={control}
                                    render={
                                    ({
                                        field: { onChange, onBlur, value, name }
                                    }) => (
                                        <PhoneInput
                                            
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            name= "phn"
                                            id = 'phn'
                                            inputProps={{
                                                "aria-invalid":false,
                                                required: "true",
                                              }}
                                            disableInitialCountryGuess={true}
                                            autoComplete='none'
                                            inputClass={`MuiInputBase-input MuiInputBase-phone ${classes.phone}`}
                                            containerClass="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl "
                                            buttonClass=""
                                            specialLabel=""
                                            inputStyle={{
                                            fontSize: "0.8rem",
                                            width: "100%",
                                            backgroundColor:'transparent',
                                            }}
                                            placeholder='Entrez votre numéro'
                                            country= 'cd'


                                        />
                                    )
                                    }
                                />
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>Adresse</Typography>
                                <TextField type='text' {...register('addr',{required:true})} required variant='outlined' placeholder='03:00'  fullWidth={true} className={classes.textField}/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>Organisation</Typography>
                                <TextField type='text' {...register('org',{required:true})} required variant='outlined' placeholder='03:00'  fullWidth={true} className={classes.textField}/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>Fonction</Typography>
                                <TextField type='text' {...register('fon',{required:true})} required variant='outlined' placeholder='03:00'  fullWidth={true} className={classes.textField}/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>Province</Typography>
                                <TextField type='text' {...register('prov',{required:true})} required variant='outlined' placeholder='03:00'  fullWidth={true} className={classes.textField}/>
                            </Grid>
                            <Grid item  xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Typography>Ville & Territoire</Typography>
                                <TextField type='text' {...register('ville',{required:true})} required variant='outlined' placeholder='03:00'  fullWidth={true} className={classes.textField}/>
                            </Grid>
                        </Grid>
                
                        <Grid item className={classes.spacebetween} >
                        <Button type='submit' disabled={isCircular===true?true:false}  fullWidth className={classes.btn}> {isCircular===true?<CircularProgress size={30} style={{color:'white'}} />:<Typography>SAUVEGARDER</Typography>}</Button>
                        </Grid>
                    </form>
            </Grid>
             </Grid>
            </Grid>

        </Grid>
       
    )
}

export default UtilisateurCreate