import { makeStyles } from '@material-ui/core/styles'


const Style = makeStyles((theme) => ({

    
    Root:{
        marginTop:130,
        color:'#1D2F3F',
        [theme.breakpoints.down("sm")]:{
            padding:'3em 0em',
        },
        [theme.breakpoints.up("sm")]:{
            padding:'5em 0em',
        },
        '& h1':{
            fontWeight:theme.typography.fontWeightBold,
            margin:"0.5em 0em",
            [theme.breakpoints.down('md')]:{
                fontSize:"9vw",
                textAlign:"center"
            },
            [theme.breakpoints.up('md')]:{
                fontSize:"4vw",
            },
            [theme.breakpoints.up('xl')]:{
                fontSize:"3.8rem",
            }
        },
        "& p":{
            fontSize:"1rem",
            margin:"0.5em 0em",
        },
        
    },

    

    formcontainer:{
        padding:"0.2em 0em"
    },
    inputcontainer:{

        margin:"0.5em 0em",
        '& h5':{
            fontWeight:theme.typography.fontWeightRegular,
            fontSize:'1rem',
            marginBottom:'0.2em'
        },
        '& input':{
            fontSize:'0.8rem'
        }
    },
    Button: {
        margin:"1em 0em",
        padding:"1em 0em",
        background:'#FFAE02',
        alignSelf:"center",
    },
    phone: {

        "&:focus": {
            borderColor: "#FFAE02 !important",
            boxShadow:"0 0 0 1px #FFAE02 !important"
        },
        "&:active": {
            borderColor: "#FFAE02 !important",
            boxShadow:"0 0 0 1px #FFAE02 !important"
        }
        
    }
    
    
}))


export {Style};