import React from 'react'
import { DataGrid, frFR } from '@material-ui/data-grid';
import { Grid } from '@material-ui/core'


const TableData=({columns, rows, loading,onRowClick,onSelectionModelChange})=>{
    

      return (
        <Grid style={{ height: 400, width: '100%' }}>
             <DataGrid
                rows={rows}
                columns={columns}
                loading={loading}
                pageSize={15}
                checkboxSelection
                style={{borderTop:0,borderLeft:0,borderRight:0}}
                showCellRightBorder={false}
                showColumnRightBorder={false}
                onRowClick={onRowClick}
                onSelectionModelChange={onSelectionModelChange}
                localeText={frFR.props.MuiDataGrid.localeText}
              
                />
        </Grid>
      )
}

export default TableData;