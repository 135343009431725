import React,{useState,useEffect}  from  'react'
import {Button,CircularProgress,Grid,TextField,Typography,Box ,Collapse,IconButton} from '@material-ui/core'
import  {useForm,Controller} from 'react-hook-form'
import jwt_decode from "jwt-decode";
import {sendCodeAction} from '../../../store/actions/auth-actions'
import {useSelector,useDispatch} from 'react-redux'
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'




const UserProfile = (props)=>{

    const refresh = props.match.params.token
    const refresh_token =JSON.parse(atob(refresh.split('.')[1]))
    const now = Math.ceil(Date.now() / 1000);
    const token = jwt_decode(props.match.params.token)
    const { register,control ,handleSubmit,reset} = useForm();
    const {email}=JSON.parse(sessionStorage.getItem('data1'))
    const dispatch = useDispatch()
    const [ison, setison] = useState(false)
    const {message} = useSelector(state => state.msg)
    const [opens, setOpens] = React.useState(false);

    

    




    const onSubmit=(data,e)=>{
        e.preventDefault()
        if(data.fn && data.fn){
            setison(true)
            setOpens(false)
            sessionStorage.setItem('data2',JSON.stringify(data))
            dispatch(sendCodeAction(email,data.fn)).then(()=>{
                setison(false)
                props.handClick()
            },()=>{
                setison(false)
                setOpens(true)
            })

        }
    }




    useEffect(() => {
        if(refresh_token.exp>now){
            const data2 =JSON.parse(sessionStorage.getItem('data2'))
            if(data2){
                reset({
                org: token.organisation,
                fn: data2.fn,
                ln:data2.ln,
                phone:data2.phone,
                addr:data2.addr,
                fon:data2.fon,
                ville:data2.ville,
                prov: data2.prov
                })
            }else{
             reset({
                 org: token.organisation
             })
            }
 
        }
        
    }, [])
     return (
        <form onSubmit={handleSubmit(onSubmit)}>
        <Grid Container direction='row' spacing={3} >
        <Grid item xs={12} sm={12}>
            <Box height={20}/>
                <Collapse in={opens}>
                    <Alert
                      severity={'error'}
                      action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpens(false);    
                             }}>
                        <CloseIcon fontSize="inherit" />
                        </IconButton>}>
                    { message}
                    </Alert>
                </Collapse> 
        </Grid>
        <Box height={20}/>
        <Grid item xs={12} sm={12}>
        <Typography>Nom</Typography>
        <TextField 
            type='text'
            inputProps={{maxLength:20}} 
            helperText='Nom doit avoir 20 caractères au plus'
            
            required
            variant='outlined' 
           fullWidth 
            size='small'
            {...register('fn',{required:true})}
        />
        </Grid>
        <br/>
        <Grid item xs={12} sm={12}>
        <Typography>Prénom</Typography>
        <TextField 
             type='text'
             inputProps={{maxLength:20}} 
             helperText='Prénom doit avoir 20 caractères au plus'
             
            required
            variant='outlined' 
           fullWidth 
            size='small'
            {...register('ln',{required:true})}
        />
        </Grid>
        <br/>
        <Grid item xs={12} sm={12}>
        <Typography>Numéro de téléphone</Typography>
        <Controller
            name='phone'
            control={control}
            render={
            ({
                field: { onChange, onBlur, value, name }
            }) => (
                <PhoneInput
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name= "phone"
                    id = 'phone'
                    inputProps={{
                        "aria-invalid":false,
                        required: "true",
                      }}
                    disableInitialCountryGuess={true}
                    containerClass="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl "
                    buttonClass=""
                    specialLabel=""
                    inputStyle={{
                    fontSize: "0.8rem",
                    width: "100%",
                    backgroundColor:'transparent',
                    }}
                    placeholder='Entrez votre numéro'
                    country= 'cd'
                />
            )
            }
        />
        </Grid>
        <br/>
        <Grid item xs={12} sm={12}>
        <Typography>Adresse</Typography>
        <TextField 
            required
             type='text'
             inputProps={{maxLength:20}} 
             helperText='Adresse doit avoir 20 caractères'
             

            variant='outlined' 
           fullWidth 
            size='small'
            {...register('addr',{required:true})}
        />
        </Grid>
        <br/>
        <Grid item xs={12} sm={12}>
        <Typography>Organisation</Typography>
        <TextField 
            disabled
            required
             type='text'
             inputProps={{maxLength:20}} 
             helperText='Organisation doit avoir 20 caractères'
             
            variant='outlined' 
           fullWidth 
            size='small'
            {...register('org',{required:true})}
        />
        </Grid>
        <br/>
        <Grid item xs={12} sm={12}>
        <Typography>Fonction</Typography>
        <TextField 
            required
             type='text'
             inputProps={{maxLength:20}} 
             helperText='Fonction doit avoir 20 caractères au plus'
             
            variant='outlined' 
           fullWidth 
            size='small'
            {...register('fon',{required:true})}
        />
        </Grid>
        <br/>
        <Grid item xs={12} sm={12}>
        <Typography>Ville & Territoire</Typography>
        <TextField 
            required
             type='text'
             inputProps={{maxLength:20}} 
             helperText='Ville et Territoire doivent avoir 20 caractères au plus'
             
            variant='outlined' 
           fullWidth 
            size='small'
            {...register('ville',{required:true})}
        />
        </Grid>
        <br/>
        <Grid item xs={12} sm={12}>
        <Typography>Province</Typography>
        <TextField 
            required
             type='text'
             inputProps={{maxLength:20}} 
             helperText='Province doit avoir 20 caractères'
             
            variant='outlined' 
           fullWidth 
            size='small'
            {...register('prov',{required:true})}
        />
        </Grid>
        <br/>
        <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container justifyContent='flex-end' spacing={2}>
                <Grid item>
                   <Button disabled={ison===true?true:false} onClick={props.handleBack} color='secondary' variant='text'>Précédent</Button>
                </Grid>
                <Grid item>
                   {
                       ison ===true?<CircularProgress size={30}/>:<Button type='submit' color='primary' variant='outlined'>Suivant</Button>
                   }
                </Grid>
            </Grid>
        </Grid>
        
    </Grid>    
  </form>
     )
}

export default UserProfile