import { makeStyles } from "@material-ui/core/styles";

const Style = makeStyles((theme) => ({
  root: {
    marginTop:80,
    color: "#1D2F3F",
    '& p': {
      textAlign:theme.typography.textBodyAlign
    },
    [theme.breakpoints.down("md")]:{
      padding:'3em 0em',
    },
    [theme.breakpoints.up("md")]:{
      padding:'6em 0em',
    },
    "& h5": {
      fontWeight: "700",
      marginBottom:"1em"
    },
    "& a": {
      textDecoration: "none",
      color: "#EB9B29",
    },
    "& dt": {
      fontWeight: theme.typography.fontWeightMedium
    }
  },
}));

export default Style;
