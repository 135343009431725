//Packages & Dependecies
import React ,{useEffect,useRef,useState} from "react";
import { useForm , Controller} from "react-hook-form";
import { Typography, Grid, TextField, Button,IconButton,Collapse, Box } from "@material-ui/core";
import PhoneInput from 'react-phone-input-2'
import ReCAPTCHA from "react-google-recaptcha";
import 'react-phone-input-2/lib/material.css'
import {contactActions} from '../../../../store/actions/auth-actions'
import { useSelector,useDispatch } from "react-redux";
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Style } from "./style";
import SEO from "../../sc/seo"






const Contactes_nous = () => {

  const classes = Style();
  const reRef = useRef()
  const dispatch = useDispatch()
  const [isCircular, setisCircular] = useState(false)
  const {success} = useSelector((state)=>state.contact.contact)??''
  const msg = useSelector((state)=>state.msg.message)
  const [open, setOpen] = React.useState(false);
   
 

  const { register, handleSubmit, control,reset } = useForm();

  const onSubmit = async(data,e) => {
    setOpen(false)
    e.preventDefault()
    setisCircular(true)
    const token = await reRef.current.executeAsync();
    reRef.current.reset()
    if(token!=''){
      dispatch(contactActions(
        data.nom,
        data.prenom,
        data.email,
        data.phon,
        data.msg,
        token
      )).then(()=>{
        setOpen(true)
        setisCircular(false)
        reset()
      },()=>{
        setOpen(true)
        setisCircular(false)
      })
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])

  return (
    <React.Fragment>
      <SEO
        title="Contactez-nous"
        keywords="Contactez-nous"
      />
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <Grid container className={classes.Root} justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <Typography variant="h1">
                CONTACTEZ-NOUS
              </Typography>
              <Typography variant="body1">
                Chèr(e)s visiteurs, si vous souhaitez de plus amples informations sur une
                thématique spécifique, contactez-nous et introduisez votre demande dans l’espace
                « Message ». Nous vous répondrons dès que possible. Merci
              </Typography>
            </Grid>

            <Grid item md={5} className={classes.formcontainer} >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container justifyContent="space-between" spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Collapse in={open}>
                      <Alert
                        severity={msg ? 'error' : 'success'}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {msg ? msg : success}
                      </Alert>
                    </Collapse>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>Nom</Typography>
                    <TextField inputProps={{ maxLength: 50 }} placeholder='Entre votre nom' required fullWidth type='text' variant="outlined" {...register('nom', { required: true })} /></Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>Prenom</Typography>
                    <TextField inputProps={{ maxLength: 50 }} placeholder='Entre votre nom' required fullWidth type='text' variant="outlined" {...register('prenom', { required: true })} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>E-mail</Typography>
                    <TextField fullWidth placeholder='Entre votre e-mail' type='email' required variant="outlined" {...register('email', { required: true })} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>Phone</Typography>
                    <Controller
                      name='phon'
                      control={control}
                      render={
                        ({
                          field: { onChange, onBlur, value, name }
                        }) => (
                            <PhoneInput

                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              name="phon"
                              id='phon'

                              inputProps={{
                                "aria-invalid": false,
                                required: "true",
                              }}
                              disableInitialCountryGuess={true}
                              autoComplete='none'
                              inputClass={`MuiInputBase-input MuiInputBase-phone ${classes.phone}`}
                              containerClass="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl "
                              buttonClass=""
                              specialLabel=""
                              inputStyle={{
                                fontSize: "0.8rem",
                                width: "100%",
                                backgroundColor: 'transparent',
                              }}
                              placeholder='Entrez votre numéro'
                              country='cd'
                            />
                          )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography>Message</Typography>
                    <TextField required {...register('msg', { required: true })} inputProps={{ maxLength: 1000 }} placeholder='Entre votre message' multiline rows={8} fullWidth type='text' variant="outlined" />
                  </Grid>
                </Grid>
                <Grid container>
                  <Button
                    variant="contained"
                    className={classes.Button}
                    disabled={isCircular === true ? true : false}

                    disableElevation
                    fullWidth
                    type="submit"
                  >
                    {isCircular === true ? <CircularProgress size={30}
                      style={{ color: 'white' }} /> : <Typography>envoyer</Typography>}
                  </Button>
                </Grid>
                <Grid item>
                  <ReCAPTCHA
                    ref={reRef}
                    size="invisible"
                    sitekey={process.env.REACT_APP_SITE_KEY}
                  />
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
    };
    
    export default Contactes_nous;
