import React, { useState,useEffect,useRef }from 'react';
import { Link } from 'react-router-dom'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Image from '../../assets/images/Image.webp'
import { Grid, Typography,Box } from '@material-ui/core'
import Style from './style'
import ShareIcon from '@material-ui/icons/ShareOutlined';
import IconButton from '@material-ui/core/IconButton';
import ShareComp from '../../widgets/shareComp'
import {getActualiteByIdPub} from '../../../../store/actions/actualite-actions'
import { useSelector,useDispatch } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import ReactHtmlParser from 'react-html-parser';
import SEO from '../../sc/seo'
import { useParams } from 'react-router-dom';





const Article = () => {

    const classes = Style();
    const dispatch = useDispatch()
    const [shareOpen, setShareOpen] = useState(false)
    const {actualitebyId} = useSelector(state => state.actualitePub)
    const actualiteRef = useRef({})
    actualiteRef.current = actualitebyId
    const Params = useParams()
    const shareHandle = () => {
        setShareOpen(!shareOpen)
    }

    
    useEffect(() => {
        
        if (!sessionStorage.getItem('rowid')) {
            sessionStorage.setItem('rowid',Params.id)
        }
        if(sessionStorage.getItem('rowid')){
            dispatch(getActualiteByIdPub(sessionStorage.getItem('rowid')))
       }
        window.scrollTo(0, 0)
        return () => {
            sessionStorage.clear('rowid')
       }
      }, [])

    
    if (actualiteRef.current !== undefined) {
        return (
            <React.Fragment>
                <SEO
                    title="L'Article"
                    keywords="L'Article"
                />
                <Grid container className={classes.root} justifyContent="center">
                    <Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
                        {actualiteRef.current.cover_image && <img src={actualiteRef.current.cover_image} alt={"Article"} width="100%" />}
                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={8} xl={8} className={classes.head}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                            {actualiteRef.current.title}
                        </Typography>

                        <Typography variant='caption'>
                            {actualiteRef.current.created_date}
                        </Typography>

                        <IconButton
                            aria-label="share"
                            onClick={shareHandle}
                            className={classes.share}
                        >
                            <ShareIcon />
                        </IconButton>



                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
                        <Typography variant="body1" className={classes.body}>
                            {ReactHtmlParser(actualiteRef.current.description)}
                        </Typography>


                        <Link className={classes.link}>
                            {actualiteRef.current.document === null ? null :
                                <Grid container spacing={2}>
                                    <CloudDownloadIcon />
                                    <Typography variant="body1">
                                        Téléchargez le document
                            </Typography>


                                </Grid>
                            }
                        </Link>

                        <ShareComp
                            open={shareOpen}
                            handleClose={shareHandle}
                            value={window.location.href}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
      }else{
        return (
            <React.Fragment>
                <SEO
                    title="L'Article"
                    keywords="L'Article"
                />
                <Grid container className={classes.root} justifyContent="center">
                    <Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
                        <Skeleton variant='rect' width='100%' height={300} />
                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={8} xl={8} className={classes.head}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                            <Skeleton variant='rect' width='100%' height={20} />

                        </Typography>

                        <Typography variant='caption'>
                            <Skeleton variant='rect' width='40%' height={20} />

                        </Typography>
                        <br />
                        <Skeleton variant='rect' width='10%' height={30} />




                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
                        <Typography variant="body1" className={classes.body}>
                            <Skeleton variant='rect' width='100%' height={300} />

                        </Typography>


                        <Link className={classes.link}>
                            <Skeleton variant='rect' width='40%' height={10} />

                        </Link>

                        <ShareComp
                            open={shareOpen}
                            handleClose={shareHandle}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
                     
              }
            
        
                   
                
              
            
        
           
        };
        
export default Article;