import React, {useEffect } from "react";
import Style from "./style";
import {Grid,List, ListItem, Typography}  from  '@material-ui/core'
import { NavLink } from "react-router-dom";
import SEO from '../../sc/seo'


const SiteMap =()=>{

    const classes = Style();
    useEffect(() => {
        window.scrollTo(0, 0)
    
      }, [])

  return (
    <React.Fragment>
      <SEO
        title="Site map"
        keywords="Site map"
      />
      <Grid container direction='row' className={classes.root} spacing={4}>
        <Grid item xs={6} s={6} md={4} lg={4} xl={4}>
          <List >
            <ListItem


              className={classes.margin_botton_1}
            >
              <Typography variant="body1" className={classes.list_title}>
                Les pages principales
                  </Typography>
            </ListItem>
            <ListItem

            >
              <NavLink to="/" className={classes.link_style}>
                <Typography variant="body1">Accueil</Typography>
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/Actualite" className={classes.link_style}>
                <Typography variant="body1">
                  Actualités et Agenda
                    </Typography>
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/Video" className={classes.link_style}>
                <Typography variant="body1">Videos</Typography>
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/Clec" className={classes.link_style}>
                <Typography variant="body1">CLEC</Typography>
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/Members" className={classes.link_style}>
                <Typography variant="body1">Membres</Typography>
              </NavLink>
            </ListItem>

          </List>
        </Grid>

        <Grid item xs={6} s={6} md={4} lg={4} xl={4}>
          <List >
            <ListItem



              className={classes.margin_botton_1}
            >
              <Typography variant="body1" className={classes.list_title}>
                Les 4 pôles
                  </Typography>
            </ListItem>
            <ListItem>
              <NavLink to="/Offre" className={classes.link_style}>
                <Typography variant="body1">L’offre</Typography>
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/Demande" className={classes.link_style}>
                <Typography variant="body1">La demande</Typography>
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/Insertion" className={classes.link_style}>
                <Typography variant="body1">L’insertion</Typography>
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/Regulation" className={classes.link_style}>
                <Typography variant="body1">La regulation</Typography>
              </NavLink>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} s={6} md={4} lg={4} xl={4}>
          <List>
            <ListItem
              className={classes.margin_botton_1}
            >
              <Typography variant="body1" className={classes.list_title}>
                Autres pages
                  </Typography>
            </ListItem>
            <ListItem>
              <NavLink className={classes.link_style} to="/Sommes_nous">
                <Typography variant="body1">Qui sommes-nous?</Typography>
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink className={classes.link_style} to="/Contactez_nous">
                <Typography variant="body1">Contactez-nous</Typography>
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/Mention_legale" className={classes.link_style}>
                <Typography variant="body1">Mentions légales</Typography>
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/Sitemap" className={classes.link_style}>
                <Typography variant="body1">Plan du site</Typography>
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/Ccc" className={classes.link_style}>
                <Typography variant="body1">CCC</Typography>
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/Paternaires" className={classes.link_style}>
                <Typography variant="body1">Paternaires</Typography>
              </NavLink>
            </ListItem>
            <ListItem>
              <a href={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/login'} className={classes.link_style}>
                <Typography variant="body1">Espace membres</Typography>
              </a>
            </ListItem>
          </List>
        </Grid>

      </Grid>
    </React.Fragment>
    )
  }
  
export default SiteMap