import React,{useEffect,useRef} from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import Table from '../../widgets/table';
import Style from './style'
import SortIcon from '@material-ui/icons/Sort';
import TabBar from '../../widgets/tabbar';
import { useSelector } from 'react-redux';
import SEO from '../../sc/seo'






const Members =()=>{

    const classes = Style();
    const {membres} = useSelector(state => state.membrePub)
    const membreRef = useRef([])
    membreRef.current = membres
    const columns = [
        {
            field:   `organisation`,
            headerName: `INSTITUTION`,
            headerClassName: classes.tableHead,
            width:200
        },
        {
            field:   `sigle`,
            headerName: `SIGLE`,
            width: 200,
          }, 
        {
            field:   `email`,
            headerName: `EMAIL`,
            headerClassName: classes.tableHead,
            width:300
        },
        {
            field:   `phonenumber`,
            headerName: `NUMERO DE TELEPHONE`,
            headerClassName: classes.tableHead,
            width:300
        },
        {
            field:   `address`,
            headerName: `ADRESSE`,
            headerClassName: classes.tableHead,
            width:300
        },
    ]


    const Panel = (panelTitle, data) => (
        <Grid container>
            <Grid item xs = {12}>
                <Grid container justifyContent="space-between" className={classes.sectionTitle}>
                    <Typography variant="h5">
                        {panelTitle}
                    </Typography>
                    <Button>
                        <SortIcon />
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs = {12} >
                <Table
                    columns={columns}
                    rows={data}
                />
            </Grid>
        </Grid>
    )
    const tabs = [
            {
                tabtile:"TOUT",
                tabPanel: Panel("Membres",  [])
            },
            {
                tabtile:"L'OFFRE", 
                tabPanel: Panel("Membres", [])
            },
            {
                tabtile:"LA DEMANDE",
                tabPanel: Panel("Membres", [])
            },
            {
                tabtile:"L'INSERTION",
                tabPanel: Panel("Membres", [])
            },
            {
                tabtile:"LA REGULATION",
                tabPanel: Panel("Membres", [])
            },
            ]

    
    
        useEffect(() => {
            window.scrollTo(0, 0)
        
            }, [])

    
    if(membreRef.current!== undefined){

        return (
            <React.Fragment>
                <SEO
                    title="Membres"
                    keywords="Membres"
                />
                <Grid container className={classes.root} justifyContent="center">
                    <Grid item className={classes.pageHead} xs={10} md={8}>
                        <Typography variant='h2'>
                            NOS MEMBRES
                    </Typography>
                        <Typography variant='body1'>
                            Les membres du CEC-FPI/SK sont ceux qui ont adhéré conformément aux règlements d’ordre intérieur du Cadre et à l’arrêté l’instituant. Il s’agit des personnes morales basées au Sud-Kivu qui sont représentées au niveau de la synergie, par des responsables desdites structures et sont repartis selon les quatre pôles de la formation professionnelle selon leurs objectifs et domaines d’intervention.
                        <br />
                            Ils sont actuellement au nombre de 57 membres et le CEC prévoit les limiter actuellement à 100 membres tout au plus.
                        <br />
                            A ce niveau, il faille souligner que les CLEC sont considérés comme des membres à part entière du CEC-FPI.
                        <br />
                            Voici une liste exhaustive desdits membres. Les portes du cadre restent néanmoins ouvertes pour tous ceux-là qui peuvent apporter un plus et qui interviennent dans le secteur de la formation professionnelle.
        
                    </Typography>
                    </Grid>
                    <Grid item className={classes.tab} xs={10}>
                        <TabBar
                            Tabs={
                                [
                                    {
                                        tabtile:"TOUT",
                                        tabPanel: Panel("Membres",  membreRef.current.filter((e) => e.display ===true))
                                    },
                                    {
                                        tabtile:"L'OFFRE", 
                                        tabPanel: Panel("Membres", membreRef.current.filter((e)=>e.pole ==="L'offre" && e.display ===true))
                                    },
                                    {
                                        tabtile:"LA DEMANDE",
                                        tabPanel: Panel("Membres", membreRef.current.filter((e)=>e.pole ==="La demande" && e.display ===true))
                                    },
                                    {
                                        tabtile:"L'INSERTION",
                                        tabPanel: Panel("Membres", membreRef.current.filter((e)=>e.pole ==="L'insertion" && e.display ===true))
                                    },
                                    {
                                        tabtile:"LA REGULATION",
                                        tabPanel: Panel("Membres",membreRef.current.filter((e)=>e.pole ==="La regulation" && e.display ===true))
                                    },
                                    ]
                            }
                            tabConfig={{
                                xs: 12,
                                md: 10,
                                xl: 6
                            }}
                            panelConfig={{
                                xs: 12,
    
                            }}
                        />
                    </Grid>
    
                </Grid>
            </React.Fragment>
        )
    }else{
        return (
            <React.Fragment>
                <Grid container className={classes.root} justifyContent="center">
                    <Grid item className={classes.pageHead} xs={10} md={8}>
                        <Typography variant='h2'>
                            NOS MEMBRES
                    </Typography>
                        <Typography variant='body1'>
                            Les membres du CEC-FPI/SK sont ceux qui ont adhéré conformément aux règlements d’ordre intérieur du Cadre et à l’arrêté l’instituant. Il s’agit des personnes morales basées au Sud-Kivu qui sont représentées au niveau de la synergie, par des responsables desdites structures et sont repartis selon les quatre pôles de la formation professionnelle selon leurs objectifs et domaines d’intervention.
                        <br />
                            Ils sont actuellement au nombre de 57 membres et le CEC prévoit les limiter actuellement à 100 membres tout au plus.
                        <br />
                            A ce niveau, il faille souligner que les CLEC sont considérés comme des membres à part entière du CEC-FPI.
                        <br />
                            Voici une liste exhaustive desdits membres. Les portes du cadre restent néanmoins ouvertes pour tous ceux-là qui peuvent apporter un plus et qui interviennent dans le secteur de la formation professionnelle.
        
                    </Typography>
                    </Grid>
                    <Grid item className={classes.tab} xs={10}>
                        <TabBar
                            Tabs={tabs}
                            tabConfig={{
                                xs: 12,
                                md: 10,
                                xl: 6
                            }}
                            panelConfig={{
                                xs: 12,
    
                            }}
                        />
                    </Grid>
    
                </Grid>
            </React.Fragment>
        )
    }
   
}

export default Members