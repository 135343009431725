import { makeStyles } from "@material-ui/core/styles";

const Style = makeStyles((theme) => ({
  root: {
    marginTop:80,
    color: "#1D2F3F",
    marginBottom: "10vw",
    '& p': {
      textAlign:theme.typography.textBodyAlign
    },
    '& h4': {
      marginBottom:"0.5em"
    }
  },

  cecFpiContainer: {
    background: "#FAEBEB",
    margin: "5vw 0vw",
    padding:"5vw 0vw",
    "& h1": {
      marginBottom: "1vw",
      fontSize:"3.5rem"
    }
  
  },

  paragraphContainer:{
    margin:"4vw 0vw"
  },

  section: {
    background: "#FFEDC6",
    "& h3": {
      fontWeight: "600",
      fontSize:"2rem",
      margin:"1.2vw 0vw"
    },
    "& h2": {
      fontWeight: "700",
      textAlign: "center",
      fontSize:"2.75rem"
    },
    "& img": {
      margin: "4vw 0vw",
      height: `${(70 * 9) / 16}vw`,
    }
  }

}));

export default Style;
