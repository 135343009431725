import React,{useState,useEffect,useRef} from 'react'
import TableData from '../../../sc/table'
import {Grid,Typography,Tab,Tabs,IconButton,Paper, Box ,Collapse} from '@material-ui/core'
import PropTypes from "prop-types";
import useStyles from './style'
import SortIcon from '@material-ui/icons/Sort';
import {Print} from '@material-ui/icons';
import { BtnOrange} from '../../../sc/utilities';
import { useHistory } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {useSelector,useDispatch} from 'react-redux'
import {getMembres,deleteMember} from '../../../../../store/actions/membre-actions'
import DialogBox from '../../../sc/dialog';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Receipt} from "./widget/print";
import { useReactToPrint } from 'react-to-print';



const columns = [
  {
      field:   `first_name`,
      headerName: `NOM`,
      width: 180,
  },
  {
      field:   `last_name`,
      headerName: `PRENOM`,
      width: 250,
  },
  {
      field:   `email`,
      headerName: `E-MAIL`,
      width: 230,
  },
  {
      field:   `pole`,
      headerName: `POLE`,
      width: 150,
  },
  {
      field:   `fonction`,
      headerName: `FONCTION`,
      width: 230,
  },
  {
    field:   `link`,
    headerName: `LIEN`,
    width: 230,
  },
{
  field:   `phonenumber`,
  headerName: `NUMERO DE TELEPHONE`,
  width: 230,
},
{
  field:   `organisation`,
  headerName: `INSTITUTION`,
  width: 230,
}, 
{
  field:   `sigle`,
  headerName: `SIGLE`,
  width: 230,
}, 
]



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        
          <Typography>{children}</Typography>
       
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};


const Member =()=>{
  const history = useHistory()
  const classes = useStyles()
  const [value, setValue] = React.useState(0);
  const {membres} = useSelector((state)=>state.membre)
  const delmembre=useSelector((state)=>state.membre.deleteMembre)
  const [rowId, setrowId] = useState([])
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const [isDelete, setisDelete] = useState(false)
  const [isDel, setisDel] = useState(false)
  const [opens, setOpens] = React.useState(false);
  const [er, seter] = useState(false)
  const [email, setemail] = useState('')
  const dispatch = useDispatch()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const msg = useSelector((state)=>state.msg.message)
  const [currentUser, setcurrentUser] = useState('')


  const componentRef = useRef();
  const handlePrint = useReactToPrint(
        {
        content: () => componentRef.current,
        documentTitle:`Membres ${new Date().getDay()}-${new Date().getMonth()}-${new Date().getFullYear()}`,
        onAfterPrint:()=>{
           
        }
       
    });


  const handleClose = () => {
        setOpen(false);
      };
    // const handleClickOpen = () => {
    //    setOpen(true);
    //  };
    
    


    const handleDelete =()=>{
       
        if(rowId.length!==0 && isDelete===true){
        setisDel(true)
        dispatch(deleteMember(rowId)).then(()=>{
            dispatch(getMembres())
            setisDel(false)
            setOpens(true)
            handleClose()

        },()=>{
           setOpens(true)
            setisDel(false)
            handleClose()
            seter(true)
        })


        }
    }

    const handleRowSelected =(rowId)=>{

        if(rowId===0){
            setrowId()
        }

        setrowId(rowId)
      
       
    }



  const handleRowClick=(event)=>{
    history.push({
      pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/membre/detail/${event.row.first_name}=${event.id}`,
     
    })
    sessionStorage.setItem('rowId',event.id)
}
  


 

 const tablabel=[
   {
      title:'MEMBRES ACTIFS',
       num :membres!==undefined?membres.filter((e)=>e.display===true).length:null ,
      css: {backgroundColor:'#F2D006',color:'white', padding:'3px', margin:'5px' ,fontWeight:300}
    },
    {
    title:'MEMBRES INACTIFS',
    num : membres!==undefined?membres.filter((e)=>e.display===false).length:null,
    css: {backgroundColor:'#F1545B',color:'white', padding:'3px', margin:'5px' ,fontWeight:300}
  },
]
  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("value",0)
  };
  const handleCreate =()=>{
    history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/membre/create`)
  }

  const  getUserRole =()=>{
    const role  = sessionStorage.getItem('role')
    const user =sessionStorage.getItem('user')
    setcurrentUser(role)
    setemail(user)
}
useEffect(() => {
   getUserRole()
}, [])
    return (
        <Grid container>
        <Grid item xs={12} sm={12}>
            <Paper elevation={0} className={classes.backPaper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Tabs
                          disableRipple
                          value={value}
                          onChange={handleChange}
                          TabIndicatorProps={{ className: classes.indicatedColor }}
                          variant="fullWidth"
                          style={{color:'black'}}
                          scrollButtons="on" >
                            {tablabel.map(tab => (
                              <Tab
                              key={tab}
                              label={<Typography className={classes.tab}>{tab.title}<span style={tab.css}>{tab.num}</span></Typography>} />))}
                      </Tabs> 
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container justify='flex-end' alignItems='flex-end'>
                            <Grid item>
                            {currentUser<=2?<Grid container direction='row' spacing={3}>
                                <Grid item><BtnOrange onClick={handleCreate} btnYellowTitle="CRÉER" startIcon={<AddCircleOutlineIcon style={{color:'white',fontSize:14}}/>}/></Grid>
                            </Grid>:null}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box height={20}/>
                        <Collapse in={opens}>
                            <Alert
                            severity={er?'error':'success'}
                            action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpens(false);
                                    seter(true)
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            >
                            {msg!==null?msg: delmembre}
                            </Alert>
                        </Collapse>
                <Box height={20}/>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Grid container justify='flex-start' alignItems='flex-start'>
                                    <Grid item>
                                        <Typography className={classes.backPaperTitle}>Membres</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Grid container justify='flex-end' alignItems='flex-end'>
                                {/* <Grid item>
                                        <IconButton onClick={()=>handlePrint()}><Print/></IconButton>
                                </Grid> */}
                                <Grid item>
                                        <IconButton ><SortIcon/></IconButton>
                                </Grid>
                                
                            </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TabPanel value={value} index={0}>
                        <TableData
                                columns={columns}
                                rows={membres!==undefined?membres.filter((e)=>e.display===true):[]}
                                onRowClick ={handleRowClick} 
                                onSelectionModelChange={(selectedRow)=>handleRowSelected(selectedRow)}

                            />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <TableData
                                  columns={columns}
                                  rows={membres!==undefined?membres.filter((e)=>e.display===false):[]}
                                  onRowClick ={handleRowClick} 
                                  onSelectionModelChange={(selectedRow)=>handleRowSelected(selectedRow)}
                              />
                        </TabPanel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "none" ,marginTop:50}}>
                        <Receipt ref={componentRef} email={email} membre={membres!==undefined?membres.filter((e)=>e.display===true):[]} />
                    </Grid>
                </Grid>
            </Paper>
            <DialogBox
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    //title= {`${userbyId.first_name}`}
                    Oui={isDel===true?(<CircularProgress size={10}/>):'Oui'}
                    confirnClick={handleDelete}
                    nonOnclick={handleClose}
                    content='Etês-vous sûr de vouloir supprimer ce membre'
                />
        </Grid>
    </Grid>
      
    )
}

export default Member



                