
import {
    FETCH_PROFILE_SUCCES,
    FETCH_PROFILE_FAIL,
    FETCH_USERS_BY_ID_SUCCES,
    FETCH_USERS_BY_ID_FAIL,
    DELETE_USER_BY_ID_SUCCES,
    DELETE_USER_BY_ID_FAIL,
    UPDATE_USER_SUCCES,
    UPDATE_USER_FAIL,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAIL,
    CLEAR_DATA
  } from "../contants/type";



  const initialState = {
      profiles :[]
  }

  const initialStateUserById = {
      userbyId:[]
  }

  const getUsersReducers =(state=initialState,action)=>{
      const {type,payload} = action

      switch(type){
          case FETCH_PROFILE_SUCCES:
              return {
                  ...state,
                 profiles:payload
              }
           case FETCH_PROFILE_FAIL:
               return {
                   ...state,
               }
           default:
               return state

      }
  }

  const getUserByIdReducer = (state=initialStateUserById,action)=>{
      const {type,payload} = action
      switch(type){
        case FETCH_USERS_BY_ID_SUCCES:
            return {
                ...state,
                userbyId:payload
            }
         case FETCH_USERS_BY_ID_FAIL:
             return {
                 ...state,
             }
        case CLEAR_DATA:
        return {}
         default:
             return state

    }
  }
const getDeleteUser = (state = initialState ,action)=>{
    const {type,payload} =action
    switch(type){
        case DELETE_USER_BY_ID_SUCCES:

            return {
                ...state,
                deleteuser:payload

            }
        case DELETE_USER_BY_ID_FAIL:
                return {
                    ...state
                }
        default:
            return {
                ...state
            }
    }

}

const updateUserReducer = (state = initialState,action)=>{
    const {type,payload} = action

    switch(type){


        case UPDATE_USER_SUCCES:
               return {
                   ...state,
                   updateUser:payload
               }

           case UPDATE_USER_FAIL:
               return {
                   ...state
               }
           default:
               return state
    }
} 


const userProfileReducer = (state = [],action)=>{
    const {type,payload} = action

    switch(type){


        case USER_PROFILE_SUCCESS:
               return {
                   ...state,
                    profile:payload
               }

           case USER_PROFILE_FAIL:
               return {
                   ...state
               }
           default:
               return state
    }
} 


const UserReducers = {
    getUsersReducers,
    getUserByIdReducer,
    getDeleteUser,
    updateUserReducer,
    userProfileReducer
}

  export default UserReducers