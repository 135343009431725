import React,{useState,useEffect} from  'react'
import {Grid,Typography,TextField,MenuItem,Select,Button,Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions,Collapse,IconButton} from '@material-ui/core'
import {useForm} from 'react-hook-form'
import axiosInstance from '../../../../../../service/axiosApi'
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';





const SendInvitation = ({fullScreen,open,nonComfirm})=>{
    const [role, setrole] = useState("")
    const [opens, setOpens] = React.useState(false);
    const [isOn, setisOn] = useState()
    const [msg, setmsg] = useState('')
    const [err, seterr] = useState(false)
    
    const handleRole = (e)=>{
        const value  =  e.target.value
        setrole(value)
    }
    const {handleSubmit,register,reset,formState:{errors}} = useForm()

    const onSubmit =(data,e)=>{
        e.preventDefault()
        if(data && role){
          handEmamil(
                data.nom,
                data.email,
                role,
                data.org
            )
        }
    }

    const handEmamil = async(fullname,email,role,organisation)=>{
        setisOn(true)
        await axiosInstance.post('api/account/sendInvitation/',{
            fullname,email,role,organisation
        }).then((res)=>{
            seterr(false)
            setOpens(true)
            setisOn(false)
            setmsg(res.data.message)
            reset()
            setrole()

        }).catch(()=>{
            setisOn(false)
            setmsg('E-mail envoié a echoué, veuillez vérifier votre connexion')
            seterr(true)
            setOpens(true)
        })
    }
    useEffect(() => {
       
    }, [axiosInstance])
    return (
       
        <Dialog
            fullScreen={fullScreen}
            open={open}
            aria-labelledby="responsive-dialog-title" >
            <DialogTitle id="responsive-dialog-title">Création d'utilisateur</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <DialogContentText>
                <Grid container direction= 'column' justifyContent='center' spacing={2}>
                    <Grid item>
                    <Collapse in={opens}>
                    <Alert
                    severity={err?'error':'success'}
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpens(false);
                            seterr(true)
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    >
                    { msg}
                    </Alert>
                </Collapse>
                    </Grid>
                     <Grid item>
                         <Typography>Nom complet</Typography>
                         <TextField {...register('nom',{required:true})} fullWidth variant='outlined' size='small'  placeholder="Entre le nom" required />
                     </Grid>
                     <Grid item>
                         <Typography>E-mail</Typography>
                         <TextField error={errors.email?true:false}
                                    helperText={errors.email?'write a valid email':null} 
                                    {...register('email',{required:true,pattern:/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/})} fullWidth variant='outlined' size='small'  placeholder="Entrez l'adresse e-mail" required />
                     </Grid>
                     <Grid item>
                         <Typography>Rôle</Typography>
                         <Select
                            required
                            labelId="demo-simple-select-helper-label"
                            variant="outlined"
                            id="demo-simple-select-helper"
                            size="small"
                            fullWidth
                            value={role}
                            onClick={handleRole}
                            >
                            <MenuItem value={"1"}>Administrateur</MenuItem>
                            <MenuItem value={"2"}>Secrétaire</MenuItem>
                            <MenuItem value={"3"}>Auteur</MenuItem>
                            <MenuItem value={"4"}>Contributeur</MenuItem>
                            <MenuItem value={"5"}>Membre</MenuItem>
                        </Select>
                     </Grid>
                     <Grid item>
                         <Typography>Organisation</Typography>                          
                         <TextField {...register('org',{required:true})} fullWidth variant='outlined' size='small' required placeholder="Entrez l'organisation" />
                     </Grid>
                    
                 </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button 
                onClick={nonComfirm}
                style={{color:'#009688',fontWeight:300}}
                 size='small'>Annuler
            </Button>
            <Button 
                type= 'submit'
                style={{backgroundColor:'green',color:'white',fontWeight:300}}
                 size='small'>
                {isOn===true?<CircularProgress size = {10}/>:'ENVOYER' }
            </Button>
            </DialogActions>
        </form>
        </Dialog>
       
       
    )
}

export default SendInvitation
