import React,{useState,useEffect} from 'react'
import {Button, Box,Select,MenuItem,Grid,Collapse,IconButton,TextField, Typography} from '@material-ui/core'
import useStyles from '../style'
import CloudUpload from '@material-ui/icons/CloudUpload'
import { useSelector,useDispatch } from 'react-redux';
import  {useForm} from 'react-hook-form'
import CircularProgress from '@material-ui/core/CircularProgress';
import {getActualite,getActualiteById,addActualite,updateActualite,updateActualiteWithNoDoc,updateActualiteWithNoImageAndDoc,updateActualiteWithNoImage} from '../../../../../../store/actions/actualite-actions'
import { useHistory } from 'react-router';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import Image from '../../../../../website/assets/images/Image.webp'

// import CloudUploadIcon from '@mui/icons-material/CloudUpload';


const ActualiteCreate=(props)=>{
    const classes = useStyles()
    const dispatch  = useDispatch()
    const history  = useHistory()
    const {register,handleSubmit,reset} = useForm()
    const [email, setemail] = useState('')
    const [isCircular, setisCircular] = useState(false)
    const {actualitebyId} = useSelector((state)=>state.actualite)
    const [changeCategory, setchangeCategory] = useState()
    const [errmsg, seterrmsg] = useState("Les formats compatibles sont : .jpg, jpeg, png. Taille Maximale: 1.5 MB.")
    const [img, setimg] = useState('')
    const [selectedImage, setselectedImage] = useState(null)
    const [errmsgdoc, seterrmsgdoc] = useState("Les formats compatibles sont : .pdf, .docx, .txt. Taille Maximale: 1.5 MB.")
    const [doc, setdoc] = useState('')
    const [updaedDoc, setupdaedDoc] = useState()
    const [addData, setaddData] = useState("")
    const [open, setOpen] = React.useState(false);
    const msg = useSelector((state)=>state.msg.message)
    const {message} = useSelector((state)=>state.actualite.addedactualite)??''




    var formats = [
    'font',
    'size',
    'bold', 'italic', 'underline',
    'header',
    'list', 'bullet',
    'align',
    'color', 'background',
    'link','video',
    'blockquote','script','indent'
    ];
    const handleChange=(editor)=>{

        const data  = editor;
        setaddData(data)
    }
   const  handleDoc = (e)=>{
    const value =e.target.files[0]
    const limit =2639815;

    if(value!=null){
     

        if(!value.name.match(/\.(pdf|docx)$/)){

            seterrmsgdoc("Fichier no supporté!")
        }else if(value.name.match(/\.(pdf|docx)$/)&& value.size >limit){
            seterrmsgdoc('Le fichier est trop large. La Taille doit être au plus 1.5 MB.')

        }else if(value.name.match(/\.(pdf|docx)$/)&& value.size <=limit){
            setdoc(value)
            seterrmsgdoc('Fichier ou Taille no supporté!')
        }
    
    }else{
        seterrmsgdoc("Formats de fichiers supportés: .pdf, .docx Taille Maximale: 1.5 MB.")
    } 
}

    const  handleImage = (e)=>{
        const value =e.target.files[0]
        const limit =2639815;
    
        if(value!=null){
    
            if(!value.name.match(/\.(jpg|jpeg|png)$/)){
    
                seterrmsg("Format no supporté!")
            }else if(value.name.match(/\.(jpg|jpeg|png)$/)&& value.size >limit){
                seterrmsg('Le fichier est trop large. La Taille doit être au plus 1.5 MB.')
    
            }else if(value.name.match(/\.(jpg|jpeg|png)$/)&& value.size <=limit){
                setimg(value)
                seterrmsg('Format ou Taille no supporté!')
            }
        }else{
            seterrmsg("Les formats compatibles sont : .jpg, jpeg, png. Taille Maximale: 1.5 MB.")
        } 
    }

   
    const [pole, setpole] = useState('')
    const getpole = (e)=>{
        const value = e.target.value
        setpole(value)

    }
    const getUser =()=>{
        var email =  sessionStorage.getItem('user')
        setemail(email)
       
      }
      const onSubmit=(data,e)=>{
        e.preventDefault()
        setOpen(false)
        if(data){
           if(props.location.data && img==='' && doc === ''){
            setisCircular(true)
            dispatch(updateActualiteWithNoImageAndDoc(
                props.location.data,
                data.titre,
                pole,
                addData,
                email,
                null
            )).then(()=>{
                setisCircular(false)
                dispatch(getActualite())
                history.push({
                    pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/actualite/detail/${data.titre}=${props.location.data}` ,
                    data:props.location.data
                 })
                sessionStorage.setItem('rowId',props.location.data,)
                reset()
            },()=>{
                setOpen(true)
                setisCircular(false)
                setpole()
                setdoc('')
                setimg('')
                setaddData('')
                reset()
                
            })
           }else if(props.location.data && img==='' && doc !== ''){
            setisCircular(true)
            dispatch(updateActualiteWithNoImage(
                props.location.data,
                data.titre,
                pole,
                doc,
                addData,
                email,
                null
            )).then(()=>{
                setisCircular(false)
                dispatch(getActualite())
                history.push({
                    pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/actualite/detail/${data.titre}=${props.location.data}` ,
                    data:props.location.data
                 })
                sessionStorage.setItem('rowId',props.location.data,)
                reset()
            },()=>{
                setOpen(true)
                setisCircular(false)
                setpole()
                setdoc('')
                setimg('')
                setaddData('')
                reset()
                
            })
           }else if (props.location.data && img!=='' && doc === ''){
            setisCircular(true)
            dispatch(updateActualiteWithNoDoc(
                props.location.data,
                data.titre,
                img,
                pole,
                addData,
                email,
                null
            )).then(()=>{
                setisCircular(false)
                dispatch(getActualite())
                history.push({
                    pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/actualite/detail/${data.titre}=${props.location.data}` ,
                    data:props.location.data
                 })
                sessionStorage.setItem('rowId',props.location.data,)
                reset()
            },()=>{
                setOpen(true)
                setisCircular(false)
                setpole()
                setdoc('')
                setimg('')
                setaddData('')
                reset()
                
            })
           }
           else{
            setOpen(false)
            setisCircular(true)
            dispatch(addActualite(
                data.titre,
                img,
                pole,
                doc,
                addData,
                email
            )).then(()=>{
                setisCircular(false)
                setOpen(true)
                dispatch(getActualite())
                setpole()
                setdoc('')
                setimg('')
                setaddData('')
                reset()
                
            },()=>{
                setisCircular(false)
                setOpen(true)
            })
           }
        }
    }

    useEffect(() => {
        getUser()
        if(history.action ==='POP'){
            history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/actualite`)
        }
        if(props.location.data){
            dispatch(getActualiteById(`${parseInt(props.location.data)}`)).then(()=>{
               

                if(actualitebyId!==undefined){
                   
                    reset({
                        cat: actualitebyId.category,
                        titre:actualitebyId.title,
                        desc:actualitebyId.description,
                    })
                    if (actualitebyId.category==="L'offre"){
                        setchangeCategory('A')
                        setpole('A')
                    }
                    else if (actualitebyId.category==="La demande"){
                        setchangeCategory('B')
                        setpole('B')
                    }
                    else if (actualitebyId.category==="L'insertion"){
                        setchangeCategory('C')
                        setpole('C')
                    }
                    else if (actualitebyId.category==="La regulation"){
                        setchangeCategory('D')
                        setpole('D')
                    }
                    else if (actualitebyId.category==="CLEC"){
                        setchangeCategory('E')
                        setpole('E')
                    }
                    else if (actualitebyId.category==="CCC"){
                        setchangeCategory('F')
                        setpole('F')
                    }
                    else if (actualitebyId.category==="Autres"){
                        setchangeCategory('G')
                        setpole('G')
                    }
                    setupdaedDoc(actualitebyId.document)
                    setaddData(actualitebyId.description)
                   
                   
                }
            },()=>{
                setOpen(true)
            })
            
           
        }
        if(actualitebyId===undefined){
            if(img){
                setselectedImage(URL.createObjectURL(img))
            }
        }else{
            if(img){
                setselectedImage(URL.createObjectURL(img))
            } 
        }
        
    }, [img])


    return (
     
        <Grid container direction='column' className={classes.root}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container justifyContent='center' alignItems="center">
                    <Grid item><Typography variant='h3' style={{fontWeight:'bold'}} color='primary'>{props.location.data?'Actualiser':'Ajouter'} une actualité</Typography></Grid>
                </Grid>
            </Grid>
            <Box height={40}/>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Collapse in={open}>
                        <Alert
                        severity={msg?'error':'success'}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        >
                            {msg?msg:message}
                        </Alert>
                    </Collapse>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.backPaper}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container justify='center' alignItems='center'>
                    <Grid item>
                    <Grid container  direction='row'  className={classes.spacebetween} spacing={4}>
                        <Grid item  xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Typography>Titre*</Typography>
                            <TextField inputProps={{maxLength:30}} helperText='Titre doit avoir 30 caractères' type='text' {...register('titre',{required:true})} required variant='outlined'  fullWidth  className={classes.textField} placeholder='Entrez le titre'/>
                        </Grid>
                        <Grid item  xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Typography>Catégories*</Typography>
                            {changeCategory?
                            <TextField  onClick={()=>{setchangeCategory()}} type='text' {...register('cat',{required:true})} required variant='outlined'  fullWidth className={classes.textField}/>
                               :
                               <Select
                               required
                               labelId="demo-simple-select-helper-label"
                               variant="outlined"
                               id="demo-simple-select-helper"
                               size="small"
                               fullWidth
                               required
                               value={pole}
                               onClick={getpole}
                               className={classes.textField} >
                                   <MenuItem value="A">L'offre</MenuItem>
                                   <MenuItem value="B">La demande</MenuItem>
                                   <MenuItem value="C">L'insertion</MenuItem>
                                   <MenuItem value="D">La regulation</MenuItem> 
                                   <MenuItem value="E">CLEC</MenuItem>                                    
                                   <MenuItem value="F">CCC</MenuItem> 
                                   <MenuItem value="G">Autres</MenuItem>
                           </Select> }
                        </Grid>
                        <Grid item  xs={12} sm={4} md={4} lg={4} xl={4}>
                            {selectedImage && img?(
                                   <Box mt={2} textAlign='left'>
                                       <div style={{marginBottom:10}}>Image Preview*:</div>
                                         <img onClick={()=>setimg(null)} src={selectedImage} alt={selectedImage.name} height={50} width={50} />
                                   </Box>
                               ):(
                                <div>
                                    <input 
                                       
                                        accept="image/*" 
                                        type="file" 
                                        id="select-image"
                                        style={{ display: 'none' }}
                                        onChange={e => handleImage(e)}
                                    />
                                    <label htmlFor="select-image">
                                        <Button  endIcon={<CloudUpload/>} variant='text' color="primary" component="span" >
                                         Photo 
                                        </Button>
                                    </label>
                                </div>
                               )}
                                <small >{errmsg}</small>
                            
                        </Grid>
                    
                    </Grid>
                    <Grid item className={classes.spacebetween} >
                            <Typography>Description</Typography>
                        <ReactQuill
                                        value={addData||''}
                                        theme= 'snow'
                                        onChange={handleChange}
                                        modules={{
                                            toolbar:{
                                                container:[
                                                    [{ 'font': [] }],
                                                    ['bold', 'italic', 'underline'],
                                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                    [{'list': 'ordered'}, {'list': 'bullet'}],
                                                    [{ 'script': 'sub'}, { 'script': 'super' }],
                                                    [{ 'indent': '-1'}, { 'indent': '+1' }], 
                                                    [{ 'direction': 'rtl' }],   
                                                    [{ 'align': [] }],
                                                    [{ 'color': [] }, { 'background': [] }],
                                                    ['link','video'],
                                                    ['blockquote'],
                                                    ['clean']
                                                    ],
                                                
                                        
                                            }
                                        }}  
                                        formats={formats}
                                        placeholder="Écrivez le texte ici"
                                        style={{height:300}}
                                        />
                        </Grid>
                    <Box height={10}/>
                    <Grid item className={classes.spacebetween} >
                        <Grid container justify='space-between' spacing={2} >
                            <Grid item xs={12} sm={0} md={0} lg={0} xl={0}>
                            {doc || updaedDoc?(
                                   <Box mt={2} textAlign='left'>
                                       <div style={{marginBottom:10}} onClick={()=>{
                                           setupdaedDoc(null)
                                           setdoc(null)
                                       }}>{updaedDoc?'update document':doc.name}:</div>
                                      
                                   </Box>
                               ):(
                                <div>
                                    <input 
                                       
                                        accept="document/*" 
                                        type="file" 
                                        id="select-file"
                                        style={{ display: 'none' }}
                                        onChange={e => handleDoc(e)}
                                    />
                                    <label htmlFor="select-file">
                                        <Button  variant='text' color="primary" component="span" endIcon ={<CloudUpload style={{color:'#FFAE02',}}/>}>
                                            document
                                        </Button>
                                    </label>
                                </div>
                               )}
                                <small >{errmsgdoc}</small>
                            </Grid>
                            <Grid item xs={12} sm={0} md={0} lg={0} xl={0}>
                                <Button type='submit' disabled={isCircular===true?true:false}  fullWidth className={classes.btn}> {isCircular===true?<CircularProgress size={30} style={{color:'white'}} />:<Typography>{props.location.data?'Sauvegarder':'Ajouter'}</Typography>}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
              </form>
            </Grid>

        </Grid>
       
    )
}

export default ActualiteCreate