import { makeStyles } from '@material-ui/core/styles'

const Style = makeStyles((theme)=> ({

    root:{
        marginTop:80,
        marginBottom:'10vw',
        color:'#1D2F3F',
        textAlign: 'center',
        '& span': {
            textAlign:theme.typography.textBodyAlign
          },
        [theme.breakpoints.down("md")]:{
            padding:'3em 0em',
        },
          [theme.breakpoints.up("md")]:{
            padding:'6em 0em',
        },
    },
    
    pageHead:{

            marginBottom:'5vw',
            '& h2':{
                [theme.breakpoints.down("sm")]:{
                    fontSize:"2.15rem",
                },
                fontWeight: '700',
                marginBottom: '0.7rem'
            }
    },

    partenaireContainer:{

            background:'#E5FFFA',
            margin:'2em 1em',
            padding:'1vw 1vw',
            '& img':{
                width:'8em',
                height:'8em'
            },
            '& h2':{

                fontSize:'1.2em',
                fontWeight:'400',
                margin:'0.5vw 0vw',
            },
            '& span':{
                margin:'1vw 0vw',
                fontWeight:'200'
            },
            '& a':{
                '& p':{
                    fontSize:"0.8rem",
                },
                '& svg':{
                    fontSize:"0.8rem",
                }
            }
        
    },

    

}))

export default Style;