import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Accueil from "../website/pages/accueil/accueil";
import Actualite from "../website/pages/actualite_et_agenda/actualite";
import Activite from "../website/pages/activite/activite";
import Article from "../website/pages/article/article";
import Ccc from "../website/pages/ccc/ccc";
import Clec from "../website/pages/clec/clec";
import Contactes_nous from "../website/pages/contactez_nous/contactes_nous";
import Demande from "../website/pages/demande/demande";
import Insertion from "../website/pages/insertion/insertion";
import Members from "../website/pages/membres/membre";
import Mention_legale from "../website/pages/mentions_legales/mentions_legales";
import Offre from "../website/pages/offre/offre"; 
import Paternaires from "../website/pages/partenaires/partenaires";
import Sommes_nous from "../website/pages/qui_sommes_nous/sommes_nous";
import Regulation from "../website/pages/regulation/regulation";
import Sitemap from "./pages/sitemap/sitemap";
import Video from "../website/pages/video/video";
import Appbar from "../website/sc/appbar";
import Footer from "../website/sc/footer";
import DefaultPage from "./pages/404/404";
import BackTop from "./widgets/backTop";
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';





export const history = createBrowserHistory();


const WebSite = (props) => {

  history.listen((location)=>{
    ReactGA.pageview(location)
    console.log(location);
   })
  return (
    <Router history={history}>
      <Appbar {...props} />
      <Switch>
        <Route exact path="/" component={Accueil} />
        <Route exact path="/Actualite" component={Actualite} />
        <Route exact path="/Activite/:title=:id" component={Activite} />
        <Route exact path="/Article/:title=:id" component={Article} />
        <Route exact path="/Ccc" component={Ccc} />
        <Route exact path="/Clec" component={Clec} />
        <Route exact path="/Contactez_nous" component={Contactes_nous} />
        <Route exact path="/Demande" component={Demande} />
        <Route exact path="/Insertion" component={Insertion} />
        <Route exact path="/Members" component={Members} />
        <Route exact path="/Mention_legale" component={Mention_legale} />
        <Route exact path="/Offre" component={Offre} />
        <Route exact path="/Paternaires" component={Paternaires} />
        <Route exact path="/Sommes_nous" component={Sommes_nous} />
        <Route exact path="/Regulation" component={Regulation} />
        <Route exact path="/Video" component={Video} />
        <Route exact path="/Sitemap" component={Sitemap} />
        <Route exact component={DefaultPage} />
      </Switch>
      <BackTop/>
      <Footer />
    </Router>
  );
};

export default WebSite;
