import api from './axiosApi';


const getUser =()=>{
   return api.get('api-profile/profile/').then((res)=>{
        return res.data.profiles
    })
}

const getUserById =(id)=>{
  return  api.get(`api-profile/profile/${id}/`).then((res)=>{
        return res.data.Profile
    })
}

const updateUser =(
        id,
        email,
        first_name,
        last_name,
        phonenumber,
        address,
        organisation,
        fonction,
        province,
        ville_territory,
        created_by,
        modification,
        )=>{
   return  api.patch(`api-profile/profile/${id}/`,{
        id,
        email,
        first_name,
        last_name,
        phonenumber,
        address,
        organisation,
        fonction,
        province,
        ville_territory,
        created_by,
        modification,
    }).then((res)=>{
        return res.data
    })
}

const deleteUser =(id)=>{
    return api.delete(`api-profile/profile/${id}/`).then((res)=>{
        return res.data
    })
}

const createModification=(
    modified_by,
    modification_date,
    app,
    Activity
)=>{
    return api.post('api-modification/modification/',{
        modified_by,
        modification_date,
        app,
        Activity
    }).then((res)=>{
        return res.data
    })
}
const userProfile  =(id)=>{
    return api.post('api-profile/user/profile/',{id}).then((res)=>{
        return res.data.user
    })
}
const UserActions = {
    getUser,
    getUserById,
    updateUser,
    deleteUser,
    createModification,
    userProfile
}

export default UserActions;
   
