import api from './axiosApi';
import apiPub from './axiosPub'


const addPartenaire =(
    name,
    logo,
    localisation,
    email,
    description,
    link,
    create_by,
)=>{

    const formData  =new FormData() 
    formData.append('name',name)
    formData.append('logo',logo)
    formData.append('localisation',localisation)
    formData.append('email',email)
    formData.append('description',description)
    formData.append('link',link)
    formData.append('name',name)
    formData.append('create_by',create_by)
   return api.post('api-partner/partner/',
    formData
   ).then((res)=>{
        return res.data
    })
}

const getPartenaire =()=>{
    return api.get('api-partner/partner/').then((res)=>{
         return res.data.partners
     })
 }

 const deletePartenaire =(id)=>{
    return api.delete(`api-partner/partner/${id}/`).then((res)=>{
        return res.data
    })
}

const updatePartenaire =(
    id,
    name,
    logo,
    localisation,
    email,
    description,
    link,
    create_by,
    )=>{
        const formData  =new FormData() 
        formData.append('name',name)
        formData.append('logo',logo)
        formData.append('localisation',localisation)
        formData.append('email',email)
        formData.append('description',description)
        formData.append('link',link)
        formData.append('name',name)
        formData.append('create_by',create_by)
    return api.patch(`api-partner/partner/${id}/`,formData).then((res)=>{
        return res.data
    })
}

const updatePartenaireWithoutLogo =(
    id,
    name,
    localisation,
    email,
    description,
    link,
    create_by,
    )=>{
        const formData  =new FormData() 
        formData.append('name',name)
        formData.append('localisation',localisation)
        formData.append('email',email)
        formData.append('description',description)
        formData.append('link',link)
        formData.append('name',name)
        formData.append('create_by',create_by)
    return api.patch(`api-partner/partner/${id}/`,formData).then((res)=>{
        return res.data
    })
}

const getbyIdPartenaire =(id)=>{
    return api.get(`api-partner/partner/${id}/`).then((res)=>{
        return res.data.partner
    })
}

const createModification=(
    modified_by,
    modification_date,
    app,
    Activity
)=>{
    return api.post('api-modification/modification/',{
        modified_by,
        modification_date,
        app,
        Activity
    }).then((res)=>{
        return res.data
    })
}



const paternerPub =()=>{
    return apiPub.get(`api-partner/partner/pub/`).then((res)=>{
        return res.data.partners
    })
}


const paternerByIdPub =(id)=>{
    return apiPub.get(`api-partner/partner/pub/${id}/`).then((res)=>{
        return res.data
    })
}

const PartenaireService = {
    getPartenaire,
    createModification,
    getbyIdPartenaire,
    deletePartenaire,
    updatePartenaire,
    addPartenaire,
    paternerPub,
    paternerByIdPub,
    updatePartenaireWithoutLogo
}

export default PartenaireService;









