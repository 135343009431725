import {
    SET_MESSAGE,
    FETCH_AGENDA_SUCCES,
    FETCH_AGENDA_FAIL,
    DELETE_AGENDA_SUCCES,
    DELETE_AGENDA_FAIL,
    ADD_AGENDA_SUCCES,
    ADD_AGENDA_FAIL,
    UPDATE_AGENDA_SUCCES,
    UPDATE_AGENDA_FAIL,
    FETCH_AGENDA_BY_ID_SUCCES,
    FETCH_AGENDA_BY_ID_FAIL,
    CLEAR_DATA
} from '../contants/type'

import AgendaService from '../../service/agenda'


export const getAgenda =()=>dispatch=>{
    return AgendaService.getAgenda().then((data)=>{
        dispatch({
            type:FETCH_AGENDA_SUCCES,
            payload: data
        })
        return Promise.resolve()

    },(error)=>{
     const message =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
        type:FETCH_AGENDA_FAIL,
        });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
    })
}

export const addAgenda = (
    title,
    date,
    start_time,
    end_time,
    description,
    create_by,
)=>dispatch=>{
    return AgendaService.addAgenda(
    title,
    date,
    start_time,
    end_time,
    description,
    create_by,
    ).then((data)=>{
        dispatch({
            type:ADD_AGENDA_SUCCES,
            payload:data
        })
        return Promise.resolve()
    },(error)=>{
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
          type:ADD_AGENDA_FAIL,
        });
    
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
    
        return Promise.reject();
  
    })
}

export const updateAgenda = (
    id,
    title,
    date,
    start_time,
    end_time,
    description,
    create_by,
    modification
)=>dispatch=>{
    return AgendaService.updateAgenda(
    id,
    title,
    date,
    start_time,
    end_time,
    description,
    create_by,
    modification
    ).then((data)=>{
        dispatch({
            type:UPDATE_AGENDA_SUCCES,
            payload:data
        })
        return Promise.resolve()
    },(error)=>{
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
          type:UPDATE_AGENDA_FAIL,
        });
    
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
    
        return Promise.reject();
  
    })
}


export const getAgendaById =(id)=>dispatch=>{
    return AgendaService.getAgendaById(id).then((data)=>{
      dispatch({
          type: FETCH_AGENDA_BY_ID_SUCCES,
          payload: data
      })
      return Promise.resolve()
    },(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:FETCH_AGENDA_BY_ID_FAIL,
      });
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
  
      return Promise.reject();
    })
  
}

export const deleteAgenda =(id)=>dispatch=>{
    return AgendaService.deleteAgenda(id).then((data)=>{
      dispatch({
          type:DELETE_AGENDA_SUCCES,
          payload: data
      })
      return Promise.resolve()
    },(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:DELETE_AGENDA_FAIL,
      });
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
  
      return Promise.reject();
    })
  
}


export const getAgendaPub =()=>dispatch=>{
  return AgendaService.agendaPub().then((data)=>{
      dispatch({
          type:FETCH_AGENDA_SUCCES,
          payload: data
      })
      return Promise.resolve()

  },(error)=>{
   const message =
      (error.response &&
      error.response.data &&
      error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
      type:FETCH_AGENDA_FAIL,
      });

  dispatch({
    type: SET_MESSAGE,
    payload: message,
  });

  return Promise.reject();
  })
}


export const getAgendaByIdPub =(id)=>dispatch=>{
  return AgendaService.agendaByIdPub(id).then((data)=>{
    dispatch({
        type: FETCH_AGENDA_BY_ID_SUCCES,
        payload: data
    })
    return Promise.resolve()
  },(error)=>{
    const message =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString();
    dispatch({
      type:FETCH_AGENDA_BY_ID_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  })

}
export const removeData = () => {
  return {
    type:CLEAR_DATA,
  };
};


