import React from 'react'
import {Switch,Route} from'react-router-dom'
import Actualite from '../pages/dashboard/actualite/actualite'
import Agenda from '../pages/dashboard/agenda/agenda'
import CCC from '../pages/dashboard/ccc/ccc'
import clec from '../pages/dashboard/clec/clec'
import Membre from '../pages/dashboard/member/member'
// import Projet from '../pages/dashboard/projet/projet'
import Partenaire from '../pages/dashboard/partenaire/partenaire'
import PartenaireDetail from '../pages/dashboard/partenaire/widget/detailPartenaire'
import Utilisataire from '../pages/dashboard/utitlisateur/utilisateur'
import Dashboard from '../../dashboard/pages/dashboard'
import AgendaDetail from './dashboard/agenda/widget/agendaDetail'
import MembreDetail from './dashboard/member/widget/memberDetail'
import UtilisateurDetail from './dashboard/utitlisateur/widget/utilisateurDetail'
// import ProjetDetail from './dashboard/projet/widget/projetDetail'
import ActualiteDetail from './dashboard/actualite/widget/actualiteDetail'
import CccDetail from './dashboard/ccc/widget/cccDetail'
import ClecDetail from './dashboard/clec/widget/clecDetail'
import AgendaCreate from './dashboard/agenda/widget/createAgenda'
// import CreateProjet from './dashboard/projet/widget/createProjet'
import CreatePartenaire from './dashboard/partenaire/widget/createPartenaire'
import MembreCreate from './dashboard/member/widget/createMembre'
import UtilisateurCreate from './dashboard/utitlisateur/widget/CreateUtilisateur'
import ActualiteCreate from './dashboard/actualite/widget/createActualite'
import ClecCreate from './dashboard/clec/widget/createClec'
import CccCreate from './dashboard/ccc/widget/createCcc'
import Parametre from './dashboard/utitlisateur/widget/paremetre'
import UpdateUserAccount from './dashboard/utitlisateur/widget/updateUser'
import UserProfileDetail from './dashboard/utitlisateur/widget/user_profile'
import EspaceMembre from './dashboard/espaceMembre/spaceMember'
import CreateEspace from './dashboard/espaceMembre/widget/creeSpace'




const MainRoutes = ()=>{
    return (
       
       <div>
            <Dashboard>
                <Switch>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard'} component={EspaceMembre}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/espace/create'} component={CreateEspace}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/agenda'} component={Agenda}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/actualite'} component={Actualite}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/ccc'} component={CCC}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/clec'} component={clec}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/membre'} component={Membre}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/partenaire'} component={Partenaire}/>
                    {/* <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/projet'} component={Projet}/> */}
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/utilisateur'} component={Utilisataire}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/agenda/detail/:elijah=:detail'} component={AgendaDetail}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/membre/detail/:elijah=:detail'} component={MembreDetail}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/partenaire/detail/:elijah=:detail'} component={PartenaireDetail}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/utilisateur/detail/:elijah=:detail'} component={UtilisateurDetail}/>
                    {/* <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/projet/detail/:elijah=:detail'} component={ProjetDetail}/> */}
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/actualite/detail/:elijah=:detail'} component={ActualiteDetail}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/ccc/detail/:elijah=:detail'} component={CccDetail}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/clec/detail/:elijah=:detail'} component={ClecDetail}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/agenda/create'} component={AgendaCreate}/>
                    {/* <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/projet/create'} component={CreateProjet}/> */}
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/partenaire/create'} component={CreatePartenaire}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/membre/create'} component={MembreCreate}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/utilisateur/create'} component={UtilisateurCreate}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/actualite/create'} component={ActualiteCreate}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/clec/create'} component={ClecCreate}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/ccc/create'} component={CccCreate}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/utilisateur/parametre/:id'} component={Parametre}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/utilisateur/account/update'} component={UpdateUserAccount}/>
                    <Route  exact path={'/'+process.env.REACT_APP_DASHBOARD_TOKEN+'/dashboard/utilisateur/detail/:detail'} component={UserProfileDetail}/>
                </Switch>
        </Dashboard>
       </div>
        
    )
}
export default MainRoutes

