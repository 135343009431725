import {
    SET_MESSAGE,
    FETCH_CLEC_SUCCES,
    FETCH_CLEC_FAIL,
    DELETE_CLEC_SUCCES,
    DELETE_CLEC_FAIL,
    ADD_CLEC_FAIL,
    ADD_CLEC_SUCCES,
    UPDATE_CLEC_SUCCES,
    UPDATE_CLEC_FAIL,
    FETCH_CLEC_BY_ID_SUCCES,
    FETCH_CLEC_BY_ID_FAIL,
    CLEAR_DATA
} from '../contants/type'

import clecService from '../../service/clec'


export const getClec =()=>dispatch=>{
    return clecService.getClec().then((data)=>{
        dispatch({
            type:FETCH_CLEC_SUCCES,
            payload: data
        })
        return Promise.resolve()

    },(error)=>{
     const message =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
        type:FETCH_CLEC_FAIL,
        });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
    })
}

export const addClec = (
      name,
      location,
      description,
      create_by,

)=>dispatch=>{
    return clecService.addClec(
    name,
    location,
    description,
    create_by,
    ).then((data)=>{
        dispatch({
            type:ADD_CLEC_SUCCES,
            payload:data
        })
        return Promise.resolve()
    },(error)=>{
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
          type:ADD_CLEC_FAIL,
        });
    
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
    
        return Promise.reject();
  
    })
}

export const updateClec = (
    id,
    name,
    location,
    description,
    create_by,
    modification
)=>dispatch=>{
    return clecService.updateClec(
      id,
      name,
      location,
      description,
      create_by,
      modification
    ).then((data)=>{
        dispatch({
            type:UPDATE_CLEC_SUCCES,
            payload:data
        })
        return Promise.resolve()
    },(error)=>{
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        dispatch({
          type:UPDATE_CLEC_FAIL,
        });
    
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
    
        return Promise.reject();
  
    })
}


export const getClecById =(id)=>dispatch=>{
    return clecService.getClecById(id).then((data)=>{
      dispatch({
          type:FETCH_CLEC_BY_ID_SUCCES,
          payload: data
      })
      return Promise.resolve()
    },(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:FETCH_CLEC_BY_ID_FAIL,
      });
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
  
      return Promise.reject();
    })
  
}

export const deleteClec =(id)=>dispatch=>{
    return clecService.deleteClec(id).then((data)=>{
      dispatch({
          type:DELETE_CLEC_SUCCES,
          payload: data
      })
      return Promise.resolve()
    },(error)=>{
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
        type:DELETE_CLEC_FAIL,
      });
  
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
  
      return Promise.reject();
    })
  
}



export const getClecPub =()=>dispatch=>{
  return clecService.clecPub().then((data)=>{
      dispatch({
          type:FETCH_CLEC_SUCCES,
          payload: data
      })
      return Promise.resolve()

  },(error)=>{
   const message =
      (error.response &&
      error.response.data &&
      error.response.data.message) ||
      error.message ||
      error.toString();
      dispatch({
      type:FETCH_CLEC_FAIL,
      });

  dispatch({
    type: SET_MESSAGE,
    payload: message,
  });

  return Promise.reject();
  })
}


export const getClecByIdPub =(id)=>dispatch=>{
  return clecService.clecByIdPub(id).then((data)=>{
    dispatch({
        type:FETCH_CLEC_BY_ID_SUCCES,
        payload: data
    })
    return Promise.resolve()
  },(error)=>{
    const message =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString();
    dispatch({
      type:FETCH_CLEC_BY_ID_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  })

}
export const removeData = () => {
  return {
    type:CLEAR_DATA,
  };
};

