import React, { useState,useEffect }from 'react';
import {CircularProgress, Grid, Typography, Box ,Collapse,IconButton } from '@material-ui/core'
import useStyles from '../style'
import EditIcon from '@material-ui/icons/Edit';
import {BtnOrange,BtmRed} from '../../../../sc/utilities'
import Backpaper from '../../../../sc/backPaper';
import {getCccById,getCcc,deleteCcc} from '../../../../../../store/actions/ccc-actions'
import { useSelector,useDispatch } from 'react-redux';
import DialogBox from '../../../../sc/dialog';
import { useHistory } from 'react-router';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';



const CccDetail = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const {cccbyId} = useSelector(state => state.ccc)
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const history  = useHistory()
    const [isDel, setisDel] = useState(false)
    const [opens, setOpens] = React.useState(false);
    const msg = useSelector((state)=>state.msg.message)
    const [currentUser, setcurrentUser] = useState('')

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        setOpen(false);
      };
    const handleClickOpen = () => {
       setOpen(true);
     };
  
    const handleDelete =()=>{
        handleClickOpen()
    }
    const handleConfirmDelete = (id)=>{
        setisDel(true)
        setOpens(false)
        dispatch(deleteCcc(id)).then(()=>{
             dispatch(getCcc())
             setisDel(false)
              history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/ccc`)  
        },()=>{
            setOpens(true)
            setisDel(false)
            handleClose()
        })
    }
    const handleModifier = ()=>{
        history.push({
                pathname: `/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/ccc/create`,
                data:cccbyId.id
        })

    }
    const  getUserRole =()=>{
        const role  = sessionStorage.getItem('role')
        setcurrentUser(role)
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(getCccById(parseInt(sessionStorage.getItem('rowId')))).then(()=>{},()=>{
                setOpens(true)
        })
        getUserRole()
        

    }, [])

    return (
        <Backpaper
        yellowbtn={currentUser<=3?<BtnOrange onClick={handleModifier} btnYellowTitle="Mddifier" startIcon={<EditIcon style={{fontSize:14}}/>} />:null}
        redbtn={currentUser<=3?<BtmRed onClick={handleDelete}/>:null}
        content={
            <Grid container justifyContent='center' spacing={4}>
                <Grid item xs ={10}  sm={10} md={10} lg={8} xl={8}>
                <Box height={20}/>
                <Collapse in={opens}>
                    <Alert
                   severity={'error'}
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpens(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    >
                    { msg}
                    </Alert>
                </Collapse>
                <Box height={20}/>
                </Grid>
                <Grid item xs ={10}  sm={10} md={10} lg={8} xl={8} className = {classes.head}>
                    {
                        cccbyId?<Typography variant = "h5" style={{fontWeight:'bold'}}>
                        {cccbyId.name}
                        </Typography>:<Skeleton variant='rect' width={100} height={10}/>
                    }
                </Grid>
                <Grid item xs ={10}  sm={10} md={10} lg={8} xl={8} className = {classes.head}>
                    {
                        cccbyId?<Typography>
                        {cccbyId.location}
                        </Typography>:<Skeleton variant='rect' width={100} height={10}/>
                    }
                </Grid>
                <Grid item xs ={10}  sm={10} md={10} lg={8} xl={8}>
                    {
                        cccbyId?<Typography variant = "body1"  className = {classes.body}>
                        {cccbyId.description}
                </Typography>:
                <Skeleton variant='rect' width={400} height={100}/>
                    }
                </Grid>
                <DialogBox
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    Oui={isDel?(<CircularProgress size={10}/>):'Oui'}

                    confirnClick={()=>handleConfirmDelete(cccbyId.id)}
                    nonOnclick={handleClose}
                    content={`Etês-vous sûr de vouloir supprimer ce membre ?`}
                />
            </Grid>
        }
   />
    );
};

export default CccDetail;