import axios from "axios";

import {config} from '../config/config'


const baseURL = config.url.API_URL;


const axiosInstance = axios.create({
	baseURL: baseURL,
	timeout: 200000,
	headers: {
		Authorization: sessionStorage.getItem('token')
			? 'Bearer ' + sessionStorage.getItem('token')
			: null,
		'Content-Type': 'application/json',
		//'Content-Type': 'multipart/form-data',
		accept: 'application/json',
	}, 
});



axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;

		if (typeof error.response === 'undefined') {
			return Promise.reject(error);
		}

		if (
			error.response.status === 401 &&
			originalRequest.url === baseURL + 'api-token/token/refresh/'
		) {
			window.location.href = `/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/login`;
			
			return Promise.reject(error);
		}

		if (
			error.response.data.code === 'token_not_valid' &&
			error.response.status === 401 &&
			error.response.statusText === 'Unauthorized'
		) {
			const refreshToken = sessionStorage.getItem('refresh');

			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

				const now = Math.ceil(Date.now() / 1000);

				if (tokenParts.exp > now) {
					return axiosInstance
						.post('api-token/token/refresh/', { refresh: refreshToken })
						.then((response) => {
							sessionStorage.setItem('token', response.data.access);


							axiosInstance.defaults.headers['Authorization'] =
								'Bearer ' + response.data.access;
							originalRequest.headers['Authorization'] =
								'Bearer ' + response.data.access;

							return axiosInstance(originalRequest);
						})
						.catch((err) => {

						});
				} else {

					window.location.href = `/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/login`;
					sessionStorage.removeItem('token');
					sessionStorage.removeItem('refresh');

				}
			} else {

				window.location.href = `/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/login`;
				sessionStorage.removeItem('token');
				sessionStorage.removeItem('refresh');
			}
		}

		// specific error handling done elsewhere
		return Promise.reject(error);
	}
);

export default axiosInstance;






