import React,{useState,useEffect} from "react";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Alert from '@material-ui/lab/Alert';
import { Grid, Collapse} from "@material-ui/core";
import { Link } from "react-router-dom";
import Breadcrumb from "../widgets/beadcrumb";
import congo from "../assets/images/congo.webp";
import Logo from "../assets/images/Logo.webp";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from '@material-ui/icons/Menu';
import {getActualitePub} from '../../../store/actions/actualite-actions'
import {getAgendaPub} from '../../../store/actions/agenda-actions'
import {getCccPub} from '../../../store/actions/ccc-actions'
import {getClecPub} from '../../../store/actions/clec-actions'
import { getMembrePub } from '../../../store/actions/membre-actions'
import {getPartenairePub} from '../../../store/actions/partenaire-actions'
import { clearMessage, setMessage } from '../../../store/actions/message-actions'
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close';



const useStyles = makeStyles((theme) => ({
 
  appBarTransparent: {
    backgroundColor: 'transparent'
},
big_title: {
  fontWeight: theme.typography.fontWeightBold,
},
list_title: {
  fontWeight: theme.typography.fontWeightBold,
},
footer_bg: {
  backgroundColor: "#F6FBFF",
  marginTop: "10px",
},
oxide_color: {
  color: "#EB9B29",
  textDecoration:"none"
},
link_style: {
  marginLeft:5,
  marginRight:5,
  textDecoration: "none",
  color: "#1D2F3F",
  "&:hover": {
    background: "#ffae02",
  },
 
},

link_styles:{
  marginLeft:5,
  marginRight:5,
  textDecoration: "none",
  color: "#1D2F3F",
 
},

// AppBar Styles

title: {
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "block",
  },
},

sectionDesktop: {
  padding: "5px",
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
},
  whitebg: {
    backgroundColor:"#0000",
  [theme.breakpoints.up("sm")]: {
  },
},
alignApp: {
  background:"white",
  [theme.breakpoints.up("sm")]: {
    justifyContent: "center",
  },
},
sectionMobile: {
  display: "flex",
  backgroundColor:'white',
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
},
media: {
  height: 45,
},

// Margins and Paddings
margin_top_5: {
  marginTop: "5vh",
},
margin_topbot_5: {
  marginTop: "5vh",
  marginBottom: "5vh",
},
margin_botton_10: {
  marginBottom: "10vh",
},
margin_botton_5: {
  marginBottom: "5vh",
},
margin_botton_3: {
  marginBottom: "3vh",
},
margin_botton_4: {
  marginBottom: "4vh",
},
margin_botton_2: {
  marginBottom: "2vh",
},
margin_botton_1: {
  marginBottom: "1vh",

 
},
icon:{
  
  [theme.breakpoints.down('xs')]:{
    fontSize:15
  }
},
typodescr:{
  width:'70%',
  [theme.breakpoints.down('xs')]:{
    width:'100%'
  }

},
oxide:{
  [theme.breakpoints.down('xs')]:{
    fontSize:12,
    marginTop:10
  }
  },
  alert: {
    background: "white",
    padding: "1px 8px",
    borderRadius:0,
    "& > div": {
      color: "#f3655a !important",
    }
}
 
}));



export default function Appbar(props) {
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorpole, setAnchorpole] = React.useState(null);
  const { message } = useSelector(state => state.msg)
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch()

  const handleClick = (event) => {
   
    setAnchorpole(event.currentTarget);
  };

  const handleClose = () => {
     setAnchorpole(null);
  };

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const poles = [
    {
      'title':"Accueil",
      'link':"/"
  },
  {
    'title':"L' offre",
    'link':"/Offre"
  },
  {
    'title':"La demande",
    'link':"/Demande"
  },
  {
    'title':"L' insertion",
    'link':"/Insertion"
  },
  {
    'title':"La regulation",
    'link':"/Regulation"
  },
  {
    'title':"Actualités et Agenda",
    'link':"/Actualite"
  },
{
  'title':"Videos",
  'link':"/Video"
  },
{
  'title':"CLEC",
  'link':"/Clec"
  },
{
  'title':"Membres",
  'link':"/Members"
  },

]
const [navBackground, setNavBackground] = useState('appBarTransparent')
  const navRef = React.useRef()
  navRef.current = navBackground

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {
        poles.map((e , i)=>(
          <MenuItem key={i} onClick={handleMobileMenuClose}>
            <Link to={e.link} className={classes.link_style}>
              <Typography>{e.title}</Typography>
            </Link>
          </MenuItem>
        ))
      }
      
    </Menu>
  );
  useEffect(() => {
    window.addEventListener('offline', () => {
        dispatch(setMessage("Network Error"))
    });
    window.addEventListener('online', () => {
        dispatch(clearMessage())
    });
    
    },[])

  useEffect(() => {
    
    if(message !== "Network Error") {
    dispatch(getActualitePub())
    dispatch(getAgendaPub())
    dispatch(getCccPub())
    dispatch(getClecPub())
    dispatch(getMembrePub())
    dispatch(getPartenairePub())
  }
    setOpen(message==="Network Error")
  //   const handleScroll = () => {
  //     const show = window.scrollY > 20
  //     if (show) {
  //         setNavBackground('whitebg')
  //     } else {
  //         setNavBackground('whitebg')
  //     }
  // }
  // document.addEventListener('scroll', handleScroll)
  // return () => {
  //     document.removeEventListener('scroll', handleScroll)
  // }
  

  }, [message])

  return (
    <React.Fragment>
      <AppBar 
      position="fixed" 
       className={classes[navRef.current]}
       elevation={0}
      >
        <Grid container direction="row" alignItems="center" className = {classes.alignApp}>
          <Grid item xs={12} sm={false} md={false} lg={false} xl={false}
          className={classes.sectionMobile}
          >
            <Grid container direction='row'>
              <Grid item xs={2} sm={false} md={false} lg={false} xl={false}>
            <IconButton
              aria-label="show more"
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            </Grid>
            <Grid item xs={9} sm={false} md={false} lg={false} xl={false}>
                <Grid container justify="flex-end" alignItems="flex-end">
                  <Grid item>
                    <img src={Logo} width="auto" height={30} alt="Logo" style={{paddingTop:10}} />
                  </Grid>

                </Grid>
                </Grid>
                <Grid item xs={1} sm={false} md={false} lg={false} xl={false}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={11} sm={12} md={10} lg={10} xl={10}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              className={classes.sectionDesktop}
            >
              <Grid item>
                <Link to="/">
                  <img src={Logo} width="auto" height='60' alt="Logo" />
                </Link>
              </Grid>
              <Grid item>
                <Link to="/" className={classes.link_style}>
                  <Typography>Accueil</Typography>
                </Link>
              </Grid>
              <Grid item>
                <IconButton aria-controls="simple-menu" aria-haspopup="true"  onClick={handleClick} style={{marginTop:15,padding:5, color:"inherit"}}> <Typography >Les 4 pôles</Typography> {<ExpandMoreIcon/>}</IconButton> 
                <Menu
                id="simple-menu"
                anchorEl={anchorpole}
                keepMounted
                open={Boolean(anchorpole)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}  >
                <MenuItem onClick={handleClose} value="1" component={Link} to ='/Offre'>L' offre</MenuItem>
                <MenuItem onClick={handleClose} value='2' component={Link} to ='/Demande'>La demande</MenuItem>
                <MenuItem onClick={handleClose} value='3' component={Link} to ='/Insertion'>L' insertion</MenuItem>
                <MenuItem onClick={handleClose} value='4' component={Link} to ='/Regulation'>La regulation</MenuItem>
              </Menu>
              </Grid>
              <Grid item>
                <Link to="/Actualite" className={classes.link_style}>
                  <Typography>Actualités et Agenda</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link to="/Video" className={classes.link_style}>
                  <Typography>Videos</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link to="/Clec" className={classes.link_style}>
                  <Typography>CLEC</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link to="/Members" className={classes.link_style}>
                  <Typography>Membres</Typography>
                </Link>
              </Grid>
              <Grid item>
                <MenuItem>
                  
                  <img src={congo} width="auto" height='45' alt="Flag"/>
                </MenuItem>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
        <Grid container style = {{backgroundColor:"#0000"}}>
          <Grid item xs={12}>
            <Breadcrumb {...props} />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              {message === "Network Error" && <Collapse in={open}>
                <Alert
                  severity={'error'}
                  className={classes.alert}
                >
                  No network
                </Alert>
              </Collapse>}
            </Grid>

          </Grid>
        </Grid>

      </AppBar>
      {renderMobileMenu}
    </React.Fragment>
  );
}
