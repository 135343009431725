import React,{ useEffect } from 'react';
import Image1 from '../../assets/images/clec/IMG_5735.JPG'
import Image2 from '../../assets/images/clec/Picture1.JPG'
import Image3 from '../../assets/images/clec/IMG_1616.JPG'
import Image4 from '../../assets/images/clec/IMG_2697.JPG'
import Image5 from '../../assets/images/clec/IMG_5755.JPG'

import ClecTemp from '../../widgets/clecComp'
import SEO from '../../sc/seo'



const Clec =()=>{


    const clecs = [
            {
                title:"CLEC KABARE",
                Body:"Le Territoire de KABARE est une entité administrative déconcentrée de la province du Sud-Kivu avec comme Chef-lieu la localité de KABARE.<br/><br/>Après une importante campagne de sensibilisation en date du 28 mars 2021 à Kavumu à travers l'émission <q> En direct du village </q> en partenariat avec Radio MAENDELEO et avec l'appui du projet PROMOST financé par la Coopération suisse et mis en œuvre par Swisscontact, un grand intérêt a été suscité auprès de la population pour l'importance du secteur de la formation et de l'insertion professionnelle. Les acteurs locaux ont exprimé dans toutes leurs interventions, la ferme volonté de s'unir pour prendre en main la destinée du secteur de l’ETFP dans leur région, secteur qu'ils considèrent comme prioritaire pour le développement socio-économique. <br/><br/>Ceci a rencontré le consentement du Comité de Coordination Collégiale (CCC) du CEC-FPI qui avait envisagé la mise en place de CLEC dans certaines zones stratégiques de la province du Sud-Kivu. Enfin, le 1<sup>er</sup> avril 2021, le processus de mise en place du CLEC de KAVUMU a été lancé par une séance de sensibilisation et de formation fourni par l'inspecteur Dr Fernand HEMBI et de Mme Tharcille NABINTU. Quelques semaines plus tard, le CLEC de KAVUMU a finalement été mis en place par une délégation du CEC-FPI conduite par son vice-président.",
                image:Image5
            },
            {
                title:"CLEC WALUNGU",
                Body: "Le CLEC WALUNGU a été définitivement implanté en date du 12 août 2021 à WALUNGU, avec l’élection d’un premier comité composé de 9 membres.<br/><br/>C’est fut après des activités de sensibilisation menée par Monsieur Pierre Canisius MONDO, secrétaire du CEC-FPI qui a réuni à deux reprises tous les acteurs clés du secteur de la Formation Professionnelle et de l’Insertion à WALUNGU autour des activités de sensibilisation et formation sur l’implantation d’un CLEC. En attendant leur intégration totale au sein du CEC-FPI, à l’occasion de l’AG extraordinaire prévu au mois d’octobre, le CLEC WALUNGU a directement amorcé ses activités notamment en élaborant son règlement d’ordre intérieur et un plan d’action annuelle selon le modèle proposé par le CEC-FPI. Ces documents laissent entrevoir une bonne suite de ses activités en faveur de la Formation Professionnelle et de l’insertion dans ce vaste territoire.  ",
                image:Image3
            },
            {
                title:"CLEC KALEHE",
                Body: "Après les séances de prise de contact et de sensibilisation menées par Monsieur Cibey MAGADJU, membre du CCC, il a été organisé en date du 21 août 2021 l’activité finale d’implantation du CLEC KALEHE à IHUSI. Un comité a été élu pour conduire les activités du dit CLEC. Au vu de la grandeur de ce territoire, les animateurs élus ont pris l’engagement d’implanter des sous CLEC au niveau des groupements éloignés du centre d’IHUSI-TERRITOIRE. En attendant l’activité d’intégration envisagée pour le mois d’octobre, le CLEC KALEHE a également adopté son règlement d’ordre intérieur et son plan d’action opérationnelle annuelle en rassurant que tous les efforts seront de mise pour le développement rapide du secteur de la formation professionnelle et de l’Insertion. ",
                image:Image2
            },
            {
                title:"CLEC IDJWI",
                Body: "Implanté définitivement le 15 août 2021 après des séances de sensibilisation et de formation, le CLEC IDJWI est désormais opérationnel dans l’axe Nord du territoire d’IDJWI.<br/><br/>Après le lancement d’activités de formations professionnelles à travers le projet PROMOST III (Promouvoir le développement des compétences et la création d'emplois dans la région des Grands Lacs - Phase 3), financé par la Coopération suisse et mis en œuvre par Swisscontact à IDJWI, le CEC-FPI par l’entremise de son vice-président, a permis de procéder à une grande séance de sensibilisation sur le secteur de l’ETFP et l’insertion en faveur d’une trentaine d’acteurs clés de ce dernier. C’est à cette occasion que le processus d’implantation du CLEC IDJWI a été lancé.",
                image:Image4
            },
            {
                title:"CLEC NYANGEZI",
                Body: "Le Groupement de NYANGEZI est l’une des grandes agglomérations du territoire de WALUNGU et qui se trouve très éloigné du chef-lieu. <br/><br/>Suivant l'exemple de KAVUMU, une importante activité de sensibilisation a été organisée en partenariat avec RADIO MAENDELEO et son programme <q>En direct du village</q> soutenu par le projet PROMOST, dans le centre de NYANGEZI. Cela a permis au CEC-FPI de sensibiliser les acteurs clés du système de l’ETFP à NYANGEZI. C'était le 25 avril 2021 qui a marqué le début du processus de mise en œuvre du sous-CLEC de NYANGEZI. <br/><br/>Compte tenu de la distance géographique du groupement de NYANGEZI par rapport à la ville de Bukavu et des activités du projet PROMOST III sur cet axe principal, le CCC a soutenu la création du sous-CLEC NYANGEZI sur demande des acteurs locaux de la zone.",
                image:Image1
            }
        ];

    const actualite = [{},{},{}]
    
    useEffect(() => {
        window.scrollTo(0, 0)
    
      }, [])
    

    return (
        <React.Fragment>
            <SEO
                title="CLEC"
                keywords="CLEC"
            />
            <ClecTemp
                title="CLEC"
                Body="Afin d'assurer une bonne coordination du système de formation professionnelle dans les huit zones de la province du Sud-Kivu, il est important que les principaux acteurs du secteur soient pris en compte dans les zones rurales et qu'ils disposent également de plateformes d'échange locales. Ainsi, le Cadre d'Echange et de Concertation des acteurs clés du système de la Formation Professionnelle et de l'Insertion (CEC-FPI) a favorisé la mise en place de CLEC (Cadres locaux d'échanges et de concertations) dans certains centres économiques et sur les grands axes. Les CLEC sont membre à part entière du CEC-FPI et leur mode de fonctionnement est similaire à ce dernier.<br/><br/>Pour des raisons stratégiques, 4 CLEC sont actuellement opérationnels, à savoir : le <em>CLEC KABARE</em> , le <em>CLEC WALUNGU</em> , le <em>CLEC KALEHE</em> , le <em>CLEC IDJWI</em> et un sous-CLEC à <em>NYANGEZ</em>I. La création d’un prochain CLEC est prévu à <em>UVIRA</em>."
                List={clecs}
                ActuTitle="Les actualités du CLEC"
                actualites={actualite}
            />
        </React.Fragment>
    )
}

export default Clec