import React,{useState} from 'react'
import Backpaper from '../../../sc/backPaper'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {Grid,Box ,Collapse,Tab,Tabs,IconButton,Typography} from '@material-ui/core'
import News from '../../../sc/news';
import NewsSkeleton from '../../../sc/newsSkeleton';
import { useHistory } from 'react-router';
import {BtnOrange} from '../../../sc/utilities'
import {useSelector,useDispatch} from 'react-redux'
import DialogBox from '../../../sc/dialog';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {getActualite,deleteActualite} from '../../../../../store/actions/actualite-actions'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { Pagination } from "@material-ui/lab";
import PropTypes from "prop-types";
import style from './style';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
      >
        {value === index && (
          
            <Typography>{children}</Typography>
         
        )}
      </div>
    );
  }
  
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };
  

const Actualite =()=>{

    
    const history = useHistory()
    const  {actualite} = useSelector((state)=>state.actualite)
    const [open, setOpen] = useState(false);
    const [opens, setOpens] = React.useState(false);
    const theme = useTheme();
    const [isDelete, setisDelete] = useState(false)
    const [er, seter] = useState(false)
    const dispatch = useDispatch()
    const [value, setValue] = React.useState(0);
    const msg = useSelector((state)=>state.msg.message)
    const deleteAct = useSelector((state) => state.actualite.deleteactualite)
    const [currentPage, setCurrentPage] = React.useState(1);
    const newsPerPages = 6
    const classes = style()

   
  const handleCount = (length, numberPerPage) => (length % numberPerPage) > 0 ? parseInt(length / numberPerPage) + 1 : parseInt(length / numberPerPage)
  const handlePageChange = (value, update) => {
    update(value)
    window.scrollTo(0,0)
  }
    const handleDetail =(id,title)=>{
        history.push({
            pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/actualite/detail/${title}=${id}`,
           
        })
        sessionStorage.setItem('rowId',id)
    }
    const handleCreate =()=>{
        history.push(`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/actualite/create`)
    }
    const handleDelete=(id)=>{

        setisDelete(true)
        dispatch(deleteActualite(id)).then(()=>{
            dispatch(getActualite())
            setOpen(false);
            setOpens(true)
            setisDelete(false)
        },()=>{
            setOpen(false);
            setOpens(true)
            setisDelete(false)
            seter(true)
        })

    }
    const handleModifier=(id,)=>{
        history.push({
            pathname:`/${process.env.REACT_APP_DASHBOARD_TOKEN}/dashboard/actualite/create`,
            data:id
        })
    }
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        setOpen(false);
       
      
      };
    const handleClickOpen = () => {
       setOpen(true);
     };
    
     const tablabel=[
        {
           title:'ACTUALITES APPROUVÉES',
           num :actualite!==undefined?actualite.filter((e)=>e.approval===true).length:null ,
           css: {backgroundColor:'#F2D006',color:'white', padding:'3px', margin:'5px' ,fontWeight:300}
         },
         {
         title:'ACTUALITES A APPROUVER',
         num : actualite!==undefined?actualite.filter((e)=>e.approval===false).length:null,
         css: {backgroundColor:'#F1545B',color:'white', padding:'3px', margin:'5px' ,fontWeight:300}
       },
     ]
     const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem("value",0)
      };
    
    
   if (actualite!==undefined){
    return (
        <Backpaper
            title={"Actualités"}
            yellowbtn = {<BtnOrange onClick={handleCreate} btnYellowTitle="CRÉER" startIcon={<AddCircleOutlineIcon style={{color:'white',fontSize:14}}/>} />}

            content={
               <div>
                   <Box height={20}/>
                        <Collapse in={opens}>
                            <Alert
                            severity={er?'error':'success'}
                            action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpens(false);
                                    seter(false)
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            >
                        { er===true?msg:deleteAct}
                    </Alert>
                </Collapse>
                <Box height={20}/>
                    
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        {/* <Paper elevation={0} className={classes.backPaper}> */}
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Tabs
                                    disableRipple
                                    value={value}
                                    onChange={handleChange}
                                    TabIndicatorProps={{ className: classes.indicatedColor }}
                                    variant="fullWidth"
                                    style={{color:'black'}}
                                    scrollButtons="on" >
                                        {tablabel.map(tab => (
                                        <Tab
                                        key={tab}
                                        label={<Typography className={classes.tab}>{tab.title}<span style={tab.css}>{tab.num}</span></Typography>} />))}
                                </Tabs> 
                                </Grid>
                                
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box height={20}/>
                                <TabPanel value={value} index={0}>
                                <Grid container direction='row' spacing={4}>
                                {   
                                    actualite?
                                    actualite.filter((e)=>e.approval===true).slice((currentPage - 1) * newsPerPages, currentPage * newsPerPages).map((e)=>(
                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                            <News 
                                                image={e.cover_image}
                                                title={e.title}
                                                desc={e.description}
                                                publishedDate={e.created_date}
                                                onClick={()=>{handleDetail(e.id,e.title)}}
                                                handleModifie={()=>{handleModifier(e.id)}}
                                                handledelete={handleClickOpen}
                                            />
                                            <DialogBox
                                                fullScreen={fullScreen}
                                                open={open}
                                                onClose={handleClose}
                                                //title= {`${userbyId.first_name}`}
                                                Oui={isDelete===true?(<CircularProgress size={10}/>):'Oui'}
                                                confirnClick={()=>handleDelete(e.id)}
                                                nonOnclick={handleClose}
                                                content='Etês-vous sûr de vouloir supprimer cette actualité'
                                            />
                                        </Grid>
                                    )):
                                    (
                                        <div>
                                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                <NewsSkeleton/>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                <NewsSkeleton/>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                <NewsSkeleton/>
                                            </Grid>
                                        </div>
                                    )
                                }
                                    {
                                        actualite &&
                                        <Grid container justifyContent="center">
                                            <Grid item style={{ margin: "10px 0px" }}>
                                                <Pagination size="small" count={handleCount(actualite.length, newsPerPages)} page={currentPage} onChange={(event, value) => handlePageChange(value, setCurrentPage)} />
                                            </Grid>
                                        </Grid>
                                    }
                            
                                
                                
                            </Grid>
                                    </TabPanel>
                                    <Box height={20}/>
                                    <TabPanel value={value} index={1}>
                                    <Grid container direction='row' spacing={4}>
                                {   
                                    actualite?
                                    actualite.filter((e)=>e.approval===false).slice((currentPage - 1) * newsPerPages, currentPage * newsPerPages).map((e)=>(
                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                            <News 
                                                image={e.cover_image}
                                                title={e.title}
                                                desc={e.description}
                                                publishedDate={e.created_date}
                                                onClick={()=>{handleDetail(e.id,e.title)}}
                                                handleModifie={()=>{handleModifier(e.id)}}
                                                handledelete={handleClickOpen}
                                            />
                                            <DialogBox
                                                fullScreen={fullScreen}
                                                open={open}
                                                onClose={handleClose}
                                                //title= {`${userbyId.first_name}`}
                                                Oui={isDelete===true?(<CircularProgress size={10}/>):'Oui'}
                                                confirnClick={()=>handleDelete(e.id)}
                                                nonOnclick={handleClose}
                                                content='Etês-vous sûr de vouloir supprimer cette actualité'
                                            />
                                        </Grid>
                                    )):
                                    (
                                        <div>
                                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                <NewsSkeleton/>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                <NewsSkeleton/>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                <NewsSkeleton/>
                                            </Grid>
                                        </div>
                                    )
                                }
                                    {
                                        actualite &&
                                        <Grid container justifyContent="center">
                                            <Grid item style={{ margin: "10px 0px" }}>
                                                <Pagination size="small" count={handleCount(actualite.length, newsPerPages)} page={currentPage} onChange={(event, value) => handlePageChange(value, setCurrentPage)} />
                                            </Grid>
                                        </Grid>
                                    }
                            
                                
                                
                            </Grid>
                                    </TabPanel>
                                </Grid>
                            </Grid>
                        {/* </Paper> */}
                        
                    </Grid>
                </Grid>
               </div>
            }
            
        />
    )
        }
    else if(actualite!==undefined){
        return (
            <Backpaper
            title={"Actualites"}
            yellowbtn = {<BtnOrange  onClick={handleCreate} btnYellowTitle="CRÉER" startIcon={<AddCircleOutlineIcon style={{color:'white',fontSize:14}}/>} />}

            content={
                <div>
                    <Box height={20}/>
                        <Collapse in={opens}>
                            <Alert
                            severity={er?'error':'success'}
                            action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpens(false);
                                    seter(false)
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            >
                        { er===true?msg:deleteAct}
                    </Alert>
                </Collapse>
                <Box height={20}/>
                
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        {/* <Paper elevation={0} className={classes.backPaper}> */}
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Tabs
                                    disableRipple
                                    value={value}
                                    onChange={handleChange}
                                    TabIndicatorProps={{ className: classes.indicatedColor }}
                                    variant="fullWidth"
                                    style={{color:'black'}}
                                    scrollButtons="on" >
                                        {tablabel.map(tab => (
                                        <Tab
                                        key={tab}
                                        label={<Typography className={classes.tab}>{tab.title}<span style={tab.css}>{tab.num}</span></Typography>} />))}
                                </Tabs> 
                                </Grid>
                                
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box height={20}/>
                                <TabPanel value={value} index={0}>
                                    <Grid container justifyContent='center'  alignItems='center' style={{paddingTop:70,paddingBottom:70}}>
                                        <Grid item>
                                            <Typography align='center'>
                                                Pas post  d'actualité 
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    </TabPanel>
                                    <Box height={20}/>
                                    <TabPanel value={value} index={1}>
                                        <Grid container justifyContent='center'  alignItems='center' style={{paddingTop:70,paddingBottom:70}}>
                                            <Grid item>
                                                <Typography align='center'>
                                                    Pas post  d'actualité 
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                </Grid>
                            </Grid>
                        {/* </Paper> */}
                        
                    </Grid>
                </Grid>
                </div>
            }
            
        />
        )
    }

    return (
        <Backpaper
            title={"Actualités"}
            yellowbtn = {<BtnOrange disabled onClick={handleCreate} btnYellowTitle="CRÉER" startIcon={<AddCircleOutlineIcon style={{color:'white',fontSize:14}}/>} />}

            content={
                <Grid container direction='row' spacing={4}>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <NewsSkeleton/>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <NewsSkeleton/>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <NewsSkeleton/>
                    </Grid>
                </Grid>
            }
            
        />
    )
}
   



export default Actualite